import { AddCommentPropertyButton } from "@app/core/comments/components/buttons/add-comment/components/add-comment-property/_index";
import { PropertyComments } from "@app/core/comments/components/property-comments/_index";
import { MailMergeButtonManagePage } from "@app/core/new-mail-merge/buttons/mail-merge-manage-page/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AddAttachmentPropertyButton } from "@app/products/property/components/action-bar/button/add-attachment/_index";
import { PropertyAssociations } from "@app/products/property/components/associations/_index";
import { eComponent } from "@app/products/property/components/associations/model";
import { PropertyActions } from "@app/products/property/components/child-screen/actions";
import { PropertyDocuments } from "@app/products/property/components/child-screen/documents/_index";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ECustomColNameProperty } from "@app/products/property/config";
import { eAccordion } from "@app/products/property/model";
import { ToggleLevyCalculationsButton } from "@app/products/property/supplementary-rates/[id]/components/buttons/tools/toggle-levy-calculations/_index";
import { AddAssessmentButton } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/assessment/add/_index";
import { RemoveAssessmentButton } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/assessment/remove/_index";
import { AddSupplementaryMasterPropertyButton } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/master-property/_index";
import { MasterPropertyAdjustmentButton } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/master-property/adjustment/_index";
import { CompleteIncompleteButton } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/supplementary-rates/complete-incomplete/_index";
import { DeleteSupplementaryButton } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/supplementary-rates/delete-suplementary/_index";
import { ModifySupplementaryButton } from "@app/products/property/supplementary-rates/[id]/components/buttons/workflow/supplementary-rates/modify-supplementary/_index";
import { SupplementaryRatesAssessment } from "@app/products/property/supplementary-rates/[id]/components/child-screens/assessment/_index";
import { SupplementaryRatesBalanceTransfers } from "@app/products/property/supplementary-rates/[id]/components/child-screens/balance-transfers/_index";
import { SupplementaryRatesCharges } from "@app/products/property/supplementary-rates/[id]/components/child-screens/charges/_index";
import { SupplementaryRatesDetails } from "@app/products/property/supplementary-rates/[id]/components/child-screens/detail/_index";
import { SupplementaryRatesGeneral } from "@app/products/property/supplementary-rates/[id]/components/child-screens/general/_index";
import { SupplementaryRatesLevies } from "@app/products/property/supplementary-rates/[id]/components/child-screens/levies/_index";
import { SupplementaryMasterProperties } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/_index";
import { SupplementaryTransactions } from "@app/products/property/supplementary-rates/[id]/components/child-screens/transaction/_index";
import { SupplementaryRatesValuations } from "@app/products/property/supplementary-rates/[id]/components/child-screens/valuations/_index";
import { SupplementaryRatesDetailTab } from "@app/products/property/supplementary-rates/[id]/components/reference-sidebar/detail/_index";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { AssessmentAdjustmentsButton } from "@app/products/property/supplementary-rates/components/action-bar/buttons/assessment-adjustments/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { CommunityProperty, Label } from "@common/stores/products/config";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCGeneralPanel } from "@components/cc-general-panel/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { eAccordionType } from "@components/custom-panelbar/model";
import Loading from "@components/loading/Loading";
import { isUndefined } from "lodash";
import { observer } from "mobx-react-lite";
import { default as React, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedSupplementaryRates = observer(() => {
  const { isLLS } = CommunityProperty.getFlagOfStates();
  const { supplementaryAssessments, isLoading } = useSupplementaryRatesStore();
  const { responseLoadError, loadSupplementaryRates } =
    useSupplementaryRatesStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const isComplete = useMemo(() => {
    return supplementaryAssessments?.SupplementaryDetail?.IsComplete;
  }, [supplementaryAssessments]);

  const hasAssessments = useMemo(() => {
    return supplementaryAssessments?.SupplementaryDetail?.HasAssessments;
  }, [supplementaryAssessments]);

  //Get label
  const assessmentLabel = Label.CommunityProperty.getLabel(
    ECustomColNameProperty.Assessment
  );

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `Supplementary ${id ?? ""}`,
      LinkUrl: managePageUrl,
      LinkText: `Property - Supplementary Rates - ${id}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.CommunityProperty,
      RecordType_ENUM: RECORDTYPE.CommunityProperty_Unknown,
      Record_ID: +id,
    },
  ];

  const listPanelBar: IPropertyPanelBar[] = [
    {
      accordionId: eAccordion.Supplementary_Actions_And_Workflows,
      title: "Actions and Workflows",
      component: (
        <PropertyActions
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Supplementary}
          componentNumber={eComponent.Supplementary}
        />
      ),
      accordionType: eAccordionType.Actions_And_Workflows,
    },
    // Requirement: https://dev.azure.com/openofficeau/Radium/_workitems/edit/12802/
    // {
    //   title: "Audit",
    //   component: (
    //     <PropertyAudit
    //       id={parseInt(id)}
    //       componentNumber={eComponent.Supplementary}
    //     />
    //   ),
    // },
    {
      accordionId: eAccordion.Supplementary_Details,
      title: "Details",
      component: <SupplementaryRatesDetails />,
    },
    {
      accordionId: eAccordion.Supplementary_Assessment,
      title: assessmentLabel,
      component: <SupplementaryRatesAssessment />,
      isExpanded: true,
    },
    {
      accordionId: eAccordion.Supplementary_Levies,
      title: "Levies",
      component: <SupplementaryRatesLevies />,
    },
    {
      accordionId: eAccordion.Supplementary_Charges,
      title: "Charges",
      component: <SupplementaryRatesCharges />,
    },
    {
      accordionId: eAccordion.Supplementary_Balance_Transfers,
      title: "Balance Transfers",
      component: <SupplementaryRatesBalanceTransfers />,
      isVisible: !isLLS,
    },
    {
      accordionId: eAccordion.Supplementary_Related,
      title: "Related",
      component: (
        <PropertyAssociations
          id={parseInt(id)}
          componentNumber={eComponent.Supplementary}
        />
      ),
    },
    {
      accordionId: eAccordion.Supplementary_Transactions,
      title: "Transactions",
      component: <SupplementaryTransactions />,
    },
    {
      accordionId: eAccordion.Supplementary_Master_Properties,
      title: "Master Properties",
      component: <SupplementaryMasterProperties />,
      isVisible: !isLLS,
    },
    {
      accordionId: eAccordion.Supplementary_Documents,
      title: "Documents",
      component: (
        <PropertyDocuments
          componentId={parseInt(id)}
          componentNumber={eComponent.Supplementary}
        />
      ),
    },
    {
      accordionId: eAccordion.Supplementary_Comments,
      title: "Comments",
      component: (
        <PropertyComments
          id={parseInt(id)}
          recordType={RECORDTYPE.CommunityProperty_Supplementary}
        />
      ),
    },
    {
      accordionId: eAccordion.Supplementary_Valuations,
      title: "Valuations",
      component: <SupplementaryRatesValuations />,
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"SUPPLEMENTARY RATES"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadSupplementaryRates(+id)}
        />
      ) : (
        <>
          <FormTitle
            title={`${id} - ${supplementaryAssessments?.SupplementaryDetail?.Name}`}
            badge={
              !isUndefined(isComplete)
                ? isComplete
                  ? "Completed"
                  : "Incomplete"
                : ""
            }
          />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <CCNavButton title={"Supplementary rates"} type="dropdown">
                  {/* Complete button */}
                  <CompleteIncompleteButton
                    isSupplementaryComplete={isComplete}
                  />
                  {/* Incomplete button */}
                  <CompleteIncompleteButton
                    isSupplementaryComplete={isComplete}
                    isCompleteButton={false}
                  />

                  <DeleteSupplementaryButton
                    isDisabled={isComplete || hasAssessments}
                  />
                  <ModifySupplementaryButton />
                </CCNavButton>
                <CCNavButton title={assessmentLabel} type="dropdown">
                  <AddAssessmentButton />
                  <AssessmentAdjustmentsButton />
                  <RemoveAssessmentButton />
                </CCNavButton>
                <CCNavButton title="Master property" type="dropdown">
                  <AddSupplementaryMasterPropertyButton />
                  <MasterPropertyAdjustmentButton />
                </CCNavButton>
              </CCNavButton>,

              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Supplementary}
                />
                <AddAttachmentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Supplementary}
                />
                <AddCommentPropertyButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CommunityProperty_Supplementary}
                />
                {/* TODO: will be shown when it has a function */}
                {/* <CCNavButton title={"Contact"} /> */}
                <MailMergeButtonManagePage
                  productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
                  dataSetFilter={
                    MAILMERGEDATASET.COMMUNITYPROPERTY_Supplementary
                  }
                  recordType={RECORDTYPE.CommunityProperty_Supplementary}
                  isEmptyGridSelectedRow={!parseInt(id)}
                  selectedRecordIds={[parseInt(id)]}
                />
              </CCNavButton>,
              <CCNavButton title="Tools" type="sub">
                <ToggleLevyCalculationsButton />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,

              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                <CCGeneralPanel
                  isExpanded={false}
                  isKeepMounted={false}
                  component={<SupplementaryRatesGeneral />}
                />
                <PropertyPanelBar
                  initialPanels={listPanelBar}
                  componentId={parseInt(id)}
                  componentNumber={eComponent.Supplementary}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <SupplementaryRatesDetailTab />,
                    },
                    {
                      title: "History",
                      component: (
                        <PropertyHistoryTab
                          recordType={
                            RECORDTYPE.CommunityProperty_Supplementary
                          }
                        />
                      ),
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
