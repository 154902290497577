import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import {
  EInstalmentPlanOption,
  instalmentPlanData,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/instalments/config";
import { useNoticeRunAttributeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-run-attributes/store";
import { isShowWithNoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/utils";
import { ECustomColNameProperty } from "@app/products/property/config";
import { DATE_FORMAT } from "@common/constants/common-format";
import { DTO_LOV_Number } from "@common/models/odataResponse";
import { Label } from "@common/stores/products/config";
import { validatorDateValueWithMinMax } from "@common/utils/field-validators";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";
import { listTypeShowAssessmentPaying } from "./config";

export const NoticeRunInstalmentsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    const { valueGetter, onChange } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    //store
    const { attributeLOVs } = useNoticeRunAttributeStepStore();
    const instalmentPlanLov = useMemo(() => {
      return attributeLOVs?.InstalmentPlans ?? [];
    }, [attributeLOVs?.InstalmentPlans]);

    const handleValidateFromDate = useCallback(
      (value: Date) => {
        return validatorDateValueWithMinMax(
          value,
          null,
          valueGetter(nameOf("DueTo"))
        );
      },
      [valueGetter, nameOf]
    );

    const handleValidateToDate = useCallback(
      (value: Date) => {
        return validatorDateValueWithMinMax(
          value,
          valueGetter(nameOf("DueFrom")),
          null
        );
      },
      [valueGetter, nameOf]
    );

    //Get label
    const assessmentLabelLowercase = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.AssessmentLowercase
    );

    const handleSelectionByInstalmentPlan = (event: RadioGroupChangeEvent) => {
      if (event?.value === EInstalmentPlanOption.NoSelection) {
        onChange(nameOf("InstalmentPlans"), { value: [] });
      }
      onChange(nameOf("_option.SelectionByInstalmentPlan"), {
        value: event?.value,
      });
      onChange(nameOf("SpecificRange"), {
        value:
          event?.value === EInstalmentPlanOption.NoSelection ? false : true,
      });
    };
    const valueSelectionByInstalmentPlan =
      getFieldValue("_option.SelectionByInstalmentPlan") ??
      EInstalmentPlanOption.NoSelection;

    const isDefaultInstalmentPlanUsed = getFieldValue(
      "_option.IsDefaultInstalmentPlanUsed"
    );
    const isDisabledInstalmentPlan = useMemo(() => {
      return (
        valueSelectionByInstalmentPlan === EInstalmentPlanOption.NoSelection ||
        isDefaultInstalmentPlanUsed
      );
    }, [valueSelectionByInstalmentPlan, isDefaultInstalmentPlanUsed]);

    return (
      <div className="cc-form">
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Selection by instalment plan</label>
              <Field
                name={nameOf("_option.SelectionByInstalmentPlan")}
                component={CCRadioGroup}
                data={instalmentPlanData}
                disabled={options?.isReadOnly || isDefaultInstalmentPlanUsed}
                onChange={handleSelectionByInstalmentPlan}
              />
            </div>
          </div>
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">Due</label>
              <Field
                name={nameOf("DueFrom")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                disabled={options?.isReadOnly}
                max={
                  getFieldValue("DueTo")
                    ? new Date(getFieldValue("DueTo"))
                    : undefined
                }
                validator={
                  !options?.isReadOnly ? handleValidateFromDate : undefined
                }
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">To</label>
              <Field
                name={nameOf("DueTo")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                disabled={options?.isReadOnly}
                min={
                  getFieldValue("DueFrom")
                    ? new Date(getFieldValue("DueFrom"))
                    : undefined
                }
                validator={
                  !options?.isReadOnly ? handleValidateToDate : undefined
                }
              />
            </div>
          </div>
          <div className="cc-field">
            <label className="cc-label">Instalment plan</label>
            <Field
              name={nameOf("InstalmentPlans")}
              component={CCMultiSelectDropdown}
              data={instalmentPlanLov ?? []}
              onChange={(event: MultiSelectChangeEvent) => {
                let value = event?.value;
                if (value?.length) {
                  value = value.map((item: DTO_LOV_Number) => item.Code);
                }
                onChange(nameOf("InstalmentPlans"), {
                  value: value,
                });
              }}
              value={getMultipleSelectValue(
                getFieldValue("InstalmentPlans"),
                instalmentPlanLov ?? [],
                "Code"
              )}
              textField="Name"
              dataItemKey="Code"
              disabled={options?.isReadOnly || isDisabledInstalmentPlan}
            />
          </div>
          {isShowWithNoticeType(listTypeShowAssessmentPaying, valueGetter) && (
            <div className="cc-field">
              <label className="cc-label">
                {`Only ${assessmentLabelLowercase}s paying in full`}
              </label>
              <Field
                name={nameOf("OnlyAssessmentsPayingInFull")}
                checked={getFieldValue("OnlyAssessmentsPayingInFull")}
                component={CCSwitch}
                disabled={options?.isReadOnly}
              />
            </div>
          )}
        </section>
      </div>
    );
  }
);
