import { CreateRequests } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/dialogs/create-requests/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const CreateRequestsButton = () => {
  const [showCreateRequests, setShowCreateRequests] = useState(false);

  return (
    <>
      <CCNavButton
        title="Create requests"
        onClick={() => setShowCreateRequests(true)}
      />

      {showCreateRequests && (
        <CreateRequests
          onClose={() => {
            setShowCreateRequests(false);
          }}
        />
      )}
    </>
  );
};
