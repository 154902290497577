import { Addresses } from "@app/core/addresses/_index";
import { AddAdditionalAddressButton } from "@app/core/addresses/components/buttons/add-additional-address/_index";
import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { FurtherInformationRequest } from "@app/core/further-info/[id]/model";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { AddRelatedButton } from "@app/core/related/related-button/_index";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { ViewNearMapButton } from "@app/core/view-on-map/components/buttons/view-near-map/_index";
import { ViewVICPlanButton } from "@app/core/view-on-map/components/buttons/view-vic-plan/_index";
import { EPPRProductType } from "@app/core/view-on-map/components/buttons/view-vic-plan/config";
import { ViewDocumentsButton } from "@app/core/view/components/buttons/view-documents/_index";
import { PlanningEnquiryButton } from "@app/products/town-planning/ppr/[id]/components/buttons/add-to-register/planning-enquiry/_index";
import { AddAppealButton } from "@app/products/town-planning/ppr/[id]/components/buttons/add/appeal/_index";
import { DecisionDetailsButton } from "@app/products/town-planning/ppr/[id]/components/buttons/more/decision-details/_index";
import { PlansToComplyButton } from "@app/products/town-planning/ppr/[id]/components/buttons/plans-to-comply/_index";
import { PPRChangeApplicationNumberButton } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-application-number/_index";
import { ChangeDecisionButton } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-decision/_index";
import { ChangedLodgedDateButton } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-lodged-date/_index";
import { PPRChangeStatusButton } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/change-status/_index";
import { ClearReportLinkButton } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/clear-report-link/_index";
import { ClearResponseLinkButton } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/clear-response-link/_index";
import { UndoAppealCompleteButton } from "@app/products/town-planning/ppr/[id]/components/buttons/tools/undo-appeal-complete/_index";
import { AllocateToOfficerButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/allocate-to-officer/_index";
import { AmendApplicationButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/amend-application/_index";
import { AmendPermitButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/amend-permit/_index";
import { AppealCompleteApplicationButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appeal-complete-application/_index";
import { AppealWithdrawnButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appeal-withdrawn/_index";
import { AppealedButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/appealed/_index";
import { ApproveLetterApplicationButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/approve-letter-application/_index";
import { ApproveReportAndResponseButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/approve-report-and-response/_index";
import { CreateApplicationReportButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/create-application-report/_index";
import { CreateResponseLetterButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/create-response-letter/_index";
import { FiNotRequiredButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/fi-not-required/_index";
import { LodgeApplicationButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/lodge-application/_index";
import { NoAppealButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/no-appeal/_index";
import { OfficerAssessmentButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/officer-assessment/_index";
import { PermitCompletedButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/permit-completed/_index";
import { RejectLetterApplicationButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/reject-letter-application/_index";
import { ReportAndResponseNotApprovedButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/report-and-response-not-approved/_index";
import { RequestFIButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/request-fi/_index";
import { ResendForTLApprovalButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/resend-for-tl-approval/_index";
import { SendForTLApprovalButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-for-tl-approval/_index";
import { SendLetterToRAandApplicantButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-letter-to-ra-applicant/_index";
import { SendReptAndRespLetterForApprovalButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-rept-and-resp-letter-for-approval/_index";
import { SendResponseToRAAndApplicantButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/send-response-to-ra-and-applicant/_index";
import { WithDrawApplicationButton } from "@app/products/town-planning/ppr/[id]/components/buttons/work-flow/withdraw-application/_index";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { Appeals } from "@app/products/town-planning/ppr/[id]/components/child-screens/appeals/_index";
import { PPREnquiries } from "@app/products/town-planning/ppr/[id]/components/child-screens/enquiries/_index";
import { PPRFurtherInfo } from "@app/products/town-planning/ppr/[id]/components/child-screens/further-info/_index";
import { PPRForm } from "@app/products/town-planning/ppr/[id]/components/child-screens/general/_index";
import { PPRPlanToComply } from "@app/products/town-planning/ppr/[id]/components/child-screens/plans-to-comply/_index";
import { PPRRelated } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/_index";
import { IRelatedParams } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/model";
import { PPRContactsTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/contact/_index";
import { PPRDetailTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/detail/_index";
import { PPRHistoryTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/history/_index";
import { PPRViewOnMapTab } from "@app/products/town-planning/ppr/[id]/components/reference-sidebar/view-on-map/_index";
import { PPRSubmitActions } from "@app/products/town-planning/ppr/[id]/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { processDataParentSentToAppeal } from "@app/products/town-planning/ppr/[id]/util";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { AddFIRequestButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/fi-request/_index";
import { AddMeetingButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/meeting/_index";
import { AddReferralButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/referral/_index";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Action } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import {
  CCNavButton,
  ICCNavButtonProps,
} from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedPPR = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const {
    ppr,
    loadPPR,
    isLoading,
    responseLoadError,
    pprId,
    onSubmit,
    isInactive,
  } = usePPRStore();
  const { settings } = useCommonCoreStore();
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const defaultFIDays = getNumberValueSetting(
    settings[ECorporateSettingsField.TP_StatDaysFIDays]
  );

  const relatedAccordionParams = useMemo(() => {
    if (pprId) {
      const data: IRelatedParams = {
        RecordType: RECORDTYPE.TP_PPRApplication,
        RecordID: pprId,
        InvoiceNumber: null,
        AssessmentNumberKey: null,
        AddressLine1: null,
        Suburb: null,
        PersonKey: null,
        WasteWaterSchemeKey: null,
        AncestorRecordType: null,
        AncestorRecordId: null,
      };

      if (ppr?.SiteAddress) {
        data.AssessmentNumberKey = ppr.SiteAddress.AssessmentNo;
        data.AddressLine1 = ppr.SiteAddress.Formatted_AddressLine1;
        data.Suburb = ppr.SiteAddress.Suburb;
      }

      if (ppr?.Applicant && ppr.Applicant.Contact) {
        data.PersonKey = ppr.Applicant.Contact.Contact_ID;
      }

      return data;
    }
    return undefined;
  }, [ppr, pprId]);

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        ppr?.ApplicationNumberDisplay,
        ppr?.SiteAddress?.Formatted_SingleLine,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `PPR - Register - ${ppr?.Application_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.TownPlanning,
      RecordType_ENUM: RECORDTYPE.TP_PPRApplication,
      Record_ID: pprId ?? 0,
    },
  ];

  const listPanelBar: ITabProps[] = [
    {
      title: "Addresses",
      tabType: TabTableType.Addresses,
      component: (
        <Addresses id={pprId} recordType={RECORDTYPE.TP_PPRApplication} />
      ),
    },
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions id={pprId} recordType={RECORDTYPE.TP_PPRApplication} />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={pprId} recordType={RECORDTYPE.TP_PPRApplication} />
      ),
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion
          id={pprId}
          recordType={RECORDTYPE.TP_PPRApplication}
        />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={pprId} recordType={RECORDTYPE.TP_PPRApplication} />
      ),
    },
    {
      title: "Related",
      tabType: TabTableType.Related,
      component: <PPRRelated relatedParam={relatedAccordionParams} />,
    },
    {
      title: "Plans to Comply",
      tabType: TabTableType.PlansToComply,
      component: <PPRPlanToComply />,
      isVisible: !ppr?.Flag_PlansToComply,
    },
    {
      title: "Appeals",
      tabType: TabTableType.Appeals,
      component: (
        <Appeals id={pprId} recordType={RECORDTYPE.TP_PPRApplication} />
      ),
    },
    {
      title: "Further Information",
      tabType: TabTableType.FurtherInfo,
      component: <PPRFurtherInfo />,
    },
    {
      title: "Enquiries",
      tabType: TabTableType.PPREnquiries,
      component: <PPREnquiries recordType={RECORDTYPE.CORE_Register} />,
    },
  ];

  const PPRNavButton = useCallback(
    (props: ICCNavButtonProps) => {
      const { disabled, ...others } = props;
      return (
        <CCNavButton
          {...others}
          disabled={isInactive ? isInactive : disabled}
        />
      );
    },
    [isInactive]
  );

  const formNavigationTitle = useMemo(() => {
    let title = "Application";
    if (ppr?.AmendmentReference) title = "Amendment";
    else if (ppr?.PlansToComplyReference) title = "Plans to Comply";
    return title;
  }, [ppr]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={formNavigationTitle} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPPR(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle
            title={ppr?.ApplicationNumberDisplay}
            badge={ppr?.Status_Name ?? ""}
          />
          <CCManagePageActionBar
            leftActions={[
              <PPRNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <>
                  {ppr?.Status_ENUM !== Application_Status.Withdrawn ? (
                    <>
                      <LodgeApplicationButton />
                      <SendForTLApprovalButton />
                      <ResendForTLApprovalButton />
                      <AllocateToOfficerButton />
                      <OfficerAssessmentButton />
                      <RequestFIButton />
                      <FiNotRequiredButton />
                      <AmendPermitButton />
                      <NoAppealButton />
                      <AppealedButton />
                      <AppealCompleteApplicationButton />
                      <AppealWithdrawnButton />
                      <PlansToComplyButton isPPRWorkflow={true} />
                      <SendReptAndRespLetterForApprovalButton />
                      <SendResponseToRAAndApplicantButton />
                      <SendLetterToRAandApplicantButton />
                      <ApproveReportAndResponseButton />
                      <ReportAndResponseNotApprovedButton />
                      <ApproveLetterApplicationButton />
                      <RejectLetterApplicationButton />
                      <CreateApplicationReportButton />
                      <CreateResponseLetterButton />
                      <PermitCompletedButton />
                      <AmendApplicationButton />
                      <WithDrawApplicationButton />
                    </>
                  ) : null}
                </>
              </PPRNavButton>,
              <PPRNavButton
                title={"Save"}
                onClick={onSubmit}
                name={PPRSubmitActions.Save}
              />,
              <PPRNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                />
                <AddAdditionalAddressButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                />
                <AdditionalContactButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                />
                <AddAppealButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                  data={processDataParentSentToAppeal(ppr)}
                />
                <AddAttachmentButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                />
                <AddCommentButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                />
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                  recordId={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                />
                <AddDocumentButton
                  dataSetFilter={MAILMERGEDATASET.TOWNPLANNING_PPR_Applications}
                  recordType={RECORDTYPE.TP_PPRApplication}
                  productType={PRODUCT_TYPE_NUMBER.TownPlanning}
                  id={pprId}
                />

                <AddFIRequestButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                  data={
                    {
                      DateRequested: new Date(),
                      Officer_ID: ppr?.Planner_ID,
                      Officer: { DisplayName: ppr?.Planner },
                      NumberOfDaysGiven: defaultFIDays,
                    } as FurtherInformationRequest
                  }
                />
                <PlansToComplyButton />
                <AddMeetingButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                  data={
                    {
                      ActionOfficer_ID: ppr?.Planner_ID,
                      ActionOfficer: { DisplayName: ppr?.Planner },
                    } as Action
                  }
                />
                <AddReferralButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                />
                <AddRelatedButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                />
              </PPRNavButton>,
              <PPRNavButton title={"Add to Register"} type="sub">
                <PlanningEnquiryButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                />
              </PPRNavButton>,
              <PPRNavButton title={"View"} type="sub">
                <ViewDocumentsButton
                  id={pprId}
                  recordType={RECORDTYPE.TP_PPRApplication}
                />
              </PPRNavButton>,
              <PPRNavButton title={"Map"} type="sub">
                <ViewNearMapButton address={ppr?.SiteAddress} />
                <ViewVICPlanButton pprType={EPPRProductType.PPR} />
              </PPRNavButton>,
              <PPRNavButton title={"Tools"} type="sub">
                <PPRChangeApplicationNumberButton />
                <ChangeDecisionButton />
                <ChangedLodgedDateButton />
                <PPRChangeStatusButton />
                <ClearReportLinkButton />
                <ClearResponseLinkButton />
                <UndoAppealCompleteButton />
              </PPRNavButton>,
              <PPRNavButton title={"More options"} type="more">
                <DecisionDetailsButton />
              </PPRNavButton>,
            ]}
            rightActions={[
              <PPRNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon
                bookmarkList={bookmarkList}
                disabled={isInactive}
              />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {ppr && (
                  <>
                    <PPRForm />
                    <TabTable
                      id={pprId}
                      recordType={RECORDTYPE.TP_PPRApplication}
                      initialPanels={listPanelBar}
                      options={{
                        applicationNumber: ppr.ApplicationNumber,
                        infringeKey: ppr?.ApplicationNumberDisplay,
                        invoiceNumber: relatedAccordionParams?.InvoiceNumber,
                        propertyAssessmentNoKey:
                          relatedAccordionParams?.AssessmentNumberKey,
                        addressLine1: relatedAccordionParams?.AddressLine1,
                        suburb: relatedAccordionParams?.Suburb,
                        personKey: relatedAccordionParams?.PersonKey,
                        schemeKey: relatedAccordionParams?.WasteWaterSchemeKey,
                        refNoKey: relatedAccordionParams?.CsmRefNoKey
                          ? relatedAccordionParams.CsmRefNoKey.toString()
                          : undefined,
                        ancestorRecordId:
                          relatedAccordionParams?.AncestorRecordId,
                        ancestorRecordType:
                          relatedAccordionParams?.AncestorRecordType,
                      }}
                      disabled={isInactive}
                    />
                  </>
                )}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <PPRDetailTab /> },
                    { title: "Map", component: <PPRViewOnMapTab /> },
                    {
                      title: "Contacts",
                      component: <PPRContactsTab />,
                    },
                    {
                      title: "History",
                      component: <PPRHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
