import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { ViewNearMapButton } from "@app/core/view-on-map/components/buttons/view-near-map/_index";
import { ViewVICPlanButton } from "@app/core/view-on-map/components/buttons/view-vic-plan/_index";
import { EPPRProductType } from "@app/core/view-on-map/components/buttons/view-vic-plan/config";
import { ViewDocumentsButton } from "@app/core/view/components/buttons/view-documents/_index";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { PPRRelated } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/_index";
import { IRelatedParams } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/model";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { PPREnquiryToolsButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/tools/_index";
import { EnquiriesApproveResponseButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/approve-response/_index";
import { CreateApplicationButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/create-application/_index";
import { EnquiriesLodgeApplicationButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/lodge-application/_index";
import { EnquiriesNotForVrButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/not-for-vr/_index";
import { RejectResponseButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/reject-response/_index";
import { SendForApprovalButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/send-for-approval/_index";
import { EnquiriesSendResponseButton } from "@app/products/town-planning/ppr/enquiries/_id/components/buttons/workflow/send-response/_index";
import { PPREnquiryForm } from "@app/products/town-planning/ppr/enquiries/_id/components/child-screens/general/_index";
import { PPREnquiryContactsTab } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/contact/_index";
import { PPREnquiryDetailTab } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/detail/_index";
import { PPREnquiryHistoryTab } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/history/_index";
import { PPREnquiryViewOnMapTab } from "@app/products/town-planning/ppr/enquiries/_id/components/reference-sidebar/view-on-map/_index";
import { PPREnquirySubmitActions } from "@app/products/town-planning/ppr/enquiries/_id/model";
import { usePPREnquiryStore } from "@app/products/town-planning/ppr/enquiries/_id/store";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import {
  CCNavButton,
  ICCNavButtonProps,
} from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedEnquiries = observer(() => {
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { id } = useParams<{ id: string }>();
  const {
    pprEnquiryId,
    pprEnquiry,
    loadPPREnquiry,
    isLoading,
    responseLoadError,
    onSubmit,
    isInactive,
  } = usePPREnquiryStore();

  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();

  const relatedAccordionParams = useMemo(() => {
    if (pprEnquiryId) {
      const data: IRelatedParams = {
        RecordType: RECORDTYPE.CORE_Register,
        RecordID: pprEnquiryId,
        InvoiceNumber: null,
        AssessmentNumberKey: null,
        AddressLine1: null,
        Suburb: null,
        PersonKey: null,
        WasteWaterSchemeKey: null,
        AncestorRecordType: null,
        AncestorRecordId: null,
      };

      if (pprEnquiry?.Address) {
        data.AssessmentNumberKey = pprEnquiry.Address.AssessmentNo;
        data.AddressLine1 = pprEnquiry.Address.Formatted_AddressLine1;
        data.Suburb = pprEnquiry.Address.Suburb;
      }

      if (pprEnquiry?.Contact && pprEnquiry.Contact.Contact) {
        data.PersonKey = pprEnquiry.Contact.Contact.Contact_ID;
      }

      return data;
    }
    return undefined;
  }, [pprEnquiry, pprEnquiryId]);

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([
        pprEnquiry?.Register_ID,
        pprEnquiry?.Address?.Formatted_SingleLine,
      ])}`,
      LinkUrl: managePageUrl,
      LinkText: `PPR - Enquiry - ${pprEnquiry?.Register_ID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.TownPlanning,
      RecordType_ENUM: RECORDTYPE.TP_PPRApplication,
      Record_ID: pprEnquiryId ?? 0,
    },
  ];
  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions id={pprEnquiryId} recordType={RECORDTYPE.CORE_Register} />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={pprEnquiryId} recordType={RECORDTYPE.CORE_Register} />
      ),
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion
          id={pprEnquiryId}
          recordType={RECORDTYPE.CORE_Register}
        />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={pprEnquiryId} recordType={RECORDTYPE.CORE_Register} />
      ),
    },
    {
      title: "Related",
      tabType: TabTableType.Related,
      component: <PPRRelated relatedParam={relatedAccordionParams} />,
    },
  ];

  const EnquiryNavButton = useCallback(
    (props: ICCNavButtonProps) => {
      const { disabled, ...others } = props;
      return (
        <CCNavButton
          {...others}
          disabled={isInactive ? isInactive : disabled}
        />
      );
    },
    [isInactive]
  );

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Planning Enquiry"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadPPREnquiry(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle
            title={pprEnquiry?.Register_Number}
            badge={pprEnquiry?.Status_Name}
          />
          <CCManagePageActionBar
            leftActions={[
              <EnquiryNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
              >
                <EnquiriesLodgeApplicationButton />
                <EnquiriesNotForVrButton />
                <SendForApprovalButton />
                <EnquiriesApproveResponseButton />
                <RejectResponseButton />
                <EnquiriesSendResponseButton />
              </EnquiryNavButton>,
              <EnquiryNavButton
                title={"Save"}
                onClick={onSubmit}
                name={PPREnquirySubmitActions.Save}
              />,
              <EnquiryNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={pprEnquiryId}
                  bubbleUps={pprEnquiry?.BubbleUps}
                  recordType={RECORDTYPE.CORE_Register}
                />
                <AdditionalContactButton
                  id={pprEnquiryId}
                  bubbleUps={pprEnquiry?.BubbleUps}
                  recordType={RECORDTYPE.CORE_Register}
                />
                <AddAttachmentButton
                  id={pprEnquiryId}
                  recordType={RECORDTYPE.CORE_Register}
                />
                <AddCommentButton
                  id={pprEnquiryId}
                  recordType={RECORDTYPE.CORE_Register}
                />
                <CommunicationButtonListView
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  recordId={pprEnquiryId}
                  recordType={RECORDTYPE.CORE_Register}
                />
                <AddDocumentButton
                  dataSetFilter={MAILMERGEDATASET.Register_PlanningEnquiry}
                  recordType={RECORDTYPE.CORE_Register}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  id={pprEnquiryId}
                />
              </EnquiryNavButton>,
              <EnquiryNavButton title={"View"} type="sub">
                <ViewDocumentsButton
                  id={pprEnquiryId}
                  recordType={RECORDTYPE.CORE_Register}
                />
              </EnquiryNavButton>,
              <PPREnquiryToolsButton />,
              <EnquiryNavButton title={"Map"} type="sub">
                <ViewNearMapButton address={pprEnquiry?.Address} />
                <ViewVICPlanButton pprType={EPPRProductType.Enquiries} />
              </EnquiryNavButton>,
              <EnquiryNavButton title={"More options"} type="more">
                <CreateApplicationButton />
              </EnquiryNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {pprEnquiry && <PPREnquiryForm />}
                <TabTable
                  id={pprEnquiryId}
                  recordType={RECORDTYPE.CORE_Register}
                  initialPanels={listPanelBar}
                  options={{
                    invoiceNumber: relatedAccordionParams?.InvoiceNumber,
                    propertyAssessmentNoKey:
                      relatedAccordionParams?.AssessmentNumberKey,
                    addressLine1: relatedAccordionParams?.AddressLine1,
                    suburb: relatedAccordionParams?.Suburb,
                    personKey: relatedAccordionParams?.PersonKey,
                    schemeKey: relatedAccordionParams?.WasteWaterSchemeKey,
                    refNoKey: relatedAccordionParams?.CsmRefNoKey
                      ? relatedAccordionParams.CsmRefNoKey.toString()
                      : undefined,
                    ancestorRecordId: relatedAccordionParams?.AncestorRecordId,
                    ancestorRecordType:
                      relatedAccordionParams?.AncestorRecordType,
                  }}
                  disabled={isInactive}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <PPREnquiryDetailTab /> },
                    {
                      title: "Map",
                      component: <PPREnquiryViewOnMapTab />,
                    },
                    {
                      title: "Contacts",
                      component: <PPREnquiryContactsTab />,
                    },
                    {
                      title: "History",
                      component: <PPREnquiryHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
