import { loadViewConfiguresColumns } from "@app/products/property/api";
import { nameOfLov, ViewConfiguration } from "@app/products/property/model";
import { colRatingPeriod } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/rating-periods/config";
import { V_Supp_Assess_Rating_Period } from "@app/products/property/supplementary-rates/[id]/components/forms/existed/components/form-steps/assessment-adjustments/components/form-elements/rating-periods/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import Loading from "@components/loading/Loading";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export const RatingPeriodsFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const nameOfRatingPeriod = nameOfFactory<V_Supp_Assess_Rating_Period>();
const FormStepElement = ({
  formRenderProps,
  nameOf,
  isLoadingStep,
  setIsLoadingStep = () => {},
  loadFailedStep,
  setLoadFailedStep = () => {},
  options = {
    isReadOnly: false,
  },
}: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const ratingPeriodCalculation = getFieldValue(
    "_option.RatingPeriodCalculation"
  );

  //Use store
  const { assessmentAdjustmentLOVs, supplementaryAssessmentId } =
    useSupplementaryRatesStore();

  //Use state
  const [colFields, setColFields] = useState<IColumnFields[]>(
    colRatingPeriod ?? []
  );

  /**
   * Handle select rating period
   * @param: dataItem
   */
  const handleSelectRatingPeriod = (
    dataItem: V_Supp_Assess_Rating_Period[]
  ) => {
    const selectedRatingPeriod = dataItem ?? [];
    onChange(nameOf("_option.SelectedRatingPeriod"), {
      value: selectedRatingPeriod,
    });
    onChange(nameOf("_option.RatingPeriodCalculation"), {
      value: getDropdownValue(
        selectedRatingPeriod[0]?.Rating_Period_Id ?? "",
        assessmentAdjustmentLOVs?.SuppAssessRatingPeriod ?? [],
        "Rating_Period_Id"
      ),
    });
  };

  /**
   * Load view configuration of rating period grid
   */
  const loadRatingPeriodView = async () => {
    setIsLoadingStep(true);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.AssessmentAdjustment_RatingPeriod,
      colRatingPeriod
    );
    setIsLoadingStep(false);
    if (Array.isArray(response)) {
      setColFields(response);
    } else {
      setLoadFailedStep({
        onReload: () => {
          loadRatingPeriodView();
        },
        responseError: {
          status: response.status,
          error: response.error ?? "Load failed",
        },
      });
    }
  };

  useEffectOnce(() => {
    loadRatingPeriodView();
  });

  if (isLoadingStep) {
    return <Loading isLoading={isLoadingStep} />;
  }

  if (loadFailedStep) {
    return (
      <CCLoadFailed
        onReload={loadFailedStep?.onReload}
        responseError={loadFailedStep?.responseError}
      />
    );
  }

  return (
    <section className="cc-field-group mb-4">
      <div className="cc-field">
        <CCGrid
          dataUrl={`/odata/property/internal/supplementaryratesassessmentratingperiodregister/${supplementaryAssessmentId}?$count=true&`}
          columnFields={colFields}
          primaryField={nameOfRatingPeriod("Rating_Period_Id")}
          selectableMode="single"
          onSelectionChange={handleSelectRatingPeriod}
          selectedRows={getFieldValue("_option.SelectedRatingPeriod")}
          readOnly={options?.isReadOnly}
        />
      </div>

      <div className="cc-field">
        <label className="cc-label">Rating period</label>
        <div className="cc-custom-sub-panel-bar">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <Checkbox
                label="Calculate adjustments for this rating period"
                checked={
                  ratingPeriodCalculation?.SARP_Calculate_Adjustments ?? false
                }
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">
                Valuation for previous calculation
              </label>
              <Field
                name={nameOf("ValuationforPreviousCalculationId")}
                component={CCSearchComboBox}
                data={assessmentAdjustmentLOVs?.ValuationCalculation ?? []}
                disabled
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                value={getDropdownValue(
                  ratingPeriodCalculation?.Old_Valuation_Id?.toString() ?? "",
                  assessmentAdjustmentLOVs?.ValuationCalculation ?? [],
                  nameOfLov("Code")
                )}
                onChange={(event: ComboBoxChangeEvent) => {
                  onChange(nameOf("ValuationforPreviousCalculationId"), {
                    value: event.value?.Code ?? null,
                  });
                }}
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Valuation for new calculation</label>
              <Field
                name={nameOf("ValuationforNewCalculationId")}
                component={CCSearchComboBox}
                disabled
                textField={nameOfLov("Name")}
                dataItemKey={nameOfLov("Code")}
                value={getDropdownValue(
                  ratingPeriodCalculation?.New_Valuation_Id?.toString() ?? "",
                  assessmentAdjustmentLOVs?.ValuationCalculation ?? [],
                  nameOfLov("Code")
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
