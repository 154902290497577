import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { colPPRFurtherInfo } from "@app/products/town-planning/ppr/[id]/components/child-screens/further-info/config";
import { IPPRFurtherInfo } from "@app/products/town-planning/ppr/[id]/components/child-screens/further-info/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

const nameOf = nameOfFactory<IPPRFurtherInfo>();
export const PPRFurtherInfo = observer(() => {
  const { pprId } = usePPRStore();
  const { isDisabled } = useTabTableStore();
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const [gridSelectedRows, setGridSelectedRows] = useState<IPPRFurtherInfo[]>(
    []
  );
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const [isDeleting, setIsDeleting] = useState(false);

  const handleConfirmDelete = async (data: IDeleteConfirmation) => {
    setIsDeleting(true);
    const response = await deleteRefUsageByIdAndRecordType(
      gridSelectedRows?.map((item: IPPRFurtherInfo) => item.ID),
      RECORDTYPE.CORE_FurtherInformationRequest,
      data?.Reason
    );
    setIsDeleting(false);
    if (isSuccessResponse(response)) {
      setShowConfirmDeleteDialog(false);
      clearErrorNotification();
      setGridSelectedRows([]);
      pushNotification({
        title: "Further information deleted successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Deleted further information failed",
          type: "error",
          description: response.data?.Errors,
        },
      ]);
    }
  };

  return (
    <div className="cc-ppr-further-info">
      <CCGrid
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              type="button"
              iconClass="fas fa-minus"
              title="Remove"
              disabled={isDisabled ? isDisabled : gridSelectedRows.length !== 1}
              onClick={() => {
                setShowConfirmDeleteDialog(true);
              }}
            />
          </div>
        }
        selectableMode="multiple"
        dataUrl={`/odata/core/internal/firequests/Summary(id=${pprId},recordType=${RECORDTYPE.TP_PPRApplication})?$count=true&`}
        primaryField={nameOf("ID")}
        selectedRows={gridSelectedRows}
        onSelectionChange={(dataItem: IPPRFurtherInfo[]) => {
          setGridSelectedRows([...dataItem]);
        }}
        columnFields={colPPRFurtherInfo}
        state={{
          sort: [{ field: nameOf("CreatedDate"), dir: "desc" }],
        }}
      />
      {showConfirmDeleteDialog && (
        <ConfirmDelete
          onClose={() => {
            setShowConfirmDeleteDialog(false);
          }}
          header={"Delete Confirmation"}
          contentDelete={"Further Info"}
          handleSubmit={handleConfirmDelete}
          isDeleting={isDeleting}
          notifications={notifications}
        />
      )}
    </div>
  );
});
