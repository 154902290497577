import { BELL_CHARACTER } from "@common/constants/characters";
import { ChecklistAnswerType } from "@common/constants/enumerations";
import {
  DynamicQuestionResponse,
  dynamicQuestionFieldName,
} from "@common/pages/actions/components/action-bar/dialog/dynamic-question/model";
import { ICCCheckboxGroupItem } from "@components/cc-checkbox-group/model";
import { RadioButtonProps } from "@progress/kendo-react-inputs";

export const refactorDynamicQuestionData = (
  dynamicQuestionData: DynamicQuestionResponse[]
) => {
  if (dynamicQuestionData.length > 0) {
    return dynamicQuestionData.reduce(
      (
        dynamicQuestionObj: any,
        currentDynamicQuestion: DynamicQuestionResponse
      ) => {
        switch (currentDynamicQuestion.ChecklistAnswerType_ENUM) {
          case ChecklistAnswerType.Label:
            dynamicQuestionObj = {
              ...dynamicQuestionObj,
              [`${dynamicQuestionFieldName.Label}${currentDynamicQuestion.ChecklistQuestion_ID}`]:
                currentDynamicQuestion,
            };
            break;
          case ChecklistAnswerType.TextBox:
            dynamicQuestionObj = {
              ...dynamicQuestionObj,
              [`${dynamicQuestionFieldName.TextBox}${currentDynamicQuestion.ChecklistQuestion_ID}`]:
                currentDynamicQuestion,
            };
            break;
          case ChecklistAnswerType.RadioButton:
            dynamicQuestionObj = {
              ...dynamicQuestionObj,
              [`${dynamicQuestionFieldName.RadioButton}${currentDynamicQuestion.ChecklistQuestion_ID}`]:
                currentDynamicQuestion,
            };
            break;
          case ChecklistAnswerType.CheckBox:
            dynamicQuestionObj = {
              ...dynamicQuestionObj,
              [`${dynamicQuestionFieldName.CheckBox}${currentDynamicQuestion.ChecklistQuestion_ID}`]:
                currentDynamicQuestion,
            };
            break;
          case ChecklistAnswerType.DatePicker:
            dynamicQuestionObj = {
              ...dynamicQuestionObj,
              [`${dynamicQuestionFieldName.DatePicker}${currentDynamicQuestion.ChecklistQuestion_ID}`]:
                currentDynamicQuestion,
            };
            break;
        }
        return dynamicQuestionObj;
      },
      {}
    );
  }
  return {};
};

export const refactorListData = (data: string) => {
  const newData = data
    ?.split(BELL_CHARACTER)
    ?.reduce((dataList: Array<RadioButtonProps>, currentItem: string) => {
      if (currentItem)
        dataList.push({
          label: currentItem,
          value: currentItem,
        });
      return dataList;
    }, []);

  return newData?.filter(
    (dataItem, index, array) =>
      index === array.findIndex((item) => item.label === dataItem.label)
  );
};

export const refactorListDataCheckBox = (data: string, value: boolean) => {
  const newData = data
    ?.split(BELL_CHARACTER)
    ?.reduce((dataList: Array<RadioButtonProps>, currentItem: string) => {
      if (currentItem)
        dataList.push({
          label: currentItem,
          value: value,
        });
      return dataList;
    }, []);

  return newData?.filter(
    (dataItem, index, array) =>
      index === array.findIndex((item) => item.label === dataItem.label)
  );
};

export const refactorCheckBoxValues = (data: ICCCheckboxGroupItem[]) => {
  return data.reduce(
    (resultString: string, currentCheckboxItem: ICCCheckboxGroupItem) => {
      if (currentCheckboxItem.value)
        resultString += `${BELL_CHARACTER}${currentCheckboxItem.label}`;
      return resultString;
    },
    ""
  );
};
