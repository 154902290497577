import { stringLengthValidator } from "@app/products/direct-debit/system-admin/util";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { PickDaysOfWeek } from "@common/pages/settings/system-admin/task-scheduling/components/pick-day-of-week/_index";
import {
  daysOfWeekData,
  forADurationOptions,
  jobTypeOptions,
  repeatJobOptions,
} from "@common/pages/settings/system-admin/task-scheduling/constant";
import {
  DayOfWeekType,
  ScheduleJobType,
  SchedulingJob_LOVs,
  eForADuration,
  nameOfIJobsSchedulingData,
  nameOfIKeyValuePacket,
} from "@common/pages/settings/system-admin/task-scheduling/model";
import { getDropdownValue } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import {
  NumericTextBoxChangeEvent,
  RadioGroup,
} from "@progress/kendo-react-inputs";
import React, { useCallback } from "react";

interface IJobsSchedulingElementProps {
  formRenderProps: FormRenderProps;
  lovs?: SchedulingJob_LOVs;
}

export const jobNameValidator = (value: any) =>
  stringLengthValidator(value, 0, 100);

export const JobsSchedulingElement = ({
  formRenderProps,
  lovs,
}: IJobsSchedulingElementProps) => {
  const { valueGetter, onChange } = formRenderProps;

  const jobScheduleStartDateTime = valueGetter(
    nameOfIJobsSchedulingData("JobScheduleStartDateTime")
  );
  const jobScheduleExpireDateTime = valueGetter(
    nameOfIJobsSchedulingData("JobScheduleExpireDateTime")
  );
  const isExpire = valueGetter(nameOfIJobsSchedulingData("IsExpire"));
  const jobRepeatJobTime = nameOfIJobsSchedulingData("RepeatJobTime");

  const validatorExpireDate = useCallback(
    (value: Date) => {
      if (value < jobScheduleStartDateTime) {
        return "The date and time a job begins cannot be later than the date and time it expires.";
      }
      if (value < new Date()) {
        return "The date and time a task expires cannot be before the date and time now.";
      }
      return requiredValidator(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobScheduleExpireDateTime, jobScheduleStartDateTime]
  );

  const validatorDuration = useCallback(
    (value: number) => {
      if (
        value !== eForADuration.Indefinitely &&
        value < valueGetter(nameOfIJobsSchedulingData("RepeatJobTime"))
      ) {
        return "The repetition interval value must be less than or equal to the repetition duration value.";
      }

      return requiredValidator(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobRepeatJobTime]
  );

  const validatorStartDate = useCallback(
    (value: Date) => {
      if (isExpire && value >= jobScheduleExpireDateTime) {
        return "The date and time a job begins cannot be later than the date and time it expires.";
      }

      return requiredValidator(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jobScheduleStartDateTime, jobScheduleExpireDateTime, isExpire]
  );
  // #endregion Validators
  return (
    <section className="cc-field-group mb-4">
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Name" isMandatory />
          <Field
            name={nameOfIJobsSchedulingData("JobName")}
            component={CCInput}
            validator={jobNameValidator}
          />
        </div>

        <div className="cc-field">
          <CCLabel title="Action" isMandatory />
          <Field
            name={nameOfIJobsSchedulingData("GroupEnum")}
            component={CCDropDownList}
            data={lovs?.GroupName ?? []}
            textField={nameOfIKeyValuePacket("Value")}
            dataItemKey={nameOfIKeyValuePacket("Key")}
            validator={requiredValidator}
            value={getDropdownValue(
              valueGetter(nameOfIJobsSchedulingData("GroupEnum")),
              lovs?.GroupName ?? [],
              nameOfIKeyValuePacket("Key")
            )}
            onChange={(e: DropDownListChangeEvent) => {
              onChange(nameOfIJobsSchedulingData("GroupEnum"), {
                value: e.target?.value?.Key,
              });
              onChange(nameOfIJobsSchedulingData("GroupName"), {
                value: e.target?.value?.Value,
              });
            }}
          />
        </div>

        <div className="cc-field">
          <CCLabel title="Start" isMandatory />
          <Field
            name={nameOfIJobsSchedulingData("JobScheduleStartDateTime")}
            component={CCDateTimePicker}
            format={DATETIME_FORMAT.DATETIME_CONTROL}
            validator={validatorStartDate}
          />
        </div>

        <div className="cc-field">
          <CCLabel title="Job type" />
          <Field
            name={nameOfIJobsSchedulingData("JobType")}
            component={RadioGroup}
            data={jobTypeOptions}
            layout={"horizontal"}
          />
        </div>
      </div>

      {valueGetter(nameOfIJobsSchedulingData("JobType")) ===
      ScheduleJobType.Daily ? (
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCLabel title="Recur every" />
            <div className="cc-custom-input-group">
              <Field
                name={nameOfIJobsSchedulingData("RecurEvery")}
                component={CCNumericTextBox}
                min={1}
                value={
                  valueGetter(nameOfIJobsSchedulingData("RecurEvery")) ?? 1
                }
                onChange={(event: NumericTextBoxChangeEvent) => {
                  onChange(nameOfIJobsSchedulingData("RecurEvery"), {
                    value: event?.target?.value,
                  });
                }}
              />
              <div className="cc-input-group-postfix">day(s)</div>
            </div>
          </div>
        </div>
      ) : null}

      {valueGetter(nameOfIJobsSchedulingData("JobType")) ===
      ScheduleJobType.Weekly ? (
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <PickDaysOfWeek
              data={daysOfWeekData}
              values={valueGetter(nameOfIJobsSchedulingData("DayOfWeek")) ?? []}
              onChange={(values: DayOfWeekType[]) => {
                onChange(nameOfIJobsSchedulingData("DayOfWeek"), {
                  value: values.sort((a, b) => a - b),
                });
              }}
            />
          </div>
        </div>
      ) : null}

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Repeat job" />
          <Field
            name={nameOfIJobsSchedulingData("IsRepeatJob")}
            component={CCSwitch}
            checked={valueGetter(nameOfIJobsSchedulingData("IsRepeatJob"))}
          />
        </div>
      </div>

      {valueGetter(nameOfIJobsSchedulingData("IsRepeatJob")) ? (
        <div className="cc-form-cols-2">
          <div className="cc-field">
            <CCLabel title="Repeat job every" isMandatory />
            <Field
              name={nameOfIJobsSchedulingData("RepeatJobTime")}
              component={CCDropDownList}
              data={repeatJobOptions}
              isKeyValueDropdown
              validator={requiredValidator}
            />
          </div>

          <div className="cc-field">
            <CCLabel title="For a duration of" isMandatory />
            <Field
              name={nameOfIJobsSchedulingData("Duration")}
              component={CCDropDownList}
              data={forADurationOptions}
              isKeyValueDropdown
              validator={validatorDuration}
            />
          </div>
        </div>
      ) : null}

      <div className="cc-form-cols-2">
        <div className="cc-field">
          <CCLabel title="Expire" />
          <Field
            name={nameOfIJobsSchedulingData("IsExpire")}
            component={CCSwitch}
            checked={valueGetter(nameOfIJobsSchedulingData("IsExpire"))}
          />
        </div>

        {valueGetter(nameOfIJobsSchedulingData("IsExpire")) ? (
          <div className="cc-field">
            <CCLabel title="Expire Date" isMandatory />
            <Field
              name={nameOfIJobsSchedulingData("JobScheduleExpireDateTime")}
              component={CCDateTimePicker}
              format={DATETIME_FORMAT.DATETIME_CONTROL}
              validator={validatorExpireDate}
            />
          </div>
        ) : null}
      </div>

      <div className="cc-form-cols-1">
        <div className="cc-field">
          <CCLabel title="Enabled" />
          <Field
            name={nameOfIJobsSchedulingData("IsEnable")}
            component={CCSwitch}
            checked={valueGetter(nameOfIJobsSchedulingData("IsEnable"))}
          />
        </div>
      </div>
    </section>
  );
};
