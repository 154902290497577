import {
  InspectionType,
  InspectionTypeUpdateTriggers,
} from "@app/core/inspections/[id]/model";
import { ProductType } from "@app/products/crms/components/dialogs/acknowledge-events/model";
import { DynamicQuestionPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/dynamic-question-picker/_index";
import { getFeeTypesById } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/dialogs/add-fee-types/api";
import { isSuccessResponse } from "@common/apis/util";
import {
  CURRENCY_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import {
  ChecklistType,
  DefaultAffectSchedule,
  InspectionClassification,
} from "@common/constants/enumerations";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { DynamicQuestionList_Light } from "@common/models/dynamicQuestion";
import { ActionTypePicker } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/_index";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { SystemFilter } from "@common/pages/settings/lookups/components/system-filter/_index";
import { AdditionalFees } from "@common/pages/settings/lookups/inspection-types/_id/components/additional-fees/_index";
import { DocumentsSelection } from "@common/pages/settings/lookups/inspection-types/_id/components/documents-selection/_index";
import {
  InspectionTypeLovs,
  nameOfIKeyValuePacket,
} from "@common/pages/settings/lookups/inspection-types/_id/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import {
  convertStrIdsToList,
  getDropdownValue,
  nameOfFactory,
} from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { GetHelpText } from "@common/utils/help-text";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDropDownList } from "@components/cc-drop-down-list/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { ActiveProduct } from "@components/layout/model";
import Loading from "@components/loading/Loading";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import {
  InputChangeEvent,
  SwitchChangeEvent,
} from "@progress/kendo-react-inputs";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import "./_index.scss";
export interface IInspectionTypeFormElementProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<InspectionType>();
const nameOfDynamicQuestion = nameOfFactory<DynamicQuestionList_Light>();

export const InspectionTypeFormElement = observer(
  ({ formRenderProps }: IInspectionTypeFormElementProps) => {
    const { dataForms } = useFlexibleFormStore();
    const { settings } = useCommonCoreStore();
    const { productsActive } = useGlobalStore();
    const { pushNotification } = useCCAppNotificationStore();

    const { valueGetter, onChange } = formRenderProps;

    // #region state
    const [isLoading, setIsLoading] = useState(false);
    // #endregion state

    // #region  Get Lovs
    const inspectionTypeLovs = dataForms?.GeneralFormLovs as InspectionTypeLovs;
    // #endregion Get Lovs

    // #region setting value
    const isGroupInspectionTypes = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_GroupInspectionTypes]
    );
    const isEnableInspectionAutoActions = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_EnableInspectionAutoActions
      ]
    );
    // #endregion

    // #region Get field value
    const inspectionClassificationEnum = valueGetter(
      nameOf("InspectionClassification_ENUM")
    );

    const isDisabledAffectSchedule = useMemo(() => {
      return valueGetter(nameOf("Flag_SimpleInspection")) === true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(nameOf("Flag_SimpleInspection"))]);

    // #endregion Get field value

    // #region functions
    const handleChangeClassification = (event: ComboBoxChangeEvent) => {
      onChange(nameOf("InspectionClassification_ENUM"), {
        value: event.value?.Key,
      });
    };

    const handleChangeSimpleInspection = (event: SwitchChangeEvent) => {
      const newValue = event?.target?.value;
      onChange(nameOf("Flag_SimpleInspection"), {
        value: newValue,
      });
      if (newValue) {
        onChange(nameOf("DefaultAffectSchedule_ENUM"), {
          value: DefaultAffectSchedule.YesLocked,
        });
      }
    };

    const handleChangeFeeOption1 = async (event: ComboBoxChangeEvent) => {
      if (event?.value === null) {
        onChange(nameOf("Flag_InheritFeeType"), { value: false });
        onChange(nameOf("FeeAmount"), { value: null });
      }
      const selectedValue = event.value?.[nameOfIKeyValuePacket("Key")] ?? null;
      onChange("FeeType_ID", {
        value: selectedValue,
      });

      if (valueGetter(nameOf("Flag_InheritFeeType"))) {
        const feeType = await getFeeTypesData(selectedValue);
        onChange(nameOf("FeeAmount"), { value: feeType?.DefaultAmount });
      }
    };

    const handleChangeFeeOption2 = async (event: ComboBoxChangeEvent) => {
      if (event?.value === null) {
        onChange(nameOf("Flag_InheritFeeType2"), { value: false });
        onChange(nameOf("FeeAmount2"), { value: null });
      }
      const selectedValue = event.value?.[nameOfIKeyValuePacket("Key")] ?? null;
      onChange("FeeType2_ID", {
        value: selectedValue,
      });

      if (valueGetter(nameOf("Flag_InheritFeeType2"))) {
        const feeType = await getFeeTypesData(selectedValue);
        onChange(nameOf("FeeAmount2"), { value: feeType?.DefaultAmount });
      }
    };

    const getFeeTypesData = async (feeTypeId: number) => {
      setIsLoading(true);
      const response = await getFeeTypesById(feeTypeId);
      setIsLoading(false);
      if (isSuccessResponse(response) && response?.data) {
        return response.data;
      } else {
        pushNotification({
          autoClose: false,
          title: "Get fee type failed",
          description: response?.error,
          type: "error",
        });
      }
      return null;
    };

    const handleChangeInheritFee1 = async (event: SwitchChangeEvent) => {
      onChange(nameOf("Flag_InheritFeeType"), {
        value: event?.target?.value ?? false,
      });
      const feeTypeId = valueGetter(nameOf("FeeType_ID"));

      if (event?.target?.value && feeTypeId) {
        const feeType = await getFeeTypesData(feeTypeId);
        onChange(nameOf("FeeAmount"), { value: feeType?.DefaultAmount });
      }
    };

    const handleChangeInheritFee2 = async (event: SwitchChangeEvent) => {
      onChange(nameOf("Flag_InheritFeeType2"), {
        value: event?.target?.value ?? false,
      });
      const feeTypeId = valueGetter(nameOf("FeeType2_ID"));

      if (event?.target?.value && feeTypeId) {
        const feeType = await getFeeTypesData(feeTypeId);
        onChange(nameOf("FeeAmount2"), { value: feeType?.DefaultAmount });
      }
    };

    const handlePickAutoActionTypes = (data: IRespActionType[]) => {
      if (data) {
        onChange(nameOf("ActionTypeIds"), {
          value: data.map((value) => value.ID),
        });

        onChange(nameOf("ActionType_CSV"), {
          value: data
            .reduce((pre, cur) => pre + ", " + cur.Name, "")
            .slice(1)
            .trim(),
        });
      } else {
        onChange(nameOf("ActionTypeIds"), {
          value: null,
        });
        onChange(nameOf("ActionType_CSV"), {
          value: null,
        });
      }
      updateSaveTriggers(InspectionTypeUpdateTriggers.CreateActionTypes);
    };

    const handlePickTechOneActionType = (data: IRespActionType) => {
      if (data) {
        onChange(nameOf("TechOneActionTypeId"), {
          value: data?.ID,
        });

        onChange(nameOf("TechOneActionType_CSV"), {
          value: data?.Name,
        });
      } else {
        onChange(nameOf("TechOneActionTypeId"), {
          value: null,
        });
        onChange(nameOf("TechOneActionType_CSV"), {
          value: null,
        });
      }
      updateSaveTriggers(InspectionTypeUpdateTriggers.CreateTechOneActionType);
    };

    const handleOnPickApplicationAssessment = (
      checklist: DynamicQuestionList_Light[]
    ) => {
      if (checklist.length > 0) {
        onChange(nameOf("ApplicationAssessment"), {
          value: checklist,
        });
      } else {
        onChange(nameOf("ApplicationAssessment"), {
          value: null,
        });
      }
    };

    const handlePickInspectionTypeChecklist = (
      checklist: DynamicQuestionList_Light
    ) => {
      onChange(nameOf("InspectionTypeChecklist_ID"), {
        value: checklist.DynamicQuestionList_ID,
      });
      onChange(nameOf("InspectionTypeChecklist_Name"), {
        value: checklist.DynamicQuestionList_Name,
      });
      onChange(nameOf("InspectionTypeChecklist"), {
        value: checklist,
      });
    };

    const updateSaveTriggers = (triggers: InspectionTypeUpdateTriggers) => {
      let saveTriggers: InspectionTypeUpdateTriggers[] =
        valueGetter(nameOf("_SaveTriggers")) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(nameOf("_SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };
    // #endregion functions

    // #region logic show/hide enable/disable
    const systemFilterEnums = convertStrIdsToList(
      valueGetter(nameOf("SystemFilter_ENUMS")) ?? "",
      "|"
    );
    const isIncludeHealManagerModule = systemFilterEnums.includes(
      ProductType.HealthManager
    );
    const divFiveStarOverwrite = useMemo(() => {
      return (
        inspectionClassificationEnum ===
          InspectionClassification.SOUTHAUSTRALIA_FOODSAFETYRATINGSCHEME ||
        inspectionClassificationEnum ===
          InspectionClassification.SOUTHAUSTRALIA_FOODSAFETYRATINGSCHEME_V3
      );
    }, [inspectionClassificationEnum]);
    const isShowMobileUpdateActionType = useMemo(() => {
      return (
        inspectionClassificationEnum ===
        InspectionClassification.FOODPREMISES_INSPECTION_ASSESSMENT_NSW
      );
    }, [inspectionClassificationEnum]);
    const isShowAssessmentQuestionDetails = useMemo(() => {
      return (
        inspectionClassificationEnum ===
          InspectionClassification.FIELD_DATA_SHEET_LLS ||
        inspectionClassificationEnum ===
          InspectionClassification.INS_PASTURE_EXPANSION_FIELD_DATA_SHEET_LLS
      );
    }, [inspectionClassificationEnum]);

    const isActiveStandardsAndRegulation = useMemo(() => {
      if (productsActive.length === 0) {
        return false;
      }
      return productsActive.some(
        (item: ActiveProduct) =>
          item.ProductType_ENUM === PRODUCT_TYPE.StandardsAndRegulation
      );
    }, [productsActive]);
    // #endregion logic show/hide enable/disable

    return (
      <>
        <Loading isLoading={isLoading} isFullScreen />
        <FormElement id="cc-form-manage-page-general">
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Name" isMandatory />
                <Field
                  component={CCInput}
                  name={nameOf("Name")}
                  validator={requiredValidator}
                />
              </div>

              {isGroupInspectionTypes ? (
                <div className="cc-field">
                  <CCLabel title="Group" />
                  <Field
                    component={CCSearchComboBox}
                    name={nameOf("Group")}
                    data={inspectionTypeLovs?.Group ?? []}
                    dataItemKey={nameOfIKeyValuePacket("Key")}
                    textField={nameOfIKeyValuePacket("Value")}
                    isUseDefaultOnchange
                  />
                </div>
              ) : null}

              <div className="cc-field">
                <CCLabel title="Code" isMandatory />
                <Field
                  component={CCNumericTextBox}
                  name={nameOf("Code")}
                  format={NUMBER_FORMAT.NUMBER2}
                  validator={requiredValidator}
                />
              </div>
            </div>
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title="Classification" isMandatory />
                <Field
                  name={nameOf("InspectionClassification_ENUM")}
                  textField="Value"
                  dataItemKey="Key"
                  data={inspectionTypeLovs?.InspClass ?? []}
                  component={CCSearchComboBox}
                  validator={requiredValidator}
                  value={getDropdownValue(
                    valueGetter(nameOf("InspectionClassification_ENUM")),
                    inspectionTypeLovs?.InspClass ?? [],
                    "Key"
                  )}
                  onChange={handleChangeClassification}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Description" />
                <Field
                  component={CCTextArea}
                  name={nameOf("Description")}
                  rows={3}
                />
              </div>
            </div>
          </section>

          <hr className="cc-divider" />

          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Show NC items" isMandatory />
                <Field
                  name={nameOf("Flag_AllowNCItems")}
                  checked={valueGetter(nameOf("Flag_AllowNCItems")) ?? false}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Inherit NC items" isMandatory />
                <Field
                  name={nameOf("Flag_InheritNCItems")}
                  checked={valueGetter(nameOf("Flag_InheritNCItems")) ?? false}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field">
                <CCLabel
                  title="Simple inspection"
                  isMandatory
                  customTooltip={
                    <CCTooltip
                      type="custom"
                      position="right"
                      content=" "
                      customTemplate={GetHelpText("1343")}
                    >
                      <i className="fa fa-info-circle ml-1 text-accent" />
                    </CCTooltip>
                  }
                />
                <Field
                  name={nameOf("Flag_SimpleInspection")}
                  checked={
                    valueGetter(nameOf("Flag_SimpleInspection")) ?? false
                  }
                  component={CCSwitch}
                  onChange={handleChangeSimpleInspection}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Assessment" isMandatory />
                <Field
                  name={nameOf("Flag_IsAssessment")}
                  checked={valueGetter(nameOf("Flag_IsAssessment")) ?? false}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Scoring" isMandatory />
                <Field
                  name={nameOf("Flag_IsScoring")}
                  checked={valueGetter(nameOf("Flag_IsScoring")) ?? false}
                  component={CCSwitch}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Allow follow-up" isMandatory />
                <Field
                  name={nameOf("Flag_IsAllowFollowUp")}
                  checked={valueGetter(nameOf("Flag_IsAllowFollowUp")) ?? false}
                  component={CCSwitch}
                />
              </div>
              {isIncludeHealManagerModule ? (
                <>
                  <div className="cc-field">
                    <CCLabel title="Enable FSS" isMandatory />
                    <Field
                      name={nameOf("Flag_IsAllowFSS")}
                      checked={valueGetter(nameOf("Flag_IsAllowFSS")) ?? false}
                      component={CCSwitch}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Administrative fee" isMandatory />
                    <Field
                      name={nameOf("Flag_AdministrativeFee")}
                      checked={
                        valueGetter(nameOf("Flag_AdministrativeFee")) ?? false
                      }
                      component={CCSwitch}
                    />
                  </div>
                </>
              ) : null}

              {divFiveStarOverwrite ? (
                <div className="cc-field">
                  <CCLabel title="Assessment rating overwrite" />
                  <Field
                    name={nameOf("Flag_AssessmentRatingOverwrite")}
                    checked={
                      valueGetter(nameOf("Flag_AssessmentRatingOverwrite")) ??
                      false
                    }
                    component={CCSwitch}
                  />
                </div>
              ) : null}

              <div className="cc-field">
                <CCLabel title="Default affect schedule" />
                <Field
                  name={nameOf("DefaultAffectSchedule_ENUM")}
                  component={CCDropDownList}
                  data={inspectionTypeLovs?.DefaultAffectSchedule ?? []}
                  disabled={isDisabledAffectSchedule}
                  isKeyValueDropdown
                />
              </div>

              {isIncludeHealManagerModule ? (
                <>
                  <div className="cc-field">
                    <CCLabel title="Deduction type" />
                    <Field
                      component={CCDropDownList}
                      name={nameOf("DeductionType_ENUM")}
                      data={inspectionTypeLovs?.DeductionType ?? []}
                      isKeyValueDropdown
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="FSR code" />
                    <Field
                      component={CCDropDownList}
                      name={nameOf("FSRCode_ENUM")}
                      data={inspectionTypeLovs?.FSRCode ?? []}
                      isKeyValueDropdown
                    />
                    <div className="cc-text-info cc-padding-text-info">
                      ** NOTE: 'FOLLOW UP INSPECTION' will make related notice
                      MANDATORY
                    </div>
                  </div>
                </>
              ) : null}

              <div className="cc-field">
                <CCLabel
                  title="Enable search warrant"
                  customTooltip={
                    <CCTooltip
                      type="custom"
                      position="right"
                      content=" "
                      customTemplate={GetHelpText("SearchWarrantOption")}
                    >
                      <i className="fa fa-info-circle ml-1 text-accent" />
                    </CCTooltip>
                  }
                />
                <Field
                  name={nameOf("Flag_SearchWarrant")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Flag_SearchWarrant")) ?? false}
                  disabled={!isActiveStandardsAndRegulation}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Enable seizure" />
                <Field
                  name={nameOf("Flag_Seizure")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Flag_Seizure")) ?? false}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Enable issue category" />
                <Field
                  name={nameOf("Flag_IssueCategory")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Flag_IssueCategory")) ?? false}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Enable workflow" />
                <Field
                  name={nameOf("Flag_IncludeWorkflow")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Flag_IncludeWorkflow")) ?? false}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Enable TCR assessment" />
                <Field
                  name={nameOf("Flag_TCRAssessment")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Flag_TCRAssessment")) ?? false}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Enable location" />
                <Field
                  name={nameOf("Flag_Location")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Flag_Location")) ?? false}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Enable related notice" />
                <Field
                  name={nameOf("Flag_RelatedNotice")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Flag_RelatedNotice")) ?? false}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Enable related incident" />
                <Field
                  name={nameOf("Flag_RelatedIncident")}
                  component={CCSwitch}
                  checked={valueGetter(nameOf("Flag_RelatedIncident")) ?? false}
                />
              </div>

              <div className="cc-field">
                <CCLabel title="Registration certificate displayed" />
                <Field
                  name={nameOf("Flag_RegistrationCertificateDisplayed")}
                  component={CCSwitch}
                  checked={
                    valueGetter(
                      nameOf("Flag_RegistrationCertificateDisplayed")
                    ) ?? false
                  }
                />
              </div>
            </div>
          </section>

          <hr className="cc-divider" />

          <section className="cc-field-group">
            <div className="cc-form-cols-1">
              <CCLabel title="Fee types" />
            </div>
            <div className="cc-field-group cc-custom-sub-panel-bar">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Fee option1" />
                  <Field
                    name={nameOf("FeeType_ID")}
                    component={CCSearchComboBox}
                    data={inspectionTypeLovs?.FeeType ?? []}
                    dataItemKey={nameOfIKeyValuePacket("Key")}
                    textField={nameOfIKeyValuePacket("Value")}
                    value={getDropdownValue(
                      valueGetter(nameOf("FeeType_ID")),
                      inspectionTypeLovs?.FeeType ?? [],
                      nameOfIKeyValuePacket("Key")
                    )}
                    onChange={handleChangeFeeOption1}
                  />
                </div>
                {!isNil(valueGetter(nameOf("FeeType_ID"))) ? (
                  <>
                    <div className="cc-field">
                      <CCLabel title="Inherit fee" />
                      <Field
                        name={nameOf("Flag_InheritFeeType")}
                        component={CCSwitch}
                        checked={
                          valueGetter(nameOf("Flag_InheritFeeType")) ?? false
                        }
                        onChange={handleChangeInheritFee1}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Amount" isMandatory />
                      <Field
                        name={nameOf("FeeAmount")}
                        component={CCNumericTextBox}
                        disabled={valueGetter(nameOf("Flag_InheritFeeType"))}
                        format={CURRENCY_FORMAT.CURRENCY2}
                        validator={requiredValidator}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Number of units" isMandatory />
                      <Field
                        name={nameOf("Flag_IsNumberofUnits")}
                        component={CCSwitch}
                        checked={
                          valueGetter(nameOf("Flag_IsNumberofUnits")) ?? false
                        }
                        validator={requiredValidator}
                      />
                    </div>
                    {valueGetter(nameOf("Flag_IsNumberofUnits")) ? (
                      <div className="cc-field">
                        <CCLabel title="Units label" />
                        <Field
                          name={nameOf("UnitsLabel")}
                          component={CCInput}
                          value={
                            valueGetter(nameOf("UnitsLabel")) ??
                            "Number of Units"
                          }
                          onChange={(e: InputChangeEvent) => {
                            onChange(nameOf("UnitsLabel"), { value: e.value });
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Fee option2" />
                  <Field
                    name={nameOf("FeeType2_ID")}
                    component={CCSearchComboBox}
                    data={inspectionTypeLovs?.FeeType ?? []}
                    dataItemKey={nameOfIKeyValuePacket("Key")}
                    textField={nameOfIKeyValuePacket("Value")}
                    value={getDropdownValue(
                      valueGetter(nameOf("FeeType2_ID")),
                      inspectionTypeLovs?.FeeType ?? [],
                      nameOfIKeyValuePacket("Key")
                    )}
                    onChange={handleChangeFeeOption2}
                  />
                </div>
                {!isNil(valueGetter(nameOf("FeeType2_ID"))) ? (
                  <>
                    <div className="cc-field">
                      <CCLabel title="Inherit fee" />
                      <Field
                        name={nameOf("Flag_InheritFeeType2")}
                        component={CCSwitch}
                        checked={
                          valueGetter(nameOf("Flag_InheritFeeType2")) ?? false
                        }
                        onChange={handleChangeInheritFee2}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Amount" isMandatory />
                      <Field
                        name={nameOf("FeeAmount2")}
                        component={CCNumericTextBox}
                        disabled={valueGetter(nameOf("Flag_InheritFeeType2"))}
                        format={CURRENCY_FORMAT.CURRENCY2}
                        validator={requiredValidator}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Number of units" isMandatory />
                      <Field
                        name={nameOf("Flag_IsNumberofUnits2")}
                        component={CCSwitch}
                        checked={
                          valueGetter(nameOf("Flag_IsNumberofUnits2")) ?? false
                        }
                        validator={requiredValidator}
                      />
                    </div>
                    {valueGetter(nameOf("Flag_IsNumberofUnits2")) ? (
                      <div className="cc-field">
                        <CCLabel title="Units label" />
                        <Field
                          name={nameOf("UnitsLabel2")}
                          component={CCInput}
                          value={
                            valueGetter(nameOf("UnitsLabel2")) ??
                            "Number of Units"
                          }
                          onChange={(e: InputChangeEvent) => {
                            onChange(nameOf("UnitsLabel2"), { value: e.value });
                          }}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>

              {/* #region Additional Fees*/}
              <div className="cc-form-cols-1">
                <AdditionalFees formRenderProps={formRenderProps} />
              </div>
              {/* #endregion Additional Fees */}
            </div>
          </section>

          <hr className="cc-divider" />

          <section className="cc-field-group">
            <CCLabel title="Mobile settings" />
            <div className="cc-field-group cc-custom-sub-panel-bar">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Default document" />
                  <Field
                    name={nameOf("InspectionReport_Document_IDs")}
                    component={CCSearchComboBox}
                    data={inspectionTypeLovs?.InspectionReport ?? []}
                    dataItemKey={nameOfIKeyValuePacket("Key")}
                    textField={nameOfIKeyValuePacket("Value")}
                    value={getDropdownValue(
                      valueGetter(nameOf("InspectionReport_Document_IDs"))
                        ? parseInt(
                            valueGetter(nameOf("InspectionReport_Document_IDs"))
                          )
                        : "",
                      inspectionTypeLovs?.InspectionReport ?? [],
                      nameOfIKeyValuePacket("Key")
                    )}
                    onChange={(event: ComboBoxChangeEvent) => {
                      onChange(nameOf("InspectionReport_Document_IDs"), {
                        value: event?.value?.Key,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="cc-form-cols-1">
                <CCLabel title="Documents selection" />
                <DocumentsSelection formRenderProps={formRenderProps} />
              </div>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel
                    title="Action type - sent"
                    customTooltip={
                      <CCTooltip
                        type="custom"
                        position="right"
                        content=" "
                        customTemplate={GetHelpText("1341")}
                      >
                        <i className="fa fa-info-circle ml-1 text-accent" />
                      </CCTooltip>
                    }
                  />
                  <Field
                    name={nameOf("InspectionReport_Send_ActionType_ID")}
                    component={CCSearchComboBox}
                    data={inspectionTypeLovs?.ActionType ?? []}
                    dataItemKey={nameOfIKeyValuePacket("Key")}
                    textField={nameOfIKeyValuePacket("Value")}
                    isUseDefaultOnchange
                  />
                </div>
                <div className="cc-field">
                  <CCLabel
                    title="Action type - to send"
                    customTooltip={
                      <CCTooltip
                        type="custom"
                        position="right"
                        content=" "
                        customTemplate={GetHelpText("1342")}
                      >
                        <i className="fa fa-info-circle ml-1 text-accent" />
                      </CCTooltip>
                    }
                  />
                  <Field
                    name={nameOf("InspectionReport_NotSend_ActionType_ID")}
                    component={CCSearchComboBox}
                    data={inspectionTypeLovs?.ActionType ?? []}
                    dataItemKey={nameOfIKeyValuePacket("Key")}
                    textField={nameOfIKeyValuePacket("Value")}
                    isUseDefaultOnchange
                  />
                </div>

                {isEnableInspectionAutoActions ? (
                  <>
                    <div className="cc-field">
                      <CCLabel title="Enable auto actions" />
                      <Field
                        name={nameOf("Flag_AutoAction")}
                        component={CCSwitch}
                        checked={
                          valueGetter(nameOf("Flag_AutoAction")) ?? false
                        }
                      />
                    </div>
                    {valueGetter(nameOf("Flag_AutoAction")) ? (
                      <div className="cc-field">
                        <CCLabel title="Auto action types" />
                        <Field
                          name={nameOf("ActionTypeIds")}
                          placeholder={"Auto action types"}
                          value={valueGetter(nameOf("ActionType_CSV"))}
                          component={ActionTypePicker}
                          selectableMode={"multiple"}
                          recordType={RECORDTYPE.CORE_ActionType}
                          showClearButton
                          onPickActionType={handlePickAutoActionTypes}
                          selectedItemIDs={valueGetter(nameOf("ActionTypeIds"))}
                        />
                      </div>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </section>

          <hr className="cc-divider" />
          <section className="cc-field-group">
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title="Inspection Checklist"
                  informationTooltip="Dynamic Checklist only available for Ready Community"
                />
                <Field
                  name={nameOf("InspectionTypeChecklist")}
                  placeholder={"Inspection type check list"}
                  component={DynamicQuestionPicker}
                  checklistType={ChecklistType.InspectionTypeChecklist}
                  selectableMode="single"
                  onPickDynamicQuestion={handlePickInspectionTypeChecklist}
                  textField={nameOfDynamicQuestion("DynamicQuestionList_Name")}
                />
              </div>
              {valueGetter(nameOf("InspectionTypeChecklist_ID")) && (
                <>
                  <div className="cc-field">
                    <CCLabel title="Inspection Checklist label" />
                    <Field
                      name={nameOf("InspectionTypeChecklist_Label")}
                      component={CCInput}
                      placeholder={"Checklist Label"}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="Is Inspection Checklist mandatory" />
                    <Field
                      name={nameOf("IsInspectionTypeChecklistMandatory")}
                      component={CCSwitch}
                      checked={valueGetter(
                        nameOf("IsInspectionTypeChecklistMandatory")
                      )}
                    />
                  </div>
                </>
              )}
            </div>
          </section>

          <hr className="cc-divider" />

          <section className="cc-field-group">
            <CCLabel title="Modules" />
            <div className="cc-field-group cc-custom-sub-panel-bar">
              <div className="cc-form-cols-3">
                <SystemFilter formRenderProps={formRenderProps} />
              </div>

              {isShowMobileUpdateActionType ? (
                <div className="cc-form-cols-3">
                  <div className="cc-field">
                    <CCLabel title="Mobile update action type" />
                    <Field
                      name={nameOf("TechOneActionTypeId")}
                      placeholder={"Mobile update action type"}
                      value={valueGetter(nameOf("TechOneActionType_CSV"))}
                      component={ActionTypePicker}
                      recordType={RECORDTYPE.CORE_ActionType}
                      showClearButton
                      onPickActionType={handlePickTechOneActionType}
                      selectedItemIDs={[
                        valueGetter(nameOf("TechOneActionTypeId")),
                      ]}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </section>

          {isShowAssessmentQuestionDetails ? (
            <>
              <hr className="cc-divider" />
              <section className="cc-field-group">
                <CCLabel title="Assessment question list" />
                <div className="cc-field-group cc-custom-sub-panel-bar">
                  <div className="cc-form-cols-3">
                    <div className="cc-field">
                      <CCLabel title="Assessment question list" />
                      <Field
                        name={nameOf("ApplicationAssessment")}
                        placeholder={"Action type check list"}
                        component={DynamicQuestionPicker}
                        checklistType={ChecklistType.LandManagementChecklist}
                        selectableMode="multiple"
                        onPickDynamicQuestion={
                          handleOnPickApplicationAssessment
                        }
                        textField={nameOfDynamicQuestion(
                          "DynamicQuestionList_Name"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : null}
        </FormElement>
      </>
    );
  }
);
