import ChangeInstalmentDialog from "@app/products/property/assessments/components/dialogs/change-instalments/_index";
import { CommunityProperty } from "@common/stores/products/config";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ChangeInstalmentButton = observer(() => {
  const { isLLS } = CommunityProperty.getFlagOfStates();
  const [showChangeInstalmentDialog, setShowChangeInstalmentDialog] =
    useState(false);

  return (
    <>
      <CCNavButton
        title="Change instalment"
        onClick={() => setShowChangeInstalmentDialog(true)}
        invisible={isLLS}
      />

      {showChangeInstalmentDialog && (
        <ChangeInstalmentDialog
          onClose={() => {
            setShowChangeInstalmentDialog(false);
          }}
        />
      )}
    </>
  );
});
