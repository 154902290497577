import { useIsNew } from "@common/hooks/useIsNew";
import { ISecurityTemplateNotificationSection } from "@common/pages/settings/security/security-templates/model";
import { ExistManageSecurityTemplate } from "@common/pages/settings/security/security-templates/_id/forms/existed/_index";
import { NewSecurityTemplate } from "@common/pages/settings/security/security-templates/_id/forms/new/_index";
import { useSecurityTemplateStore } from "@common/pages/settings/security/security-templates/_id/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageSecurityTemplate = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const securityTemplateId = parseInt(params.id);
  const { resetStore, loadSecurityTemplate, setNotificationSection } =
    useSecurityTemplateStore();

  useEffect(() => {
    if (location.state) {
      setNotificationSection(
        location.state as ISecurityTemplateNotificationSection
      );
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line
  }, [isNew]);

  useEffect(() => {
    loadSecurityTemplate(securityTemplateId, isNew);
    // eslint-disable-next-line
  }, [securityTemplateId, isNew]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  if (isNew) {
    return <NewSecurityTemplate />;
  }
  return <ExistManageSecurityTemplate />;
};

export default observer(ManageSecurityTemplate);
