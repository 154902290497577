import { ConcessionCards } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/components/detail/_index";
import { colOwnersConcessionCard } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/config";
import { concessionCardsFormValidator } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/util";
import {
  DTO_Owners,
  DTO_Rebate_Concession_Card,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";

const nameOfOwner = nameOfFactory<DTO_Owners>();
export const ConcessionCardsFormStep = (props: IFormStepElement) => {
  const { options } = props;
  const formValidator = useCallback((values) => {
    return concessionCardsFormValidator(values);
  }, []);

  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!options.isReadOnly ? formValidator : undefined}
    />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));

    const owners = getFieldValue("Owners") ?? [];

    return (
      <div className="cc-field">
        <CCLabel
          title="Concession cards held"
          informationTooltip="Presented date of the concession card(s) must be less than or equal to today's date"
          errorMessage={errors?.[nameOf("")]}
        />
        <CCGrid
          data={owners}
          primaryField={nameOfOwner("Entity_Id")}
          columnFields={colOwnersConcessionCard}
          readOnly={false}
          editableMode="cell"
          detail={(props: GridDetailRowProps) => (
            <ConcessionCards
              {...props}
              data={
                owners.find(
                  (item: DTO_Owners) =>
                    item.Entity_Id === props.dataItem.Entity_Id
                )?.ConcessionCards ?? []
              }
              options={{
                isReadOnly: options?.isReadOnly,
              }}
              handleDataChange={(dataItem: DTO_Rebate_Concession_Card[]) => {
                const index = owners.findIndex(
                  (item: DTO_Owners) =>
                    item.Entity_Id === props.dataItem.Entity_Id
                );
                if (index !== -1) {
                  // Create a new array with the updated element
                  const newOwners = cloneDeep(owners);
                  newOwners[index] = {
                    ...newOwners[index],
                    ConcessionCards: dataItem,
                  };
                  onChange(nameOf("Owners"), {
                    value: newOwners,
                  });
                }
              }}
            />
          )}
        />
      </div>
    );
  }
);
