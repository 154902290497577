import { odataCoreGetInspectionTypesByProductType } from "@app/core/components/common/utils";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { Svc_InspectionType } from "@common/models/inspectionType";
import { SettingsActionBarNavDropdown } from "@common/pages/settings/components/nav-dropdown/_index";
import { FormActionCheckPermission } from "@common/pages/settings/constant";
import { InspectionTypesDetailsTab } from "@common/pages/settings/lookups/inspection-types/components/sidebar/details/_index";
import { InspectionTypeHistoryTab } from "@common/pages/settings/lookups/inspection-types/components/sidebar/history/_index";
import { inspectionTypeConfig } from "@common/pages/settings/lookups/inspection-types/config";
import { SETTING_CORE_INSPECTIONTYPE } from "@common/pages/settings/lookups/inspection-types/constant";
import {
  inspectionTypeListsBookmark,
  inspectionTypeListsDetailsBookmark,
} from "@common/pages/settings/lookups/inspection-types/utils";
import { settingsRoute } from "@common/pages/settings/route";
import { usePermissionStore } from "@common/pages/settings/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<Svc_InspectionType>();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_InspectionType,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { hasPermission, checkPermissionForCurrentMenu, resetStore } =
    usePermissionStore();
  const history = useHistory();

  const handleCheckPermission = async () => {
    setIsLoading(true);
    await checkPermissionForCurrentMenu(
      FormActionCheckPermission.GLOBAL_SETTINGS,
      1
    );
    setIsLoading(false);
  };

  useEffectOnce(() => {
    handleCheckPermission();

    return () => {
      resetStore();
    };
  });

  useCCListViewActionBar({
    title: settingsRoute.name,
    leftComponents: [
      <SettingsActionBarNavDropdown category={settingsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton
        title="New"
        onClick={() => {
          history.push(`${SETTING_CORE_INSPECTIONTYPE}/new`);
        }}
      />,
      <DeleteButton
        recordType={RECORDTYPE.CORE_InspectionType}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon disabled={!hasPermission} />,
      <ActionBarFilterIcon disabled={!hasPermission} />,
      <ActionBarInfoIcon disabled={!hasPermission} />,
      <ListViewBookmarkIcon
        linkUrl={SETTING_CORE_INSPECTIONTYPE}
        productType={PRODUCT_TYPE.Core}
        recordType={RECORDTYPE.CORE_InspectionType}
        detail={inspectionTypeListsDetailsBookmark.getBookmarkDetail}
        displayName={inspectionTypeListsDetailsBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          inspectionTypeListsBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={inspectionTypeListsBookmark.getBookmarkListViewDetail}
        isDisabledRecordListView={!hasPermission}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <InspectionTypesDetailsTab />,
      },
      {
        title: "History",
        component: <InspectionTypeHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper
      isLoadingPermission={isLoadingPermission || isLoading}
    >
      <CCProductListView
        columnFields={inspectionTypeConfig}
        primaryField={nameOf("ID")}
        dataUrl={odataCoreGetInspectionTypesByProductType(
          PRODUCT_TYPE_NUMBER.Core
        )}
      />
    </LoadingPermissionWrapper>
  );
});
