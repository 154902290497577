import { loadViewResponseById } from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/api";
import { ViewResponse_Response } from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { formatDisplayValue } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const ViewResponseReferenceSideBarDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [viewResponseInfo, setViewResponseInfo] = useState<
    ViewResponse_Response | undefined
  >();
  const { lastSelectedRow } = useCCProductListViewStore();

  const params: { id: string } = useParams();
  const viewResponseId = params.id;
  useEffect(() => {
    if (lastSelectedRow?.Id || viewResponseId) {
      const ID: any = lastSelectedRow?.Id || viewResponseId;
      setIsLoading(true);
      loadViewResponseById(ID).then((data) => {
        setViewResponseInfo(data);
        setIsLoading(false);
      });
    }
  }, [lastSelectedRow, viewResponseId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!viewResponseInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <>
        <ProductReferenceBlock>
          <ProductReferenceRow
            title={"Request File Sent: "}
            value={formatDisplayValue(
              "22-Feb-2021 11:06",
              DATETIME_FORMAT.DATETIME
            )}
          />
          <ProductReferenceRow
            title={"Number of CentreLink Responses: "}
            value={"443"}
          />
          <ProductReferenceRow
            title={"Number of External Responses: "}
            value={"0"}
          />
          <ProductReferenceRow
            title={"Imported Responses On: "}
            value={formatDisplayValue(
              viewResponseInfo?.CentreLinkResponseBatch,
              DATETIME_FORMAT.DATETIME
            )}
          />
          <ProductReferenceRow
            title={"Imported Responses By: "}
            value={"SpagS"}
          />
        </ProductReferenceBlock>
      </>
    </ProductReferenceBody>
  );
});
