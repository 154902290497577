import { CCDialog } from "@components/cc-dialog/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";

interface ICreateRequestsDialog {
  onClose: () => void;
  onOkButton?: () => void;
}
export interface IDataGridDialog {
  SessionNumber: number;
  Name: string;
}
export const CreateRequests = ({
  onClose,
  onOkButton,
}: ICreateRequestsDialog) => {
  return (
    <div className="cc-create-requests-dialog">
      <Form
        initialValues={{
          Description: "",
          WithAnActiveAssessmentRebate: false,
          NotPreviouslyConfirmed: true,
        }}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <CCDialog
              height="auto"
              maxWidth="30%"
              titleHeader={"Create Requests"}
              onClose={onClose}
              bodyElement={
                <div className="cc-form">
                  <div className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Description of the batch of concession cards to be
                          verified with centrelink
                        </label>
                        <Field
                          name={"Description"}
                          rows={4}
                          component={CCTextArea}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Only include concession cards associated with an
                          active assessment rebate
                        </label>

                        <Field
                          name={"WithAnActiveAssessmentRebate"}
                          checked={formRenderProps.valueGetter(
                            "WithAnActiveAssessmentRebate"
                          )}
                          component={CCSwitch}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Only include concession cards not previously confirmed
                        </label>

                        <Field
                          name={"NotPreviouslyConfirmed"}
                          checked={formRenderProps.valueGetter(
                            "NotPreviouslyConfirmed"
                          )}
                          component={CCSwitch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              footerElement={
                <div className={"cc-dialog-footer-actions-right"}>
                  <Button className={"cc-dialog-button"} onClick={onClose}>
                    Cancel
                  </Button>

                  <Button
                    className={"cc-dialog-button"}
                    themeColor="primary"
                    onClick={onClose}
                  >
                    OK
                  </Button>
                </div>
              }
            />
          </FormElement>
        )}
      />
    </div>
  );
};
