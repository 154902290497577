import {
  ContactClassification,
  ContactExtension,
  ContactMethodPreferred,
  Contact_AddressBookFlag,
} from "@app/core/contacts/_id/model";
import { Attachment } from "@app/core/documents/model";
import {
  AddressBookSection,
  SiteUserType,
} from "@common/constants/enumerations";
import { Address, AddressBook } from "@common/input-pickers/address/model";
import {
  DBRowState,
  DTOBaseClass_Standard,
} from "@common/models/baseClassStandard";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { Keyword } from "@common/models/keyword";

export interface Contact extends DTOBaseClass_Standard {
  //TODO: Do not use these field for now
  //For places where they are used, keep them as they are to avoid impacts, will recheck later
  _WorkPhone: string;
  _HomePhone: string;
  _Fax: string;
  _Mobile: string;
  _PreferredMethod_Formatted?: string;

  //Use these fields instead of above ones
  WorkPhone: string;
  HomePhone: string;
  Fax: string;
  Mobile: string;

  Contact_ID: number;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  ContactClassification_ENUM: ContactClassification;
  ContactClassification_Name: string;
  PreferredMethod_ENUM: ContactMethodPreferred;
  PreferredMethod_Name: string;
  PreferredMethod_Formatted: string;
  NARNumber: string;
  NARUpdated: boolean | null;
  Flag_SendSMSAlerts: boolean;
  Queued_Sys_DBRowState: DBRowState | null;
  Custom_Tags: string;
  Officer_Ticket_Counter: number;
  Email: string;
  Website: string;
  ABN: string;
  ACN: string;
  VIP: boolean | null;
  VIPTypeID: number | null;
  VIPType: string;
  VIPRelationshipOwnerID: number | null;
  VIPRelationshipOwner: string;
  ContactRoles_Count: number;
  PreferredAddress: Address;
  Organisation: string;
  Preferred_ContactAddressType_KWD: number | null;
  Preferred_ContactAddressType_Name: string;
  PostalAddress: Address;
  Postal_ContactAddressType_KWD: number | null;
  Postal_ContactAddressType_Name: string;
  AlternateAddress: Address;
  Alternate_ContactAddressType_KWD: number | null;
  Alternate_ContactAddressType_Name: string;
  AddressBook_ID: number | null;
  AddressBook: AddressBook;
  AddressBookSection_ENUM: AddressBookSection;
  AddressBookFlag_ENUM: Contact_AddressBookFlag;
  CopiedFrom_Contact_ID: number;
  DisplayName_Format1: string;
  DisplayName_Format2: string;
  DisplayName_WithSalutation: string;
  DisplayName_PlusPhoneNumbers: string;
  FullContactDetails: string;
  FullContactDetailsWithBR: string;
  DisplayName_CM: string;
  SynchDate: string;
  _ExtensionType: ContactExtension[];
  Extension: Contact_Extension;
  Contact_CopiedFrom_PortalUser_MemberGUID: string | null;
  Contact_CopiedFrom_PortalUser_MemberUserName: string;
  ContactAlert: string;
  MergedContact_ID: number | null;
  Flag_Duplicate: boolean | null;
  IsAdministrativeAccount: boolean | null;
  ContactComments: string;
  Qualifications: string;
  IsTRIMContact: boolean | null;
  _SaveTriggers: ContactUpdateTriggers[];
  IsContactCopied: boolean;
  IDCardNumber: string;
  UserType_ENUM: SiteUserType | null;
  UserType_Name: string;
  Photo_Attachment_ID: number | null;
  Photo_Attachment: Attachment;
  Sign_Attachment_ID: number | null;
  Sign_Attachment: Attachment;
  AuthorisedActs_KWDs: string;
  Qualification_KWD: number | null;
  Qualification_Name: string;
  Date_IoA: string | null;
  Has_IoA_Location: boolean | null;
  Date_DeliveryLGIS: string | null;
  Signed_IoA_Attachment_ID: number | null;
  Signed_IoA_Attachment: Attachment;
  Date_CardOrginalIssue: string | null;
  Date_CardProduced: string | null;
  Date_CardReceivedSigned: string | null;
  Date_CardReIssue: string | null;
  Date_CardReIssueReceivedSigned: string | null;
  AQN: string;
  PortalStatus: string;
  ABNValid: boolean;
  ACNValid: boolean;
  GoldenRecord: boolean;
}

export enum ContactUpdateTriggers {
  UpdatePrimaryAddress,
  ClearPrimaryAddress,
  UpdateSecondaryAddress,
  ClearSecondaryAddress,
  UpdateOtherAddress,
  ClearOtherAddress,
  UpdateGroups,
  LoginProviders,
  Products,
  SecurityTemplates,
  UpdateContactAddressTypeFirst,
  UpdateContactAddressTypeSecond,
  UpdateContactAddressTypeOther,
  UpdatePartner1,
  UpdatePartner2,
  UpdatePartner3,
  UpdatePartner4,
  RemovePartner1,
  RemovePartner2,
  RemovePartner3,
  RemovePartner4,
  RearrangePartner,
  UpdateCMLastKnownAddress,
  ClearCMLastKnownAddress,
  UpdateVIPRelatioshipOwner,
  SwitchPrimaryAddress,
  SwitchSecondaryAddress,
  SwitchOtherAddress,
  BackfillApiPayloadObjectPartner1,
  BackfillApiPayloadObjectPartner2,
  BackfillApiPayloadObjectPartner3,
  BackfillApiPayloadObjectPartner4,
  BackfillApiPayloadObjectAddressBook,
}

export interface Contact_Extension {
  DateOfBirth: string | null;
  VerificationQuestion: string;
  VerificationResponse: string;
  JobTitle: string;
  Organisation: string;
  ContactAttention: string;
  ShowContactAttention: boolean | null;
  FSSCertificate: string;
  FSSCertificateExpiryDate: string | null;
  LicenceNo: string;
  FSSCertificateNo: string;
  CM_PlaceOfBirth: string;
  CM_Contactable: boolean | null;
  CM_Deceased: boolean | null;
  DebtorNo: string;
  CM_LastKnownAddress_Id: number | null;
  CM_DatePassedAway: string | null;
  CM_LastKnownAddress: Address;
  Gender: string;
  AgeAtDeath: number | null;
  Language_ID: number | null;
  Language: Keyword;
  SecondLanguage: string;
  DisabilityType_KWD: number | null;
  DisabilityDetails: string;
  Lst_Disability_Category: number[];
  Lst_Disability_SubCategory: number[];
  TTY: string;
  Prisoner: boolean | null;
  CRNNumber: string;
  AboriginalStatus_KWD: number | null;
  LanguageAtHome: boolean | null;
  LanguageAtHome_KWD: number | null;
  Officer_Ticket_Counter: number | null;
}

export interface Contact_LookaheadSearch {
  Classification: string;
  DisplayName: string;
  PreferredMethod: string;
  WorkPhone: string;
  HomePhone: string;
  Mobile: string;
  Email: string;
  Fax: string;
  Contact_DisplayName: string;
  Contact_PreferredMethod: string;
  Person_Salutation: string;
  Person_FirstName: string;
  Person_LastName: string;
  Person_Organisation: string;
  Person_JobTitle: string;
  Person_ABN: string;
  Company_TradingName: string;
  Company_ACN: string;
  Company_ABN: string;
  Company_Website: string;
  Company_ContactPerson: string;
}

export interface PSAR_Council_Contacts {
  ContactID: number;
  Contact_RID: number;
  DisplayName: string;
  DisplayAddress: string;
  DisplayContact: string;
}

export interface ImportContact_IP {
  PropNo: string;
  PropAddrLine1: string;
  PropAddrLine2: string;
  PropAddrLine3: string;
  Unit_no: string;
  house_no: string;
  street_name: string;
  Owners: string;
  PostalAddrLine1: string;
  PostalAddrLine2: string;
  PostalAddrLine3: string;
  PostalAddrLocationDesc: string;
  PostalAddrPropertyName: string;
  PostalAddrState: string;
  PostalAddrPostcode: string;
  PostalAddrCountry: string;
  AssessmentNumber: string;
  HomePhone: string;
  WorkPhone: string;
  Fax: string;
  Mobile: string;
  Email: string;
}

export interface ContactLovs {
  AddressBooks: IKeyValuePacket[];
  Classifications: IKeyValuePacket[];
  PreferredMethods: IKeyValuePacket[];
  PersonSalutations: IKeyValuePacket[];
  IncBodyTypes: IKeyValuePacket[];
  VIPTypes: IKeyValuePacket[];
  GenderPronouns: IKeyValuePacket[];
}

export interface Contact_JSON {
  ID: number;
  DisplayName: string;
  WorkPhone: string;
  HomePhone: string;
  Mobile: string;
  Email: string;
  AddressBookFlag: number;
}

export interface NARContact_JSON {
  ID: string;
  DisplayName: string;
  WorkPhone: string;
  HomePhone: string;
  Mobile: string;
  Email: string;
  Address: string;
}

export interface ContactLookahead_JSON {
  ID: number;
  DisplayName: string;
  Phone: string;
  ContactInfo: string;
  AddressBook: string;
  ProductType: string;
  AddressBookSection: string;
  Email: string;
  Organisation: string;
  GoldUser: string;
  Gender: string;
  Entity_Id: string;
  Entity_Name_Address_Id: number | null;
  EntityId_Name: string;
  Formatted_Name_Address: string;
  BlankSpacer: string;
}

export interface PreviewContact_JSON {
  ID: number;
  DisplayName: string;
  WorkPhone: string;
  HomePhone: string;
  Mobile: string;
  Email: string;
  AddressBookFlag: number;
}

export interface Contact_MatchFilters {
  ClientEnterprise: string;
  ContactClassification_Name: string;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  TradingName: string;
  Mobile: string;
  Email: string;
  AsyncRequestNo: number;
  FilterAll: boolean;
  FilterGlobal: boolean;
  FilterExisting: boolean;
  FilterProduct: string;
  FilterSection: string;
  FilterTags: string;
  ExcludePartnership: boolean;
  IsSearchSiteUser: boolean;
  Author_ID: number | null;
  IsContactNumber: boolean;
}

export interface CM_Contact_JSON {
  ID: number;
  DisplayName: string;
  Extension_DateOfBirth: string | null;
  Contact_DateOfBirth: string;
  Suburb: string;
  Extension_CM_Deceased: boolean | null;
  IsDeceased: string;
  Extension_CM_DatePassedAway: string | null;
  Contact_DatePassedAway: string;
  AddressBookFlag: number;
}

export interface CM_ContactLookahead_JSON {
  ID: number;
  DisplayName: string;
  Extension_DateOfBirth: string | null;
  Contact_DateOfBirth: string;
  Suburb: string;
  Extension_CM_Deceased: boolean | null;
  IsDeceased: string;
  Extension_CM_DatePassedAway: string | null;
  Contact_DatePassedAway: string;
  AddressBook: string;
  ProductType: string;
  AddressBookSection: string;
  BlankSpacer: string;
}

export interface CM_PreviewContact_JSON {
  ID: number;
  DisplayName: string;
  Extension_DateOfBirth: string | null;
  Contact_DateOfBirth: string;
  Suburb: string;
  Extension_CM_Deceased: boolean | null;
  IsDeceased: string;
  Extension_CM_DatePassedAway: string | null;
  Contact_DatePassedAway: string;
  AddressBookFlag: number;
}

export interface CM_Contact_MatchFilters {
  ClientEnterprise: string;
  ContactClassification_Name: string;
  ID: number;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  Extension_DateOfBirth: string | null;
  Contact_DateOfBirth: string;
  Suburb: string;
  Extension_CM_Deceased: boolean | null;
  IsDeceased: string;
  Extension_CM_DatePassedAway: string | null;
  Contact_DatePassedAway: string;
  AsyncRequestNo: number;
  FilterAll: boolean;
  FilterGlobal: boolean;
  FilterExisting: boolean;
  FilterProduct: string;
  FilterSection: string;
  FilterTags: string;
}
