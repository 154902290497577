import { DTO_ParcelZone } from "@app/products/property/parcels/[id]/components/child-screens/zones/model";

export const mockParcelZones: DTO_ParcelZone[] = [
  {
    ZoningScheme: "ZoningScheme1",
    ActiveTo: new Date(),
    ActiveFrom: new Date(),
    Name: "Name1",
    Code: "12345",
    ChangedOn: new Date(),
    ChangedBy: "Tin Nguyen",
    Id: 1,
  },
  {
    ZoningScheme: "ZoningScheme2",
    ActiveTo: new Date(),
    ActiveFrom: new Date(),
    Name: "Name2",
    Code: "67890",
    ChangedOn: new Date(),
    ChangedBy: "Tin Nguyen",
    Id: 2,
  },
  {
    ZoningScheme: "ZoningScheme3",
    ActiveTo: new Date(),
    ActiveFrom: new Date(),
    Name: "Name3",
    Code: "112233",
    ChangedOn: new Date(),
    ChangedBy: "Tin Nguyen",
    Id: 3,
  },
];
