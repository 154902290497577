import { IPrivateRouteProps } from "@/routes/model";
import { conditionReferenceRoute } from "@app/core/condition-reference/route";
import { conditionRoute } from "@app/core/condition/route";
import { contactRoute } from "@app/core/contacts/route";
import { feesRoute } from "@app/core/fees/route";
import { furtherInfoRoute } from "@app/core/further-info/route";
import { inspectionsRoute } from "@app/core/inspections/route";
import { invoiceRoute } from "@app/core/invoice/route";
import { locationRegisterRoute } from "@app/core/location-register/route";
import { MailMergeDialog } from "@app/core/mail-merge/form-steps/_index";
import { noticesRoute } from "@app/core/notices/route";
import InspectionAssessment from "@app/products/ams/components/inspection/InspectionAssessment";
import KanbanBoard from "@app/products/ams/components/kanban/KanbanBoard";
import ManageCategory from "@app/products/ams/forms/ManageCategory";
import ManageWorkOrder from "@app/products/ams/forms/ManageWorkOrder";
import BookingCalendar from "@app/products/event-management/components/booking-calendar/_index";
import AuthService from "@common/apis/auth.service";
import { GeneralRoute } from "@common/constants/menu";
import { actionsRoute } from "@common/pages/actions/route";
import { appealRoute } from "@common/pages/appeal/route";
import { ChangePasswordPage } from "@common/pages/change-password/_index";
import { ManageContactRelationship } from "@common/pages/contacts/contact-relationship/_index";
import ErrorDialog from "@common/pages/error/_index";
import { ForgotPasswordPage } from "@common/pages/forgot-password/_index";
import { HomePage } from "@common/pages/home/_index";
import { LoginPage } from "@common/pages/login/_index";
import { setSessionRedirectURL } from "@common/pages/login/util";
import ManageProfile from "@common/pages/profile/_index";
import { reportsRoute } from "@common/pages/report/route";
import { ResetPasswordPage } from "@common/pages/reset-password/_index";
import { Search } from "@common/pages/search/_index";
import MailMergeDownload from "@common/pages/settings/mail-merge";
import Loading from "@components/loading/Loading";
import { ActionsRouter } from "@routes/ActionsRouter";
import { AppealRoute } from "@routes/AppealRoute";
import { ConditionReferenceRouter } from "@routes/ConditionReferenceRouter";
import { ConditionRouter } from "@routes/ConditionRouter";
import { OldContactRouter } from "@routes/ContactRouter";
import { FeesRouter } from "@routes/FeesRouter";
import { InspectionsRouter } from "@routes/InspectionsRouter";
import { InvoiceRouter } from "@routes/InvoiceRouter";
import { LocationRegisterRouter } from "@routes/LocationRegisterRouter";
import { NoticesRouter } from "@routes/NoticesRouter";
import { ReportsRouter } from "@routes/ReportRouter";
import { SettingRouter } from "@routes/SettingRouter";
import { ContactRoute } from "@routes/core/ContactRoute";
import { FurtherInfoRoute } from "@routes/core/FurtherInfoRoute";
import { IProductRouteProps, ProductRoute } from "@routes/product-route/_index";
import { propsProductsRoute } from "@routes/product-route/config";
import { createBrowserHistory } from "history";
import { observer } from "mobx-react-lite";
import React, { Suspense, useEffect } from "react";
import { Redirect, Route, Router, Switch, useLocation } from "react-router-dom";
export const history = createBrowserHistory();

export const AppRoutes: React.FC = observer(() => {
  // const HomePage = React.lazy(() => import("./app/core/home/HomePage"));
  const Layout = React.lazy(() => import("./components/layout/Layout"));

  return (
    <Router history={history}>
      <Suspense fallback={<Loading isLoading isFullScreen />}>
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/forgot-password" component={ForgotPasswordPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route path="/change-password" component={ChangePasswordPage} />
          <Route exact path="/">
            <Redirect to="/login" exact />
          </Route>
          <Layout>
            <PrivateRoute exact path="/home" component={HomePage} />
            <PrivateRoute path={["/profile"]} component={ManageProfile} />
            <PrivateRoute
              path={["/mailmerge/download"]}
              component={MailMergeDownload}
            />
            <PrivateRoute
              path={["/mailmergeselection"]}
              component={MailMergeDialog}
            />

            {propsProductsRoute.map(
              (product: IProductRouteProps, index: number) => (
                <ProductRoute key={index} {...product} />
              )
            )}

            <Route path={`/${actionsRoute.path}`} component={ActionsRouter} />

            <Route
              path={`/core/${inspectionsRoute.path}`}
              component={InspectionsRouter}
            />

            <Route
              path={`/core/${conditionRoute.path}`}
              component={ConditionRouter}
            />

            <Route
              path={`/core/${conditionReferenceRoute.path}`}
              component={ConditionReferenceRouter}
            />

            <Route
              path={`/core/${locationRegisterRoute.path}`}
              component={LocationRegisterRouter}
            />

            <Route
              path={`/core/${invoiceRoute.path}`}
              component={InvoiceRouter}
            />

            <Route path={`/${noticesRoute.path}`} component={NoticesRouter} />

            <Route path={`/core/${feesRoute.path}`} component={FeesRouter} />

            <Route path={`/${appealRoute.path}`} component={AppealRoute} />

            <Route path={`/${reportsRoute.path}`} component={ReportsRouter} />
            <Route
              path={`/core/${furtherInfoRoute.path}`}
              component={FurtherInfoRoute}
            />
            <Route path={`/${contactRoute.path}`} component={ContactRoute} />
            <Route
              path={GeneralRoute.SETTINGS_PATH}
              component={SettingRouter}
            />

            <Route
              path={`${GeneralRoute.CONTACTS_PATH}`}
              component={OldContactRouter}
            />

            {/* <PrivateRoute path="/workorder" component={WorkOrderGrid} /> */}

            <PrivateRoute
              path={["/newworkorder", "/manageWorkOrder/:id"]}
              component={ManageWorkOrder}
            />

            <Route path={"/managecategory"} component={ManageCategory} />

            <Route
              path={"/manageassessment"}
              component={InspectionAssessment}
            />

            <Route path={"/managekanban"} component={KanbanBoard} />

            {/* <PrivateRoute
              path={[
                "/newaction/:parentid?/:parenttypeenum",
                "/manageaction/:id",
              ]}
              component={(props: any) => (
                <ManageAction
                  Id={props.match.params.id}
                  ParentId={props.match.params.parentid}
                  ParentType_ENUM={props.match.params.parenttypeenum}
                />
              )}
            /> */}

            <PrivateRoute
              path={["/managecontactrelationship/:id"]}
              component={ManageContactRelationship}
            />

            <PrivateRoute
              path={["/managefacilitybookingcalendar/:id"]}
              component={(props: any) => (
                <BookingCalendar proprietorRID={props.match.params.id} />
              )}
            />

            <PrivateRoute path={["/search"]} component={Search} />
            <PrivateRoute
              path={["/error"]}
              component={() => <ErrorDialog error="" />}
            />
          </Layout>
        </Switch>
      </Suspense>
    </Router>
  );
});

export const PrivateRoute = (props: IPrivateRouteProps) => {
  const location = useLocation();
  // Get action bar state in setting page

  useEffect(() => {
    if (!AuthService.getCurrentUser()) {
      setSessionRedirectURL();
      history.push("/login");
    }
  }, [location]);

  const { component, ...options } = props;

  return <Route {...options} exact={true} component={component} />;
};
