import { KEY_ENTER } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/components/custom-cells/constant";
import { DATE_FORMAT } from "@common/constants/common-format";
import { ICustomEditCell } from "@components/cc-grid/model";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import React, { useEffect, useState } from "react";

export const DateInputCell = ({
  value,
  onChange,
  dataIndex,
  dataItem,
  field,
}: ICustomEditCell) => {
  const [data, setData] = useState<any>({ value: value });

  useEffect(() => {
    const keydownEnterHandler = (e: any) => {
      if (e.key === KEY_ENTER) {
        if (onChange) onChange(data);
      }
    };

    document
      .getElementById("cc-date-input")
      ?.addEventListener("keydown", keydownEnterHandler);

    return () => {
      document
        .getElementById("cc-date-input")
        ?.removeEventListener("keydown", keydownEnterHandler);
    };
  }, [data, onChange]);

  return (
    <DatePicker
      id="cc-date-input"
      value={data?.value ?? null}
      onChange={(event: DatePickerChangeEvent) => {
        setData({
          field: field,
          dataIndex: dataIndex || 0,
          dataItem,
          syntheticEvent: event.syntheticEvent,
          value: event.value,
        });
      }}
      onBlur={() => {
        if (field && onChange) {
          onChange(data);
        }
      }}
      format={DATE_FORMAT.DATE_CONTROL}
      max={new Date()}
    />
  );
};
