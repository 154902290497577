import {
  Address,
  AddressClassification,
  FORMATTYPE_ADDRESS,
  PropertyAssessment,
} from "@app/products/waste-water/[id]/model";
import {
  BOOLEAN_FORMAT,
  COMMON_FORMAT,
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { format, formatDate, formatNumber } from "@telerik/kendo-intl";
import { isEmpty, isNil, isNumber, trim } from "lodash";

export const formatCurrency = (price: number = 0): string => {
  let formatValue = formatNumber(price, CURRENCY_FORMAT.CURRENCY1);
  return formatValue;
};

export const formatAssessmentNumberInMasked = (
  assessmentNumber: number | string
): string => {
  if (!assessmentNumber) return "";
  const assessmentNumberStr = isNumber(assessmentNumber)
    ? String(assessmentNumber)
    : assessmentNumber.trim();

  const formatValue = format(
    "{0:n}{1:n}{2:n}-{3:n}{4:n}{5:n}-{6:n}{7:n}{8:n}{9:n}-{10:n}{11:n}{12:n}", //mask="000-000-0000-000"
    Array.from(assessmentNumberStr)
  );

  return trim(formatValue, "-");
};

export const formatPropertyCurrency = (price: number = 0): string => {
  return formatNumber(price, CURRENCY_FORMAT.CURRENCY1);
};

export const formatPropertyPercentage = (num: number = 0): string => {
  return formatNumber(num, PERCENTAGE_FORMAT.PERCENTAGE);
};

export const formatFloatingNumber = (num: number = 0): string => {
  return formatNumber(num, NUMBER_FORMAT.NUMBER1);
};

export const formatRichText = (input: string): string => {
  return input.replaceAll("m<sup>2</sup>", "m²");
};

export const formatStyleCurrency = (value?: number): React.CSSProperties => {
  if (value === null || value === undefined) return {};
  let style: React.CSSProperties = {
    color: "var(--disabled)",
  };
  if (value > 0) {
    style.color = "var(--accent)";
  } else if (value < 0) {
    style.color = "var(--error)";
  }
  return style;
};

export const formatStyleUnRelevant = (): React.CSSProperties => {
  return {
    color: "var(--disabled)",
  };
};

export const formatStyleProminent = (): React.CSSProperties => {
  return {
    fontWeight: 500,
  };
};

export const formatOSFees = (value?: number): React.CSSProperties => {
  let style: React.CSSProperties = {};
  if (isNil(value) || !isNumber(value)) return {};
  if (value > 0) {
    style.color = "var(--currency-red)";
    style.fontWeight = "bold";
  } else if (value < 0) {
    style.fontWeight = "bold";
  }
  return style;
};

export const formatStyleAlignRight = (): React.CSSProperties => {
  return {
    textAlign: "right",
  };
};

export const formatStyleRightSideBarImage = (): React.CSSProperties => {
  let style: React.CSSProperties = {};
  style.width = "50%";
  style.display = "block";
  style.marginLeft = "auto";
  style.marginRight = "auto";
  return style;
};

export const formatDateByKendo = (
  date: any,
  format: DATE_FORMAT | DATETIME_FORMAT = DATE_FORMAT.DATE,
  locale?: string
) => {
  if (isNil(date)) return "";
  return formatDate(new Date(date), format, locale);
};

export const formatNumberDynamic = (
  value: number | null | undefined,
  format: string
): string => {
  if (isNil(value)) return "";
  return formatNumber(value, format);
};

export const formatNumberByKendo = (
  num: number | null | undefined,
  format: NUMBER_FORMAT | CURRENCY_FORMAT | PERCENTAGE_FORMAT,
  locale?: string
): string => {
  if (num === null || num === undefined) return "";
  return formatNumber(num, format, locale);
};

export const formatIconValue = (value: any, format: ICON_FORMAT) => {
  switch (format) {
    case ICON_FORMAT.ICON_BOOLEAN:
      return value
        ? sanitizeHtml(
            "<i class='fas fa-check text-success' style='padding-left:40%;'></i>"
          )
        : sanitizeHtml(
            "<i class='fas fa-times text-danger' style='padding-left:40%;'></i>"
          );
    case ICON_FORMAT.ICON_BOOLEAN_WITHOUT_CROSS:
      return value
        ? sanitizeHtml(
            "<i class='fas fa-check text-success' style='padding-left:40%;'></i>"
          )
        : null;
    case ICON_FORMAT.ICON_STANDARD_REPORT:
      return sanitizeHtml(
        "<i class='fas fa-chart-bar' style='padding-left:40%;'></i>"
      );
    case ICON_FORMAT.ICON_SHARE_REPORT:
      return sanitizeHtml(
        "<i class='fas fa-share-alt' style='padding-left:40%;'></i>"
      );
    case ICON_FORMAT.ICON_URGENT:
      return value
        ? sanitizeHtml(
            "<i title='Urgent' class='far fa-exclamation-triangle' style='color: var(--warning);'></i>"
          )
        : false;
    case ICON_FORMAT.ICON_CALL_RETURN:
      return value
        ? sanitizeHtml(
            "<i title='Return Call Required' class='far fa-phone-alt' style='color:green;'></i>"
          )
        : false;
    case ICON_FORMAT.ICON_HAZARD:
      return value
        ? sanitizeHtml(
            "<i title='Hazard' class='far fa-bolt' style='color:rgb(239,57,11);'></i>"
          )
        : false;
    case ICON_FORMAT.ICON_PADLOCK:
      return sanitizeHtml(
        "<i class='fa fa-lock' style='padding-left:40%;'></i>"
      );
    default:
      return value;
  }
};

export const formatBooleanValue = (value: any, format: BOOLEAN_FORMAT) => {
  if (value === null || value === undefined) return "";
  switch (format) {
    case BOOLEAN_FORMAT.BOOLEAN:
      return value ? "Yes" : "No";
    default:
      return value;
  }
};

export const styleAlignRight = [
  DATE_FORMAT.DATE,
  DATETIME_FORMAT.DATETIME,
  CURRENCY_FORMAT.CURRENCY1,
  NUMBER_FORMAT.NUMBER1,
  PERCENTAGE_FORMAT.PERCENTAGE,
  NUMBER_FORMAT.NUMBER2,
];

export const styleAlignRightGridEditing = [
  "{0:a2}",
  "{0:##,#}",
  "{0:dd MMM yyyy}",
];

//Convert size in bytes to human readable format
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes <= 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const splitCamelCase = (input: string = "") => {
  if (typeof input === "string") {
    return input.replace(/([a-z](?=[A-Z]))/g, "$1 ");
  }
  return input;
};

export const capitalizeFirstLetter = (input: string | undefined | null) => {
  var newInput = input?.trim();
  if (!newInput) return undefined;
  return (
    newInput.trim().charAt(0).toUpperCase() + newInput.slice(1).toLowerCase()
  );
};

// display text with threedot by limit character
export const displayDotByLimit = (input: string, limit: number) => {
  // check if limit is <0 => wrong logic force to default 15 char limit
  limit = limit > 0 ? limit : 15;
  var baseLimit = limit;
  // check if length > limit
  if (input.length > limit) {
    var ch = input[limit];
    // check if current limit is at space char
    do {
      if (limit > input.length) {
        limit = baseLimit;
        break;
      }
      limit += 1;
      ch = input[limit];
    } while (ch !== " " && ch !== "\t" && ch !== "\n");
    // slice from 0 > limit
    return input.slice(0, limit) + "...";
  }
  return input;
};

export const formatDisplayValue = (value: any, format?: string) => {
  if (value === undefined && format !== ICON_FORMAT.ICON_BOOLEAN) {
    return "";
  }

  switch (format) {
    case BOOLEAN_FORMAT.BOOLEAN:
      return value ? "Yes" : "No";
    case ICON_FORMAT.ICON_BOOLEAN:
      return value
        ? sanitizeHtml(
            "<i class='fas fa-check text-success' style='padding-left:40%;'></i>"
          )
        : sanitizeHtml(
            "<i class='fas fa-times text-danger' style='padding-left:40%;'></i>"
          );
    case ICON_FORMAT.ICON_BOOLEAN_WITHOUT_CROSS:
      return value
        ? sanitizeHtml(
            "<i class='fas fa-check text-success' style='padding-left:40%;'></i>"
          )
        : null;
    case ICON_FORMAT.ICON_STANDARD_REPORT:
      return sanitizeHtml(
        "<i class='fas fa-chart-bar' style='padding-left:40%;'></i>"
      );
    case ICON_FORMAT.ICON_SHARE_REPORT:
      return sanitizeHtml(
        "<i class='fas fa-share-alt' style='padding-left:40%;'></i>"
      );
    case ICON_FORMAT.ICON_PADLOCK:
      return sanitizeHtml(
        "<i class='fa fa-lock' style='padding-left:40%;'></i>"
      );
    case COMMON_FORMAT.COST_GRID:
      return formatCurrency(value);
    case DATE_FORMAT.DATE:
    case COMMON_FORMAT.PROPERTYGRID_DATE:
      return formatDateByKendo(value);
    case DATE_FORMAT.MONTH_YEAR:
      return formatDateByKendo(value, DATE_FORMAT.MONTH_YEAR);
    case CURRENCY_FORMAT.CURRENCY1:
    case COMMON_FORMAT.PROPERTYGRID_CURRENCY:
      return formatPropertyCurrency(value);
    case NUMBER_FORMAT.NUMBER1:
      return formatFloatingNumber(value);
    case DATETIME_FORMAT.DATETIME:
      return formatDateByKendo(value, DATETIME_FORMAT.DATETIME);
    case PERCENTAGE_FORMAT.PERCENTAGE:
      return formatPropertyPercentage(value);
    default:
      return value;
  }
};

export const getFullAddressHTML = (address?: Address) => {
  if (!address) return "";
  // Handle for the data return string or number
  let addressClassificationEnum = parseInt(
    AddressClassification[address.AddressClassification_ENUM]
  );
  if (isNaN(addressClassificationEnum))
    addressClassificationEnum = address.AddressClassification_ENUM;
  if (
    addressClassificationEnum === AddressClassification.International ||
    addressClassificationEnum === AddressClassification.Consultant_Address
  )
    return formatAddressInternational(
      FORMATTYPE_ADDRESS.HTML,
      address.PropertyName,
      address.AddressLine1,
      address.AddressLine2,
      address.Suburb,
      address.Postcode,
      address.Country,
      address.Location_Description,
      address.State
    );
  else if (addressClassificationEnum === AddressClassification.Line_Address)
    return formatAddressTypeLine(
      FORMATTYPE_ADDRESS.HTML,
      address.StreetName,
      address.AddressLine1,
      address.AddressLine2,
      address.AddressLine3
    );
  else {
    let LotNo = "";
    let streetSuffix = "";
    if (
      !isNil(address.PropertyAssessment) &&
      address.PropertyAssessment.LotNo &&
      address.PropertyAssessment.LotNo.trim() !== ""
    )
      LotNo = address.PropertyAssessment.LotNo;

    if (
      !isNil(address?.PropertyAssessment) &&
      !isEmpty(address.PropertyAssessment.Street_Suffix)
    )
      streetSuffix = address.PropertyAssessment.Street_Suffix;

    return formatAddress(
      FORMATTYPE_ADDRESS.HTML,
      address.PropertyName,
      address.AddressBoxType_Name,
      address.PoBoxNo,
      address.UnitNo,
      address.StreetNo,
      address.StreetName,
      address.Suburb,
      address.State,
      address.Postcode,
      address.Country,
      address.Location_Description,
      addressClassificationEnum === AddressClassification.POBox ||
        addressClassificationEnum === AddressClassification.DFSV_Area_POBox,
      address.Flag_StreetName_HasParts,
      address.StreetNamePart_Name,
      address.StreetNamePart_Type,
      LotNo,
      streetSuffix
    );
  }
};

export const getFullAddressSingleLine = (address?: Address) => {
  if (!address) return "";
  if (
    address?.AddressClassification_ENUM ===
      AddressClassification.International ||
    address?.AddressClassification_ENUM ===
      AddressClassification.Consultant_Address
  ) {
    return formatAddressInternational(
      FORMATTYPE_ADDRESS.SINGLELINE,
      address.PropertyName,
      address.AddressLine1,
      address.AddressLine2,
      address.Suburb,
      address.Postcode,
      address.Country,
      address.Location_Description,
      address.State
    );
  } else if (
    address?.AddressClassification_ENUM === AddressClassification.Line_Address
  ) {
    return formatAddressTypeLine(
      FORMATTYPE_ADDRESS.SINGLELINE,
      address.StreetName,
      address.AddressLine1,
      address.AddressLine2,
      address.AddressLine3
    );
  } else {
    let LotNo = "";
    if (
      !isNil(address?.PropertyAssessment) &&
      isEmpty(address.PropertyAssessment.LotNo) === false
    )
      LotNo = address.PropertyAssessment.LotNo;

    return formatAddress(
      FORMATTYPE_ADDRESS.SINGLELINE,
      address.PropertyName,
      address.AddressBoxType_Name,
      address.PoBoxNo,
      address.UnitNo,
      address.StreetNo,
      address.StreetName,
      address.Suburb,
      address.State,
      address.Postcode,
      address.Country,
      address.Location_Description,
      address.AddressClassification_ENUM === AddressClassification.POBox ||
        address.AddressClassification_ENUM ===
          AddressClassification.DFSV_Area_POBox,
      address.Flag_StreetName_HasParts,
      address.StreetNamePart_Name,
      address.StreetNamePart_Type,
      LotNo
    );
  }
};

export const formatAddressInternational = (
  addressFormat: FORMATTYPE_ADDRESS,
  propertyName: string,
  addressLine1: string,
  addressLine2: string,
  suburb: string,
  postcode: string,
  country: string,
  careOf: string,
  state: string
) => {
  let sb = "";
  let separator = ", ";

  if (isNil(careOf)) careOf = "";
  else careOf = careOf.trim();

  if (isNil(propertyName)) propertyName = "";
  else propertyName = propertyName.trim();

  if (isNil(addressLine1)) addressLine1 = "";
  else addressLine1 = addressLine1.trim();

  if (isNil(addressLine2)) addressLine2 = "";
  else addressLine2 = addressLine2.trim();

  if (isNil(suburb)) suburb = "";
  else suburb = suburb.trim();

  if (isNil(state)) state = "";
  else state = state.trim();

  if (isNil(postcode)) postcode = "";
  else postcode = postcode.trim();

  if (isNil(country)) country = "";
  else country = country.trim();

  if (
    careOf === "" &&
    propertyName === "" &&
    addressLine1 === "" &&
    addressLine2 === "" &&
    suburb === "" &&
    country === "" &&
    postcode === ""
  )
    return "";
  else {
    // EXPECTED OUTPUT : PropertyName + ", " + AddressLine1 + ", " + AddressLine2 + ", " + Suburb + ", " + State + ", " + Country + " " + Postcode;

    sb += careOf;

    if (sb.length > 0) sb += separator;
    sb += propertyName;

    if (sb.length > 0) sb += separator;
    sb += addressLine1;
    if (sb.length > 0 && addressLine2 !== "") sb += separator;
    sb += addressLine2;
    if (sb.length > 0 && suburb !== "") sb += separator;
    sb += suburb;

    if (sb.length > 0 && state !== "") sb += separator;
    sb += state;
    if (sb.length > 0 && country !== "") sb += separator;
    sb += country;
    if (sb.length > 0 && postcode !== "") sb += " ";
    sb += postcode;
  }

  return sb.toString().replace("  ", " ").trim();
};

const formatAddressTypeLine = (
  AddressFormat: FORMATTYPE_ADDRESS,
  StreetName: string,
  AddressLine1: string,
  AddressLine2: string,
  AddressLine3: string
) => {
  let sb = "";
  let separator = ", ";

  if (isNil(StreetName)) StreetName = "";
  else StreetName = StreetName.trim();

  if (isNil(AddressLine1)) AddressLine1 = "";
  else AddressLine1 = AddressLine1.trim();

  if (isNil(AddressLine2)) AddressLine2 = "";
  else AddressLine2 = AddressLine2.trim();

  if (isNil(AddressLine3)) AddressLine3 = "";
  else AddressLine3 = AddressLine3.trim();

  if (
    StreetName === "" &&
    AddressLine1 === "" &&
    AddressLine2 === "" &&
    AddressLine3 === ""
  )
    return "";
  else {
    switch (AddressFormat) {
      case FORMATTYPE_ADDRESS.HTML:
        separator = "<BR />";
        break;
      case FORMATTYPE_ADDRESS.VBSTRING:
        separator = "\n";
        break;
      case FORMATTYPE_ADDRESS.SINGLELINE:
        separator = ", ";
        break;
      case FORMATTYPE_ADDRESS.GOOGLEMAPS:
        separator = ", ";
        break;
    }

    sb += StreetName;

    if (sb.length > 0 && AddressLine1 !== "") sb += separator;
    sb += AddressLine1;
    if (sb.length > 0 && AddressLine2 !== "") sb += separator;
    sb += AddressLine2;

    if (sb.length > 0 && AddressLine3 !== "") sb += separator;
    sb += AddressLine3;
  }

  return sb.toString().replace("  ", " ").trim();
};

const formatAddress = (
  AddressFormat: FORMATTYPE_ADDRESS,
  PropertyName: string,
  AddressBoxType_Name: string,
  PoBoxNo: string,
  UnitNo: string,
  StreetNo: string,
  StreetName: string,
  Suburb: string,
  State: string,
  Postcode: string,
  Country: string,
  Location_Description: string,
  IsPoBox: boolean,
  Flag_StreetName_HasParts: boolean,
  StreetNamePart_Name: string,
  StreetNamePart_Type: string,
  LotNo = "",
  streetSuffix = ""
) => {
  let sb = "";
  let separator = ", ";

  if (isNil(PropertyName)) PropertyName = "";
  else PropertyName = PropertyName.trim();

  if (isNil(AddressBoxType_Name)) AddressBoxType_Name = "";
  else AddressBoxType_Name = AddressBoxType_Name.trim();

  if (isNil(PoBoxNo)) PoBoxNo = "";
  else PoBoxNo = PoBoxNo.trim();

  if (isNil(UnitNo)) UnitNo = "";
  else UnitNo = UnitNo.trim();

  if (isNil(StreetNo)) StreetNo = "";
  else StreetNo = StreetNo.trim();

  //added to show lot no - must be done after street no check
  if (isEmpty(StreetNo) && isEmpty(LotNo) === false)
    StreetNo = "Lot " + LotNo.trim();

  if (isNil(StreetName)) StreetName = "";
  else StreetName = StreetName.trim();

  if (Flag_StreetName_HasParts)
    StreetName = (
      (StreetNamePart_Name ?? "") +
      " " +
      (StreetNamePart_Type ?? "")
    ).trim();

  if (isNil(Suburb)) Suburb = "";
  else Suburb = Suburb.trim();

  if (isNil(State)) State = "";
  else State = State.trim();

  if (isNil(Postcode)) Postcode = "";
  else Postcode = Postcode.trim();

  if (isNil(Country)) Country = "";
  else Country = Country.trim();

  if (isNil(Location_Description)) Location_Description = "";
  else Location_Description = Location_Description.trim();

  if (
    PropertyName === "" &&
    AddressBoxType_Name === "" &&
    PoBoxNo === "" &&
    UnitNo === "" &&
    StreetNo === "" &&
    StreetName === "" &&
    Suburb === "" &&
    State === "" &&
    Postcode === "" &&
    Location_Description === ""
  )
    return "";
  else {
    if (IsPoBox) {
      // EXPECTED OUTPUT : AddressBoxType_Name + " " + PoBoxNo + ", " + Suburb + ", " + State + " " + Postcode;
      sb += Location_Description;
      if (sb.length > 0 && !isEmpty(AddressBoxType_Name)) sb += separator;
      sb += AddressBoxType_Name;
      if (sb.length > 0 && !isEmpty(PoBoxNo)) sb += " ";
      sb += PoBoxNo;

      if (sb.length > 0 && !isEmpty(Suburb)) sb += separator;
      sb += Suburb;

      if (sb.length > 0 && (!isEmpty(State) || !isEmpty(Postcode))) sb += " ";
      sb += State;
      if (!isEmpty(State)) sb += " ";
      sb += Postcode;
    } else {
      // EXPECTED OUTPUT : PropertyName + ", " + UnitNo + "/" + StreetNo + " " + StreetName + ", " + Suburb + ", " + State + " " + Postcode;
      // EXPECTED OUTPUT : PropertyName + ", " + StreetNo + " " + StreetName + ", " + Suburb + ", " + State + " " + Postcode;
      // EXPECTED OUTPUT : PropertyName + ", " + StreetName + ", " + Suburb + ", " + State + " " + Postcode;
      // EXPECTED OUTPUT : PropertyName + ", " + StreetName + ", " + State + " " + Postcode;
      // EXPECTED OUTPUT : Location_Description + ", " + State
      sb += PropertyName;
      if (sb.length > 0 && !isEmpty(PropertyName)) sb += separator;
      sb += Location_Description;

      if (!isEmpty(UnitNo) && !isEmpty(StreetNo) && !isEmpty(StreetName)) {
        if (sb.length > 0) sb += separator;

        sb += `${UnitNo}/${StreetNo} ${StreetName}`;
        if (!isEmpty(streetSuffix)) sb += ` ${streetSuffix}`;
      } else if (!isEmpty(StreetNo) && !isEmpty(StreetName)) {
        if (sb.length > 0) sb += separator;

        sb += `${StreetNo} ${StreetName}`;
        if (!isEmpty(streetSuffix)) sb += ` ${streetSuffix}`;
      } else if (!isEmpty(StreetName)) {
        if (sb.length > 0) sb += separator;
        sb += StreetName;
        if (!isEmpty(streetSuffix)) sb += ` ${streetSuffix}`;
      } else if (!isEmpty(StreetNo) && isEmpty(StreetName)) {
        if (sb.length > 0) sb += separator;

        sb += StreetNo;
        //sb += (" " + StreetName);
      }

      if (sb.length > 0 && !isEmpty(Suburb)) sb += separator;
      sb += Suburb;

      // if (sb.length > 0 && (State != String.Empty || Postcode != String.Empty)) sb += (separator);
      if (sb.length > 0 && !isEmpty(State)) sb += " ";
      sb += State;
      if (sb.length > 0 && !isEmpty(Postcode)) sb += " ";
      sb += Postcode;
    }
  }

  return sb.toString().replace(", ,", ", ").replace("  ", " ").trim();
};

export const getOwnersDisplay = (propertyAssessment: PropertyAssessment) => {
  let OwnersDisplay = "";

  OwnersDisplay = propertyAssessment?.Owners_Name;

  if (isEmpty(propertyAssessment?.Owners_Care_Of) === false) {
    if (isEmpty(OwnersDisplay))
      OwnersDisplay = `C/O  ${propertyAssessment?.Owners_Care_Of}`;
    else OwnersDisplay += `<BR/>C/O ${propertyAssessment?.Owners_Care_Of}`;
  }

  if (isEmpty(propertyAssessment?.Owners_Address) === false) {
    if (isEmpty(OwnersDisplay))
      OwnersDisplay = propertyAssessment?.Owners_Address;
    else {
      if (isEmpty(propertyAssessment?.Owners_StreetNumber) === false)
        OwnersDisplay += `<BR/>${propertyAssessment?.Owners_StreetNumber} ${propertyAssessment?.Owners_Address}`;
      else OwnersDisplay += `<BR/>${propertyAssessment?.Owners_Address}`;
    }
  }

  if (isEmpty(propertyAssessment?.Owners_Locality) === false) {
    if (isEmpty(OwnersDisplay))
      OwnersDisplay = propertyAssessment?.Owners_Locality;
    else
      OwnersDisplay += `<BR/>${propertyAssessment?.Owners_Locality}${
        propertyAssessment?.Owners_State
          ? ", " + propertyAssessment?.Owners_State
          : ""
      } ${propertyAssessment?.Owners_Postcode ?? ""}`;
  }

  if (isEmpty(propertyAssessment?.Owners_Phone) === false) {
    if (isEmpty(OwnersDisplay))
      OwnersDisplay = propertyAssessment?.Owners_Phone;
    else OwnersDisplay += `<BR/>${propertyAssessment?.Owners_Phone}`;
  }

  return OwnersDisplay;
};

export const getRatePayersDisplay = (
  propertyAssessment: PropertyAssessment
) => {
  let RatePayersDisplay = "";

  RatePayersDisplay = propertyAssessment?.RatePayers_Name;

  if (isEmpty(propertyAssessment?.RatePayers_Care_Of) === false) {
    if (isEmpty(RatePayersDisplay))
      RatePayersDisplay = `C/O ${propertyAssessment?.RatePayers_Care_Of}`;
    else
      RatePayersDisplay += `<BR/>C/O ${propertyAssessment?.RatePayers_Care_Of}`;
  }

  if (isEmpty(propertyAssessment?.RatePayers_Address) === false) {
    if (isEmpty(RatePayersDisplay))
      RatePayersDisplay = propertyAssessment?.RatePayers_Address;
    else RatePayersDisplay += `<BR/>${propertyAssessment?.RatePayers_Address}`;
  }

  if (isEmpty(propertyAssessment?.RatePayers_Locality) === false) {
    if (isEmpty(RatePayersDisplay))
      RatePayersDisplay = propertyAssessment?.RatePayers_Locality;
    else
      RatePayersDisplay += `<BR/>${propertyAssessment?.RatePayers_Locality}, ${propertyAssessment?.RatePayers_State} ${propertyAssessment?.RatePayers_Postcode}`;
  }

  return RatePayersDisplay;
};

export const getStringDisplayFromArrayObject = (
  list: any[],
  valueField: string
) => {
  let displayString = "";
  if (list && list.length > 0) {
    displayString = list
      .reduce((init, cur): string => {
        if (cur[valueField]) {
          return init + ", " + cur[valueField];
        }
        return init;
      }, "")
      .slice(1);
  }
  return displayString;
};

export const getDisplayFloorNumber = (
  propertyAssessment: PropertyAssessment
) => {
  let retVal = "";
  const floorPrefix1 = !isEmpty(propertyAssessment?.FloorPrefix1)
    ? propertyAssessment?.FloorPrefix1
    : "";
  const floorNumber1 = propertyAssessment?.FloorNumber1
    ? propertyAssessment?.FloorNumber1
    : "";
  const floorSuffix1 = !isEmpty(propertyAssessment?.FloorSuffix1)
    ? propertyAssessment?.FloorSuffix1
    : "";

  const floorPrefix2 = !isEmpty(propertyAssessment?.FloorPrefix2)
    ? propertyAssessment?.FloorPrefix2
    : "";
  const floorNumber2 = propertyAssessment?.FloorNumber2
    ? propertyAssessment?.FloorNumber2
    : "";
  const floorSuffix2 = !isEmpty(propertyAssessment?.FloorSuffix2)
    ? propertyAssessment?.FloorSuffix2
    : "";

  let floor1 = floorPrefix1 + floorNumber1 + floorSuffix1;
  let floor2 = floorPrefix2 + floorNumber2 + floorSuffix2;
  if (!isEmpty(floor1)) retVal += floor1;
  if (!isEmpty(floor2)) {
    if (retVal.length > 0) retVal += "-";
    retVal += floor2;
  }

  if (!isEmpty(propertyAssessment?.FloorTypeAbbreviation))
    retVal = `${propertyAssessment?.FloorTypeAbbreviation} ${retVal}`.trim();

  return retVal;
};

export const getDisplayBuildingUnitNumber = (
  propertyAssessment: PropertyAssessment
) => {
  let retVal = "";
  const unitPrefix1 = !isEmpty(propertyAssessment?.UnitPrefix1)
    ? propertyAssessment?.UnitPrefix1
    : "";
  const unitNumber1 = propertyAssessment?.UnitNumber1
    ? propertyAssessment?.UnitNumber1
    : "";
  const unitSuffix1 = !isEmpty(propertyAssessment?.UnitSuffix1)
    ? propertyAssessment?.UnitSuffix1
    : "";
  const unitPrefix2 = !isEmpty(propertyAssessment?.UnitPrefix2)
    ? propertyAssessment?.UnitPrefix2
    : "";
  const unitNumber2 = propertyAssessment?.UnitNumber2
    ? propertyAssessment?.UnitNumber2
    : "";
  const unitSuffix2 = !isEmpty(propertyAssessment?.UnitSuffix2)
    ? propertyAssessment?.UnitSuffix2
    : "";

  let unit1 = unitPrefix1 + unitNumber1 + unitSuffix1;
  let unit2 = unitPrefix2 + unitNumber2 + unitSuffix2;
  if (!isEmpty(unit1)) retVal += unit1;
  if (!isEmpty(unit2)) {
    if (retVal.length > 0) retVal += "-";
    retVal += unit2;
  }
  if (!isEmpty(propertyAssessment?.UnitAbbreviation))
    retVal = (propertyAssessment.UnitAbbreviation + " " + retVal).trim();

  return retVal;
};

export const getDisplayHouseNumber = (
  propertyAssessment: PropertyAssessment
) => {
  let retVal = "";
  const housePrefix1 = !isEmpty(propertyAssessment?.HousePrefix1)
    ? propertyAssessment?.HousePrefix1
    : "";
  const houseNumber1 = propertyAssessment?.HouseNumber1
    ? propertyAssessment?.HouseNumber1
    : "";
  const houseSuffix1 = !isEmpty(propertyAssessment?.HouseSuffix1)
    ? propertyAssessment?.HouseSuffix1
    : "";

  const housePrefix2 = !isEmpty(propertyAssessment?.HousePrefix2)
    ? propertyAssessment?.HousePrefix2
    : "";
  const houseNumber2 = propertyAssessment?.HouseNumber2
    ? propertyAssessment?.HouseNumber2
    : "";
  const houseSuffix2 = !isEmpty(propertyAssessment?.HouseSuffix2)
    ? propertyAssessment?.HouseSuffix2
    : "";
  let house1 = housePrefix1 + houseNumber1 + houseSuffix1;
  let house2 = housePrefix2 + houseNumber2 + houseSuffix2;

  if (!isEmpty(house1)) retVal += house1;
  if (!isEmpty(house2)) {
    if (retVal.length > 0) retVal += "-";
    retVal += house2;
  }

  return retVal;
};

export const getDisplayStreetName = (
  propertyAssessment: PropertyAssessment
) => {
  let retVal = !isEmpty(propertyAssessment?.Street_Name)
    ? propertyAssessment?.Street_Name
    : "";
  // let streetTypeAbbreviation = !isEmpty(
  //   propertyAssessment?.Street_TypeAbbreviation
  // )
  //   ? propertyAssessment?.Street_TypeAbbreviation
  //   : "";
  let streetSuffix = !isEmpty(propertyAssessment?.Street_Suffix)
    ? propertyAssessment?.Street_Suffix
    : "";

  // TODO: Re-check this logic later
  // if (!isEmpty(streetTypeAbbreviation)) {
  //   if (retVal.length > 0) retVal += " ";
  //   retVal += streetTypeAbbreviation;
  // }
  if (!isEmpty(streetSuffix)) {
    if (retVal.length > 0) retVal += " ";
    retVal += streetSuffix;
  }
  return retVal;
};

//Replace normal hyphen by Non-Breaking Hyphen to not break a word when down line
export const replaceHyphenByNonBreakingHyphen = (input: string) => {
  return sanitizeHtml(input?.replaceAll("-", "&#8209;"));
};

/**
 * Transfer mask from system mask (api response) to Kendo mask

* Input: System mask:
 ** X - an alphanumeric character, i.e. alphabet or number
 ** A - an alphabet only
 ** 9 - a number only
 ** null - can be any format

 * Output: Kendo mask:
 ** A - an alphanumeric character, i.e. alphabet or number
 ** L - an alphabet only
 ** 9 - a number only
 ** null - can be any format
 */
export const formatInputMask = (mask: string | null) => {
  if (!mask) return;
  return mask.replace(/[XA]/g, (character) => {
    switch (character) {
      case "X":
        return "A";
      case "A":
        return "L";
      default:
        return character;
    }
  });
};

export const isValidValueWithMask = (value: string, mask?: string) => {
  if (!mask) return true; //null - can be any format
  if (mask?.length !== value?.length) {
    return false;
  }
  for (let i = 0; i < mask.length; i++) {
    const valueChar = value[i];
    const maskChar = mask[i];
    switch (maskChar) {
      //X - an alphanumeric character, i.e. alphabet or number
      case "X":
        if (!/[a-zA-Z0-9]/.test(valueChar)) return false;
        break;
      //A - an alphabet only
      case "A":
        if (!/[a-zA-Z]/.test(valueChar)) return false;
        break;
      //9 - a number only
      case "9":
        if (!/[0-9]/.test(valueChar)) return false;
        break;
    }
  }
  return true;
};

export const trimSpace = (stringValue: string) => {
  return stringValue.replace(/\s+/g, " ");
};
