import {
  DTO_AssessmentCharge,
  DTO_AssessmentChargeBalances,
  DTO_ChargeInstallments,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/adjust-charge-balances/model";
import { CurrencyInputCell } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/allocations-to-charge-balances/components/currency-input-cell/_index";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleProminent } from "@common/utils/formatting";
import { totalColumnField } from "@common/utils/grid";
import { CurrencyCell } from "@components/cc-grid/components/grid-cells/currency/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { GridFooterCellProps } from "@progress/kendo-react-grid";
import React from "react";

const nameOf = nameOfFactory<DTO_AssessmentCharge>();
const nameOfAssessmentChargeBalances =
  nameOfFactory<DTO_AssessmentChargeBalances>();
const nameOfChargeInstallments = nameOfFactory<DTO_ChargeInstallments>();
export const colCharges: IColumnFields[] = [
  {
    field: nameOf("ChargeDate"),
    title: "Charge Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("GrossAmount"),
    title: "Gross Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("RebatesGiven"),
    title: "Rebates Given",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Balance"),
    title: "Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Name"),
    title: "Name",
  },
  {
    field: nameOf("Code"),
    title: "Code",
  },
  {
    field: nameOf("Description"),
    title: "Description",
  },
  {
    field: nameOf("RatingPeriod"),
    title: "Rating Period",
  },
];

export const colChargeBalances: IColumnFields[] = [
  {
    field: nameOfAssessmentChargeBalances("CI_Instalment_Number"),
    title: "Instal. No",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAssessmentChargeBalances("Due_Date"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfAssessmentChargeBalances("Levy_Name"),
    title: "Levy Name",
  },
  {
    field: nameOfAssessmentChargeBalances("CB_Original_Amount"),
    title: "Original Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentChargeBalances("CB_Balance"),
    title: "Current Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentChargeBalances("CB_AmendBalanceBy"),
    title: "Amend Balance By",
    format: CURRENCY_FORMAT.CURRENCY1,
    editable: true,
    editCell: CurrencyInputCell,
  },
  {
    field: nameOfAssessmentChargeBalances("CB_AmendedBalance"),
    title: "Amended Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    editable: true,
    editCell: CurrencyInputCell,
  },
  {
    field: nameOfAssessmentChargeBalances("CB_Interest"),
    title: "Current Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessmentChargeBalances("CB_AmendInterestBy"),
    title: "Amend Interest By",
    format: CURRENCY_FORMAT.CURRENCY1,
    editable: true,
    editCell: CurrencyInputCell,
  },
  {
    field: nameOfAssessmentChargeBalances("CB_AmendedInterest"),
    title: "Amended Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
    editable: true,
    editCell: CurrencyInputCell,
  },
];

export const colInstalmentSummary: IColumnFields[] = [
  {
    field: nameOfChargeInstallments("Installment_No"),
    title: "Instal. No",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfChargeInstallments("Due_Date"),
    title: "Due Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOfChargeInstallments("Original_Amount"),
    title: "Original Amount",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfChargeInstallments("Original_Amount"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfChargeInstallments("Current_Balance"),
    title: "Current Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfChargeInstallments("Current_Balance"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfChargeInstallments("Current_Interest"),
    title: "Current Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfChargeInstallments("Current_Interest"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfChargeInstallments("New_Balance"),
    title: "New Balance",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfChargeInstallments("New_Balance"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
  {
    field: nameOfChargeInstallments("New_Interest"),
    title: "New Interest",
    format: CURRENCY_FORMAT.CURRENCY1,
    calculateFooterCellValue: (props: GridFooterCellProps, gridData: any) =>
      totalColumnField(nameOfChargeInstallments("New_Interest"), gridData),
    footerCell: <CurrencyCell style={formatStyleProminent} />,
  },
];
