import { ConfirmDontVerifySelectedCards } from "@app/products/property/centrelink-customer-confirmation/view-request-file/components/dialogs/confirm-dont-verify-selected-cards/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { TYPE_NOTIFICATION } from "@components/cc-notification/comp-notification/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
export const DontVerifySelectedCardsButton = observer(() => {
  const [showDialog, setShowDialog] = useState(false);
  const { gridSelectedRows, pushNotifications } = useCCProductListViewStore();

  const [itemsSelected, setItemsSelected] = useState<any[]>(gridSelectedRows);

  useEffect(() => {
    setItemsSelected(gridSelectedRows);
  }, [gridSelectedRows]);

  const isDisableButton = () => {
    return itemsSelected?.length > 0 ? false : true;
  };

  return (
    <>
      <CCNavButton
        title="Don't verify selected cards"
        disabled={isDisableButton()}
        onClick={() => setShowDialog(true)}
      />

      {showDialog && (
        <ConfirmDontVerifySelectedCards
          onClose={() => {
            setShowDialog(false);
          }}
          onSubmit={() => {
            setShowDialog(false);
            pushNotifications([
              {
                isAutoClose: true,
                primaryContent: `Removed successfully`,
                type: TYPE_NOTIFICATION.SUCCESS,
              },
            ]);
          }}
        />
      )}
    </>
  );
});
