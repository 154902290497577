import { RatingStar } from "@app/products/hm/components/rating-star/_index";
import { getHMPremiseById } from "@app/products/hm/premises/[id]/api";
import {
  Premises_Status,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { getFriendlyNameFrequency } from "@app/products/hm/premises/[id]/util";
import { APIResponse } from "@common/apis/model";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { getStringValueSetting } from "@common/stores/products/util";
import {
  capitalizeFirstLetter,
  formatDisplayValue,
  formatOSFees,
} from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import "./_index.scss";

export const PremiseDetailsTab = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const { lastSelectedId } = useCCProductListViewStore();
  const { cancelToken } = useCancelRequest();
  const { settings } = useCommonCoreStore();

  const [premisesObj, setPremisesObj] = useState<Svc_Premises | undefined>();

  const osFeeLabel = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_OSFeeLabel]
  );

  const managePremiseSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => {
          const id: number = initialData;
          return getHMPremiseById(id, cancelToken());
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<IIdentityPacket<Svc_Premises>> =
            dataFromApi;
          const premiseData = response?.data?.ReturnObj;
          setPremisesObj(premiseData);
        },
      },
    ],
  });

  useEffect(() => {
    if (!isNil(lastSelectedId)) {
      managePremiseSlider.fetchApi({
        initialData: parseInt(lastSelectedId),
      });
    } else {
      setPremisesObj(dataForms?.GeneralForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForms?.GeneralForm, lastSelectedId]);

  const inspectionFrequency = useMemo(() => {
    if (!isNil(premisesObj?.Premises?.InspectionFrequency)) {
      return getFriendlyNameFrequency(
        premisesObj?.Premises?.InspectionFrequency
      );
    }
    return "";
  }, [premisesObj]);

  const riskValue = useMemo(() => {
    if (premisesObj?.Premises?.PremisesType?.RiskClassification_ID) {
      return premisesObj?.Premises?.PremisesType.RiskClassification?.Name;
    } else {
      return "";
    }
  }, [
    premisesObj?.Premises?.PremisesType?.RiskClassification?.Name,
    premisesObj?.Premises?.PremisesType?.RiskClassification_ID,
  ]);

  if (managePremiseSlider?.isFetching) return <Loading isLoading />;
  if (!premisesObj) return <NoData />;

  return (
    <ProductReferenceBody>
      <ProductReferenceHeading
        title={"Premise type:"}
        value={premisesObj?.Premises?.PremisesType?.Name}
        primary
      />

      <ProductReferenceBlock>
        {!isNil(premisesObj?.Premises?.Rating) && (
          <ProductReferenceRow
            customValue={
              <RatingStar numberOfStar={premisesObj?.Premises?.Rating} />
            }
          />
        )}

        <ProductReferenceRow
          title={"Reference number:"}
          value={premisesObj?.Premises?.ReferenceNumber ?? ""}
        />
        <ProductReferenceRow
          title={"Status:"}
          value={
            premisesObj?.Premises?.Status_ENUM
              ? Premises_Status[premisesObj?.Premises?.Status_ENUM]
              : ""
          }
          primary
        />

        <ProductReferenceRow title={"Risk:"} value={riskValue ?? ""} />
        <ProductReferenceRow
          title={"Lodge:"}
          value={
            premisesObj?.Premises?.Date_Lodged
              ? formatDisplayValue(
                  premisesObj?.Premises?.Date_Lodged,
                  DATE_FORMAT.DATE
                )
              : ""
          }
        />
        <ProductReferenceRow
          title={"Issued:"}
          value={
            premisesObj?.Premises?.Date_Issued
              ? formatDisplayValue(
                  premisesObj?.Premises?.Date_Issued,
                  DATE_FORMAT.DATE
                )
              : ""
          }
        />
        <ProductReferenceRow
          title={"Registered from:"}
          value={
            premisesObj?.Premises?.Date_RegistrationFrom
              ? formatDisplayValue(
                  premisesObj?.Premises?.Date_RegistrationFrom,
                  DATE_FORMAT.DATE
                )
              : ""
          }
        />
        <ProductReferenceRow
          title={"Registered to:"}
          value={
            premisesObj?.Premises?.Date_RegistrationTo
              ? formatDisplayValue(
                  premisesObj?.Premises?.Date_RegistrationTo,
                  DATE_FORMAT.DATE
                )
              : ""
          }
        />
        <ProductReferenceRow
          title={"Inspection frequency:"}
          value={inspectionFrequency}
        />
        <hr />
        <ProductReferenceRow
          value={
            premisesObj?.OSFee
              ? formatDisplayValue(
                  premisesObj?.OSFee,
                  CURRENCY_FORMAT.CURRENCY1
                )
              : "$0"
          }
          title={capitalizeFirstLetter(osFeeLabel) ?? "OS fees:"}
          format="currency"
          style={formatOSFees(premisesObj?.OSFee ?? 0)}
          isPropertyStyle={false}
        />
        {premisesObj?.Premises?.Alert && (
          <ProductReferenceRow
            badge
            warning
            value={premisesObj?.Premises?.Alert}
          />
        )}

        {/* Waiting for confirmation */}
        {/* <ProductReferenceRow
            badge
            value={"Requires a new certificate"}
            primary
          /> */}
      </ProductReferenceBlock>
    </ProductReferenceBody>
  );
});
