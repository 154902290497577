import { useIsNew } from "@common/hooks/useIsNew";
import { ExistedDynamicQuestionLists } from "@common/pages/settings/lookups/dynamic-lists/_id/components/forms/existed/_index";
import { NewDynamicQuestionLists } from "@common/pages/settings/lookups/dynamic-lists/_id/components/forms/new/_index";
import { useDynamicQuestionListsStore } from "@common/pages/settings/lookups/dynamic-lists/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageDynamicQuestionLists = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const dynamicQuestionListsId = parseInt(params.id);
  const { resetStore, loadDynamicQuestionLists } =
    useDynamicQuestionListsStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    loadDynamicQuestionLists(dynamicQuestionListsId, isNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicQuestionListsId, isNew]);

  if (isNew) {
    return <NewDynamicQuestionLists />;
  }
  return <ExistedDynamicQuestionLists />;
};

export default observer(ManageDynamicQuestionLists);
