import { postSendCommunication } from "@app/core/communication/buttons-list-view/components/buttons/api";
import { decryptionHtmlForEditor } from "@app/core/communication/dialogs/components/form-elememts/message-template/components/cc-edit-html-tool/util";
import { ICommunication } from "@app/core/communication/dialogs/model";
import { saveMailMergeDocument } from "@app/core/new-mail-merge/buttons/components/buttons/api";
import { MailMergeSelectDocument } from "@app/core/new-mail-merge/buttons/components/buttons/model";
import { MailMerge_SelectMergeDocument_Ext } from "@app/core/new-mail-merge/dialogs/model";
import {
  BATCH_RECALL_INITIAL_FORM,
  BATCH_RECALL_STEP_KEYS as keys,
} from "@app/core/recall/constant";
import { Svc_BatchRecallRequest } from "@app/core/recall/model";
import { FormAction } from "@app/products/crms/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { customLogger } from "@common/utils/logger";
import { isNil } from "lodash";
import { postSaveRecallsInBatch } from "./api";

export const handlePostingBatchRecall = async (
  formValues: any,
  meta: {
    recordIDs: number[];
    productType: PRODUCT_TYPE_NUMBER;
    recordType: RECORDTYPE;
  },
  callbacks: {
    onSuccess?: (data: any) => void;
    onError?: (response: any) => void;
  }
) => {
  const recall = shaveBatchRecallRequest(formValues);
  const requestData: Svc_BatchRecallRequest = {
    ProductType: meta.productType,
    RecordType: meta.recordType,
    BatchRecall: recall,
    Ids: meta.recordIDs,
  };
  const response = await postSaveRecallsInBatch(requestData);
  if (
    isSuccessResponse(response) &&
    !isNil(response.data) &&
    response.data.SuccessIDs?.length > 0
  ) {
    callbacks?.onSuccess?.(response.data);
    return true;
  } else {
    callbacks?.onError?.(response);
    return false;
  }
};

export const handleSendingCommunicationForBatchRecall = async (
  formValues: any,
  callbacks: { onSuccess?: () => void; onError?: (response: any) => void }
) => {
  //#region prepare data
  const data = shaveCommunicationRequest(formValues);
  const communicationPacket = data[keys.CommunicationRecipient];
  const messagePacket = data[keys.CommunicationMessageTemplate];
  const attachmentPacket = data[keys.CommunicationAttachment];
  const recipientsSelected = communicationPacket.SelectedToRows.map(
    (item: any) => {
      item.SendToEmail = communicationPacket.UseEmail;
      item.SendToSMS = communicationPacket.UseSMS;
      return item;
    }
  );
  const communication: ICommunication = Object.assign(
    {},
    communicationPacket.Communication,
    {
      SelectedActionType_ID: data.Workflow?.ActionTypes?.Key ?? 0,
      Recipients: recipientsSelected,
      EmailSubject:
        messagePacket.SubjectContent.replaceAll("\r", "").replaceAll(
          "\n",
          ""
        ) ?? "",
      EmailContent: decryptionHtmlForEditor(messagePacket.EmailContent) ?? "",
      SMSContent: messagePacket.SMSContent ?? "",
      SendByUsingPreferredMethod: communicationPacket.UsePreferred,
      SendToEmail: communicationPacket.UseEmail,
      SendToSMS: communicationPacket.UseSMS,
      SendCopyToMe: messagePacket.IncludeMe,
      SendCopyToMeBCC: messagePacket.SendCopyToMeBCC,
      SenderEmail: data.SendFrom?.Value,
      Records_UserDefinedParentRecordId: "",
      PreferredAuthor_DisplayName: "",
      AccessToken: "",
      ListOfAttachments: attachmentPacket.ListOfAttachments,
      ListOfTemplateAttachments: attachmentPacket.ListOfTemplateAttachments,
      ListOfAttachmentsFromCurrent:
        attachmentPacket.ListOfAttachmentsFromCurrent,
      SelectedMailMergeDocumentID: data.Template.SelectedMailMergeDocumentID,
    }
  );
  //#endregion

  //#region call API to send communication
  const response = await postSendCommunication(
    communication,
    RECORDTYPE.CORE_Recall
  );
  customLogger("Batch Recall - Send Communication, postSendCommunication").info(
    response?.data
  );
  if (isSuccessIdentityPacket(response)) {
    callbacks?.onSuccess?.();
    return true;
  } else {
    callbacks?.onError?.(response);
    return false;
  }
  //#endregion
};

export const handleSendingMailMergeForBatchRecall = async (
  formValues: any,
  recallIds: number[],
  productType: PRODUCT_TYPE_NUMBER,
  callbacks: { onSuccess?: () => void; onError?: (response: any) => void }
) => {
  //#region prepare data
  const mailMerge: MailMerge_SelectMergeDocument_Ext =
    shaveMailMergeRequest(formValues);
  const requestBody: MailMergeSelectDocument = {
    SelectedRecordIDs: recallIds,
    SelectedMailMergeDocumentIDs: mailMerge.SelectedDocumentIDs,
    RecordType: RECORDTYPE.CORE_Recall,
    AppendToRecordSource: mailMerge.AppendToRecordSource,
    BatchPrint: mailMerge.BatchPrint,
    Collate: mailMerge.Collate,
    SaveAsPdf: mailMerge?._options?.SaveAsPDF ?? false,
    Category: mailMerge.Category_KWD,
    CategoryName: mailMerge.CategoryName,
  };
  //#endregion

  //#region call API to send communication
  const response = await saveMailMergeDocument(
    productType,
    requestBody,
    FormAction.CORE_ALLOW_MAILMERGE
  );
  customLogger("Batch Recall - Send Mail Merge, saveMailMergeDocument").info(
    response?.data
  );
  if (isSuccessResponse(response)) {
    if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
      callbacks?.onSuccess?.();
      if (
        requestBody?.Collate === true &&
        response.data?.Data != null &&
        response.data?.Data.length > 0
      ) {
        DownloadFile(
          response.data.Data,
          response.data.ContentType,
          response.data.FileName
        );
      }
      return true;
    }
  }
  callbacks?.onError?.(response);
  return false;
  //#endregion
};

const shaveBatchRecallRequest = (
  data: typeof BATCH_RECALL_INITIAL_FORM
): any => {
  return data?.Recall;
};

const shaveCommunicationRequest = (data: any): any => {
  const {
    //#region shaving
    [keys.Recall]: _recall,
    [keys.MailMergeTemplate]: _mailMergeTemplate,
    [keys.MailMergeOptions]: _mailMergeOptions,
    [keys.MailMergeWorkflow]: _mailMergeWorkflow,
    //#endregion
    ...communication
  } = data;
  return communication;
};

const shaveMailMergeRequest = (
  data: any
): MailMerge_SelectMergeDocument_Ext => {
  const {
    //#region shaving
    [keys.Recall]: _recall,
    [keys.CommunicationRecipient]: _to,
    [keys.CommunicationMessageTemplate]: _messageTemplate,
    [keys.CommunicationMailMergeTemplate]: _template,
    [keys.CommunicationAttachment]: _attachment,
    [keys.CommunicationWorkflow]: _workflow,
    [keys.MailMergeTemplate]: _mailMergeTemplate,
    [keys.MailMergeOptions]: _mailMergeOptions,
    [keys.MailMergeWorkflow]: _mailMergeWorkflow,
    //#endregion
    ...mailMergeFields
  } = data;
  return mailMergeFields;
};
