import { Svc_HMPremisesType } from "@app/products/hm/premises/[id]/model";
import { BOOLEAN_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { IOptionInputPicker } from "@components/cc-input-picker-search-multi-select/model";

const nameOf = nameOfFactory<Svc_HMPremisesType>();

const colPremisesTypes: IColumnFields[] = [
  { title: "Classification", field: nameOf("PremisesClassification") },
  { title: "Name", field: nameOf("Name") },
  { title: "Risk", field: nameOf("RiskClassification") },
  { title: "Category", field: nameOf("Category") },
  { title: "Renewal", field: nameOf("RenewalMethod_Name") },
  { title: "Inspection Frequency", field: nameOf("InspectionFrequency") },
  { title: "Registration Fee", field: nameOf("RegistrationFee_Amount") },
  { title: "Renewal Fee", field: nameOf("RenewalFee_Amount") },
  { title: "Transfer Fee", field: nameOf("TransferFee_Amount") },
  {
    title: "Employees",
    field: nameOf("Flag_NoOfEmployees"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Units",
    field: nameOf("Flag_NoOfUnits"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Beds",
    field: nameOf("Flag_NoOfBeds"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Food stall",
    field: nameOf("Flag_FoodVehicle"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    title: "Tobacco",
    field: nameOf("Flag_TobaccoRetail"),
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
];

export const optionPremisesTypes: IOptionInputPicker = {
  dialog: {
    titleHeader: "Premises Types",
  },
  boxSearch: {
    colSearch: nameOf("Name"),
    colShowComboboxSearch: [
      {
        title: "Name",
        field: nameOf("Name"),
      },
    ],
  },
  grid: {
    columnFields: colPremisesTypes,
    primaryField: nameOf("ID"),
    dataUrl: "odata/hm/internal/hmpremisestypes/GetPremisesTypes",
    selectableMode: "single",
    isAutoHiddenPager: false,
    itemPerPage: 10,
    state: {
      sort: [
        {
          field: nameOf("PremisesClassification"),
          dir: "asc",
        },
      ],
    },
  },
};
