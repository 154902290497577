import { MultiSelect, MultiSelectProps } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";
import React, { useState } from "react";
interface ICCMultiSelectDropdownProps {
  disabledDateInput?: boolean;
  validationMessage?: string | null;
  visited?: boolean;
}
export const CCMultiSelectDropdown = (
  props: MultiSelectProps & ICCMultiSelectDropdownProps
) => {
  const { onChange, visited, validationMessage, ...others } = props;
  const [isOpened, setIsOpened] = useState(false);

  const handleOpen = () => {
    setIsOpened(true);
  };

  const handleClose = () => {
    setIsOpened(false);
  };

  return (
    <div className="cc-multiple-selection-dropdown">
      <MultiSelect
        {...others}
        onOpen={handleOpen}
        opened={isOpened}
        onBlur={handleClose}
        onChange={onChange}
      />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
