import { eventEmitter } from "@/App";
import { history } from "@/AppRoutes";
import { getParentSummary } from "@app/core/further-info/[id]/api";
import { Ancestor } from "@app/core/further-info/[id]/model";
import {
  getInspectionById,
  getInspectionLOVs,
  postSaveInspection,
} from "@app/core/inspections/[id]/api";
import {
  firstConditionEnumArray,
  secondConditionEnumArray,
} from "@app/core/inspections/[id]/components/child-screens/general/components/inspection-form-element/config";
import { INSPECTIONS_MANAGE_ROUTE } from "@app/core/inspections/[id]/constant";
import {
  BubbleUpType,
  IInspectionLovs,
  IParentInspectionsSection,
  Inspection,
  InspectionType,
} from "@app/core/inspections/[id]/model";
import { getBubbleUpDetailsByType } from "@app/core/inspections/[id]/util";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { DBRowState } from "@app/products/crms/[id]/model";
import { KeyValuePacket } from "@app/products/crms/components/dialogs/clone-new-event/model";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import {
  DefaultAffectSchedule,
  InspectionClassification,
} from "@common/constants/enumerations";
import { FSR_INSPECTIONTYPE } from "@common/constants/fsrCode";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { commonCoreStore } from "@common/stores/core/store";
import { getNumberValueSetting } from "@common/stores/products/util";
import { getDropdownValue, mapEnum } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class InspectionsStore {
  private _inspections?: Inspection = undefined;
  private _inspectionsLOVs?: IInspectionLovs = undefined;
  private _isLoading: boolean = false;
  private _isLoadingDetails: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _parentBubbleUpDetails?: BubbleUpIdentifier = undefined;
  private _parent?: IParentInspectionsSection = undefined;
  private _isLoadingParent: boolean = false;
  private _ancestor?: Ancestor = undefined;
  private _position?: KeyValuePacket[] = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _isInactive: boolean = false;
  private _isRelatedInspectionRequired: boolean = false;
  private _isRelatedNoticeRequired: boolean = false;
  private _isDisabledAffectSchedule: boolean = false;
  private _isFormModified: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get inspections() {
    return toJS(this._inspections);
  }
  setInspections = (inspections?: Inspection) => {
    runInAction(() => {
      this._inspections = inspections;
    });
  };

  get inspectionsLOVs() {
    return toJS(this._inspectionsLOVs);
  }
  setInspectionsLOVs = (inspectionsLOVs?: IInspectionLovs) => {
    runInAction(() => {
      this._inspectionsLOVs = inspectionsLOVs;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoadingDetails: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoadingDetails;
    });
  };

  get parentBubbleUpDetails() {
    return this._parentBubbleUpDetails;
  }

  setParentBubbleUpDetails = (parentBubbleUpDetails?: BubbleUpIdentifier) => {
    runInAction(() => {
      this._parentBubbleUpDetails = parentBubbleUpDetails;
    });
  };

  get parent() {
    return this._parent;
  }
  setParent = (parent?: IParentInspectionsSection) => {
    runInAction(() => {
      this._parent = parent;
    });
  };

  get isLoadingParent() {
    return this._isLoadingParent;
  }
  setIsLoadingParent = (isLoadingParent: boolean) => {
    runInAction(() => {
      this._isLoadingParent = isLoadingParent;
    });
  };

  get ancestor() {
    return this._ancestor;
  }
  setAncestor = (parentLine?: Ancestor) => {
    runInAction(() => {
      this._ancestor = parentLine;
    });
  };

  get position() {
    return this._position;
  }
  setPosition = (position?: KeyValuePacket[]) => {
    runInAction(() => {
      this._position = position;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get isInactive() {
    return this._isInactive;
  }

  setIsInactive = (isInactive: boolean) => {
    runInAction(() => {
      this._isInactive = isInactive;
    });
  };

  get isRelatedInspectionRequired() {
    return this._isRelatedInspectionRequired;
  }

  setIsRelatedInspectionRequired = (isRelatedInspectionRequired: boolean) => {
    runInAction(() => {
      this._isRelatedInspectionRequired = isRelatedInspectionRequired;
    });
  };

  get isRelatedNoticeRequired() {
    return this._isRelatedNoticeRequired;
  }

  setIsRelatedNoticeRequired = (isRelatedNoticeRequired: boolean) => {
    runInAction(() => {
      this._isRelatedNoticeRequired = isRelatedNoticeRequired;
    });
  };

  get isDisabledAffectSchedule() {
    return this._isDisabledAffectSchedule;
  }

  setIsDisabledAffectSchedule = (isDisabledAffectSchedule: boolean) => {
    runInAction(() => {
      this._isDisabledAffectSchedule = isDisabledAffectSchedule;
    });
  };

  get isFormModified() {
    return this._isFormModified;
  }

  setFormIsModified = (isFormModified: boolean) => {
    runInAction(() => {
      this._isFormModified = isFormModified;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._inspections = undefined;
      this._isLoading = false;
      this._inspectionsLOVs = undefined;
      this._responseLoadError = undefined;
      this._parentBubbleUpDetails = undefined;
      this._parent = undefined;
      this._isLoadingParent = false;
      this._isLoadingDetails = false;
      this._ancestor = undefined;
      this._position = undefined;
      this._onSubmit = undefined;
      this._notification = undefined;
      this._isInactive = false;
      this._isFormModified = false;
    });
  };

  handleCompliance = (
    inspectionType: InspectionType,
    listCompliances: IKeyValuePacket[] | undefined,
    inspectionFormObj?: Inspection,
    errTitle?: string
  ) => {
    let inspectionCompliedLabel: number | undefined = undefined;
    let errorMsg: string | undefined = undefined;
    if (inspectionType?.Flag_SimpleInspection) {
      inspectionCompliedLabel = getNumberValueSetting(
        commonCoreStore.settings[
          ECorporateSettingsField.SimpleInspection_CompliedLabel
        ]
      );
      errorMsg =
        "Simple Inspection Compliance Label has not been entered into Global Settings->Inspections";
    } else {
      inspectionCompliedLabel = getNumberValueSetting(
        commonCoreStore.settings[
          ECorporateSettingsField.Inspection_CompliedLabel
        ]
      );
      errorMsg = "Compliance Labels have not been entered into Global Settings";
    }

    if (inspectionCompliedLabel) {
      const recentCompliance = inspectionFormObj?.Compliance_KWD;
      const hasInspectionCompliedLabel = listCompliances?.find(
        (item: IKeyValuePacket) => inspectionCompliedLabel === item.Key
      );
      const hasRecentCompliance = listCompliances?.find(
        (item: IKeyValuePacket) => recentCompliance === item.Key
      );

      if (
        (!recentCompliance || !hasRecentCompliance) &&
        hasInspectionCompliedLabel
      )
        this.setInspections({
          ...this.inspections,
          Compliance_KWD: inspectionCompliedLabel,
        } as Inspection);
    } else {
      appNotificationStore.pushNotification({
        type: "warning",
        autoClose: false,
        title: errTitle ?? errorMsg,
        description: errTitle ? errorMsg : "",
      });
      this.setInspections({
        ...this.inspections,
        InspectionType: undefined,
      } as Inspection);
      return;
    }
  };

  handleDisplayInspectionType = (
    resetAffectSchedule: boolean,
    inspectionType: InspectionType | undefined,
    inspectionsLOVs: IInspectionLovs | undefined,
    inspectionFormObj?: Inspection
  ) => {
    if (inspectionType) {
      // Is NC Item Inspection
      if (
        (inspectionType.InspectionClassification_ENUM =
          InspectionClassification.NONCOMPLIANCE_INSPECTION)
      ) {
        this.handleCompliance(
          inspectionType,
          inspectionsLOVs?.Compliance,
          inspectionFormObj,
          "Alert : Information missing from Global Settings"
        );
      }

      // Default Affect Schedule
      if (resetAffectSchedule) {
        switch (inspectionType?.DefaultAffectSchedule_ENUM) {
          case DefaultAffectSchedule.No:
          case DefaultAffectSchedule.NoLocked:
            this.setInspections({
              ...inspectionFormObj,
              AffectSchedule: false,
            } as Inspection);
            break;
          case DefaultAffectSchedule.Yes:
          case DefaultAffectSchedule.YesLocked:
          case DefaultAffectSchedule.Blank:
            this.setInspections({
              ...inspectionFormObj,
              AffectSchedule: true,
            } as Inspection);
            break;
          default:
            break;
        }
      }

      if (
        inspectionType?.DefaultAffectSchedule_ENUM ===
          DefaultAffectSchedule.NoLocked ||
        inspectionType?.DefaultAffectSchedule_ENUM ===
          DefaultAffectSchedule.YesLocked
      ) {
        this.setIsDisabledAffectSchedule(true);
      } else {
        this.setIsDisabledAffectSchedule(false);
      }

      // Assessments
      if (
        firstConditionEnumArray.includes(
          inspectionType?.InspectionClassification_ENUM
        )
      ) {
        const healthManager3StarComplianceLabel = getNumberValueSetting(
          commonCoreStore.settings[
            ECorporateSettingsField?.HealthManager_3StarComplianceLabel
          ]
        );
        if (healthManager3StarComplianceLabel) {
          this.setInspections({
            ...inspectionFormObj,
            Compliance_KWD: getDropdownValue(
              healthManager3StarComplianceLabel,
              inspectionsLOVs?.Compliance ?? [],
              "Key"
            ),
          } as Inspection);
        } else {
          appNotificationStore.pushNotification({
            type: "warning",
            autoClose: false,
            title:
              "5 Star Compliance Labels have not been entered into Health Manager system settings",
          });
          this.setInspections({
            ...inspectionFormObj,
            InspectionType: undefined,
          } as Inspection);
          return;
        }
      } else if (
        secondConditionEnumArray.includes(
          inspectionType?.InspectionClassification_ENUM
        )
      ) {
        this.handleCompliance(
          inspectionType,
          inspectionsLOVs?.Compliance,
          inspectionFormObj
        );
      }

      // Set "Corp Admin >> Global Settings >> Inspection >> Complied Value" if no other value is set
      if (!this.inspections?.Compliance_KWD) {
        this.handleCompliance(
          inspectionType,
          inspectionsLOVs?.Compliance,
          inspectionFormObj
        );
      }

      // FSR Mandatory Items
      if (inspectionType?.FSRCode_ENUM) {
        if (
          inspectionType?.FSRCode_ENUM ===
          FSR_INSPECTIONTYPE?.FOLLOW_UP_INSPECTION
        ) {
          this.setIsRelatedNoticeRequired(true);
        } else {
          this.setIsRelatedNoticeRequired(false);
        }

        if (
          inspectionType?.FSRCode_ENUM ===
          FSR_INSPECTIONTYPE?.NON_COMPLIANCE_INSPECTION__FOLLOW_UP_TO_COUNCIL_AUDIT
        ) {
          this.setIsRelatedInspectionRequired(true);
        } else {
          this.setIsRelatedInspectionRequired(false);
        }
      }
    }
  };

  loadInspections = async (recordId: number, isNew: boolean) => {
    this.setIsLoading(true);
    let errorResponse = undefined;

    let newInspection: Inspection | undefined;

    const response = await getInspectionById(recordId);
    if (isSuccessResponse(response) && response.data) {
      newInspection = response.data;
      if (!isNew) {
        this.setInspections(newInspection);
        this.loadInspectionsLOVs(newInspection);
      } else {
        this.setInspections({
          ...newInspection,
          AffectSchedule: true,
          Inspection_ID: 0,
          _BubbleUps: this.parent?.bubbleUps ?? [],
        } as Inspection);
      }
      if (
        mapEnum(response.data?.Sys_DBRowState, DBRowState) ===
        DBRowState.Inactive
      ) {
        this.setIsInactive(true);
        appNotificationStore.clearNotifications();
        appNotificationStore.pushNotification({
          autoClose: false,
          title: "Important Note",
          type: "info",
          description:
            "You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.",
        });
      }
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    const sourceIdentifier = newInspection?._BubbleUps?.find(
      (bubbleUp: BubbleUpIdentifier) =>
        mapEnum(bubbleUp.BubbleUpType_ENUM, BubbleUpType) ===
        BubbleUpType.Parent
    )?.SourceIdentifier;
    if (sourceIdentifier) {
      const newParent = {
        ...this.parent,
        id: sourceIdentifier._RecordSource_ID,
        recordType: mapEnum(
          sourceIdentifier._RecordSourceType_ENUM,
          RECORDTYPE
        ),
      };
      await this.loadParent(newParent);
    } else if (this.parent) {
      await this.loadParent(this.parent);
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);

    // Push notification
    if (this.notification && !errorResponse) {
      appNotificationStore.pushNotification(this.notification);
      this.setNotification(undefined);
    }
    if (this.parent?.notification && !errorResponse) {
      this.parent?.notification.forEach(
        (notification: IAppNotificationItemAddProps) => {
          appNotificationStore.pushNotification(notification);
        }
      );
      this.setParent({ ...this.parent, notification: [] });
      window.history.replaceState({}, "");
    }
  };

  loadParent = async (parent: IParentInspectionsSection) => {
    this.setParent(parent);
    const response = await getParentSummary(parent.id, parent.recordType);
    if (isSuccessResponse(response) && response.data) {
      this.setAncestor(response.data);
      return true;
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: response?.error ?? "Load parent data failed",
      });
      return false;
    }
  };

  loadInspectionsDetails = async (recordId: number) => {
    this.setIsLoadingDetails(true);
    let errorResponse = undefined;

    let newInspection: Inspection | undefined;

    const response = await getInspectionById(recordId);
    if (isSuccessResponse(response) && response.data) {
      newInspection = response.data;

      this.setInspections(newInspection);
      this.setParentBubbleUpDetails(
        getBubbleUpDetailsByType(newInspection?._BubbleUps, BubbleUpType.Parent)
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    const sourceIdentifier = newInspection?._BubbleUps?.find(
      (bubbleUp: BubbleUpIdentifier) =>
        mapEnum(bubbleUp.BubbleUpType_ENUM, BubbleUpType) ===
        BubbleUpType.Parent
    )?.SourceIdentifier;
    if (sourceIdentifier) {
      const newParent = {
        ...this.parent,
        id: sourceIdentifier._RecordSource_ID,
        recordType: mapEnum(
          sourceIdentifier._RecordSourceType_ENUM,
          RECORDTYPE
        ),
      };
      await this.loadParent(newParent);
    }
    this.setIsLoadingDetails(false);
    this.setResponseLoadError(errorResponse);
  };

  loadInspectionsLOVs = async (newInspection: Inspection) => {
    const responseLOVs = await getInspectionLOVs(
      newInspection._BubbleUps,
      newInspection.InspectionType_ID
    );
    let newInspectionsLOVs = undefined;
    let errorResponse = undefined;
    if (isSuccessResponse(responseLOVs)) {
      newInspectionsLOVs = responseLOVs.data;
      this.handleDisplayInspectionType(
        false,
        responseLOVs.data.InspectionTypeDetail,
        responseLOVs.data,
        newInspection
      );
    } else {
      errorResponse = {
        status: responseLOVs.status,
        error: responseLOVs.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setInspectionsLOVs(newInspectionsLOVs);
  };

  processDataToSave = (inspection: Inspection) => {
    // Implement logic to process data before saving (Page_SavePageToViewState)
    if (inspection?.InspectionType?.Flag_SimpleInspection) {
      inspection.AffectSchedule = true;
    }
    return inspection;
  };

  saveInspections = async (data: Inspection, isNew: boolean) => {
    //Process data before saving
    data = this.processDataToSave(data);

    let dataBubbleUps = undefined;
    this.setIsLoading(true);
    if (data._BubbleUps?.length === 0) {
      dataBubbleUps = { ...data, _BubbleUps: this.parent?.bubbleUps };
    }
    const response = await postSaveInspection(
      (dataBubbleUps as Inspection) ?? data
    );
    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      this.setFormIsModified(false);
      if (isNew) {
        history.replace(`${INSPECTIONS_MANAGE_ROUTE}/${response.data?.ID}`, {
          parent: {
            notification: [
              {
                autoClose: true,
                title: "Record successfully saved.",
                type: "success",
              },
            ],
          },
        });
      } else {
        this.setNotification({
          autoClose: true,
          title: "Record successfully saved.",
          type: "success",
        });
        this.loadInspections(response?.data?.source?._RecordSource_ID, false);
      }
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title:
          response?.error ??
          response?.data?.Errors ??
          "Inspections create failed",
        type: "error",
      });
    }
  };
}

const InspectionsStoreContext = createContext(new InspectionsStore());
export const useInspectionsStore = () => {
  return useContext(InspectionsStoreContext);
};
