import { eventEmitter } from "@/App";
import { isSuccessResponse } from "@common/apis/util";
import { removeJobsScheduling } from "@common/pages/settings/system-admin/task-scheduling/api";
import { IDeleteJob } from "@common/pages/settings/system-admin/task-scheduling/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IDeleteJobsSchedulingButtonProps {
  isDisabled?: boolean;
}
export const DeleteJobsSchedulingButton = observer(
  ({ isDisabled = false }: IDeleteJobsSchedulingButtonProps) => {
    const { gridSelectedRows, clearSelectedItems } =
      useCCProductListViewStore();
    const { pushNotification } = useCCAppNotificationStore();

    const [isShowConfirmDialog, setIsConfirmDialog] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDelete = async () => {
      if (!gridSelectedRows?.length) return;

      setIsLoading(true);
      const dataDelete: IDeleteJob[] = gridSelectedRows.map((item) => {
        return { Jobkey: item.JobName, Group: item.GroupName };
      });
      const response = await removeJobsScheduling(dataDelete ?? []);
      setIsLoading(false);
      setIsConfirmDialog(false);
      if (isSuccessResponse(response)) {
        clearSelectedItems();
        pushNotification({
          autoClose: true,
          title: "The job(s) was deleted successfully.",
          type: "success",
        });
      } else {
        pushNotification({
          autoClose: false,
          title: response.data?.Errors ?? "Delete the job(s) was failed.",
          type: "error",
        });
      }
      /**
       * Reload this grid for both successful and failed cases
       * Reason: Still returns an error when deleting one or more records failed.
       */
      eventEmitter.emit(CCGridEventType.RefreshOData);
    };

    return (
      <>
        <CCNavButton
          title="Delete"
          onClick={() => {
            setIsConfirmDialog(true);
          }}
          disabled={gridSelectedRows?.length <= 0 || isDisabled}
        />
        {isShowConfirmDialog && (
          <ConfirmDialog
            onClosePopup={() => setIsConfirmDialog(false)}
            title={"Confirm Delete"}
            message={`Are you sure you want to delete ${
              gridSelectedRows?.length > 1 ? "these items" : "this item"
            }?`}
            onAsyncConfirm={handleDelete}
            isLoadingYes={isLoading}
          />
        )}
      </>
    );
  }
);
