import { Documents } from "@app/core/documents/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { SendCommunicationButton } from "@common/pages/settings/communication/components/buttons/send-communication/_index";
import { CommunicationQueueForm } from "@common/pages/settings/communication/queue/_id/components/child-screens/general/_index";
import { CommunicationQueueTabDetails } from "@common/pages/settings/communication/queue/_id/components/reference-sidebar/detail/_index";
import { CommunicationQueueHistoryTab } from "@common/pages/settings/communication/queue/_id/components/reference-sidebar/history/_index";
import { useCommunicationQueueStore } from "@common/pages/settings/communication/queue/_id/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageCommunicationQueue = () => {
  const { id } = useParams<{ id: string }>();
  const {
    loadCommunicationQueue,
    communicationQueue,
    communicationQueueId,
    isLoading,
    resetStore,
    responseLoadError,
  } = useCommunicationQueueStore();
  const { resetMenu } = useCCSubActionBarStore();
  const [showSlideBar, setShowSlideBar] = useState(true);
  const managePageUrl = window.location.pathname;
  const { currentUserInfo } = useGlobalStore();
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: getDisplayTextWithDashes([
        communicationQueue?.CommunicationQueue_ID,
        communicationQueue?.DisplayName,
      ]),
      LinkUrl: managePageUrl,
      LinkText: `Settings - Communication - Queue - ${
        communicationQueue?.CommunicationQueue_ID ?? 0
      }`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_CommunicationQueue,
      Record_ID: communicationQueue?.CommunicationQueue_ID ?? 0,
    },
  ];

  const listPanelBar: ITabProps[] = [
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents
          id={communicationQueueId}
          recordType={RECORDTYPE.CORE_CommunicationQueue}
        />
      ),
    },
  ];

  useEffect(() => {
    loadCommunicationQueue(parseInt(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Communication Queue"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadCommunicationQueue(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle title={communicationQueue?.DisplayName} />

          <CCManagePageActionBar
            leftActions={[
              <SendCommunicationButton id={communicationQueueId} />,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {communicationQueue && (
                  <>
                    <CommunicationQueueForm />
                    <TabTable
                      id={communicationQueueId}
                      recordType={RECORDTYPE.CORE_CommunicationQueue}
                      initialPanels={listPanelBar}
                    />
                  </>
                )}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <CommunicationQueueTabDetails />,
                    },
                    {
                      title: "History",
                      component: <CommunicationQueueHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default observer(ManageCommunicationQueue);
