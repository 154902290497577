export const mockExternalCardsRequiringActions = [
  {
    Action: "Mismatch",
    CustomerId: "MSH04875A ",
    Org: "DVA",
    Name: "LYNCH, Thomas  ",
    Matched: "No record found for non Centrelink reference number provided",
    FirstName: true,
    Surname: true,
    Assessment103: 4450250013,
    AssessmentId: 136067,
    EntityId: 165234,
    Id: "0",
  },
  {
    Action: "Mismatch",
    CustomerId: "NA0044411  ",
    Org: "DVA",
    Name: "GIRONDOUDAS, Nikolas ",
    Matched: "No record found for non Centrelink reference number provided",
    FirstName: true,
    Surname: true,
    Assessment103: 7070200024,
    AssessmentId: 224500,
    EntityId: 118585,
    Id: "1",
  },
  {
    Action: "Mismatch",
    CustomerId: "NBUR0004  ",
    Org: "DVA",
    Name: "JOHNSON, Shaun Anthony ",
    Matched:
      "Multiple records found for non Centrelink reference number/details provided",
    FirstName: true,
    Surname: true,
    Assessment103: 3320060001,
    AssessmentId: 126583,
    EntityId: 123530,
    Id: "2",
  },
  {
    Action: "Deceased Person with Entity not indicated as an estate",
    CustomerId: "NKM09848  ",
    Org: "DVA",
    ConfirmationId: "190345",
    Name: "NICOLAOU, Bill  ",
    Matched:
      "Credentials provided for this record are valid, the record is located and result returned",
    ConfirmationDate: "22-Jun-2017",
    Deceased: true,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5780300050,
    AssessmentId: 231738,
    EntityId: 229254,
    Id: "3",
  },
  {
    Action: "Deceased Person with Active Rebate",
    CustomerId: "NKM09848  ",
    Org: "DVA",
    ConfirmationId: "190345",
    Name: "NICOLAOU, Bill",
    Matched:
      "Credentials provided for this record are valid, the record is located and result returned",
    ConfirmationDate: "22-Jun-2017",
    Deceased: true,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5780300050,
    AssessmentId: 231738,
    EntityId: 229254,
    Id: "4",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSH20030A ",
    Org: "DVA",
    Matched: "No record found for non Centrelink reference number provided",
    FirstName: true,
    Surname: true,
    Assessment103: 2200420015.018,
    AssessmentId: 283222,
    EntityId: 307370,
    Id: "5",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSH21004  ",
    Org: "DVA",
    Name: "MCFARLANE, Duncan ",
    Matched: "No record found for non Centrelink reference number provided",
    FirstName: true,
    Surname: true,
    Assessment103: 5660390019,
    AssessmentId: 150659,
    EntityId: 180530,
    Id: "6",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSM03682  ",
    Org: "DVA",
    Name: "MATTFIEWS, Margaret Enid ",
    Matched:
      "Multiple records found for non Centrelink reference number/details provided",
    FirstName: true,
    Surname: true,
    Assessment103: 6031350004,
    AssessmentId: 170865,
    EntityId: 129903,
    Id: "7",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSM08274  ",
    Org: "DVA",
    Name: "MURPHY, Peter Francis ",
    Matched:
      "Multiple records found for non Centrelink reference number/details provided",
    FirstName: true,
    Surname: true,
    Assessment103: 4440150030,
    AssessmentId: 134789,
    EntityId: 133144,
    Id: "8",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSM09427 ",
    Org: "DVA",
    Name: "FABBAN, Mary",
    Matched:
      "Multiple records found for non Centrelink reference number/details provided",
    FirstName: true,
    Surname: true,
    Assessment103: 5520660006,
    AssessmentId: 140419,
    EntityId: 89496,
    Id: "9",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSM1726  ",
    Org: "DVA",
    Name: "PEARSON. John William ",
    Matched:
      "File ID Matched, 1 record found, but the name provided did not match",
    FirstName: true,
    Surname: true,
    Assessment103: 6165080026,
    AssessmentId: 231808,
    EntityId: 291653,
    Id: "10",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSM2758  ",
    Org: "DVA",
    Name: "PRETTO. Con cetta Maria ",
    Matched:
      "File ID Matched, 1 record found, but the name provided did not match",
    FirstName: true,
    Surname: true,
    Assessment103: 6012430006,
    AssessmentId: 164524,
    EntityId: 38094,
    Id: "11",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSM34283  ",
    Org: "DVA",
    Name: "EVANS, Robert John",
    Matched: "Mandatory fields not entered.",
    FirstName: true,
    Surname: true,
    Assessment103: 2120540002.002,
    AssessmentId: 264710,
    EntityId: 242201,
    Id: "12",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSS03691  ",
    Org: "DVA",
    Name: "EDWARDS. Ronald  ",
    Matched:
      "Multiple records found for non Centrelink reference number/details provided",
    FirstName: true,
    Surname: true,
    Assessment103: 1020100006,
    AssessmentId: 101157,
    EntityId: 61192,
    Id: "13",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSS05285  ",
    Org: "DVA",
    Name: "VELLA Joseph",
    Matched:
      "Multiple records found for non Centrelink reference number/details provided",
    FirstName: true,
    Surname: true,
    Assessment103: 7060780003,
    AssessmentId: 220294,
    EntityId: 147992,
    Id: "14",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSS10747A ",
    Org: "DVA",
    Name: "MILETO, Michael Angelo",
    Matched: "No record found for non Centrelink reference number provided",
    FirstName: true,
    Surname: true,
    Assessment103: 6010230005,
    AssessmentId: 207561,
    EntityId: 266948,
    Id: "15",
  },
  {
    Action: "Mismatch",
    CustomerId: "NSS15945  ",
    Org: "DVA",
    Name: "FURBOROUGH, David Mark",
    Matched: "No record found for non Centrelink reference number provided",
    FirstName: true,
    Surname: true,
    Assessment103: 1050530001,
    AssessmentId: 105725,
    EntityId: 63142,
    Id: "16",
  },
  {
    Action: "Deceased Person with Entity not indicated as an estate",
    CustomerId: "NX225911 ",
    Org: "DVA",
    ConfirmationId: "455501",
    Name: "MILLER Peter John",
    Matched:
      "Credentials provided for this record are valid, the record is located and result returned",
    ConfirmationDate: "22-Jun-2017",
    Deceased: true,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 2200630016,
    AssessmentId: 118813,
    EntityId: 181861,
    Id: "17",
  },
  {
    Action: "Deceased Person with Active Concession Cards",
    CustomerId: "NX225911  ",
    Org: "DVA",
    ConfirmationId: "455501",
    Name: "MILLER Peter John",
    Matched:
      "Credentials provided for this record are valid, the record is located and result returned",
    ConfirmationDate: "22-Jun-2017",
    Deceased: true,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 2200630016,
    AssessmentId: 118813,
    EntityId: 181861,
    Id: "18",
  },
  {
    Action: "Deceased Person with Active Rebate",
    CustomerId: "NX225911 ",
    Org: "DVA",
    ConfirmationId: "455501",
    Name: "MILLER Peter John",
    Matched:
      "Credentials provided for this record are valid, the record is located and result returned",
    ConfirmationDate: "22-Jun-2017",
    Deceased: true,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 2200630016,
    AssessmentId: 118813,
    EntityId: 181861,
    Id: "19",
  },
  {
    Action: "Deceased Person with Entity not indicated as an estate",
    CustomerId: "NX298122  ",
    Org: "DVA",
    ConfirmationId: "78625",
    Matched:
      "Credentials provided for this record are valid, the record is located and result returned",
    ConfirmationDate: "22-Jun-2017",
    Deceased: true,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5710540017,
    AssessmentId: 157648,
    EntityId: 157768,
    Id: "20",
  },
  {
    Action: "Deceased Person with Active Concession Cards",
    CustomerId: "NX298122  ",
    Org: "DVA",
    ConfirmationId: "78625",
    Matched:
      "Credentials provided for this record are valid, the record is located and result returned",
    ConfirmationDate: "22-Jun-2017",
    Deceased: true,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5710540017,
    AssessmentId: 157648,
    EntityId: 157768,
    Id: "21",
  },
  {
    Action: "Deceased Person with Active Rebate",
    CustomerId: "NX298122 ",
    Org: "DVA",
    ConfirmationId: "78625",
    Matched:
      "Credentials provided for this record are valid, the record is located and result returned",
    ConfirmationDate: "22-Jun-2017",
    Deceased: true,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5710540017,
    AssessmentId: 157648,
    EntityId: 157768,
    Id: "22",
  },
  {
    Action: "Deceased Person with Active Concession Cards",
    CustomerId: "NX347078A  ",
    Org: "DVA",
    ConfirmationId: "1598656",
    Name: "Estate of Peter Horden TAML",
    Matched:
      "Credentials provided for this record are valid, the record is located and result returned",
    ConfirmationDate: "22-Jun-2017",
    Deceased: true,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 4430130006.005,
    AssessmentId: 210707,
    EntityId: 117326,
    Id: "23",
  },
  {
    Action: "Deceased Person with Active Rebate",
    CustomerId: "NX347078A",
    Org: "DVA",
    ConfirmationId: "1598656",
    Name: "Estate of Peter Horden TAML",
    Matched:
      "Credentials provided for this record are valid, the record is located and result returned",
    ConfirmationDate: "22-Jun-2017",
    Deceased: true,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 4430130006.005,
    AssessmentId: 210707,
    EntityId: 117326,
    Id: "24",
  },
];
