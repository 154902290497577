import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { getViewConfigurations } from "@app/products/property/api";
import { RaiseAssessmentInterestButton } from "@app/products/property/assessments/components/action-bar/buttons/raise-assessment-interest/_index";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { RaiseDeferredDutyInterestButton } from "@app/products/property/deferred-duty/components/action-bar/buttons/raise-interest/_index";
import { CreateJournalListButton } from "@app/products/property/journals/list/components/action-bar/buttons/create-journal-list/_index";
import { CreateSessionButton } from "@app/products/property/journals/list/components/action-bar/buttons/create-session/_index";
import { RaiseSingleSchemeAccountInterestButton } from "@app/products/property/journals/list/components/action-bar/buttons/raise-single-scheme-account-interest/_index";
import { colJournals } from "@app/products/property/journals/list/config";
import { PROPERTY_JOURNALS_LISTS_VIEW_URL } from "@app/products/property/journals/list/constant";
import { VO_JournalList } from "@app/products/property/journals/list/model";
import { JournalListBookmark } from "@app/products/property/journals/list/util";
import {
  JournalStatus,
  JournalStatusName,
} from "@app/products/property/journals/model";
import { journalsRoute } from "@app/products/property/journals/route";
import { JournalBookmark } from "@app/products/property/journals/util";
import { CancelJournalButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/cancel-journal/_index";
import { ReverseJournalButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/reverse-journal/_index";
import { JournalDetailTab } from "@app/products/property/journals/[id]/components/reference-sidebar/detail/_index";
import { PROPERTY_JOURNALS_ROUTE } from "@app/products/property/journals/[id]/constant";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { processDynamicColumns } from "@app/products/property/util";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useEffectOnce, useLocation } from "react-use";
import { CompleteIncompleteJournalButton } from "@app/products/property/journals/[id]/components/forms/existed/components/action-bar/buttons/complete-journal/_index";

const nameOf = nameOfFactory<VO_JournalList>();

export default observer(() => {
  const location = useLocation();

  const state = location?.state?.state;
  const {
    gridSelectedRows,
    gridDataState,
    setGridDataState,
    setGridSelectedRows,
    setIsShowFilterBar,
  } = useCCProductListViewStore();

  useEffectOnce(() => {
    if (state && state.filter) {
      const newFilterState: CompositeFilterDescriptor = {
        filters: state?.filter,
        logic: "and",
      };
      setGridDataState({ ...gridDataState, filter: newFilterState });
      setIsShowFilterBar(true);

      setGridSelectedRows([]);
    }
  });
  useEffect(() => {
    return () => {
      location.state = undefined;
    };
  }, [location]);
  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown category={journalsRoute.path} />,
    ],
    centerComponents: [
      <CCNavButton title="Workflow" type="sub-workflow">
        <CCNavButton title="Journal" type="dropdown">
          <CancelJournalButton
            disabled={
              gridSelectedRows?.length !== 1 ||
              gridSelectedRows?.[0]?.Journal_Status_Name !==
                JournalStatusName.ToBeAuthorised
            }
          />
          <CompleteIncompleteJournalButton
            disabled={
              gridSelectedRows?.length !== 1 ||
              gridSelectedRows?.[0]?.Journal_Status_Id !==
                JournalStatus.Incomplete
            }
            isCompleteButton
          />
          <CreateJournalListButton />
          <ReverseJournalButton />
        </CCNavButton>
        <CreateSessionButton />
        <CCNavButton title="Interest" type="dropdown">
          <RaiseAssessmentInterestButton buttonName="Raise assessment interest" />
          <RaiseDeferredDutyInterestButton title="Raise deferred duty interest" />
          <RaiseSingleSchemeAccountInterestButton />
        </CCNavButton>
      </CCNavButton>,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CommunityProperty}
        dataSetFilter={MAILMERGEDATASET.COMMUNITYPROPERTY_Journal}
        recordType={RECORDTYPE.CommunityProperty_Journal}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PROPERTY_JOURNALS_ROUTE}
        productType={PRODUCT_TYPE.CommunityProperty}
        recordType={RECORDTYPE.CommunityProperty_Journal}
        detail={JournalBookmark.getBookmarkDetail}
        displayName={JournalBookmark.getBookmarkDisplayName}
        listViewDetail={JournalListBookmark.getBookmarkListViewDetail}
        listViewDisplayName={JournalListBookmark.getBookmarkListViewDisplayName}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <JournalDetailTab /> },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            recordType={RECORDTYPE.CommunityProperty_Journal}
          />
        ),
      },
    ],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >(undefined);
  const [processedJournalsCol, setProcessedJournalsCol] =
    useState<IColumnFields[]>(colJournals);

  const loadData = () => {
    setIsLoading(true);
    getViewConfigurations(ViewConfiguration.OData_Journal).then((response) => {
      if (isSuccessResponse(response)) {
        const viewConfig: ICCViewColumn[] | undefined =
          response?.data?.ColumnDefinitions?.Columns;
        if (!viewConfig || viewConfig?.length === 0) {
          setProcessedJournalsCol([]);
          setIsLoading(false);
          return;
        }
        setProcessedJournalsCol(processDynamicColumns(colJournals, viewConfig));
      } else {
        setResponseLoadError({
          status: response.status,
          error: response.error ?? "Load failed",
        });
      }
      setIsLoading(false);
    });
  };

  useEffectOnce(() => {
    loadData();
  });

  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadData();
        }}
      />
    );
  if (isLoading) return <Loading isLoading={isLoading} isFullScreen />;

  return (
    <CCProductListView
      columnFields={processedJournalsCol}
      primaryField={nameOf("Journal_Number")}
      dataUrl={PROPERTY_JOURNALS_LISTS_VIEW_URL}
      state={{ sort: [{ field: nameOf("Journal_Number"), dir: "desc" }] }}
    />
  );
});
