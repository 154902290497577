import { useIsNew } from "@common/hooks/useIsNew";
import { ExistedSecurityCorporateAuth } from "@common/pages/settings/security/corporate-authorisations/_id/components/forms/existed/_index";
import { NewSecurityCorporateAuth } from "@common/pages/settings/security/corporate-authorisations/_id/components/forms/new/_index";
import { ISecurityCorporateAuthNotification } from "@common/pages/settings/security/corporate-authorisations/_id/model";
import { useCorporateAuthStore } from "@common/pages/settings/security/corporate-authorisations/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageCorporateAuth = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const corporateAuthId = parseInt(params.id);
  const { resetStore, loadCorporateAuth, setCorporateAuthNotification } =
    useCorporateAuthStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    if (location.state) {
      setCorporateAuthNotification(
        location.state as ISecurityCorporateAuthNotification
      );
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line
  }, [isNew]);

  useEffect(() => {
    loadCorporateAuth(corporateAuthId, isNew);
  }, [isNew, loadCorporateAuth, corporateAuthId]);

  if (isNew) {
    return <NewSecurityCorporateAuth />;
  }
  return <ExistedSecurityCorporateAuth />;
};

export default observer(ManageCorporateAuth);
