import {
  getContactRelationshipType,
  postContactRelationship,
} from "@app/core/contacts/components/dialogs/additional-contact/api";
import { IAdditionalContactDialog } from "@app/core/contacts/components/dialogs/additional-contact/model";
import { processAdditionalContactOption } from "@app/core/contacts/components/dialogs/additional-contact/util";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { IdentityPacketErrorStatus } from "@common/models/sysEnumerations";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { customLogger } from "@common/utils/logger";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import Loading from "@components/loading/Loading";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isNil } from "lodash";
import React, { useMemo, useRef, useState } from "react";
import { useEffectOnce } from "react-use";
const nameOf = nameOfFactory<IAdditionalContactDialog>();

interface AdditionalContactDialogProps {
  onClose: () => void;
  onSubmit: (isSuccess: boolean) => void;
  id?: number;
  recordType: RECORDTYPE;
  bubbleUps?: BubbleUpIdentifier[];
}
export const AdditionalContactDialog = ({
  onClose,
  onSubmit,
  recordType,
  id,
  bubbleUps,
}: AdditionalContactDialogProps) => {
  const [loadingStatus, setLoadingStatus] = useState<"get" | "save" | "none">(
    "none"
  );
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [relationshipType, setRelationshipType] = useState<
    IKeyValuePacket[] | undefined
  >();
  const [initialValues, setInitialValues] = useState<
    IAdditionalContactDialog | undefined
  >();

  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const options = useMemo(
    () => processAdditionalContactOption(recordType),
    [recordType]
  );

  const handleSubmit = async (values: any) => {
    const { Contact, RelationshipType } = values as IAdditionalContactDialog;
    if (isNil(id) || isNil(RelationshipType) || !Contact?.ID) return;
    setLoadingStatus("save");
    const response = await postContactRelationship({
      RecordSourceType: recordType,
      RecordSourceId: id,
      ContactId: Contact.ID,
      ContactRelationshipType: RelationshipType,
      BubbleUps: bubbleUps,
    });
    customLogger("Core Contact, Add contact, postContactRelationship").info(
      response?.data
    );
    if (isSuccessResponse(response)) {
      if (response.data?.ErrorStatus === IdentityPacketErrorStatus.Success) {
        onSubmit(true);
      } else {
        notificationRef.current?.pushNotification({
          autoClose: false,
          title: "Additional could not be added",
          type: "error",
          description: response.data?.Errors,
        });
      }
    } else {
      notificationRef.current?.pushNotification({
        autoClose: false,
        title: response?.error ?? "Additional could not be added",
        type: "error",
      });
    }
    setLoadingStatus("none");
  };

  const handleLoadRelationshipType = async () => {
    setLoadingStatus("get");
    let relationshipType = undefined;
    let errorResponse = undefined;
    const response = await getContactRelationshipType(
      options.relationshipFilters
    );
    customLogger("Core Contact, Add contact, getContactRelationshipType").info(
      response?.data
    );
    if (isSuccessResponse(response)) {
      relationshipType = response.data;
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    setInitialValues({
      Contact: null,
      RelationshipType: relationshipType?.[0]?.Key,
    });
    setRelationshipType(relationshipType);
    setResponseLoadError(errorResponse);
    setLoadingStatus("none");
  };

  useEffectOnce(() => {
    handleLoadRelationshipType();
  });

  return (
    <Form
      onSubmit={handleSubmit}
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      render={(formRenderProps: FormRenderProps) => {
        const { modified, valid, onSubmit: onFormSubmit } = formRenderProps;
        return (
          <CCDialog
            maxWidth="35%"
            height="auto"
            disabled={loadingStatus !== "none"}
            titleHeader={"Add Additional Contact"}
            onClose={onClose}
            bodyElement={
              loadingStatus === "get" ? (
                <Loading isLoading />
              ) : responseLoadError ? (
                <CCLoadFailed
                  responseError={responseLoadError}
                  onReload={handleLoadRelationshipType}
                />
              ) : (
                <div className="cc-form">
                  <CCLocalNotification ref={notificationRef} />
                  <FormElement className="cc-field-group">
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <label className="cc-label">
                          Relationship type <CCTooltip type="validator" />
                        </label>
                        <Field
                          name={nameOf("RelationshipType")}
                          component={CCSearchComboBox}
                          validator={requiredValidator}
                          data={relationshipType}
                          textField="Value"
                          dataItemKey="Key"
                          isUseDefaultOnchange
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Contact <CCTooltip type="validator" />
                        </label>
                        <Field
                          name={nameOf("Contact")}
                          component={ContactPicker}
                          isLoadDetail={false}
                          validator={requiredValidator}
                          contactClassificationFilters={options.contactFilters}
                        />
                      </div>
                    </div>
                  </FormElement>
                </div>
              )
            }
            footerElement={
              <div className={"cc-dialog-footer-actions-right"}>
                <Button className={"cc-dialog-button"} onClick={onClose}>
                  Close
                </Button>
                <Button
                  disabled={!modified || !valid}
                  themeColor="primary"
                  onClick={onFormSubmit}
                  iconClass={
                    loadingStatus === "save" ? "fas fa-spinner fa-spin" : ""
                  }
                  className={"cc-dialog-button"}
                >
                  Save
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
