import {
  FSR_BUSINESSCLASSIFICATION,
  PremisesType,
} from "@app/products/hm/model";
import {
  getNameOfPremises,
  nameOfSvcPremises,
} from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { hmOptionOfficer } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/officers-section/config";
import { HM_PREMISES_HANDLER_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  IHMHandlerEventInitialData,
  OfficerSelectionMethod,
  PremiseHandlerRequest,
  Premises,
  PremisesRegisterLovs,
  PremisesUIControl,
  Premises_Status,
  Svc_FormAction_Premise,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { CURRENCY_FORMAT } from "@common/constants/common-format";
import {
  FINANCEMETHOD,
  HEALTHMANAGER_CONFIGURATIONMODE,
} from "@common/constants/enumerations";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { SiteUser } from "@common/pages/settings/security/site-users/_id/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import {
  capitalizeFirstLetter,
  formatDisplayValue,
  formatOSFees,
} from "@common/utils/formatting";
import { CCLabel } from "@components/cc-label/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo } from "react";

export interface IOfficersSectionProps {
  formRenderProps: FormRenderProps;
  isInactive?: boolean;
}

const nameOfPremisesType = nameOfFactory<PremisesType>();
const nameOfKeyValuePacket = nameOfFactory<IKeyValuePacket>();
export const OfficersSection = observer(
  ({ formRenderProps, isInactive = false }: IOfficersSectionProps) => {
    const { dataForms } = useFlexibleFormStore();
    const { settings } = useCommonCoreStore();
    const { valueGetter, onChange } = formRenderProps;

    const premisesGeneralForm = dataForms?.GeneralForm as Svc_Premises;
    const premisesObj = valueGetter(nameOfSvcPremises("Premises")) as Premises;
    const premisesGeneralFormLovs =
      dataForms?.GeneralFormLovs as PremisesRegisterLovs;
    const premisesGeneralUIControl =
      dataForms?.GeneralUIControl as PremisesUIControl;

    // #region GET SETTING VALUES
    const hmOfficerSelectionMethod = getNumberValueSetting(
      settings[ECorporateSettingsField.HealthManager_OfficerSelectionMethod]
    );
    const osFeeLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_OSFeeLabel]
    );
    const financeMethod =
      getNumberValueSetting(
        settings[ECorporateSettingsField.CorporateSettings_FinanceMethod]
      ) ?? FINANCEMETHOD.None;
    const enableUpToGlassFinance = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
    );
    const enableMultiLineInvoice = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
    );
    const configurationMode = getNumberValueSetting(
      settings[ECorporateSettingsField.HealthManager_ConfigurationMode]
    );
    const isHealthAreaMandatory = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_HealthAreaMandatory]
    );
    // #endregion GET SETTING VALUES

    // #region SHOW/HIDE VARIABLES
    const divHealthAreaVisible = useMemo(
      () => {
        return (
          valueGetter(
            `${getNameOfPremises("PremisesType")}.${nameOfPremisesType(
              "Flag_HealthArea"
            )}`
          ) ?? false
        );
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(`${getNameOfPremises("PremisesType")}.Flag_HealthArea`)]
    );

    const isFlagFSPTrue = useMemo(
      () => {
        return (
          valueGetter(
            `${getNameOfPremises("PremisesType")}.${nameOfPremisesType(
              "Flag_FSP"
            )}`
          ) ?? false
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(`${getNameOfPremises("PremisesType")}.Flag_FSP`)]
    );

    const checkUpToGlassEnabled = useMemo(() => {
      return enableUpToGlassFinance && financeMethod === FINANCEMETHOD.Direct;
    }, [enableUpToGlassFinance, financeMethod]);

    const isDisabledHealthArea = useMemo(() => {
      return (
        hmOfficerSelectionMethod === OfficerSelectionMethod.ByArea &&
        premisesObj?.Status_ENUM === Premises_Status.Closed
      );
    }, [hmOfficerSelectionMethod, premisesObj?.Status_ENUM]);
    //#endregion SHOW/HIDE VARIABLES

    // #region Value Getters
    const officerDisplay = useMemo(
      () => {
        let litOfficer = "";
        if (valueGetter(getNameOfPremises("Officer"))) {
          litOfficer = valueGetter(getNameOfPremises("Officer")).DisplayName;
        } else {
          if (hmOfficerSelectionMethod === OfficerSelectionMethod.ByArea) {
            litOfficer = "Not Assigned - Select Area";
          } else {
            litOfficer = "";
          }
        }
        return litOfficer;
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(getNameOfPremises("Officer")), hmOfficerSelectionMethod]
    );

    const fsrBusinessClassENUM = valueGetter(
      `${getNameOfPremises("PremisesType")}.FSR_BusinessClass_ENUM`
    );

    const sideAddressArea =
      valueGetter(getNameOfPremises("SiteAddress"))?.PropertyAssessment?.Area ??
      "";
    // #endregion Value Getters

    // #region Functions
    const pickOfficerHandler = (officer: SiteUser) => {
      if (officer) {
        onChange(getNameOfPremises("Officer"), { value: officer });
        onChange(getNameOfPremises("Officer_ID"), {
          value: officer.Contact_ID,
        });
      } else {
        onChange(getNameOfPremises("Officer"), { value: null });
        onChange(getNameOfPremises("Officer_ID"), { value: null });
      }
    };

    const handleChangeHealthArea = (event: ComboBoxChangeEvent | null) => {
      const areaKey = event?.value?.Key;
      if (areaKey) {
        onChange(
          `${getNameOfPremises("SiteAddress")}.PropertyAssessment.Area`,
          {
            value: event?.value?.Value,
          }
        );
        let premisesHandlerRequest = {
          FormAction: Svc_FormAction_Premise.Form_HealthAreaChanged,
          Premises: premisesObj,
          Args: areaKey,
          IsFirstTimeLoad: false,
          UIControl: premisesGeneralUIControl,
          PremiseLOVs: premisesGeneralFormLovs,
        } as PremiseHandlerRequest;

        const handlerInitialData: IHMHandlerEventInitialData = {
          premisesRegisterHandlerRequest: premisesHandlerRequest,
          errorMsg: "Select health area failed.",
        };
        fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
          initialData: handlerInitialData,
        });
      } else {
        onChange(
          `${getNameOfPremises("SiteAddress")}.PropertyAssessment.Area`,
          {
            value: null,
          }
        );
      }
    };

    // #endregion Functions

    // #region Mandatory
    const isMandatoryHealthArea = useMemo(() => {
      return isHealthAreaMandatory && divHealthAreaVisible;
    }, [divHealthAreaVisible, isHealthAreaMandatory]);

    const healthAreaValidator = useCallback(() => {
      return isMandatoryHealthArea
        ? requiredValidator(sideAddressArea)
        : undefined;
    }, [isMandatoryHealthArea, sideAddressArea]);
    // #endRegion Mandatory
    return (
      <>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Officer" />
              <Field
                component={InputPickerSearch}
                valueMapGrid={valueGetter(getNameOfPremises("Officer_ID"))}
                name={getNameOfPremises("Officer")}
                nameDisplay="DisplayName"
                value={officerDisplay}
                options={hmOptionOfficer}
                onChange={pickOfficerHandler}
                placeholder="Select officer"
                disabled={
                  isInactive ||
                  hmOfficerSelectionMethod === OfficerSelectionMethod.ByArea
                }
              />
            </div>
            <CCValueField
              label={capitalizeFirstLetter(osFeeLabel) ?? "OS fees"}
              value={
                premisesGeneralForm?.OSFee
                  ? formatDisplayValue(
                      premisesGeneralForm?.OSFee,
                      CURRENCY_FORMAT.CURRENCY1
                    )
                  : "$0"
              }
              style={
                premisesGeneralForm?.OSFee
                  ? formatOSFees(premisesGeneralForm?.OSFee)
                  : undefined
              }
              format={CURRENCY_FORMAT.CURRENCY1}
            />
            {checkUpToGlassEnabled && enableMultiLineInvoice && (
              <CCValueField
                label="Premises balance"
                value={premisesGeneralForm?.PremisesBalance}
                style={
                  premisesGeneralForm?.PremisesBalance
                    ? formatOSFees(premisesGeneralForm?.PremisesBalance)
                    : undefined
                }
                format={CURRENCY_FORMAT.CURRENCY1}
              />
            )}
            {valueGetter(getNameOfPremises("Rating")) && (
              <CCValueField
                label="Rating"
                value={valueGetter(getNameOfPremises("Rating")) + " Star"}
              />
            )}
          </div>
          <div className="cc-form-cols-3">
            {divHealthAreaVisible && (
              <div className="cc-field">
                <CCLabel
                  title="Health area"
                  isMandatory={isMandatoryHealthArea}
                />
                <Field
                  name={`${getNameOfPremises(
                    "SiteAddress"
                  )}.PropertyAssessment.Area`}
                  component={CCSearchComboBox}
                  data={premisesGeneralFormLovs?.HealthArea}
                  textField={"Value"}
                  dataItemKey={"Value"}
                  value={getDropdownValue(
                    valueGetter(
                      `${getNameOfPremises(
                        "SiteAddress"
                      )}.PropertyAssessment.Area`
                    ),
                    premisesGeneralFormLovs?.HealthArea,
                    "Value"
                  )}
                  onChange={handleChangeHealthArea}
                  disabled={isInactive || isDisabledHealthArea}
                  validator={healthAreaValidator}
                />
              </div>
            )}
            {configurationMode ===
              HEALTHMANAGER_CONFIGURATIONMODE.WATER_AUTHORITY && (
              <div className="cc-field">
                <CCLabel title="Sewage treatment plant" />
                <Field
                  name={getNameOfPremises("SewageTreatmentPlant_KWD")}
                  component={CCSearchComboBox}
                  data={premisesGeneralFormLovs?.SewageTreatmentPlan ?? []}
                  textField={nameOfKeyValuePacket("Value")}
                  dataItemKey={nameOfKeyValuePacket("Key")}
                  isUseDefaultOnchange
                  disabled={isInactive}
                />
              </div>
            )}
            {isFlagFSPTrue && (
              <div className="cc-field cc-col-span-2">
                <CCLabel
                  title="FSP template"
                  isMandatory={
                    fsrBusinessClassENUM ===
                      FSR_BUSINESSCLASSIFICATION.CLASS_1 ||
                    fsrBusinessClassENUM === FSR_BUSINESSCLASSIFICATION.CLASS_2
                  }
                />
                <Field
                  name={getNameOfPremises("FSPTemplate_KWD")}
                  component={CCSearchComboBox}
                  data={premisesGeneralFormLovs?.FSPTemplate ?? []}
                  textField={nameOfKeyValuePacket("Value")}
                  dataItemKey={nameOfKeyValuePacket("Key")}
                  isUseDefaultOnchange
                  disabled={isInactive}
                  validator={
                    fsrBusinessClassENUM ===
                      FSR_BUSINESSCLASSIFICATION.CLASS_1 ||
                    fsrBusinessClassENUM === FSR_BUSINESSCLASSIFICATION.CLASS_2
                      ? requiredValidator
                      : undefined
                  }
                />
              </div>
            )}
          </div>
        </section>
      </>
    );
  }
);
