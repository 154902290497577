import { Journal } from "@app/core/journal/_index";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

interface IInspectionHistoryTabProps {
  // Get the data by this field
  fieldName?: string;
}

export const InspectionHistoryTab = observer(
  ({ fieldName }: IInspectionHistoryTabProps) => {
    const { lastSelectedId, lastSelectedRow } = useCCProductListViewStore();
    const params: { id: string } = useParams();
    const inspectionId =
      fieldName &&
      !isNil(lastSelectedRow?.[fieldName]) &&
      lastSelectedRow[fieldName] !== ""
        ? lastSelectedRow[fieldName]
        : params.id;

    const id: number | string = inspectionId ?? lastSelectedId;
    return <Journal id={id} recordType={RECORDTYPE.CORE_Inspection} />;
  }
);
