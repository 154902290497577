import {
  DTO_Owners,
  DTO_Rebate_Concession_Card,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { isAfter } from "date-fns";

export const concessionCardsFormValidator = (values: any) => {
  const owners = values?.Owners ?? [];
  let hasError = false;
  owners.forEach((owner: DTO_Owners) => {
    const concessionCards = owner?.ConcessionCards ?? [];
    concessionCards.forEach((card: DTO_Rebate_Concession_Card) => {
      const presentedDate = card.Presented_Date;
      if (presentedDate && isAfter(new Date(presentedDate), new Date())) {
        hasError = true;
      }
    });
  });
  if (hasError) {
    return "Presented date of the concession card(s) must be less than or equal to today's date";
  }
  return undefined;
};
