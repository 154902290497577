import { KeyValuePair } from "@app/core/documents/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  checkIsSuperAdmin,
  getHMPremiseById,
  getHMPremiseRegisterLOVs,
  postHMPremisesEventHandler,
} from "@app/products/hm/premises/[id]/api";
import { NewHMManagePremise } from "@app/products/hm/premises/[id]/components/forms/new/_index";
import {
  HM_PREMISES_GET_SLIDER,
  HM_PREMISES_HANDLER_SLIDER,
  HM_PREMISES_REGISTER_GET_INIT_SLIDER,
  TITLE_HM_PREMISES_MANAGE_PAGE,
} from "@app/products/hm/premises/[id]/constant";
import {
  ApiPremisesTransferData,
  IHMHandlerEventInitialData,
  IPremisesCCFormOptions,
  IPremisesFormSecurity,
  PremiseHandlerRequest,
  PremisesHandlerResponse,
  Premises_Status,
  Svc_FormAction_Premise,
  Svc_Premises,
  TApiPremisesHandlerResponse,
  TApiPremisesResponse,
} from "@app/products/hm/premises/[id]/model";
import {
  generatePCBACategoriesList,
  getPremisesAlerts,
} from "@app/products/hm/premises/[id]/util";
import { processPushNotificationPortal } from "@app/products/local-laws/[id]/util";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import {
  fetchApiByAlias,
  useFlexibleFetchData,
} from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IIdentityPacket } from "@common/models/identityPacket";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { EWatchStatusLoading } from "@common/stores/route/model";
import { useRouteDataStore } from "@common/stores/route/store";
import {
  CCCheckPermissionWrapper,
  TPermissionCheck,
} from "@components/cc-check-permission-wrapper/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { INotificationPortalContentExcludeId } from "@components/cc-notification-portal/components/notification-portal-content/model";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import Loading from "@components/loading/Loading";
import { isNil, size } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";
import { ExistHMManagePremise } from "./components/forms/exist/_index";

const HMManagePremise = observer(() => {
  const { cancelToken } = useCancelRequest();
  const { currentUserInfo } = useGlobalStore();
  const history = useHistory();
  const { settings } = useCommonCoreStore();
  const { isLoadingFor } = useRouteDataStore();
  const isNew = useIsNew();
  const {
    setInitialDataForms,
    isLoadingForm,
    syncFormWithStore,
    dataForms,
    setMiddlewareSubmitFormOptions,
  } = useFlexibleFormStore();
  const {
    pushNotificationPortal,
    clearNotifications,
    notificationsOtherPage,
    listenNotificationPortal,
  } = useNotificationPortalStore();
  const { resetMenu } = useCCSubActionBarStore();
  const location = useLocation();

  const premisesState = location.state as any;

  const { checkPermissions, viewActionButton } = useCheckPermissions({
    formIdentifier: FormIdentifier.HealthManager_Form_Premises,
    productType: PRODUCT_TYPE_NUMBER.HealthManager,
  });

  const currentUserID = currentUserInfo?.UserPKID;

  // #region SECURITY TEMPLATES
  const allowChangePremisesType = checkPermissions(
    FormAction.HM_CHANGEPREMISESTYPE
  );
  const allowOverrideInspFrequency = checkPermissions(
    FormAction.HM_OVERRIDEINSPFREQUENCY
  );
  const allowEditRegistration = checkPermissions(
    FormAction.HM_EDIT_REGISTRATION
  );
  const allowSaveAndEdit = checkPermissions(FormAction.CORE_ALLOW_EDIT);
  const allowDeleteInspection = checkPermissions(
    FormAction.CORE_DELETE_INSPECTION
  );
  const allowRegisterPremisesButton = checkPermissions(
    FormAction.HM_REGISTERREGISTRATION
  );
  // #endregion SECURITY TEMPLATES

  const { id } = useParams<{ id: string }>();
  const premiseID = useMemo(() => {
    return isNew ? "0" : id;
  }, [isNew, id]);

  const isShowScoresEndorse =
    getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowScoresEndorse]
    ) ?? false;
  const isFastChoice =
    getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_FastChoice]
    ) ?? false;
  // Check security
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const checkPermissionForPremises: TPermissionCheck = {
    formIdentifier: FormIdentifier.HealthManager_Form_Premises,
    productType: PRODUCT_TYPE_NUMBER.HealthManager,
    formAction: FormAction.CORE_ALLOW_NEW,
  };

  const premisesFromStore = dataForms?.GeneralForm as Svc_Premises;

  const managePremiseSlider = useFlexibleFetchData({
    alias: HM_PREMISES_GET_SLIDER,
    slides: [
      {
        fetch: () => {
          return [
            getHMPremiseRegisterLOVs(cancelToken()),
            getHMPremiseById(parseInt(premiseID), cancelToken()),
            checkIsSuperAdmin(
              currentUserID ? +currentUserID : 0,
              cancelToken()
            ),
          ];
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: TApiPremisesResponse = dataFromApi;
          const premiseLovs = response[0]?.data?.ReturnObj;
          const premiseData = response[1]?.data?.ReturnObj;
          const isSuperAdmin = response[2]?.data;

          setInitialDataForms({
            GeneralForm: premiseData,
            GeneralFormLovs: premiseLovs,
            IsSuperAdmin: isSuperAdmin,
            CCFormOptions: {
              PremisesType: premiseData?.Premises?.PremisesType,
              Date_RegistrationTo: premiseData?.Premises?.Date_RegistrationTo,
              Date_RegistrationFrom:
                premiseData?.Premises?.Date_RegistrationFrom,
            } as IPremisesCCFormOptions, // Use for saving the init value
          });
        },
        extractParamForNextAPI: ({ dataFromApi }) => {
          const response: TApiPremisesResponse = dataFromApi;
          return {
            notifications: response[1]?.data?.Notifications
              ? response[1]?.data?.Notifications
              : [],
            uiControl: {},
            premiseLOVs: response[0]?.data?.ReturnObj,
            premisesFormData: response[1]?.data?.ReturnObj,
          };
        },
      },
      {
        fetch: ({ dataFromExtractParam }) => {
          const {
            premisesFormData,
            uiControl,
            premiseLOVs,
          }: ApiPremisesTransferData = dataFromExtractParam;

          const premisesRegisterHandlerRequest = {
            FormAction: Svc_FormAction_Premise.SystemInitialise,
            Premises: premisesFormData?.Premises,
            Args: {},
            IsFirstTimeLoad: true,
            UIControl: uiControl ?? {},
            PremiseLOVs: premiseLOVs,
          };

          return postHMPremisesEventHandler(
            premisesRegisterHandlerRequest as PremiseHandlerRequest,
            cancelToken()
          );
        },
        handleSuccess: async ({ dataFromApi, dataFromExtractParam }) => {
          const response: APIResponse<
            IIdentityPacket<PremisesHandlerResponse>
          > = dataFromApi;
          const data = response?.data?.ReturnObj;
          listenNotificationPortal();

          // Generate PCAndBA categories
          const pcpaCategoriesList: KeyValuePair<string, string>[] =
            generatePCBACategoriesList(data?.Premises?.PCAndBA);
          data.Premises._PCAndBACategories = pcpaCategoriesList;

          if (isShowScoresEndorse) {
            data.Premises.Flag_ScoresEndorse = isNil(
              data?.Premises?.Flag_ScoresEndorse
            )
              ? false
              : data?.Premises?.Flag_ScoresEndorse;
          }

          if (isFastChoice) {
            data.Premises.Flag_FastChoice = isNil(
              data?.Premises?.Flag_FastChoice
            )
              ? false
              : data?.Premises?.Flag_FastChoice;
          }

          // #region Show alerts notification
          if (size(notificationsOtherPage) === 0) {
            // there is no notification from other page => clear all notification
            clearNotifications();
          }
          const alerts = getPremisesAlerts(data?.Premises);
          processPushNotificationPortal(
            alerts,
            (alert: INotificationPortalContentExcludeId) =>
              pushNotificationPortal(alert)
          );

          processPushNotificationPortal(
            dataFromExtractParam?.notifications ?? [],
            (notification: INotificationPortalContentExcludeId) =>
              pushNotificationPortal({ type: "info", title: notification })
          );
          // #endregion Show notifications

          setInitialDataForms({
            GeneralForm: {
              ...dataFromExtractParam?.premisesFormData,
              Premises: data?.Premises,
            },
            GeneralFormLovs: data?.PremiseLOVs,
            GeneralUIControl: data?.UIControl,
          });

          if (
            premisesState?.isCallInitRegisterPremises &&
            !isNew &&
            data?.Premises &&
            data?.Premises?.Status_ENUM === Premises_Status.Prelodgement
          ) {
            window.history.replaceState({}, "");
            await fetchApiByAlias(HM_PREMISES_REGISTER_GET_INIT_SLIDER, {
              initialData: data?.Premises,
            });
          }
        },
        handleError: ({ errorFromApi }) => {
          pushNotificationPortal({
            title: "Load premises was failed.",
            type: "error",
            description: errorFromApi?.error ?? "",
            autoClose: false,
          });
        },
      },
    ],
  });

  const hmPremisesHandlerSlider = useFlexibleFetchData({
    beforeFetch: () => {
      syncFormWithStore("GeneralForm");
    },
    alias: HM_PREMISES_HANDLER_SLIDER,
    slides: [
      {
        fetch: ({ initialData }) => {
          const handlerInitData: IHMHandlerEventInitialData = initialData;
          return postHMPremisesEventHandler(
            handlerInitData?.premisesRegisterHandlerRequest,
            cancelToken()
          );
        },
        handleSuccess: ({ dataFromApi }) => {
          const response: TApiPremisesHandlerResponse = dataFromApi;
          const premisesObj = response.data?.ReturnObj?.Premises;
          const uiControl = response.data?.ReturnObj?.UIControl;
          const lovs = response.data?.ReturnObj?.PremiseLOVs;
          // Generate PCAndBA categories
          const pcpaCategoriesList: KeyValuePair<string, string>[] =
            generatePCBACategoriesList(premisesObj?.PCAndBA);
          premisesObj._PCAndBACategories = pcpaCategoriesList;

          //#region save data to store ========/
          setInitialDataForms({
            GeneralForm: { ...premisesFromStore, Premises: premisesObj },
            GeneralUIControl: uiControl ?? {},
            GeneralFormLovs: lovs ?? {},
          });
          setMiddlewareSubmitFormOptions({ skipCheckModified: true });
          //#endregion save data to store =====/

          if (
            response.data?.Notifications &&
            response.data?.Notifications.length > 0
          ) {
            pushNotificationPortal({
              description: response.data?.Notifications,
              autoClose: false,
            });
          }
        },
        handleError: ({ errorFromApi, initialData }) => {
          pushNotificationPortal({
            title: initialData?.errorMsg ?? "Premises handler failed.",
            type: "error",
            description: errorFromApi?.error ?? "",
            autoClose: false,
          });
        },
      },
    ],
  });

  useEffect(() => {
    if (!isNew || (isNew && hasPermission)) {
      managePremiseSlider.fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location, hasPermission]);

  useEffectOnce(() => {
    return () => {
      clearNotifications();
      resetMenu();
    };
  });

  // Set security
  useEffect(() => {
    if (viewActionButton) {
      syncFormWithStore("GeneralForm");
      setInitialDataForms({
        ...dataForms,
        // Set security
        ParentSection: {
          AllowChangePremisesType: allowChangePremisesType,
          AllowEditRegistration: allowEditRegistration,
          AllowOverrideInspFrequency: allowOverrideInspFrequency,
          AllowSaveAndEdit: allowSaveAndEdit,
          AllowDeleteInspection: allowDeleteInspection,
          AllowRegisterPremisesButton: allowRegisterPremisesButton,
        } as IPremisesFormSecurity,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewActionButton]);

  return (
    <>
      <Loading
        isLoading={
          managePremiseSlider.isFetching ||
          hmPremisesHandlerSlider.isFetching ||
          isLoadingForm ||
          isLoadingFor(EWatchStatusLoading.IsLoadingPermission) // Loading for load security template
        }
        isFullScreen
      />
      <FormNavigation title={TITLE_HM_PREMISES_MANAGE_PAGE} />
      {managePremiseSlider.errorComponent ? (
        managePremiseSlider.errorComponent
      ) : isNew ? (
        <CCCheckPermissionWrapper
          permission={checkPermissionForPremises}
          onChangeHasPermission={() => setHasPermission(true)}
        >
          <NewHMManagePremise />
        </CCCheckPermissionWrapper>
      ) : (
        <ExistHMManagePremise />
      )}
    </>
  );
});

export default HMManagePremise;
