import { useIsNew } from "@common/hooks/useIsNew";
import { ExistedCommunicationTemplate } from "@common/pages/settings/communication/template/_id/components/forms/existed/_index";
import { NewCommunicationTemplate } from "@common/pages/settings/communication/template/_id/components/forms/new/_index";
import { ICommunicationTemplateParentSection } from "@common/pages/settings/communication/template/_id/model";
import { useCommunicationTemplateStore } from "@common/pages/settings/communication/template/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageCommunicationTemplate = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const communicationTemplateId = parseInt(params.id);
  const { resetStore, loadCommunicationTemplate, setParentSection } =
    useCommunicationTemplateStore();
  const { resetMenu } = useCCSubActionBarStore();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as ICommunicationTemplateParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    loadCommunicationTemplate(communicationTemplateId, isNew);
  }, [communicationTemplateId, isNew, loadCommunicationTemplate]);

  if (isNew) {
    return <NewCommunicationTemplate />;
  }
  return <ExistedCommunicationTemplate />;
};

export default observer(ManageCommunicationTemplate);
