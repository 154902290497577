//#region FSR
import { HMFieldMapping } from "@app/products/hm/model";

export const colHMNoticesByDate = [
  {
    field: HMFieldMapping.DateServed,
    title: "Served",
    locked: true,
    width: 150,
  },
  {
    field: HMFieldMapping.ReferenceNumber,
    title: "Registration Number",
    width: 150,
  },
  {
    field: HMFieldMapping.TradingName,
    title: "Name",
    width: 150,
  },
  {
    field: HMFieldMapping.Type,
    title: "Type",
    width: 150,
  },
  {
    field: HMFieldMapping.NoticeCategory,
    title: "Notice Category",
    width: 150,
  },
  {
    field: HMFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.DateDue,
    title: "Compliance Due",
    width: 150,
  },
  {
    field: HMFieldMapping.DateRevoked,
    title: "Complied / Revoked",
    width: 150,
  },
];
