import { getViewConfigurations } from "@app/products/property/api";
import { ICCViewColumn, ViewConfiguration } from "@app/products/property/model";
import { ISupplementaryRatesMasterProperty } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { APIResponseStatus } from "@common/constants/response-status";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class SupplementaryRatesMasterPropertyStore {
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  private _viewConfiguration: ICCViewColumn[] = [];
  private _masterPropertySelected: ISupplementaryRatesMasterProperty[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get viewConfiguration() {
    return this._viewConfiguration;
  }
  setViewConfiguration = (viewConfiguration: ICCViewColumn[]) => {
    runInAction(() => {
      this._viewConfiguration = viewConfiguration;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get masterPropertySelected() {
    return toJS(this._masterPropertySelected);
  }
  setMasterPropertySelected = (
    masterPropertySelected: ISupplementaryRatesMasterProperty[]
  ) => {
    runInAction(() => {
      this._masterPropertySelected = masterPropertySelected;
    });
  };

  loadSupplementaryRatesMasterPropertyConfig = async () => {
    let errorResponse = undefined;
    this.setIsLoading(true);
    const responseConfiguration = await getViewConfigurations(
      ViewConfiguration.Supplementary_MasterProperties
    );
    if (
      isSuccessResponse(responseConfiguration) &&
      responseConfiguration?.data
    ) {
      this.setViewConfiguration(
        responseConfiguration.data.ColumnDefinitions?.Columns
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._isLoading = false;
      this._responseLoadError = undefined;
      this._viewConfiguration = [];
      this._masterPropertySelected = [];
    });
  };
}

const supplementaryRatesMasterPropertyStoreContext = createContext(
  new SupplementaryRatesMasterPropertyStore()
);
export const useSupplementaryRatesMasterPropertyStore = () => {
  return useContext(supplementaryRatesMasterPropertyStoreContext);
};
