import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";

export const viewResponsesRoute: ICCRoute = {
  path: "view-responses",
  name: "View Responses",
  enum: eMenuAction.CentreLink_View_Responses,
  component: require("./_index").default,
  children: [
    {
      path: ":id(new|[0-9]+)",
      component: require("./[id]/_index").default,
    },
  ],
};
