import { odataCoreGetNoticeTypesByProductType } from "@app/core/components/common/utils";
import { getSiteUser } from "@app/core/inspections/[id]/api";
import { Officers } from "@app/core/inspections/[id]/model";
import { productTypeNumberLogic } from "@app/core/inspections/[id]/util";
import { getNoticesTypeById } from "@app/core/notices/[id]/api";
import { NoticeParentSection } from "@app/core/notices/[id]/components/child-screens/general/components/form-element/components/notice-parent-section/_index";
import {
  exposedToAPIOptionData,
  noticeOptionData,
  optionOfficer,
  renderOptionNoticeKeyWord,
  renderOptionNoticeType,
} from "@app/core/notices/[id]/components/child-screens/general/components/form-element/config";
import {
  Notice,
  NoticeType,
  Svc_NoticeType,
} from "@app/core/notices/[id]/model";
import { useNoticesStore } from "@app/core/notices/[id]/store";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { isSuccessResponse } from "@common/apis/util";
import { CURRENCY_FORMAT, DATE_FORMAT } from "@common/constants/common-format";
import {
  NoticeClassification,
  NoticeNumberingMethod,
} from "@common/constants/enumerations";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { Keyword } from "@common/models/keyword";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getDecimalValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCRadioGroup } from "@components/cc-radio-group/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTimePicker } from "@components/cc-time-picker/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { isNil, isNumber } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export interface INoticeFormElementProps {
  formRenderProps: FormRenderProps;
}
const nameOf = nameOfFactory<Notice>();

export const NoticeFormElement = observer(
  ({ formRenderProps }: INoticeFormElementProps) => {
    const { valueGetter, onChange } = formRenderProps;
    const { settings } = useCommonCoreStore();
    const { parent, noticesLOVs, setIsLoading, isSuperAdmin } =
      useNoticesStore();
    const { pushNotification } = useCCAppNotificationStore();

    const productType: PRODUCT_TYPE_NUMBER = productTypeNumberLogic(
      parent?.recordType
    );
    const isEnableNoticeStatusList = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_EnableNoticeStatusList]
    );
    const isCalculateInfringementTimesPenaltyUnits = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CorporateSettings_CalculateInfringementTimesPenaltyUnits
      ]
    );
    const infringementUnitAmount = getDecimalValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_InfringementUnitAmount]
    );
    const isEnableRoundedInfringementAmount = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CorporateSettings_EnableRoundedInfringementAmount
      ]
    );
    const noticeNumberingMethod =
      getNumberValueSetting(
        settings[
          ECorporateSettingsField.CorporateSettings_NoticeNumberingMethod
        ]
      ) ?? NoticeNumberingMethod.None;

    const noticeType = valueGetter(nameOf("NoticeType"));

    const isInfringement = useMemo(() => {
      return (
        noticeType !== null &&
        noticeType?.NoticeClassification_ENUM ===
          NoticeClassification.Infringement
      );
    }, [noticeType]);

    const referenceNumberLabel = isInfringement
      ? "Infringement number"
      : "Reference number";

    const isMandatoryComplianceDue =
      noticeType?.Flag_MandatoryComplianceDue ?? true;

    const noticesTypeChangeHandler = async (data: Svc_NoticeType) => {
      setIsLoading(true);
      if (data?.ID) {
        const response = await getNoticesTypeById(data.ID);
        if (
          isSuccessResponse(response) &&
          response.data &&
          response.data?.ReturnObj
        ) {
          const newNoticeType = response.data.ReturnObj;
          onChange(nameOf("NoticeType"), { value: newNoticeType });
          onChange(nameOf("NoticeType_ID"), { value: data.ID });
          onChange(nameOf("InfringementAmount"), { value: 0 });
          onChange(nameOf("SectionOfLegislation"), {
            value: newNoticeType?.Description ?? "",
          });
          displayNoticeType(newNoticeType);
        } else {
          pushNotification({
            autoClose: false,
            title: "Change notice type fail",
            description: response.data?.Errors,
            type: "error",
          });
        }
      } else {
        onChange(nameOf("NoticeType"), { value: null });
        onChange(nameOf("NoticeType_ID"), { value: null });
      }
      setIsLoading(false);
    };

    const displayNoticeType = (noticeType: NoticeType) => {
      if (!isNil(noticeType?.PenaltyUnits)) {
        const infringementAmount =
          valueGetter(nameOf("InfringementAmount")) ?? null;
        if (
          (infringementAmount === null || Number(infringementAmount) === 0) &&
          isNumber(infringementUnitAmount) &&
          isCalculateInfringementTimesPenaltyUnits
        ) {
          let amount = noticeType.PenaltyUnits * infringementUnitAmount;
          if (isEnableRoundedInfringementAmount) {
            amount = Math.round(amount);
          }
          onChange(nameOf("InfringementAmount"), { value: amount });
        }
      }
    };

    const officerChangeHandler = async (officer: Officers) => {
      if (officer) {
        setIsLoading(true);
        const response = await getSiteUser(officer?.ID);
        setIsLoading(false);
        if (isSuccessResponse(response) && response?.data) {
          onChange(nameOf("ActionOfficer"), {
            value: response?.data,
          });
          onChange(nameOf("ActionOfficer_ID"), {
            value: response.data.Contact_ID,
          });
        } else {
          pushNotification({
            title: "Change officer fail.",
            type: "error",
            autoClose: false,
          });
        }
      } else {
        onChange(nameOf("ActionOfficer"), {
          value: undefined,
        });
        onChange(nameOf("ActionOfficer_ID"), {
          value: undefined,
        });
      }
    };

    const handleChangeNoticeCategory = (noticeCategory: Keyword) => {
      onChange(nameOf("NoticeCategory_KWD"), {
        value: noticeCategory?.Keyword_ID ?? null,
      });
      onChange(nameOf("NoticeCategory_Name"), {
        value: noticeCategory?.Name ?? null,
      });
    };

    return (
      <FormElement>
        <NoticeParentSection recordType={parent?.recordType} />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Notice type" isMandatory />
              <Field
                component={InputPickerSearch}
                name={nameOf("NoticeType")}
                nameDisplay="Name"
                value={valueGetter(nameOf("NoticeType"))}
                options={renderOptionNoticeType(
                  odataCoreGetNoticeTypesByProductType(productType)
                )}
                onChange={noticesTypeChangeHandler}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Notice category" />
              <Field
                name={nameOf("NoticeCategory_KWD")}
                component={InputPickerSearch}
                options={renderOptionNoticeKeyWord(
                  "Notice Category",
                  KEYWORD_TYPE.Core_NoticeCategory,
                  productType,
                  "single"
                )}
                value={valueGetter(nameOf("NoticeCategory_Name"))}
                onChange={handleChangeNoticeCategory}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title={referenceNumberLabel} />
              <Field
                name={nameOf("ReferenceNumber")}
                placeholder={referenceNumberLabel}
                component={CCInput}
                readOnly={noticeNumberingMethod !== NoticeNumberingMethod.None}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Status" />
              {isEnableNoticeStatusList ? (
                <Field
                  name={nameOf("NoticeStatus_KWD")}
                  component={CCSearchComboBox}
                  data={noticesLOVs?.NoticeList ?? []}
                  dataItemKey="Key"
                  textField="Value"
                  isUseDefaultOnchange
                />
              ) : (
                <Field
                  name={nameOf("Status")}
                  placeholder="Status"
                  component={CCInput}
                />
              )}
            </div>
          </div>
        </section>

        {isInfringement ? (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCValueField
                    label="Units"
                    value={noticeType?.PenaltyUnits ?? null}
                  />
                </div>

                <div className="cc-field">
                  <CCValueField
                    label="Amount"
                    value={valueGetter(nameOf("InfringementAmount"))}
                    format={CURRENCY_FORMAT.CURRENCY1}
                  />
                </div>
              </div>
            </section>
          </>
        ) : null}

        <hr className="cc-divider" />

        <section className="cc-field-group">
          {isInfringement ? (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Date of offence" />
                <Field
                  name={nameOf("DateOfOffence")}
                  component={CCDatePicker}
                  format={DATE_FORMAT.DATE_CONTROL}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Time of offence" />
                <Field
                  name={nameOf("TimeOfOffence")}
                  component={CCTimePicker}
                />
              </div>
              <div className="cc-field cc-col-span-2">
                <CCLabel title="Location" />
                <Field
                  name={nameOf("LocationOfOffence")}
                  component={CCInput}
                  placeholder="Location"
                />
              </div>
            </div>
          ) : null}

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Date served" isMandatory />
              <Field
                name={nameOf("DateServed")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Officer" isMandatory />
              <Field
                component={InputPickerSearch}
                name={nameOf("ActionOfficer")}
                nameDisplay="DisplayName"
                value={valueGetter(nameOf("ActionOfficer"))}
                options={optionOfficer}
                onChange={officerChangeHandler}
                validator={requiredValidator}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Risk" />
              <Field
                name={nameOf("Risk_KWD")}
                component={CCSearchComboBox}
                data={noticesLOVs?.Risk ?? []}
                dataItemKey="Key"
                textField="Value"
                isUseDefaultOnchange
              />
            </div>

            <div className="cc-field">
              <CCLabel
                title={isInfringement ? "Payment due" : "Compliance due"}
                isMandatory={isMandatoryComplianceDue}
              />
              <Field
                name={nameOf("DateComplianceDue")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
                validator={
                  isMandatoryComplianceDue ? requiredValidator : undefined
                }
              />
            </div>
            <div className="cc-field">
              <CCLabel
                title={isInfringement ? "Date paid" : "Complied/Revoked"}
              />
              <Field
                name={nameOf("DateRevoked")}
                component={CCDatePicker}
                format={DATE_FORMAT.DATE_CONTROL}
              />
            </div>
          </div>

          {/* Implement after:  Related to WW_System */}
          {/* <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Noticee" />
              </div>
            </div> */}

          <div className="cc-form-cols-3">
            {isInfringement ? (
              <>
                <div className="cc-field">
                  <CCLabel title="Remedial works due" />
                  <Field
                    name={nameOf("DateRemedialWorksDue")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>

                <div className="cc-field">
                  <CCLabel title="Remedial works complete" />
                  <Field
                    name={nameOf("DateRemedialWorksComplete")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                  />
                </div>
              </>
            ) : null}

            <div className="cc-field">
              <CCLabel title="Notice" />
              <Field
                name={nameOf("CompliedRevokedOption")}
                component={CCRadioGroup}
                layout={"horizontal"}
                data={noticeOptionData}
              />
            </div>
          </div>
        </section>

        {noticeType && noticeType?.Flag_ExposeToAPI ? (
          <>
            <hr className="cc-divider" />
            <section className="cc-field-group">
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Exposed to API" />
                  <Field
                    name={nameOf("Flag_ExposedToAPI")}
                    component={CCRadioGroup}
                    layout={"horizontal"}
                    data={exposedToAPIOptionData}
                    disabled={!isSuperAdmin}
                    value={valueGetter(nameOf("Flag_ExposedToAPI")) ?? false}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Date served via API" />
                  <Field
                    name={nameOf("Date_ServedViaAPI")}
                    component={CCDatePicker}
                    format={DATE_FORMAT.DATE_CONTROL}
                    disabled={!isSuperAdmin}
                  />
                </div>
              </div>
            </section>
          </>
        ) : null}

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field cc-col-span-2">
              <CCLabel title="Section of legislation" />
              <Field
                name={nameOf("SectionOfLegislation")}
                component={CCTextArea}
                placeholder="Section of legislation"
                rows={4}
              />
            </div>
            <div className="cc-field cc-col-span-2">
              <CCLabel title="Description" />
              <Field
                name={nameOf("Description")}
                component={CCTextArea}
                placeholder="Description"
                rows={4}
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Rectification requirements" />
              <Field
                name={nameOf("RectificationRequirements")}
                component={InputPickerSearch}
                options={renderOptionNoticeKeyWord(
                  "Rectification requirements",
                  KEYWORD_TYPE.Core_NoticeRectificationRequirements,
                  PRODUCT_TYPE_NUMBER.Core,
                  "multiple"
                )}
                nameDisplayMerge="Name"
                value={valueGetter(nameOf("RectificationRequirements"))}
                onChange={(data: Keyword[]) => {
                  onChange(nameOf("RectificationRequirements"), {
                    value: data,
                  });
                }}
              />
            </div>
          </div>
        </section>

        <hr className="cc-divider" />

        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Related inspection" />
              <Field
                name={nameOf("RelatedInspection_ID")}
                component={CCSearchComboBox}
                data={noticesLOVs?.RelatedInspection ?? []}
                dataItemKey="Key"
                textField="Value"
                isUseDefaultOnchange
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
