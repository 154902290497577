import { KeyValuePair } from "@app/core/documents/model";
import { ILookupItem } from "@app/products/town-planning/ppr/system-admin/settings/model";
import { useSettingPPRStore } from "@app/products/town-planning/ppr/system-admin/settings/_category/store";
import { ApplicationSettingText } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/application/components/application-text-input/_index";
import { useSettingPPRManageStore } from "@app/products/town-planning/ppr/system-admin/settings/_id/store";
import { Application_Decision } from "@app/products/town-planning/ppr/[id]/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import {
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React from "react";

export type ICommonFormProps = {
  formRenderProps?: FormRenderProps;
};

export const ApplicationCustomLabelsForm = observer(
  ({ formRenderProps }: ICommonFormProps) => {
    const { configData } = useSettingPPRStore();
    const nameOfLookupItem = nameOfFactory<ILookupItem>();
    const { isEditing } = useSettingPPRManageStore();

    if (!configData) return <></>;

    const decisionTypeValueDisplay =
      formRenderProps?.valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_DecisionTypeCustomList}`
      ) ?? [];

    const handleOnChangeMultiSelect = (
      event: MultiSelectChangeEvent,
      enumParam: ECorporateSettingsField
    ) => {
      const selectedValues = event.target.value as ILookupItem[];
      formRenderProps?.onChange(`option.ValueDisplayDetails_${enumParam}`, {
        value: selectedValues,
      });
      formRenderProps?.onChange(
        configData[enumParam.toString()].FieldName ?? "",
        {
          value: selectedValues
            .map((item: ILookupItem) => item.Name)
            .toString(),
        }
      );
    };

    const customLabelForConclusionDecision =
      formRenderProps?.valueGetter(
        ECorporateSettingsField.TP_ConclusionDecision_CustomLabel_List.toString()
      ) ?? "";

    const customLabelForConclusionDecisionDisplayValue =
      (formRenderProps?.valueGetter(
        `option.ValueDisplayDetails_${ECorporateSettingsField.TP_ConclusionDecision_CustomLabel_List}`
      ) as KeyValuePair<string, string>[]) ?? [];

    const txtLblForApprovedSubjectToCondition =
      customLabelForConclusionDecisionDisplayValue.find(
        (item) => item.Key === Application_Decision.ApprovedSubjectToCondition
      );

    const txtLblForApprovedSubjectToStdCondition =
      customLabelForConclusionDecisionDisplayValue.find(
        (item) =>
          item.Key === Application_Decision.ApprovedSubjectToStandardCondition
      );

    const txtLblForRefused = customLabelForConclusionDecisionDisplayValue.find(
      (item) => item.Key === Application_Decision.Refused
    );

    const txtLblForDeferred = customLabelForConclusionDecisionDisplayValue.find(
      (item) => item.Key === Application_Decision.DeferredCommencement
    );

    const handleOnChangecustomLabelForConclusionDecision = (
      enumParam: string | undefined,
      value: any
    ) => {
      if (enumParam) {
        const customLabels: string[] = customLabelForConclusionDecision
          .split(String.fromCharCode(7))
          .filter((x: []) => x);

        const enumIndex = Object.keys(Application_Decision).indexOf(enumParam);
        const onChangeIndex = customLabels.findIndex(
          (item: string) => item.split("|")[0] === enumIndex.toString()
        );
        if (onChangeIndex !== -1) {
          customLabels[onChangeIndex] = enumIndex + "|" + value;

          formRenderProps?.onChange(
            ECorporateSettingsField.TP_ConclusionDecision_CustomLabel_List.toString(),
            {
              value: customLabels.join(String.fromCharCode(7)),
            }
          );

          customLabelForConclusionDecisionDisplayValue[onChangeIndex].Value =
            value;
          formRenderProps?.onChange(
            `option.ValueDisplayDetails_${ECorporateSettingsField.TP_ConclusionDecision_CustomLabel_List}`,
            {
              value: customLabelForConclusionDecisionDisplayValue,
            }
          );
        }
      }
    };

    const commonMultiSelectComponent = (
      enumParam: ECorporateSettingsField,
      displayValue: any
    ) => {
      return (
        configData[enumParam.toString()] && (
          <div className="cc-field">
            <CCLabel
              title={configData[enumParam.toString()].Title ?? ""}
              isMandatory={configData[enumParam.toString()].IsMandatory}
            />
            <Field
              name={configData[enumParam.toString()].FieldName}
              component={MultiSelect}
              data={configData[enumParam.toString()].LookupItems}
              textField={nameOfLookupItem("Name")}
              dataItemKey={nameOfLookupItem("Id")}
              value={displayValue}
              disabled={!isEditing}
              onChange={(event: MultiSelectChangeEvent) =>
                handleOnChangeMultiSelect(event, enumParam)
              }
              validator={
                configData[enumParam.toString()].IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        )
      );
    };

    return (
      <section className="cc-field-group">
        {commonMultiSelectComponent(
          ECorporateSettingsField.TP_DecisionTypeCustomList,
          decisionTypeValueDisplay
        )}
        <div className="cc-form-cols-2">
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelforDecisionType}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelforDecisionTypeTribunal}
          />
        </div>
        <div className="cc-form-cols-2">
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForStatusIssue}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForStatusRefused}
          />
        </div>
        <div className="cc-form-cols-1">
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelforPreferredContact}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForReasonforPermit}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelforAppLapsedButton}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelforIssueNODButton}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_Menu_Label_AllApplications}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForAmendment}
          />
        </div>
        <div className="cc-form-cols-2">
          <ApplicationSettingText
            enumParam={
              ECorporateSettingsField.TP_LabelForRecommendationTypeDelegate
            }
          />
          <ApplicationSettingText
            enumParam={
              ECorporateSettingsField.TP_LabelForRecommendationTypeDelegateMGR
            }
          />
        </div>
        <div className="cc-form-cols-1">
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForPlanner}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForAppType}
          />
          <ApplicationSettingText
            enumParam={
              ECorporateSettingsField.TP_LabelForAmendmentWorkflowButton
            }
          />
        </div>
        <div className="cc-form-cols-2">
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForStatusWithdrawn}
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForStatusDecisionIssue}
          />
        </div>
        <div className="cc-form-cols-1">
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForStatusAssessment}
          />
          <ApplicationSettingText
            enumParam={
              ECorporateSettingsField.TP_LabelForStatusFurtherInfoRequired
            }
          />
          <ApplicationSettingText
            enumParam={
              ECorporateSettingsField.TP_LabelForStatusFurtherInfoNotRequired
            }
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForStatusAdvertising}
          />
          <ApplicationSettingText
            enumParam={
              ECorporateSettingsField.TP_LabelForStatusPlannerAssessment
            }
          />
          <ApplicationSettingText
            enumParam={
              ECorporateSettingsField.TP_LabelForRequestFIWorkflowButton
            }
          />
          <ApplicationSettingText
            enumParam={
              ECorporateSettingsField.TP_LabelForPlannerAssessmentWorkflowButton
            }
          />
          <ApplicationSettingText
            enumParam={
              ECorporateSettingsField.TP_LabelForAdvertiseWorkflowButton
            }
          />
          <ApplicationSettingText
            enumParam={ECorporateSettingsField.TP_LabelForIssueWorkflowButton}
          />
          <label className="cc-label">Conclusion decision custom labels</label>
          <div className="cc-custom-sub-panel-bar">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <CCLabel title="Label for Approved Subject To Condition" />
                <Field
                  name={""}
                  component={CCInput}
                  disabled={!isEditing}
                  value={txtLblForApprovedSubjectToCondition?.Value}
                  onChange={(event: InputChangeEvent) => {
                    if (txtLblForApprovedSubjectToCondition) {
                      txtLblForApprovedSubjectToCondition.Value =
                        event.value?.toString();
                      handleOnChangecustomLabelForConclusionDecision(
                        txtLblForApprovedSubjectToCondition.Key,
                        event.value
                      );
                    }
                  }}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Label for Approved Subject To Standard Condition" />
                <Field
                  name={""}
                  component={CCInput}
                  disabled={!isEditing}
                  value={txtLblForApprovedSubjectToStdCondition?.Value}
                  onChange={(event: InputChangeEvent) => {
                    if (txtLblForApprovedSubjectToStdCondition) {
                      txtLblForApprovedSubjectToStdCondition.Value =
                        event.value?.toString();
                      handleOnChangecustomLabelForConclusionDecision(
                        txtLblForApprovedSubjectToStdCondition.Key,
                        event.value
                      );
                    }
                  }}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Label for Refused" />
                <Field
                  name={""}
                  component={CCInput}
                  disabled={!isEditing}
                  value={txtLblForRefused?.Value}
                  onChange={(event: InputChangeEvent) => {
                    if (txtLblForRefused) {
                      txtLblForRefused.Value = event.value?.toString();
                      handleOnChangecustomLabelForConclusionDecision(
                        txtLblForRefused.Key,
                        event.value
                      );
                    }
                  }}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Label for Deferred" />
                <Field
                  name={""}
                  component={CCInput}
                  disabled={!isEditing}
                  value={txtLblForDeferred?.Value}
                  onChange={(event: InputChangeEvent) => {
                    if (txtLblForDeferred) {
                      txtLblForDeferred.Value = event.value?.toString();
                      handleOnChangecustomLabelForConclusionDecision(
                        txtLblForDeferred.Key,
                        event.value
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);
