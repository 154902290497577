import { KeyValuePair } from "@app/core/documents/model";
import { PCBACategories, PremisesType } from "@app/products/hm/model";
import {
  getNameOfPremises,
  nameOfSvcPremises,
} from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { optionPremisesTypes } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/premises-type-section/config";
import { checkSecondaryMode } from "@app/products/hm/premises/[id]/config";
import { HM_PREMISES_HANDLER_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  IHMHandlerEventInitialData,
  PremiseHandlerRequest,
  Premises,
  PremisesRegisterLovs,
  PremisesUIControl,
  SecondaryModeCheckField,
  Svc_FormAction_Premise,
  Svc_HMPremisesType,
} from "@app/products/hm/premises/[id]/model";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import {
  AUSPOSTFORMAT,
  HEALTHMANAGER_CONFIGURATIONMODE,
} from "@common/constants/enumerations";
import { RECORDTYPE } from "@common/constants/recordtype";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { optionDebtorNumber } from "@common/input-pickers/debtor-number/config";
import { RefNumberUsage } from "@common/input-pickers/debtor-number/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { requiredValidator } from "@common/utils/field-validators";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { forEach, isString } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import "./_index.scss";

export interface IHMPremisesTypeSectionProps {
  isInactive?: boolean;
  allowChangePremisesType?: boolean;
  formRenderProps: FormRenderProps;
}

const nameOfPremisesType = nameOfFactory<PremisesType>();
const nameOfDebtorNumber = nameOfFactory<RefNumberUsage>();
const nameOfKeyValuePair = nameOfFactory<KeyValuePair<string, string>>();

export const PremisesTypeSection = observer(
  ({
    formRenderProps,
    isInactive = false,
    allowChangePremisesType = true,
  }: IHMPremisesTypeSectionProps) => {
    // #region STORES
    const { dataForms } = useFlexibleFormStore();
    const { settings } = useCommonCoreStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    // #endregion STORES

    // #region FORM PROPS
    const { valueGetter, onChange } = formRenderProps;
    const getValuePremises = (name: keyof Premises) =>
      valueGetter(getNameOfPremises(name));
    const premisesObj = valueGetter(nameOfSvcPremises("Premises")) as Premises;
    const premisesType = getValuePremises("PremisesType") as PremisesType;
    const getValuePremisesType = (name: keyof PremisesType) =>
      valueGetter(`${getNameOfPremises("PremisesType")}.${name}`);
    const getNameOfPremisesType = (name: keyof PremisesType) => {
      return nameOfPremisesType(name);
    };
    // #endregion FORM PROPS

    // #region VARIABLES
    const uiControlStore = dataForms?.GeneralUIControl as PremisesUIControl;
    const lovs = dataForms?.GeneralFormLovs as PremisesRegisterLovs;
    const isSuperAdmin = dataForms?.IsSuperAdmin;
    // #endregion VARIABLES

    // #region GET SETTING VALUES
    const ausPostFormat = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AusPostFormat]
    );
    const debtorNumberLabel = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberLabel]
    );
    const isDebtorNumberMandatory = getBoolValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_DebtorNumberMandatory]
    );
    const isEnableDebtorUserSelection = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_EnableDebtorUserSelection
      ]
    );
    const isShowBPAYInfo = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowBPAYInfo]
    );
    const isStreatrader = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_IsStreatrader]
    );
    const enableSecondaryMode = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableSecondaryMode]
    );
    const sFieldMapping =
      getStringValueSetting(
        settings[ECorporateSettingsField.HealthManager_TechOneFieldMapping]
      ) ?? "";
    const configurationMode = getNumberValueSetting(
      settings[ECorporateSettingsField.HealthManager_ConfigurationMode]
    );
    const pcbaLabel = getStringValueSetting(
      settings[ECorporateSettingsField.HealthManager_PCBAMenuLabel]
    );
    const autoGenerateDebtorNumber = getBoolValueSetting(
      settings[
        ECorporateSettingsField.CorporateSettings_FinanceAllocateDebtorNumber
      ]
    );
    // #endregion GET SETTING VALUES

    //#region SHOW/HIDE VARIABLES
    const [noOfUnitsLabel, setNoOfUnitsLabel] = useState("Number of units");
    const isShowDivNoUnits = useMemo(() => {
      let showDivNoUnits = false;
      if (premisesType?.Flag_CoolingTower) {
        setNoOfUnitsLabel("Number of towers");
        showDivNoUnits = true;
      } else if (premisesType?.Flag_SwimmingPool) {
        setNoOfUnitsLabel("Number of pools");
        showDivNoUnits = true;
      } else if (premisesType?.Flag_NoOfUnits) {
        showDivNoUnits = true;
      }
      return showDivNoUnits;
    }, [
      premisesType?.Flag_CoolingTower,
      premisesType?.Flag_NoOfUnits,
      premisesType?.Flag_SwimmingPool,
    ]);

    const [noOfEmployeesLabel, setNoOfEmployeesLabel] = useState(
      "Number of employees"
    );
    const isShowDivNoEmployees = useMemo(() => {
      let showDivNoEmployees = premisesType?.Flag_NoOfEmployees;
      if (premisesType?.Flag_CoolingTower) {
        setNoOfEmployeesLabel("Number of systems");
        showDivNoEmployees = true;
      }
      return showDivNoEmployees;
    }, [premisesType?.Flag_CoolingTower, premisesType?.Flag_NoOfEmployees]);

    const isShowDivPCBA = useMemo(() => {
      return premisesType?.Flag_PCBA;
    }, [premisesType?.Flag_PCBA]);
    //#endregion SHOW/HIDE VARIABLES

    //#region FIELD'S VALUE
    const riskValue = useMemo(() => {
      if (premisesType?.RiskClassification_ID) {
        return premisesType.RiskClassification?.Name;
      } else {
        return "";
      }
    }, [
      premisesType?.RiskClassification?.Name,
      premisesType?.RiskClassification_ID,
    ]);
    //#endregion FIELD'S VALUE

    // #region Secondary Mode
    const checkSecondaryModeLbtPremisesType = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.LbtPremisesType
    );
    const checkSecondaryModeTxtDebtorNo = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.TxtDebtorNo,
      configurationMode
    );
    // #endregion Secondary Mode

    //#region HANDLE ON CHANGE
    const getInitialPremisesHandlerRequest = (
      formAction: Svc_FormAction_Premise
    ) => {
      return {
        FormAction: formAction,
        Premises: premisesObj,
        Args: {},
        IsFirstTimeLoad: false,
        UIControl: uiControlStore,
        PremiseLOVs: lovs,
      } as PremiseHandlerRequest;
    };

    const handleChangePremisesType = (premisesType: Svc_HMPremisesType) => {
      if (premisesType) {
        let premisesHandlerRequest: PremiseHandlerRequest =
          getInitialPremisesHandlerRequest(
            Svc_FormAction_Premise.Form_PickPremisesType
          );
        premisesHandlerRequest.Args = [premisesType.ID];

        const handlerInitialData: IHMHandlerEventInitialData = {
          premisesRegisterHandlerRequest: premisesHandlerRequest,
          errorMsg: `Select premises type failed.`,
        };

        fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
          initialData: handlerInitialData,
        });
      } else {
        onChange(getNameOfPremises("PremisesType"), { value: null });
        onChange(getNameOfPremises("PremisesType_ID"), { value: 0 });
        onChange(getNameOfPremises("InspectionFrequency"), { value: null });
      }
    };

    const isHasProprietor = !!premisesObj?.Proprietor?.Contact?.Contact_ID;
    const onDebtorNumberButtonClick = () => {
      pushNotificationPortal({
        title: "Please select Proprietor before selecting Debtor number.",
        type: "warning",
      });
    };
    const handleOnChangeDebtorNumberValue = (value: RefNumberUsage) => {
      onChange(getNameOfPremises("DebtorNumber"), {
        value: isString(value) ? value : value?.Sys_DebtorNumber ?? "",
      });
    };

    const handleOnChangePCBACategories = (event: MultiSelectChangeEvent) => {
      const pcbaCategories =
        (event?.value as KeyValuePair<string, string>[]) ?? [];
      let pcbaCategoriesEnum: PCBACategories = PCBACategories.Unspecified;
      forEach(pcbaCategories, (pcbaCategory: KeyValuePair<string, string>) => {
        if (pcbaCategory) {
          pcbaCategoriesEnum =
            pcbaCategoriesEnum |
            PCBACategories[pcbaCategory.Key as keyof typeof PCBACategories];
        }
      });
      onChange(getNameOfPremises("PCAndBACategories"), {
        value: pcbaCategoriesEnum,
      });
      onChange(getNameOfPremises("_PCAndBACategories"), {
        value: event?.value,
      });
    };
    //#endregion HANDLE ON CHANGE

    return (
      <>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Premises type" isMandatory />
              <Field
                name={getNameOfPremises("PremisesType")}
                nameDisplay={getNameOfPremisesType("Name")}
                placeholder="Select premises type"
                component={InputPickerSearch}
                valueMapGrid={getValuePremisesType("PremisesType_ID")}
                onChange={handleChangePremisesType}
                validator={requiredValidator}
                options={optionPremisesTypes}
                disabled={
                  isInactive ||
                  checkSecondaryModeLbtPremisesType ||
                  !allowChangePremisesType
                }
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Risk" />
              <CCValueField value={riskValue} />
            </div>
            {isShowDivNoUnits && (
              <div className="cc-field">
                <CCLabel title={noOfUnitsLabel} isMandatory />
                <Field
                  name={getNameOfPremises("NoOfUnits")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                  validator={requiredValidator}
                  min={0}
                />
              </div>
            )}
            {isShowDivNoEmployees && (
              <div className="cc-field">
                <CCLabel title={noOfEmployeesLabel} isMandatory />
                <Field
                  name={getNameOfPremises("NoOfEmployees")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                  validator={requiredValidator}
                  min={0}
                />
              </div>
            )}
          </div>
          {isShowDivPCBA && (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <CCLabel title={pcbaLabel ?? "PCBA"} />
                <Field
                  disabled={isInactive}
                  name={getNameOfPremises("_PCAndBACategories")}
                  textField={nameOfKeyValuePair("Value")}
                  dataItemKey={nameOfKeyValuePair("Key")}
                  data={lovs?.PCBA}
                  component={CCMultiSelectDropdown}
                  onChange={handleOnChangePCBACategories}
                />
              </div>
            </div>
          )}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel
                title="Reference number"
                isMandatory={ausPostFormat === AUSPOSTFORMAT.Internal}
              />
              <Field
                name={getNameOfPremises("ReferenceNumber")}
                component={CCInput}
                disabled={isInactive}
                validator={
                  ausPostFormat === AUSPOSTFORMAT.Internal
                    ? requiredValidator
                    : undefined
                }
                min={0}
              />
            </div>
            <div className="cc-field">
              <CCLabel
                title={debtorNumberLabel ?? "Debtor number"}
                isMandatory={isDebtorNumberMandatory}
              />
              {isEnableDebtorUserSelection ? (
                <Field
                  name={getNameOfPremises("DebtorNumber")}
                  nameDisplay={nameOfDebtorNumber("Sys_DebtorNumber")}
                  placeholder={"Debtor number"}
                  allowCustom={
                    !(
                      !isSuperAdmin &&
                      autoGenerateDebtorNumber &&
                      configurationMode !==
                        HEALTHMANAGER_CONFIGURATIONMODE.WATER_AUTHORITY
                    ) && !checkSecondaryModeTxtDebtorNo
                  }
                  component={InputPickerSearch}
                  onChange={handleOnChangeDebtorNumberValue}
                  options={
                    optionDebtorNumber({
                      contactId: parseInt(
                        valueGetter(
                          `${getNameOfPremises(
                            "Proprietor"
                          )}.Contact.Contact_ID`
                        )
                      ),
                      recordId: premisesObj?.Premises_ID,
                      recordType: RECORDTYPE.HealthManager_Premises,
                    }) ?? []
                  }
                  onOpenDialogClick={
                    isHasProprietor ? undefined : onDebtorNumberButtonClick
                  }
                  validator={
                    isDebtorNumberMandatory ? requiredValidator : undefined
                  }
                  disabled={isInactive}
                />
              ) : (
                <Field
                  name={getNameOfPremises("DebtorNumber")}
                  component={CCInput}
                  placeholder={"Debtor number"}
                  disabled={
                    isInactive ||
                    checkSecondaryModeTxtDebtorNo ||
                    (!isSuperAdmin &&
                      autoGenerateDebtorNumber &&
                      configurationMode !==
                        HEALTHMANAGER_CONFIGURATIONMODE.WATER_AUTHORITY)
                  }
                  validator={
                    isDebtorNumberMandatory ? requiredValidator : undefined
                  }
                />
              )}
            </div>
            <div className="cc-field">
              <CCLabel title="File number" />
              <Field
                name={getNameOfPremises("FileNumber")}
                component={CCInput}
                disabled={isInactive}
              />
              {/*TODO: Implement Sharepoint URL later*/}
              {/* {premisesObj?.FileNumber && (
              <div className="cc-label-field cc-link-field">
                <a
                  href={generateSharepointURL(
                    settings,
                    RECORDTYPE.HealthManager_Premises,
                    premisesObj?.FileNumber
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cc-grid-link-text cc-label-field"
                >
                  {premisesObj?.FileNumber ?? ""}
                  <i className="fas fa-external-link-alt fa-xs cc-external-link-icon-sharepoint " />
                </a>
              </div>
            )} */}
            </div>
            {isShowBPAYInfo && (
              <div className="cc-field">
                <CCLabel title="BPAY reference" />
                <CCValueField
                  name={getNameOfPremises("BPayReference")}
                  value={
                    ausPostFormat === AUSPOSTFORMAT.Internal
                      ? getValuePremises("ReferenceNumber")
                      : getValuePremises("BPayReference")
                  }
                />
              </div>
            )}
            {isStreatrader && (
              <>
                <div className="cc-field">
                  <CCLabel title="Streatrader" />
                  <Field
                    name={getNameOfPremises("Flag_Streatrader")}
                    component={CCSwitch}
                    checked={getValuePremises("Flag_Streatrader")}
                    disabled={isInactive}
                  />
                </div>
                {getValuePremises("Flag_Streatrader") && (
                  <div className="cc-field">
                    <CCLabel title="Streatrader reference" />
                    <Field
                      name={getNameOfPremises("StreatraderRef")}
                      component={CCInput}
                      disabled={isInactive}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </section>
      </>
    );
  }
);
