import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { DynamicQuestionDialog } from "@common/pages/actions/components/action-bar/dialog/dynamic-question/_index";
import { postGetDynamicQuestion } from "@common/pages/actions/components/action-bar/dialog/dynamic-question/api";
import { Svc_DialogDynamicQuestionGetRequest } from "@common/pages/actions/components/action-bar/dialog/dynamic-question/model";
import { refactorDynamicQuestionData } from "@common/pages/actions/components/action-bar/dialog/dynamic-question/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IChecklistButtonProps {
  disabled?: boolean;
}

export const ChecklistButton = observer(
  ({ disabled }: IChecklistButtonProps) => {
    const { action } = useActionStore();
    const [isShowDynamicQuestionDialog, setIsShowDynamicQuestionDialog] =
      useState<boolean>(false);
    const [dynamicQuestionResponse, setDynamicQuestionResponse] =
      useState<object>({});
    const [responseLoadError, setResponseLoadError] =
      useState<APIResponseError>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    if (!action?.ActionType?.ActionTypeChecklist_ID) {
      return null;
    }

    const loadDynamicQuestion = async () => {
      if (action?.ActionType?.ActionTypeChecklist_ID && action?.Action_ID) {
        const dialogDynamicQuestionGetRequest: Svc_DialogDynamicQuestionGetRequest =
          {
            RecordSourceID: action?.Action_ID,
            DynamicListID:
              action?.ActionType?.ActionTypeChecklist_ID.toString(),
            IsFromAction: true,
          };
        setIsLoading(true);
        const response = await postGetDynamicQuestion(
          dialogDynamicQuestionGetRequest
        );
        setIsLoading(false);
        setResponseLoadError(undefined);
        if (isSuccessResponse(response) && response.data) {
          const newDynamicQuestionData = refactorDynamicQuestionData(
            response.data
          );
          setDynamicQuestionResponse(newDynamicQuestionData);
        } else {
          setResponseLoadError({
            status: response.status,
            error: response.error,
          });
        }
        setIsShowDynamicQuestionDialog(true);
      }
    };

    return (
      <>
        <CCNavButton
          title={
            action.ActionType.ActionTypeChecklist_Label
              ? action.ActionType.ActionTypeChecklist_Label
              : "Checklist"
          }
          onClick={loadDynamicQuestion}
          disabled={disabled}
        />
        <Loading isLoading={isLoading} isFullScreen />
        {isShowDynamicQuestionDialog && (
          <DynamicQuestionDialog
            onClosePopup={() => {
              setIsShowDynamicQuestionDialog(false);
            }}
            dynamicQuestionResponse={dynamicQuestionResponse}
            responseLoadError={responseLoadError}
            isLoading={isLoading}
            loadDynamicQuestion={loadDynamicQuestion}
          />
        )}
      </>
    );
  }
);
