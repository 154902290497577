import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { Comments } from "@app/core/comments/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { Documents } from "@app/core/documents/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { getAppealMailMergeDataSetEnum } from "@app/core/new-mail-merge/dialogs/util";
import { TabTableType } from "@app/core/tab-table/model";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { ViewDocumentsButton } from "@app/core/view/components/buttons/view-documents/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { BubbleUpType } from "@app/products/waste-water/[id]/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { mapRecordType, RECORDTYPE } from "@common/constants/recordtype";
import { AdditionalHearingButton } from "@common/pages/appeal/_id/components/buttons/workflow/additional-hearing/_index";
import { ApproveLetterAppealButton } from "@common/pages/appeal/_id/components/buttons/workflow/approve-letter/_index";
import { RejectLetterAppealButton } from "@common/pages/appeal/_id/components/buttons/workflow/reject-letter/_index";
import { AppealSendForApprovalButton } from "@common/pages/appeal/_id/components/buttons/workflow/send-for-approval/_index";
import { SendLetterToVcatApplicant } from "@common/pages/appeal/_id/components/buttons/workflow/send-letter-to-vcat-applicant/_index";
import { AppealForm } from "@common/pages/appeal/_id/components/child-screens/general/_index";
import { AppealContactsTab } from "@common/pages/appeal/_id/components/reference-sidebar/contact/_index";
import { AppealDetailTab } from "@common/pages/appeal/_id/components/reference-sidebar/detail/_index";
import { AppealHistoryTab } from "@common/pages/appeal/_id/components/reference-sidebar/history/_index";
import { AppealSubmitActions } from "@common/pages/appeal/_id/model";
import { useAppealStore } from "@common/pages/appeal/_id/store";
import { useGlobalStore } from "@common/stores/global/store";
import { getDisplayTextWithDashes } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedAppeal = observer(() => {
  const {
    isLoading,
    responseLoadError,
    onSubmit,
    appeal,
    appealId,
    loadAppeal,
    ancestor,
    isInactive,
  } = useAppealStore();
  const { id } = useParams<{ id: string }>();
  const [showSlideBar, setShowSlideBar] = useState<boolean>(true);
  const { currentUserInfo } = useGlobalStore();
  const managePageUrl = window.location.pathname;
  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `${getDisplayTextWithDashes([appeal?.AppealNo, appeal?.Status])}`,
      LinkUrl: managePageUrl,
      LinkText: `Appeal - ${id ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.TownPlanning,
      RecordType_ENUM: RECORDTYPE.TP_PPRApplication,
      Record_ID: +id ?? 0,
    },
  ];

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions id={appealId} recordType={RECORDTYPE.CORE_Appeal} />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: <Comments id={appealId} recordType={RECORDTYPE.CORE_Appeal} />,
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion id={appealId} recordType={RECORDTYPE.CORE_Appeal} />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={appealId} recordType={RECORDTYPE.CORE_Appeal} />
      ),
    },
  ];

  const formTitle = useMemo(() => {
    return ancestor?.RecordSourceIdentifier?._RecordSource_ID
      ? ancestor.RecordSourceIdentifier._RecordSource_ID.toString()
      : "";
  }, [ancestor]);

  const ancestorTypeEnum = useMemo(() => {
    if (isEmpty(appeal?._BubbleUps)) return;
    const ancestorType = appeal?._BubbleUps.find(
      (bubbleUp) => bubbleUp.BubbleUpType_ENUM === BubbleUpType.Ancestor
    )?.SourceIdentifier?._RecordSourceType_ENUM;
    return mapRecordType[ancestorType ?? ""];
  }, [appeal]);

  return (
    <>
      <Loading isLoading={isLoading} isFullScreen />
      <FormNavigation title={"Appeal"} />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadAppeal(+id);
          }}
        />
      ) : (
        <>
          <FormTitle title={formTitle} badge={appeal?.Status} />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Workflow"}
                type="sub-workflow"
                className="cc-workflow-button"
                disabled={isInactive}
              >
                <SendLetterToVcatApplicant />
                <AppealSendForApprovalButton />
                <ApproveLetterAppealButton />
                <RejectLetterAppealButton />
              </CCNavButton>,
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={AppealSubmitActions.Save}
                disabled={isInactive}
              />,
              <CCNavButton title={"Add"} type="sub" disabled={isInactive}>
                <AddActionWithParentButton
                  id={appealId}
                  bubbleUps={appeal?._BubbleUps}
                  recordType={RECORDTYPE.CORE_Appeal}
                />
                <AdditionalContactButton
                  id={appealId}
                  bubbleUps={appeal?._BubbleUps}
                  recordType={RECORDTYPE.CORE_Appeal}
                />
                <AddAttachmentButton
                  id={appealId}
                  recordType={RECORDTYPE.CORE_Appeal}
                  bubbleUps={appeal?._BubbleUps}
                />
                <AddCommentButton
                  id={appealId}
                  recordType={RECORDTYPE.CORE_Appeal}
                />
                <AddDocumentButton
                  dataSetFilter={
                    getAppealMailMergeDataSetEnum(ancestorTypeEnum) ??
                    MAILMERGEDATASET.TOWNPLANNING_PPR_Appeals
                  }
                  recordType={RECORDTYPE.CORE_Appeal}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  id={appealId}
                  bubbleUps={appeal?._BubbleUps}
                />
              </CCNavButton>,
              <CCNavButton title={"View"} type="sub" disabled={isInactive}>
                <ViewDocumentsButton
                  id={appealId}
                  recordType={RECORDTYPE.CORE_Appeal}
                />
              </CCNavButton>,
              <CCNavButton
                title={"More Options"}
                type="more"
                disabled={isInactive}
              >
                <AdditionalHearingButton />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
                disabled={isInactive}
              />,
              <ActionBarBookmarkIcon
                bookmarkList={bookmarkList}
                disabled={isInactive}
              />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-form cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {appeal && <AppealForm />}
                <TabTable
                  initialPanels={listPanelBar}
                  id={appealId}
                  recordType={RECORDTYPE.CORE_Appeal}
                  disabled={isInactive}
                />
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <AppealDetailTab /> },
                    {
                      title: "Contacts",
                      component: <AppealContactsTab />,
                    },
                    {
                      title: "History",
                      component: <AppealHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
