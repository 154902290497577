import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { colCoreRelated } from "@app/core/related/related-accordion/config";
import {
  ICoreRelated,
  ICoreRelatedParams,
} from "@app/core/related/related-accordion/model";
import { useRelatedAccordionStore } from "@app/core/related/related-accordion/store";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

export interface ICoreRelatedProps {
  relatedParam?: ICoreRelatedParams;
}
enum RelationShipEnable {
  Manual = "Manual",
  MultiTenancy = "Multi Tenancy",
}

const nameOf = nameOfFactory<ICoreRelated>();

export const RelatedAccordion = observer(
  ({ relatedParam }: ICoreRelatedProps) => {
    const { isLoading, relatedRecords, setRelatedParam, loadRelatedRecords } =
      useRelatedAccordionStore();
    const { isDisabled } = useTabTableStore();

    const [gridSelectedRows, setGridSelectedRows] = useState<ICoreRelated[]>(
      []
    );
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const { pushNotification, clearErrorNotification } =
      useCCAppNotificationStore();
    const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
      []
    );

    const handleConfirmDelete = async (data: IDeleteConfirmation) => {
      setIsDeleting(true);
      const response = await deleteRefUsageByIdAndRecordType(
        gridSelectedRows?.map((item: ICoreRelated) => item.ID),
        RECORDTYPE.CORE_Related,
        data?.Reason
      );
      setIsDeleting(false);
      if (isSuccessResponse(response)) {
        setShowConfirmDeleteDialog(false);
        loadRelatedRecords();
        clearErrorNotification();
        setGridSelectedRows([]);
        pushNotification({
          title: "Relate deleted successfully",
          type: "success",
        });
        eventEmitter.emit(CCGridEventType.RefreshOData);
        eventEmitter.emit(CCJournalEventType.RefreshData);
        eventEmitter.emit(TabTableEventType.RefreshData);
      } else {
        setNotifications([
          {
            id: "1",
            autoClose: false,
            title: "Relate deleted failed",
            type: "error",
            description: response.data?.Errors,
          },
        ]);
      }
    };

    useEffect(() => {
      if (relatedParam) {
        setRelatedParam(relatedParam);
        loadRelatedRecords();
      }
    }, [loadRelatedRecords, relatedParam, setRelatedParam]);

    return (
      <>
        <CCGrid
          isLoading={isLoading}
          toolbar={
            <div className="cc-grid-tools-bar">
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={
                  isDisabled
                    ? isDisabled
                    : gridSelectedRows?.length !== 1 ||
                      (gridSelectedRows?.[0].Relationship !==
                        RelationShipEnable.Manual &&
                        gridSelectedRows?.[0].Relationship !==
                          RelationShipEnable.MultiTenancy)
                }
                onClick={() => {
                  setShowConfirmDeleteDialog(true);
                }}
              />
            </div>
          }
          selectableMode="multiple"
          data={relatedRecords}
          primaryField={nameOf("Record_ID")}
          selectedRows={gridSelectedRows}
          onSelectionChange={(dataItem: ICoreRelated[]) => {
            setGridSelectedRows(dataItem ?? []);
          }}
          columnFields={colCoreRelated()}
        />
        {showConfirmDeleteDialog && (
          <ConfirmDelete
            onClose={() => {
              setShowConfirmDeleteDialog(false);
            }}
            header={"Delete Confirmation"}
            contentDelete={gridSelectedRows[0].Type}
            handleSubmit={handleConfirmDelete}
            isDeleting={isDeleting}
            notifications={notifications}
          />
        )}
      </>
    );
  }
);
