import { useTitleStore } from "@app/products/property/titles/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import ExistedPropertyTitle from "@app/products/property/titles/[id]/components/forms/existed/_index";
import { NewPropertyTitle } from "@app/products/property/titles/[id]/components/forms/new/_index";
import { managePagePropertyWrapper } from "@app/products/property/components/action-bar/property-workflow/component/hoc/manage-page-property-wrapper";
import { observer } from "mobx-react-lite";

const ManagePropertyTitle = observer(() => {
  const isNew = useIsNew();
  const history: any = useHistory();
  const { loadTitle, resetStore } = useTitleStore();
  const params: { id: string } = useParams();
  const titleId = params.id;
  const { resetMenu } = useCCSubActionBarStore();
  const location = useLocation();
  const state: any = location?.state;

  useEffect(() => {
    if (isNew) return;
    let notification = undefined;
    if (state) {
      notification = state?.notification;
      window.history.replaceState({}, "");
    }
    loadTitle(parseInt(titleId), notification);
    // eslint-disable-next-line
  }, [isNew, titleId, history, loadTitle]);

  useEffectOnce(() => {
    return () => {
      resetStore();
      resetMenu();
    };
  });

  if (isNew) return <NewPropertyTitle />;

  return <ExistedPropertyTitle />;
});

export default managePagePropertyWrapper(ManagePropertyTitle);
