import { ATTACHMENT_STEP } from "@app/core/communication/dialogs/components/form-elememts/attachment/_index";
import { MESSAGE_TEMPLATE_STEP } from "@app/core/communication/dialogs/components/form-elememts/message-template/_index";
import { MAIL_MERGE_TEMPLATE_FORM_STEP as COMMUNICATION_MAIL_MERGE_TEMPLATE_FORM_STEP } from "@app/core/communication/dialogs/components/form-elememts/template/_index";
import { COMMUNICATION_TO_STEP } from "@app/core/communication/dialogs/components/form-elememts/to/_index";
import { WORKFLOW_STEP } from "@app/core/communication/dialogs/components/form-elememts/work-flow/_index";
import {
  defaultAttachmentStep,
  defaultMessageTemplateStep,
  defaultToStep,
} from "@app/core/communication/dialogs/config";
import { MAIL_MERGE_OPTIONS_FORM_STEP } from "@app/core/new-mail-merge/form-steps/options/_index";
import { MAIL_MERGE_TEMPLATE_FORM_STEP } from "@app/core/new-mail-merge/form-steps/template/_index";
import { MAIL_MERGE_WORKFLOW_FORM_STEP } from "@app/core/new-mail-merge/form-steps/workflow/_index";
import { BatchRecall, BatchRecall_SendOption } from "@app/core/recall/model";
import { UploadFileInfo } from "@progress/kendo-react-upload";

//#region constant prerequisites
const defaultBatchRecall: Partial<BatchRecall> = {
  Date_Recall: new Date(),
  Number: "",
  Details: "",
  ListOfAttachments: [],
};
const keys = {
  Recall: "Recall",
  CommunicationRecipient: COMMUNICATION_TO_STEP,
  CommunicationMessageTemplate: MESSAGE_TEMPLATE_STEP,
  // CommunicationMailMergeTemplate: COMMUNICATION_MAIL_MERGE_TEMPLATE_FORM_STEP,
  CommunicationAttachment: ATTACHMENT_STEP,
  /**
   *  key "Template" is used in both dialog CommunicationDialog and (New)MailMergeDialog
   */
  CommunicationMailMergeTemplate: COMMUNICATION_MAIL_MERGE_TEMPLATE_FORM_STEP,
  /**
   *  key "Workflow" is used in both dialog CommunicationDialog and (New)MailMergeDialog
   */
  CommunicationWorkflow: WORKFLOW_STEP,
  MailMergeTemplate: `MailMerge_${MAIL_MERGE_TEMPLATE_FORM_STEP}`,
  MailMergeOptions: `MailMerge_${MAIL_MERGE_OPTIONS_FORM_STEP}`,
  MailMergeWorkflow: `MailMerge_${MAIL_MERGE_WORKFLOW_FORM_STEP}`,
};
//#endregion

//#region private types
interface UIControl {
  SendOption: BatchRecall_SendOption;
  Officer_Name: string;
  AttachmentFiles: UploadFileInfo[];
}
//#endregion

//#region exports
export const BATCH_RECALL_INITIAL_FORM: {
  Recall: Partial<BatchRecall>;
  UIControl: UIControl;
  [key: string]: any;
} = {
  Recall: defaultBatchRecall,
  //#region Send option - Communication
  [keys.CommunicationRecipient]: defaultToStep,
  [keys.CommunicationMessageTemplate]: defaultMessageTemplateStep,
  [keys.CommunicationMailMergeTemplate]: {},
  [keys.CommunicationWorkflow]: {},
  [keys.CommunicationAttachment]: defaultAttachmentStep,
  //#endregion
  //#region Send option - Mail Merge
  [keys.MailMergeTemplate]: {},
  [keys.MailMergeOptions]: {},
  [keys.MailMergeWorkflow]: {},
  //#endregion
  UIControl: {
    SendOption: "communication",
    Officer_Name: "",
    AttachmentFiles: [],
  },
};

export const BATCH_RECALL_STEP_KEYS = keys;

export const BATCH_RECALL_SEND_OPTIONS = [
  {
    value: "communication",
    label: "Communication",
  },
  {
    value: "mailmerge",
    label: "Mail merge only",
  },
  {
    value: "none",
    label: "None",
  },
] as const;
//#endregion
