import { loadAssociations } from "@app/products/property/components/associations/api";
import { DTO_AssociatedItem_Assessment } from "@app/products/property/components/associations/components/assessment/model";
import {
  DTO_ComponentAssociationItemGroup,
  eComponent,
} from "@app/products/property/components/associations/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { nameOfFactory } from "@common/utils/common";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { Button } from "@progress/kendo-react-buttons";
import { Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { colNominatedProperty } from "./config";

interface ISelectNominatePropertyDialog {
  entityId: number;
  onClose: () => void;
  onSubmit: (dataItem: any) => void;
}
const nameOfAssessment = nameOfFactory<DTO_AssociatedItem_Assessment>();
export const SelectNominatePropertyDialog = (
  props: ISelectNominatePropertyDialog
) => {
  const [selectedNominateProperty, setSelectedNominateProperty] = useState<
    any[]
  >([]);
  const [nominatePropertyData, setNominateProperty] = useState<
    DTO_AssociatedItem_Assessment[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();

  const loadData = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadAssociations(
      eComponent.Entity,
      props.entityId,
      false,
      false
    );
    if (isSuccessResponse(response) && response?.data) {
      const assessmentAssociation =
        response.data?.AssociationsByComponent?.find(
          (associationGroup: DTO_ComponentAssociationItemGroup) =>
            associationGroup.Group_Value === eComponent.Assessment
        );
      setNominateProperty(
        (assessmentAssociation?.Associations as DTO_AssociatedItem_Assessment[]) ??
          []
      );
    } else {
      setResponseLoadError({
        status: response?.status,
        error: response?.error ?? "Load nominate property data failed.",
      });
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadData();
  });

  return (
    <Form
      render={(formRenderProps: FormRenderProps) => {
        return (
          <CCDialog
            titleHeader={"Select Nominate Property"}
            onClose={props.onClose}
            maxWidth="50%"
            maxHeight="50%"
            bodyElement={
              <div className="cc-form">
                {responseLoadError ? (
                  <CCLoadFailed
                    responseError={responseLoadError}
                    onReload={() => {
                      loadData();
                    }}
                  />
                ) : (
                  <FormElement
                    onSubmit={formRenderProps.onSubmit}
                    className="cc-field-group"
                  >
                    <div className="cc-form-cols-1">
                      <div className="cc-field">
                        <CCLabel
                          title="The following are the properties the voter is entitle
                          to vote on behalf"
                        />
                        <CCGrid
                          isLoading={isLoading}
                          data={nominatePropertyData}
                          selectedRows={selectedNominateProperty}
                          columnFields={colNominatedProperty}
                          primaryField={nameOfAssessment("Id")}
                          selectableMode="single"
                          onSelectionChange={(dataItem: any) => {
                            setSelectedNominateProperty([...dataItem]);
                          }}
                        />
                      </div>
                    </div>
                  </FormElement>
                )}
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button className="cc-dialog-button" onClick={props.onClose}>
                  Cancel
                </Button>
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  onClick={() => {
                    props.onSubmit(selectedNominateProperty);
                  }}
                  type={"submit"}
                  disabled={isLoading}
                >
                  OK
                </Button>
              </div>
            }
          />
        );
      }}
    />
  );
};
