import { CoreAPIService } from "@common/apis/coreAPIService";
import { GET_MAIN_SETTINGS_LIST } from "@common/configs/setting";
import { NotificationType } from "@common/constants/notificationtype";
import { PRODUCT_TYPE } from "@common/constants/productType";
import { APIResponseStatus } from "@common/constants/response-status";
import { CONFIG_DEFAULT_IDLE } from "@common/hooks/useIdle";
import { ProductVisibility } from "@common/models/appRoutes";
import { Category, ISettings, MainSetting } from "@common/models/settings";
import { OOSys_Theme_LKP } from "@common/pages/settings/security/site-users/_id/model";
import { removeTrailingSlash } from "@common/utils/common";
import { ActiveProduct, UserInfo } from "@components/layout/model";
import log from "loglevel";
import {
  computed,
  configure,
  makeAutoObservable,
  runInAction,
  toJS,
} from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" }); // Strict mode: runInAction
export class GlobalStore {
  private _showActionBarActionItems: boolean = false;
  private _totalComments: number | null = null;
  private _totalActions: number | null = null;
  private _communicationDialogClicked: number = 0;
  private _inspectionTypeChangeDialogClicked: number = 0;
  private _isNotify: boolean = false;
  private _notificationType: NotificationType | null = null;
  private _notificationsList: string[] = [];
  private _activeProducts: ActiveProduct[] = [];
  private _settings: ISettings | null = null;
  private userInfo: UserInfo | null = null;
  private _mainSettingsList: MainSetting[] = [];
  private _isLoadingMainSettingsList: boolean = false;
  private _themeActive?: OOSys_Theme_LKP[] = [];

  private _isVisible(productType: PRODUCT_TYPE): boolean {
    return this._activeProducts.some(
      (item: ActiveProduct) => item.ProductType_ENUM === productType
    );
  }

  private _isVisibleAndIsCommunityCentral(
    productType: PRODUCT_TYPE
  ): ProductVisibility {
    if (this._activeProducts.length === 0) return ProductVisibility.None;
    const isVisible = this._activeProducts.some(
      (item: ActiveProduct) =>
        item.ProductType_ENUM === productType && item.Flag_CommunityCentral
    );
    if (isVisible) return ProductVisibility.Visible;
    return ProductVisibility.Hidden;
  }

  private _getActiveProduct(productType: string): ActiveProduct | undefined {
    return this._activeProducts.find(
      (item: ActiveProduct) =>
        item.ProductType_ENUM === productType &&
        item.Flag_CommunityCentral === true
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  get allSetting() {
    const defaultSetting: ISettings = {
      apiKey: "",
      baseAPIUrl: "",
      oDataUrl: "",
      systemSettingUrl: "",
      azureAD: undefined,
      activityDetector: {
        countDownTime: CONFIG_DEFAULT_IDLE.countDownTime,
        idleTime: CONFIG_DEFAULT_IDLE.idleTime,
      },
      googleRecaptcha: undefined,
      googleMap: undefined,
      projectName: "",
      version: "",
    };
    return toJS(this._settings) || defaultSetting;
  }
  setSettings = (settings: ISettings) => {
    const newSettings = {
      ...settings,
      //Remove the trailing slash
      baseAPIUrl: removeTrailingSlash(settings?.baseAPIUrl),
      oDataUrl: removeTrailingSlash(settings?.oDataUrl),
    };
    new CoreAPIService().initialize({ baseURL: newSettings.baseAPIUrl });
    log.setLevel(
      (newSettings.loggerOptions?.logLevel || 3) as log.LogLevelNumbers
    );
    this._settings = newSettings;
  };

  get currentUserInfo() {
    return toJS(this.userInfo);
  }
  setCurrentUserInfo = (userInfo: UserInfo | null) => {
    this.userInfo = userInfo;
  };

  get productsActive() {
    return toJS(this._activeProducts);
  }
  setActiveProducts = (products: ActiveProduct[]) => {
    runInAction(() => {
      this._activeProducts = products;
    });
  };

  get themeActive() {
    return toJS(this._themeActive);
  }
  setThemeActive = (themeActive: OOSys_Theme_LKP[]) => {
    runInAction(() => {
      this._themeActive = themeActive;
    });
  };

  get communicationDialogClicked() {
    return toJS(this._communicationDialogClicked);
  }
  setCommunicationDialogClicked = (data: number) => {
    this._communicationDialogClicked = data;
  };

  get showActionBarActionItems() {
    return toJS(this._showActionBarActionItems);
  }
  setShowActionBarActionItems = (visibility: boolean) => {
    this._showActionBarActionItems = visibility;
  };

  get totalComments() {
    return toJS(this._totalComments);
  }
  setTotalComments = (total: number | null) => {
    this._totalComments = total;
  };

  get inspectionTypeChangeDialogClicked() {
    return toJS(this._inspectionTypeChangeDialogClicked);
  }
  setInspectionTypeChangeDialogClicked = (data: number) => {
    this._inspectionTypeChangeDialogClicked = data;
  };

  get totalActions() {
    return toJS(this._totalActions);
  }
  setTotalActions = (total: number | null) => {
    this._totalActions = total;
  };

  get isNotify() {
    return toJS(this._isNotify);
  }
  setIsNotify = (isNotify: boolean) => {
    this._isNotify = isNotify;
  };

  get notificationType() {
    return toJS(this._notificationType);
  }
  setNotificationType = (notificationType: NotificationType) => {
    this._notificationType = notificationType;
  };

  get notificationsList() {
    return toJS(this._notificationsList);
  }
  setNotificationsList = (notificationList: string[]) => {
    this._notificationsList = notificationList;
  };

  get mainSettingsList() {
    return toJS(this._mainSettingsList);
  }
  setMainSettingsList = (mainSettingsList: MainSetting[]) => {
    this._mainSettingsList = mainSettingsList;
  };

  get isLoadingMainSettingsList() {
    return toJS(this._isLoadingMainSettingsList);
  }
  setIsLoadingMainSettingsList = (isLoading: boolean) => {
    this._isLoadingMainSettingsList = isLoading;
  };

  getActiveProduct = (productType: string): ActiveProduct | undefined => {
    return computed(() => this._getActiveProduct(productType)).get();
  };

  setDeactiveRow = (dataRow: any) => {
    if (dataRow) {
      window.confirm("This will deactivate selected records, are you sure?");
    }
  };

  setDeleteRow = (dataRow: any) => {
    if (dataRow) {
      //alert("Delete function run");
    }
  };

  isVisible = (productType: PRODUCT_TYPE): boolean => {
    return computed(() => this._isVisible(productType)).get();
  };

  isVisibleAndIsCommunityCentral = (
    productType: PRODUCT_TYPE
  ): ProductVisibility => {
    return computed(() =>
      this._isVisibleAndIsCommunityCentral(productType)
    ).get();
  };

  showNotifications = (
    type: NotificationType,
    data: string,
    timeout?: number
  ) => {
    runInAction(() => {
      this._notificationType = type;
      this._notificationsList = [data];
      this._isNotify = true;
      if (timeout !== 0) {
        setTimeout(
          () => {
            this.setIsNotify(false);
          },
          timeout ? timeout : 5000
        );
      }
    });
  };

  //Action
  dispose = () => {
    this._totalComments = null;
    this._totalActions = null;
    this._communicationDialogClicked = 0;
    this._inspectionTypeChangeDialogClicked = 0;
    this._isNotify = false;
    this._notificationType = null;
    this._notificationsList = [];
    this._activeProducts = [];
    this._settings = null;
  };

  loadMainSettingsList = async () => {
    this.setIsLoadingMainSettingsList(true);
    try {
      const response = await CoreAPIService.getClient().get<MainSetting[]>(
        `${globalStoreInstance.allSetting.baseAPIUrl}${GET_MAIN_SETTINGS_LIST}`
      );
      if (
        !response ||
        response.status !== APIResponseStatus.SUCCESS ||
        !response.data
      ) {
        return;
      }
      this.setMainSettingsList(response.data);
    } catch (error) {
      this.setMainSettingsList([]);
    }
    this.setIsLoadingMainSettingsList(false);
  };

  getCategoriesListByProduct = (productEnum: PRODUCT_TYPE): Category[] => {
    if (this.mainSettingsList && this.mainSettingsList.length) {
      const product = this.mainSettingsList.find(
        (item: MainSetting) => item.ProductEnum === productEnum
      );
      if (product) {
        return product.Categories;
      }
    }
    return [];
  };
}
export const globalStoreInstance = new GlobalStore();
const globalStoreContext = createContext(globalStoreInstance);
export const useGlobalStore = () => {
  return useContext(globalStoreContext);
};
