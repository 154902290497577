import { HMFieldMapping } from "@app/products/hm/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { PREMISES_MANAGE_ROUTE } from "../[id]/constant";

export const colHMPremisesPCBA = [
  {
    field: HMFieldMapping.TradingName,
    title: "Trading Name",
    locked: true,
    linkTo: (dataItem: { ID: string }) => {
      return `${PREMISES_MANAGE_ROUTE}/` + dataItem.ID;
    },
    width: 150,
  },
  {
    field: HMFieldMapping.RegNo,
    title: "Registration Number",
    width: 150,
  },
  {
    field: HMFieldMapping.Address,
    title: "Address",
    width: 150,
  },
  {
    field: HMFieldMapping.Area,
    title: "Area",
    width: 150,
  },
  {
    field: HMFieldMapping.Phone,
    title: "Phone",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesType,
    title: "Type",
    width: 150,
  },
  {
    field: HMFieldMapping.Risk,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_BodyPiercing,
    title: "Body Piercing",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_BodyTreatments,
    title: "Body Treatments",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_ColonicIrrigation,
    title: "Colonic Irrigation",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_CosmeticTattooing,
    title: "Cosmetic Tattooing",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_Accupuncture,
    title: "Dry Needling/Accupuncture",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_EarPiercing,
    title: "Ear Piercing",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_Electrolysis,
    title: "Electrolysis",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_FacialsMakeup,
    title: "Facials/Makeup",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_Hairdressing,
    title: "Hairdressing",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_LaserTreatment,
    title: "Laser Treatment",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_NailTreatment,
    title: "Nail Treatment",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_Tattooing,
    title: "Tattooing",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_Threading,
    title: "Threading",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_Waxing,
    title: "Waxing",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_Wigs,
    title: "Wigs",
    width: 150,
  },
  {
    field: HMFieldMapping.PCBA_Other,
    title: "Other",
    width: 150,
  },
  {
    field: HMFieldMapping.Officer,
    title: "Officer",
    width: 150,
  },
  {
    field: HMFieldMapping.RefNo,
    title: "Reference Number",
    width: 150,
  },
  {
    field: HMFieldMapping.FileNumber,
    title: "File Number",
    width: 150,
  },
  {
    field: HMFieldMapping.DebtorNo,
    title: "Debtor Number",
    width: 150,
  },
  {
    field: HMFieldMapping.AssessmentNo,
    title: "Assessment Number",
    width: 150,
  },
  {
    field: HMFieldMapping.LastInspection,
    title: "Last Inspection",
    width: 150,
  },
  {
    field: HMFieldMapping.Email,
    title: "Email",
    width: 150,
  },
  {
    field: HMFieldMapping.PostalAddress,
    title: "Postal Address",
    width: 150,
  },
  {
    field: HMFieldMapping.PostalAddress2,
    title: "Postal Address 2",
    width: 150,
  },
  {
    field: HMFieldMapping.Proprietor,
    title: "Proprietor",
    width: 150,
  },
  {
    field: HMFieldMapping.NoEmployees,
    title: "Emp",
    width: 150,
  },
  {
    field: HMFieldMapping.PrimaryContact,
    title: "Contact",
    width: 150,
  },
  {
    field: HMFieldMapping.DateLodged,
    title: "Lodged",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.DateRegistrationExpires,
    title: "Due Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.Fax,
    title: "Fax",
    width: 150,
  },
  {
    field: HMFieldMapping.AmountOS,
    title: "Outstanding",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: HMFieldMapping.ProprietorAddress,
    title: "Proprietor Address",
    width: 150,
  },
];
