import {
  getFromDate,
  getToDate,
} from "@app/products/ams/api/helpers/date-helper";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { getter } from "@progress/kendo-react-common";
import { isAfter, isBefore } from "date-fns";
import {
  isArray,
  isDate,
  isEmpty,
  isNaN,
  isNil,
  isObject,
  isString,
} from "lodash";

export const isEmptyStringArray = (value: String[]) => {
  return value && value.some((item) => item && item !== "")
    ? ""
    : "This field is required.";
};

export const notEmptyArrayValidator = (value: any) =>
  value && value.length ? "" : "Please add atleast one record.";

export const numberValidator = (value: any) =>
  value != null && !Number.isNaN(value) ? "" : "Please enter a valid number.";

export const textAreaValidator = (value: string) =>
  !value ? "border-danger" : "";

export const dropdownValidator = (data: any) =>
  data != null && data !== -1 ? "" : "The value for dropdown is not valid.";

export const dropdownValidatorObject = (data: any) => {
  if (!data || isNil(data?.Key)) return "The value for dropdown is not valid.";
  else return "";
};

export const validateRequired = (data: any) => {
  return data ? "" : "The value for this field is required.";
};

export const validateStringRequired = (data: string) => {
  return data && !isEmpty(data.trim()) ? "" : "GIS reference cannot be empty.";
};

export const validateNotNegativeNumber = (data: number | null) => {
  return data != null && data >= 0
    ? ""
    : "The value for this field is not negative number";
};

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex = new RegExp(/^[0-9-]+$/);
const cvcRegex = new RegExp(/^[0-9]+$/);

export const termsValidator = (value: any) =>
  value ? "" : "It's required to agree with Terms and Conditions.";
export const emailValidator = (value: any) =>
  !value
    ? "Email field is required."
    : emailRegex.test(value)
    ? ""
    : "Email is not valid format.";
export const nameValidator = (value: any) =>
  !value
    ? "Full Name is required"
    : value.length < 7
    ? "Full Name should be atleast 7 characters long."
    : "";
export const userNameValidator = (value: any) =>
  !value
    ? "User Name is required"
    : value.length < 5
    ? "User name should be at least 3 characters long."
    : "";
export const phoneValidator = (value: any) =>
  !value
    ? "Phone number is required."
    : phoneRegex.test(value)
    ? ""
    : "Not a valid phone number.";
export const cardValidator = (value: any) =>
  !value
    ? "Credit card number is required. "
    : ccardRegex.test(value)
    ? ""
    : "Not a valid credit card number format.";
export const cvcValidator = (value: any) =>
  !value
    ? "CVC code is required,"
    : cvcRegex.test(value) || value.length !== 3
    ? ""
    : "Not a valid CVC code format.";
export const guestsValidator = (value: any) =>
  !value ? "Number of guests is required" : value < 5 ? "" : "Maximum 5 guests";
export const nightsValidator = (value: any) =>
  value ? "" : "Number of Nights is required";
export const arrivalDateValidator = (value: any) =>
  value ? "" : "Arriaval Date is required";
export const colorValidator = (value: any) =>
  value ? "" : "Color is required.";

//Check isNull, isUndefine, isEmpty Array, isEmpty Obj, is NaN number, is Empty String with trim
export const requiredValidator = (value: any) => {
  const message = "This field is required.";
  if (isNil(value)) return message;
  if (isString(value) && (isEmpty(value.trim()) || isEmpty(value)))
    return message;
  if ((isArray(value) || (isObject(value) && !isDate(value))) && isEmpty(value))
    return message;
  if (isNaN(value)) return message;
  return "";
};
export const requiredStringValidator = (data: string) => {
  return data && !isEmpty(data.trim()) ? "" : "This field is required.";
};
export const passwordValidator = (value: any) =>
  value && value.length > 8 ? "" : "Password must be atleast 8 symbols.";
export const addressValidator = (value: any) =>
  value ? "" : "Address is required.";

export const requiredValidatorCheckEmptyArray = (value: any) =>
  value && value.length > 0 ? "" : "This field is required.";

const userNameGetter = getter("username");
const emailGetter = getter("email");

export const formValidator = (values: any) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);

  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: "Please fill the following fields.",
    username: !userName ? "User Name is required." : "",
    email:
      emailValue && emailRegex.test(emailValue)
        ? ""
        : "Email is required and should be in valid format.",
  };
};

export const requiredValidatorAndMaxLength = (
  value: any,
  maxLength: number
) => {
  if (value?.length > maxLength)
    return `The maximum length is ${maxLength} symbols.`;
  return requiredValidator(value);
};
export const validatorDateValueWithMinMax = (
  value: Date,
  minDate: Date | null,
  maxDate: Date | null
) => {
  const min = minDate ? getFromDate(minDate) : null; //Set min date to the beginning of the day
  const max = maxDate ? getToDate(maxDate) : null; //Set max date to the end of the day

  if (value && min && isNil(maxDate) && isBefore(value, min)) {
    return `Date input must be after ${formatDateByKendo(
      min,
      DATE_FORMAT.DATE_CONTROL
    )}`;
  }
  if (value && max && isNil(minDate) && isAfter(value, max)) {
    return `Date input must be before ${formatDateByKendo(
      maxDate,
      DATE_FORMAT.DATE_CONTROL
    )}`;
  }
  if (value && min && max && (isBefore(value, min) || isAfter(value, max))) {
    return `Date input must be between ${formatDateByKendo(
      minDate,
      DATE_FORMAT.DATE_CONTROL
    )} and ${formatDateByKendo(maxDate, DATE_FORMAT.DATE_CONTROL)}`;
  }
  return "";
};

export const nonZeroNumberValidator = (value: string) => {
  let numberValue = parseFloat(value);
  if (isNaN(numberValue) || numberValue === 0) {
    return "This field is required.";
  }
  return "";
};
export const validatePositiveCurrency = (data: number | null | undefined) => {
  if (data === null || data === undefined) return "";
  return data >= 0 ? "" : "The value for this field is not negative number";
};
