import { useAnimalsSettingStore } from "@app/products/animals/system-admin/settings/_category/store";
import { AnimalRenderSwitch } from "@app/products/animals/system-admin/settings/_id/components/switch/_index";
import {
  generalRegistrationshowTabsConfig,
  switchOptionsValueNumberString,
} from "@app/products/animals/system-admin/settings/_id/main/config";
import {
  ICommonFormProps,
  ISwitchCustomeFieldProps,
} from "@app/products/animals/system-admin/settings/_id/main/model";
import { useAnimalsSettingManageStore } from "@app/products/animals/system-admin/settings/_id/store";
import {
  ILookupItem,
  SettingField,
} from "@app/products/town-planning/ppr/system-admin/settings/model";
import { FINANCESYSTEM } from "@common/constants/enumerations";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { ComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/combo-box/_index";
import { DropdownSelectGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown-select/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { DatePickerGlobalSetting } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/pick-date-input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { TextAreaGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/text-area/_index";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getStringValueSetting } from "@common/stores/products/util";
import { getDropdownValue, nameOfFactory } from "@common/utils/common";
import { SettingDynamicGridFields } from "@components/setting/setting-dynamic-grid-fields/_index";
import {
  ComboBoxChangeEvent,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const nameOfLookupItem = nameOfFactory<ILookupItem>();

export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { getConfigDataField } = useAnimalsSettingStore();
  const { isEditing } = useAnimalsSettingManageStore();
  const { settings } = useCommonCoreStore();
  const gobalFinanceSystem = getStringValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_FinanceSystem]
  );
  const { onChange, valueGetter } = formRenderProps;

  const isEditRemoveOSFeesDeregisterField = useMemo(() => {
    return !(
      gobalFinanceSystem &&
      parseInt(gobalFinanceSystem) === FINANCESYSTEM.CommunityFinance
    );
  }, [gobalFinanceSystem]);

  const swtichStringValueFields: ISwitchCustomeFieldProps[] = [
    {
      eSettingsField: ECorporateSettingsField.Animals_AutoIssue,
    },
    {
      isEditing: isEditing && isEditRemoveOSFeesDeregisterField,
      eSettingsField: ECorporateSettingsField.Animals_RemoveOSFees,
    },
    {
      eSettingsField:
        ECorporateSettingsField.Animals_UseOpenOfficeFeeCalculator,
      isEditing: false,
    },
  ];

  const switchBooleanValueFields = [
    ECorporateSettingsField.Animals_EnableProRata,
    ECorporateSettingsField.Animals_EnableQuickEntry,
    ECorporateSettingsField.Animals_EnableRegenerateFee,
  ];

  const animalsdiscountNameData = getConfigDataField(
    ECorporateSettingsField.Animals_Discount_Name
  );

  // Implement later when having animal type api
  // const animalTypeClassificationData = getConfigDataField(
  //   ECorporateSettingsField.Animals_DangerousAnimalTypeClassification
  // );

  const switchPenaltyTransferFields = [
    ECorporateSettingsField.Animals_EnablePenaltyFees,
    ECorporateSettingsField.Animals_EnableTransfer,
    ECorporateSettingsField.Animals_DesexingCatsMandatory,
  ];

  const typeOfProofFields = [
    ECorporateSettingsField.Animals_DefaultSterlizationTypeOfProof,
    ECorporateSettingsField.Animals_DefaultObedienceTypeOfProof,
  ];

  const enableDuplicateTagNumbersFields: ISwitchCustomeFieldProps[] = [
    {
      eSettingsField: ECorporateSettingsField.Animals_EnableDuplicateTagNumber,
    },
    {
      isVisible:
        valueGetter(
          `${ECorporateSettingsField.Animals_EnableDuplicateTagNumber}`
        ) === switchOptionsValueNumberString.FALSE ?? false,
      eSettingsField:
        ECorporateSettingsField.Animals_EnableDuplicateTagNumberOnActiveRegistrationsOnly,
    },
  ];

  const enableDuplicateMicrochipNumberFields: ISwitchCustomeFieldProps[] = [
    {
      eSettingsField: ECorporateSettingsField.Animals_EnableDuplicateMicrochip,
    },
    {
      isVisible:
        valueGetter(
          `${ECorporateSettingsField.Animals_EnableDuplicateMicrochip}`
        ) === switchOptionsValueNumberString.FALSE ?? false,
      eSettingsField:
        ECorporateSettingsField.Animals_EnableDuplicateMicrochipOnActiveRegistrationsOnly,
    },
  ];

  const enablePensionerExpiryDelegateFields: ISwitchCustomeFieldProps[] = [
    {
      eSettingsField: ECorporateSettingsField.Animals_EnablePensionerExpiry,
    },
    {
      eSettingsField: ECorporateSettingsField.Animals_EnableDelegate,
    },
  ];

  const eRegistrationExpiryDateOption =
    ECorporateSettingsField.Animals_RegistrationExpiryDateOption;
  const eEnableDOGExemption =
    ECorporateSettingsField.Animals_EnableDOGExemption;
  const eEnableDocumentOrRecordsTab =
    ECorporateSettingsField.Animals_EnableDocumentOrRecordsTab;

  const enableDocumentOrRecordsTabData = useMemo(() => {
    const data: SettingField | undefined = getConfigDataField(
      eEnableDocumentOrRecordsTab
    );
    return {
      ...data,
      LookupItems: generalRegistrationshowTabsConfig,
    } as SettingField;
  }, [eEnableDocumentOrRecordsTab, getConfigDataField]);

  const registrationExpiryDateData = useMemo(() => {
    const data: SettingField | undefined = getConfigDataField(
      ECorporateSettingsField.Animals_RegistrationExpiryDate
    );
    return {
      ...data,
      Title: data?.Title !== "" ? data?.Title : "Registration expiry date",
    } as SettingField;
  }, [getConfigDataField]);

  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <AnimalRenderSwitch
          switchList={swtichStringValueFields}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />

        <DatePickerGlobalSetting
          data={getConfigDataField(
            ECorporateSettingsField.Animals_FinancialYearEndDate
          )}
          isEditing={isEditing}
        />

        {switchBooleanValueFields?.map((field: ECorporateSettingsField) => (
          <SwitchGlobalSettings
            data={getConfigDataField(field)}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        ))}
      </div>

      <div className="cc-form-cols-3">
        <SwitchGlobalSettings
          data={getConfigDataField(
            ECorporateSettingsField.Animals_EnableDiscount
          )}
          isEditing={isEditing}
          formRenderProps={formRenderProps}
        />
        <InputGlobalSettings
          data={animalsdiscountNameData}
          isEditing={isEditing}
        />
      </div>

      <div className="cc-form-cols-3">
        {switchPenaltyTransferFields.map((field: ECorporateSettingsField) => (
          <SwitchGlobalSettings
            data={getConfigDataField(field)}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        ))}
        {typeOfProofFields.map((field: ECorporateSettingsField) => (
          <ComboBoxGlobalSettings
            data={getConfigDataField(field)}
            isEditing={isEditing}
            onChange={(e: ComboBoxChangeEvent) => {
              onChange(`${field}`, { value: e.value?.Id ?? null });
            }}
            value={getDropdownValue(
              valueGetter(`${field}`),
              getConfigDataField(field)?.LookupItems ?? [],
              nameOfLookupItem("Id")
            )}
          />
        ))}
      </div>

      <div className="cc-form-cols-3">
        <AnimalRenderSwitch
          switchList={enableDuplicateTagNumbersFields}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />
      </div>

      <div className="cc-form-cols-3">
        <AnimalRenderSwitch
          switchList={enableDuplicateMicrochipNumberFields}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />
      </div>

      <div className="cc-form-cols-3">
        <AnimalRenderSwitch
          switchList={enablePensionerExpiryDelegateFields}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />

        {/* Implement later when having animal type api */}
        {/* {animalTypeClassificationData ? (
          <div className="cc-field">
            <CCLabel
              title={animalTypeClassificationData.Title}
              isMandatory={animalTypeClassificationData.IsMandatory ?? false}
            />
            <Field
              name={animalTypeClassificationData.FieldName}
              component={PickAnimalType}
              value={""}
              allowClear={true}
              selectMode="single"
              disabled={!isEditing}
              onPickAnimalType={(e: any) => {}}
              validator={
                animalTypeClassificationData.IsMandatory
                  ? requiredValidator
                  : undefined
              }
            />
          </div>
        ) : null} */}
      </div>

      <div className="cc-form-cols-3">
        <AnimalRenderSwitch
          switchList={[
            {
              eSettingsField:
                ECorporateSettingsField.Animals_EnableQuickRegister,
            },
          ]}
          formRenderProps={formRenderProps}
          optionsValue={switchOptionsValueNumberString}
        />
      </div>

      <div className="cc-form-cols-1">
        <TextAreaGlobalSettings
          data={getConfigDataField(
            ECorporateSettingsField.Animals_AssessmentExceptionList
          )}
          isEditing={isEditing}
          rows={3}
        />
      </div>

      <div className="cc-form-cols-3">
        <ComboBoxGlobalSettings
          data={getConfigDataField(eEnableDOGExemption)}
          isEditing={isEditing}
          onChange={(e: ComboBoxChangeEvent) => {
            onChange(`${eEnableDOGExemption}`, {
              value: e.value?.Id ?? null,
            });
          }}
          value={getDropdownValue(
            valueGetter(`${eEnableDOGExemption}`),
            getConfigDataField(eEnableDOGExemption)?.LookupItems ?? [],
            nameOfLookupItem("Id")
          )}
        />
      </div>

      <div className="cc-form-cols-3">
        <DropdownSelectGlobalSettings
          data={getConfigDataField(eRegistrationExpiryDateOption)}
          isEditing={isEditing}
          onChange={(e: DropDownListChangeEvent) => {
            onChange(`${eRegistrationExpiryDateOption}`, {
              value: e.value?.Id ?? null,
            });
          }}
          value={getDropdownValue(
            valueGetter(`${eRegistrationExpiryDateOption}`),
            getConfigDataField(eRegistrationExpiryDateOption)?.LookupItems ??
              [],
            nameOfLookupItem("Id")
          )}
        />

        {valueGetter(`${eRegistrationExpiryDateOption}`) === "1" ? null : (
          <DatePickerGlobalSetting
            data={registrationExpiryDateData}
            isEditing={isEditing}
          />
        )}

        <DatePickerGlobalSetting
          data={getConfigDataField(
            ECorporateSettingsField.Animals_RegistrationExpiryCalcEffectiveDate
          )}
          isEditing={isEditing}
        />

        <DropdownSelectGlobalSettings
          data={enableDocumentOrRecordsTabData}
          isEditing={isEditing}
          onChange={(e: DropDownListChangeEvent) => {
            onChange(`${eEnableDocumentOrRecordsTab}`, {
              value: e.value?.Id ?? null,
            });
          }}
          value={getDropdownValue(
            valueGetter(`${eEnableDocumentOrRecordsTab}`),
            enableDocumentOrRecordsTabData?.LookupItems ?? [],
            nameOfLookupItem("Id")
          )}
        />
      </div>

      {/* Common grid use for all form to show when adding more dynamic fields. */}
      <SettingDynamicGridFields
        formRenderProps={formRenderProps}
        isEditing={isEditing}
      />
    </section>
  );
});
