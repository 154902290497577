import { VO_CentreLink_Request } from "@app/products/property/centrelink-customer-confirmation/view-request-file/model";
import { PROPERTY_ENTITY_MANAGE_PAGE_ROUTE } from "@app/products/property/contacts-central-names/[id]/constant";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<VO_CentreLink_Request>();
export const colViewRequestFile: IColumnFields[] = [
  {
    field: nameOf("ENA_Name"),
    title: "Name",
    linkTo: (dataItem: VO_CentreLink_Request) =>
      `${PROPERTY_ENTITY_MANAGE_PAGE_ROUTE}/${dataItem?.Entity_Id}`,
  },
  {
    field: nameOf("ECC_Card_Number"),
    title: "Card Number",
  },
  {
    field: nameOf("Is_Card_Number_Valid"),
    title: "Invalid Card Number",
  },
  {
    field: nameOf("Is_Card_Duplicate"),
    title: "Duplicate Card Number",
  },
  {
    field: nameOf("Concession_Card_Name"),
    title: "Concession Card",
  },
  {
    field: nameOf("ECC_Confirmation_Date"),
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("ECC_Change_DateTime"),
    title: "Last Changed On",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOf("ECC_Change_UserId"),
    title: "Last Changed By",
  },
  {
    field: nameOf("Entity_Id"),
    title: "Entity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
