import { loadRegisterAccountLookup } from "@app/products/property/registers/[id]/components/dialogs/form-steps/transfer-to-another-account/components/form-elements/to-account/api";
import {
  listRegisterSearchOptions,
  mockRegisterDropdown,
  mockSearchRegister,
} from "@app/products/property/registers/[id]/components/dialogs/form-steps/transfer-to-another-account/components/form-elements/to-account/mock";
import { textRegisterFieldMapping } from "@app/products/property/registers/[id]/components/dialogs/form-steps/transfer-to-another-account/components/form-elements/to-account/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { requiredValidator } from "@common/utils/field-validators";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCInput } from "@components/cc-input/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  DropDownList,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React from "react";

export const TO_ACCOUNT_FORM_STEP = "ToAccount";

export const ToAccountFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = ({ formRenderProps, nameOf }: IFormStepElement) => {
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const searchRegisterTypeValue = getFieldValue("RegisterSearchBy");
  const handleSearchRegister = (event: ComboBoxFilterChangeEvent) => {
    let numLetters = 2;
    if (!searchRegisterTypeValue) return;
    onChange(nameOf("RegisterSearchValue"), { value: null });
    if (event.filter.value.length >= numLetters) {
      onChange(nameOf("_option.SearchRegister.Loading"), { value: true });
      loadRegisterAccountLookup(event.filter).then((data) => {
        onChange(nameOf("_option.SearchRegister.Data"), { value: data });
        onChange(nameOf("_option.SearchRegister.Loading"), {
          value: false,
        });
      });
    } else {
      onChange(nameOf("_option.SearchRegister.Data"), { value: [] });
    }
  };
  const handleSearchRegisterResult = (event: ComboBoxChangeEvent) => {
    if (!event.value) return;
    onChange(nameOf("AccountId"), {
      value: event.value?.AccountId,
    });
    onChange(nameOf("PropertyAddress"), {
      value: event.value?.PropertyAddress,
    });
    onChange(nameOf("AccountName"), {
      value: event.value?.AccountName,
    });
    onChange(nameOf("Category"), {
      value: event.value?.Category,
    });
    onChange(nameOf("RegisterSearchValue"), {
      value: event.value,
    });
  };
  return (
    <section className="cc-field-group">
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">Register</label>
          <Field
            name={nameOf("RegisterBy")}
            data={mockRegisterDropdown}
            component={CCSearchComboBox}
            textField="Value"
            dataItemKey="Key"
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">Search options</label>
          <Field
            name={nameOf("SearchOptions")}
            dataItemKey="Key"
            textField="Value"
            data={listRegisterSearchOptions}
            component={MultiSelect}
            placeholder={"Search options"}
          />
        </div>
      </div>
      <div className=" cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">Search by</label>
          <Field
            name={nameOf("RegisterSearchBy")}
            data={mockSearchRegister}
            component={DropDownList}
            textField="Value"
            dataItemKey="Key"
          />
        </div>
        <div className="cc-field">
          <label className="cc-label">
            Add account
            <CCTooltip
              type="validator"
              position="right"
              content="You must have at least one record to go to the next step"
            />
          </label>
          <Field
            name={nameOf("RegisterSearchValue")}
            filterable
            suggest
            textField={textRegisterFieldMapping[searchRegisterTypeValue?.Value]}
            data={getFieldValue("_option.SearchRegister.Data") ?? []}
            loading={getFieldValue("_option.SearchRegister.Loading") ?? false}
            placeholder={`Type ${searchRegisterTypeValue?.Value}`}
            component={CCComboBox}
            onFilterChange={handleSearchRegister}
            onChange={handleSearchRegisterResult}
            validator={requiredValidator}
          />
        </div>
      </div>
      <div className="cc-form-cols-1">
        <div className="cc-field">
          <label className="cc-label">Search result</label>
          <div className="cc-form-cols-1 cc-custom-sub-panel-bar">
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Account ID</label>
                <Field
                  name={nameOf("AccountId")}
                  component={CCInput}
                  placeholder="Account ID"
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Property address</label>
                <Field
                  name={nameOf("PropertyAddress")}
                  component={CCInput}
                  placeholder="Property address"
                  readOnly
                />
              </div>
            </div>
            <div className="cc-form-cols-2">
              <div className="cc-field">
                <label className="cc-label">Account name</label>
                <Field
                  name={nameOf("AccountName")}
                  component={CCInput}
                  placeholder="Account name"
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Category</label>
                <Field
                  name={nameOf("Category")}
                  component={CCInput}
                  placeholder="Category"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cc-form-cols-2">
        <div className="cc-field">
          <label className="cc-label">Transaction date</label>
          <Field
            name={nameOf("TransactionDate")}
            format={DATETIME_FORMAT.DATETIME_CONTROL}
            component={CCDateTimePicker}
          />
        </div>
      </div>
    </section>
  );
};
