import { requiredValidator } from "@common/utils/field-validators";
import {
    CCLocalNotification,
    ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import CCUploadFile from "@components/cc-upload-file/_index";
import { ConfirmDialog } from "@components/dialog/ConfirmDialog";
import { Button } from "@progress/kendo-react-buttons";
import {
    Field,
    Form,
    FormElement,
    FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useRef, useState } from "react";
import "./_index.scss";
interface ImportFileDialogProps {
  title: string;
  onClose: () => void;
  onSubmit: (file: any) => void;
}

const ACCEPT_FORMAT = [".xml"];
const MAX_FILE_SIZE = 5000000;

export const ImportFileDialog = ({
  title,
  onClose,
  onSubmit,
}: ImportFileDialogProps) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const [showFinishConfirmDialog, setShowFinishConfirmDialog] =
    useState<boolean>();

  return (
    <Form
      onSubmit={(data) => {}}
      render={(formRenderProps: FormRenderProps) => {
        const { onSubmit, modified, valid } = formRenderProps;
        return (
          <CCDialog
            titleHeader={title}
            onClose={onClose}
            maxWidth="30%"
            maxHeight="35%"
            bodyElement={
              <div className="cc-import-file cc-form">
                <CCLocalNotification ref={notificationRef} />
                <FormElement>
                  <label className="cc-label">
                    Upload file
                    <CCTooltip type="validator" position="right" />
                    <CCTooltip
                      type="custom"
                      position="auto"
                      content=" "
                      customTemplate={
                        <div>
                          &#8226; Allowed file type(s): .xml
                          <br />
                          &#8226; Maximum upload file size: 5 MB
                        </div>
                      }
                    >
                      <i className="fa fa-info-circle ml-1 text-accent" />
                    </CCTooltip>
                  </label>
                  <Field
                    name={"File"}
                    accept={ACCEPT_FORMAT}
                    component={CCUploadFile}
                    autoUpload={false}
                    batch={false}
                    multiple={false}
                    withCredentials={false}
                    restrictions={{
                      allowedExtensions: ACCEPT_FORMAT,
                      maxFileSize: MAX_FILE_SIZE,
                    }}
                    showActionButtons={false}
                    validator={requiredValidator}
                  />
                </FormElement>
              </div>
            }
            footerElement={
              <div className="cc-dialog-footer-actions-right">
                <Button
                  className="cc-dialog-button"
                  themeColor="primary"
                  disabled={!modified || !valid}
                  onClick={onSubmit}
                >
                  Finish
                </Button>
                {showFinishConfirmDialog && (
                  <ConfirmDialog
                    title="Confirmation"
                    subMessage={"Are you sure you want to submit?"}
                    onClosePopup={() => setShowFinishConfirmDialog(false)}
                    onAsyncConfirm={() => {}}
                  />
                )}
              </div>
            }
          />
        );
      }}
    />
  );
};
