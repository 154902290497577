import {
  listTypeShowCCOI,
  listTypeShowHoldingFields,
  listTypeShowNAA,
  listTypeShowOutstandingCharge,
  listTypeShowRebateEntitlements,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/assessment-selection-criteria/config";
import { useNoticeRunAssessmentStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/assessment-selection-criteria/store";
import { isShowWithNoticeType } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/utils";
import { ECustomColNameProperty } from "@app/products/property/config";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { Label } from "@common/stores/products/config";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Field, FieldArray } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

const MINIMUM_AMOUNT = 0.01;
const MAXIMUM_AMOUNT = 1000;

const validatorMinimumNoticeAmount = (values: any) => {
  if (!values) {
    return "Minimum notice amount is invalid";
  }

  if (values < 0) {
    return "The value for this field is not negative number";
  }

  if (values < MINIMUM_AMOUNT || values > MAXIMUM_AMOUNT) {
    return `Minimum notice amount must be between ${MINIMUM_AMOUNT} and ${MAXIMUM_AMOUNT}`;
  }
  return;
};

export const AssessmentSelectionCriteriaFormStep = (
  props: IFormStepElement
) => {
  return (
    <FieldArray name={props.nameOf()} {...props} component={FormStepElement} />
  );
};

const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    options = { isReadOnly: false },
  }: IFormStepElement) => {
    const { valueGetter } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    const { assessmentLOVs } = useNoticeRunAssessmentStepStore();

    const directDebitSchemesLov = useMemo(() => {
      return assessmentLOVs?.DirectDebitSchemes ?? [];
    }, [assessmentLOVs?.DirectDebitSchemes]);

    //Get label
    const assessmentLabelLowercase = Label.CommunityProperty.getLabel(
      ECustomColNameProperty.AssessmentLowercase
    );

    const isShowHoldingFields = isShowWithNoticeType(
      listTypeShowHoldingFields,
      valueGetter
    );

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-2">
            <div className="cc-field">
              <label className="cc-label">
                Minimum notice amount
                <CCTooltip type="validator" />
              </label>
              <Field
                name={nameOf("MinimumNoticeAmount")}
                component={CCCurrencyInput}
                min={0.01}
                max={1000}
                disabled={options?.isReadOnly}
                validator={
                  !options?.isReadOnly
                    ? validatorMinimumNoticeAmount
                    : undefined
                }
              />
            </div>
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">
                Exclude direct debit
                <CCTooltip
                  content={`Exclude ${assessmentLabelLowercase}s having one of the selected direct debit schemes`}
                />
              </label>
              <Field
                name={nameOf("DirectDebitSchemesToBeExcluded")}
                component={CCMultiSelectDropdown}
                data={directDebitSchemesLov ?? []}
                disabled={options?.isReadOnly}
              />
            </div>
            {isShowHoldingFields && (
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">
                    Number change of ownership after
                    <CCTooltip
                      content={`Exclude ${assessmentLabelLowercase}s where there has been a change of ownership after nominated date`}
                    />
                  </label>
                  <Field
                    name={nameOf("NoChangeOfOwnershipAfter")}
                    format={DATETIME_FORMAT.DATETIME_CONTROL}
                    component={CCDateTimePicker}
                    disabled={options?.isReadOnly}
                  />
                </div>
                <div className="cc-field">
                  <label className="cc-label">
                    No notice issued after
                    <CCTooltip
                      content={`Exclude ${assessmentLabelLowercase}s where a notice has been issued after nominated date`}
                    />
                  </label>
                  <Field
                    name={nameOf("NoNoticeIssuedAfter")}
                    format={DATETIME_FORMAT.DATETIME_CONTROL}
                    component={CCDateTimePicker}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
            )}
            {isShowWithNoticeType(
              listTypeShowRebateEntitlements,
              valueGetter
            ) && (
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <label className="cc-label">
                    Rebate entitlements
                    <CCTooltip
                      content={`One can selected ${assessmentLabelLowercase}s based on whether or not the ${assessmentLabelLowercase} has an entitlement to rebates ie. pensioner`}
                    />
                  </label>
                  <div className="cc-form-cols-2">
                    <div className="cc-field">
                      <label className="cc-label">Is Entitled to Rebates</label>
                      <Field
                        name={nameOf("EntitledToRebates")}
                        checked={getFieldValue("EntitledToRebates")}
                        component={CCSwitch}
                        disabled={options?.isReadOnly}
                      />
                    </div>
                    <div className="cc-field">
                      <label className="cc-label">
                        Is Not Entitled to Rebates
                      </label>
                      <Field
                        name={nameOf("NotEntitledToRebates")}
                        checked={getFieldValue("NotEntitledToRebates")}
                        component={CCSwitch}
                        disabled={options?.isReadOnly}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="cc-form-cols-2">
              {isShowWithNoticeType(listTypeShowNAA, valueGetter) && (
                <div className="cc-field">
                  <label className="cc-label">
                    No active arrangement
                    <CCTooltip
                      content={`Exclude ${assessmentLabelLowercase}s with an active arrangement`}
                    />
                  </label>
                  <Field
                    name={nameOf("NoActiveArrangement")}
                    checked={getFieldValue("NoActiveArrangement")}
                    component={CCSwitch}
                    disabled={options?.isReadOnly}
                  />
                </div>
              )}
              {isShowHoldingFields && (
                <div className="cc-field">
                  <div className="cc-field">
                    <label className="cc-label">
                      No interest exemption
                      <CCTooltip
                        content={`Exclude ${assessmentLabelLowercase}s with an interest exception`}
                      />
                    </label>
                    <Field
                      name={nameOf("NoInterestExemption")}
                      checked={getFieldValue("NoInterestExemption")}
                      component={CCSwitch}
                      disabled={options?.isReadOnly}
                    />
                  </div>
                </div>
              )}
              {isShowWithNoticeType(listTypeShowCCOI, valueGetter) && (
                <div className="cc-field">
                  <label className="cc-label">
                    With a current charge on instalments
                  </label>
                  <Field
                    name={nameOf("ExcludeInstalments")}
                    checked={getFieldValue("ExcludeInstalments")}
                    component={CCSwitch}
                    disabled={options?.isReadOnly}
                  />
                </div>
              )}
            </div>
            {isShowWithNoticeType(
              listTypeShowOutstandingCharge,
              valueGetter
            ) && (
              <div className="cc-form-cols-2">
                <div className="cc-field">
                  <label className="cc-label">
                    No outstanding charge in discount period
                  </label>
                  <Field
                    name={nameOf("NoOutstandingChargeInDiscountPeriod")}
                    checked={getFieldValue(
                      "NoOutstandingChargeInDiscountPeriod"
                    )}
                    component={CCSwitch}
                    disabled={options?.isReadOnly}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
