import { Addresses } from "@app/core/addresses/_index";
import { Comments } from "@app/core/comments/_index";
import Conditions from "@app/core/condition/condition-accordion/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import Inspections from "@app/core/inspections/inspections-accordion/_index";
import { InvoicesAccordion } from "@app/core/invoice/invoice-accordion/_index";
import { RelatedAccordion } from "@app/core/related/related-accordion/_index";
import { ICoreRelatedParams } from "@app/core/related/related-accordion/model";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FinanceTransactions } from "@app/core/transaction/finance-transaction-accordion/_index";
import { isVisibleFinanceTransaction } from "@app/core/transaction/finance-transaction-accordion/util";
import { EventsAccordion } from "@app/products/crms/components/event-accordion/_index";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { checkCRMSProductionIsActive } from "@app/products/crms/util";
import { Permit } from "@app/products/local-laws/[id]/model";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { COMPLAINTSMENU } from "@common/constants/enumerations";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { useGlobalStore } from "@common/stores/global/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { isEmpty } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

export const AccordionsLocalLawsPermit = observer(() => {
  const { dataForms } = useFlexibleFormStore();
  const permit = dataForms?.GeneralForm?.Permit as Permit;
  const idUrl: string = useParams<{ id: string }>().id;
  const id: number = permit?.Permit_ID || parseInt(idUrl ?? "0");

  const { productsActive } = useGlobalStore();
  const { settings } = useCommonCoreStore();
  const isShowCombineAddress = getBoolValueSetting(
    settings[ECorporateSettingsField.LLP_AllowMultipleAddresses]
  );
  const isEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );
  const isShowCUSTEventAccordion = useMemo(() => {
    const complaintsMenu = getNumberValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_ComplaintsMenu]
    );
    const checkIsActiveCRMS = checkCRMSProductionIsActive(productsActive);
    return checkIsActiveCRMS && complaintsMenu === COMPLAINTSMENU.CRMS;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsActive]);

  const { checkPermissions } = useCheckPermissions({
    formIdentifier: FormIdentifier.LLPermits_Form_Permit,
    productType: PRODUCT_TYPE_NUMBER.LLPermits,
  });

  const isShowFinanceTransactionAccordion: boolean = useMemo(() => {
    //Condition to show finance transaction:
    //1. isVisibleFinanceTransaction = true
    //2. Fee/Invoice is enabled (This Form is always enabled Invoice)
    return isVisibleFinanceTransaction(settings);
  }, [settings]);

  const relatedAccordionParams = useMemo(() => {
    if (permit && id) {
      const data: ICoreRelatedParams = {
        RecordType: RECORDTYPE.LLP_Permit,
        RecordID: id,
        InvoiceNumber: null,
        AssessmentNumberKey: null,
        AddressLine1: null,
        Suburb: null,
        PersonKey: null,
        WasteWaterSchemeKey: null,
        AncestorRecordType: null,
        AncestorRecordId: null,
      };

      if (permit?.Address) {
        data.AssessmentNumberKey = permit?.Address?.PropertyAssessment?.PID_No;
        data.AddressLine1 = permit.Address?.Formatted_AddressLine1;
        data.Suburb = permit.Address?.Suburb;
      }

      return data;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permit?.Address, permit, id]);

  const listPanelBar: ITabProps[] = [
    {
      title: "Addresses",
      tabType: TabTableType.Addresses,
      component: <Addresses id={id} recordType={RECORDTYPE.LLP_Permit} />,
      isVisible: isShowCombineAddress,
    },
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: <PPRActions id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: <Comments id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion id={id} recordType={RECORDTYPE.LLP_Permit} />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents
          id={id}
          recordType={RECORDTYPE.LLP_Permit}
          isUseSharepoint
          isAddToRecords
          isExistedFolderRecords={!isEmpty(permit?.FileNumber)}
        />
      ),
    },
    // Implement later
    // {
    //   title: "Transactions",
    //   tabType: ,
    //   component: ,
    // },
    {
      title: "Transactions",
      tabType: TabTableType.FinanceTransaction,
      isVisible: isShowFinanceTransactionAccordion,
      component: (
        <FinanceTransactions id={id} recordType={RECORDTYPE.LLP_Permit} />
      ),
    },
    {
      title: "Invoices",
      tabType: TabTableType.Invoices,
      isVisible: isEnableMultiLineInvoice,
      component: (
        <InvoicesAccordion id={id} recordType={RECORDTYPE.LLP_Permit} />
      ),
    },
    {
      title: "Inspections",
      tabType: TabTableType.Inspections,
      component: (
        <Inspections
          id={id}
          recordType={RECORDTYPE.LLP_Permit}
          isDisabledDelete={
            !checkPermissions(FormAction.CORE_DELETE_INSPECTION)
          }
        />
      ),
    },
    {
      title: "Conditions",
      tabType: TabTableType.Conditions,
      component: <Conditions id={id} recordType={RECORDTYPE.LLP_Permit} />,
    },
    {
      title: "Related",
      tabType: TabTableType.Related,
      component: <RelatedAccordion relatedParam={relatedAccordionParams} />,
    },
    {
      title: "Events",
      tabType: TabTableType.Events,
      component: <EventsAccordion id={id} recordType={RECORDTYPE.LLP_Permit} />,
      isVisible: isShowCUSTEventAccordion,
    },
    // Implement later
    // {
    //   title: "Bond history",
    //   tabType: ,
    //   component: ,
    // },
  ];
  return (
    <TabTable
      id={id}
      recordType={RECORDTYPE.LLP_Permit}
      initialPanels={listPanelBar}
      options={{
        invoiceNumber: relatedAccordionParams?.InvoiceNumber,
        propertyAssessmentNoKey: relatedAccordionParams?.AssessmentNumberKey,
        addressLine1: relatedAccordionParams?.AddressLine1,
        suburb: relatedAccordionParams?.Suburb,
        personKey: relatedAccordionParams?.PersonKey,
        schemeKey: relatedAccordionParams?.WasteWaterSchemeKey,
        refNoKey: relatedAccordionParams?.CsmRefNoKey
          ? relatedAccordionParams.CsmRefNoKey.toString()
          : undefined,
        ancestorRecordId: relatedAccordionParams?.AncestorRecordId,
        ancestorRecordType: relatedAccordionParams?.AncestorRecordType,
      }}
    />
  );
});
