import {
  DTO_ChangeOfOwnership_LOVs,
  DTO_Workflow_ChangeOfOwnership,
  RequestChangeOfOwnershipObj,
} from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/model";
import { eComponent } from "@app/products/property/components/associations/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { ResponsePacket } from "@common/models/identityPacket";

export const getNewWorkflowChangeOfOwnership = async (
  requestObjSpatial: RequestChangeOfOwnershipObj | object = {}
): Promise<APIResponse<DTO_Workflow_ChangeOfOwnership>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/int/workflow/changeofOwnership/new`,
      requestObjSpatial
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getWorkflowChangeOfOwnership = async (
  workflowId: number
): Promise<APIResponse<DTO_Workflow_ChangeOfOwnership>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/workflow/changeofownership/${workflowId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as APIResponse<ResponsePacket>;
    return {
      ...errorResponse,
      error: (errorResponse.data as ResponsePacket)?.Errors,
    };
  }
};
export const getChangeOfOwnershipLovs = async (
  componentNumber: eComponent
): Promise<APIResponse<DTO_ChangeOfOwnership_LOVs>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/changeofownership/${componentNumber}/lovs`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export type IGetInitialDataChangeOfOwnership =
  | [
      APIResponse<DTO_ChangeOfOwnership_LOVs>,
      APIResponse<DTO_Workflow_ChangeOfOwnership>
    ]
  | APIResponse<DTO_ChangeOfOwnership_LOVs | DTO_Workflow_ChangeOfOwnership>
  | undefined;
export const getInitialDataChangeOfOwnership = async (
  assessmentId: number | null,
  contactId: number | null,
  titleId: number | null,
  picId: number | null,
  componentNumber: eComponent
): Promise<IGetInitialDataChangeOfOwnership> => {
  try {
    return await Promise.all([
      getChangeOfOwnershipLovs(componentNumber),
      getNewWorkflowChangeOfOwnership({
        AssessmentId: assessmentId,
        Entity_Id: contactId,
        Title_Id: titleId,
        PIC_Id: picId,
      }),
    ]);
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessChangeOfOwnership = async (
  workflowType: WorkflowProcessMode,
  holdingData: DTO_Workflow_ChangeOfOwnership
): Promise<
  APIResponse<ResponseMessageWorkflow<DTO_Workflow_ChangeOfOwnership>>
> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/int/workflow/changeofownership/process/${workflowType}`,
      holdingData
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
