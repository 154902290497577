import {
  EWorkflowStatusUX,
  WorkflowApprovalStatus,
} from "@app/products/property/components/action-bar/property-workflow/model";
import {
  DTO_WorkflowHeader,
  EPrivilegesWorkflow,
  WorkflowTypes,
} from "@app/products/property/model";
import { isNil } from "lodash";

export const getTitleWorkflow = (
  title: string,
  prefix?: string,
  suffix?: string
) => {
  return `${prefix ? `${prefix} - ` : ""}${title}${
    suffix ? ` - ${suffix}` : ""
  }`;
};
const listEnumApproval = [
  WorkflowApprovalStatus.ToBeApproved,
  WorkflowApprovalStatus.AwaitingGisApproval,
];
export const isApprovalModeWorkflow = (
  workflowApprovalStatus?: WorkflowApprovalStatus
) => {
  if (isNil(workflowApprovalStatus)) return false;
  return listEnumApproval.includes(workflowApprovalStatus);
};

export const workflowStatus = (
  workflowHeader?: DTO_WorkflowHeader
): EWorkflowStatusUX | undefined => {
  if (
    isNil(workflowHeader) ||
    (isNil(workflowHeader?.WorkflowDraft?.WD_Workflow_Status) &&
      isNil(workflowHeader?.WorkflowDraft?.WD_Workflow_Approval_Status))
  )
    return undefined;
  return `${workflowHeader?.WorkflowDraft?.WD_Workflow_Status}${workflowHeader?.WorkflowDraft?.WD_Workflow_Approval_Status}` as EWorkflowStatusUX;
};

export const mappingPrivilege = {
  [WorkflowTypes.Create_Assessment]: EPrivilegesWorkflow.Create_Assessment,
  [WorkflowTypes.Change_Of_Ownership]: EPrivilegesWorkflow.Change_Of_Ownership,
  [WorkflowTypes.Modify_Assessment]: EPrivilegesWorkflow.Modify_Assessment,
  [WorkflowTypes.Create_LandTaxExemption]:
    EPrivilegesWorkflow.Create_LandTaxExemption,
  [WorkflowTypes.Modify_Compliance]:
    EPrivilegesWorkflow.AreaOfResponsibility_Modify,
  [WorkflowTypes.Edit_Change_Of_Ownership]:
    EPrivilegesWorkflow.Edit_Change_Of_Ownership,
  [WorkflowTypes.Split_Assessment]: EPrivilegesWorkflow.Split_Assessment,
  [WorkflowTypes.Reactivate_Assessment]:
    EPrivilegesWorkflow.Reactivate_Assessment,
  [WorkflowTypes.Associate_Assessment]:
    EPrivilegesWorkflow.Associate_Assessment,
  [WorkflowTypes.Amalgamate_Assessment]:
    EPrivilegesWorkflow.Amalgamate_Assessment,
  [WorkflowTypes.Disassociate_Assessment]:
    EPrivilegesWorkflow.Disassociate_Assessment,
  [WorkflowTypes.Create_Parcel]: EPrivilegesWorkflow.Create_Parcel,
  [WorkflowTypes.Modify_Parcel]: EPrivilegesWorkflow.Modify_Parcel,
  [WorkflowTypes.Modify_Parcel_GIS]: EPrivilegesWorkflow.Modify_Parcel_GIS,
  [WorkflowTypes.Create_MasterProperty]:
    EPrivilegesWorkflow.Create_MasterProperty,
  [WorkflowTypes.Edit_MasterProperty]: EPrivilegesWorkflow.Edit_MasterProperty,
  [WorkflowTypes.Associate_Supplementary]:
    EPrivilegesWorkflow.Associate_Supplementary,
  [WorkflowTypes.New_Supplementary]: EPrivilegesWorkflow.New_Supplementary,
  [WorkflowTypes.Complete_Supplementary]:
    EPrivilegesWorkflow.Complete_Supplementary,
  [WorkflowTypes.AssessmentAdjustment_Supplementary]:
    EPrivilegesWorkflow.AssessmentAdjustment_Supplementary,
  [WorkflowTypes.Incomplete_Supplementary]:
    EPrivilegesWorkflow.Complete_Supplementary,
  [WorkflowTypes.Create_DebtRecovery]: EPrivilegesWorkflow.Create_DebtRecovery,
  [WorkflowTypes.Create_DebtRecoveryExemption]:
    EPrivilegesWorkflow.Create_DebtRecoveryExemption,
  [WorkflowTypes.Journal_Assessment_Add_Transaction]:
    EPrivilegesWorkflow.Journal_Assessment_Add_Transaction,
  [WorkflowTypes.Journal_Add_Transaction]:
    EPrivilegesWorkflow.Journal_Add_Transaction,
  [WorkflowTypes.Journal_Authorise]: EPrivilegesWorkflow.Journal_Authorise,
  [WorkflowTypes.Journal_Create]: EPrivilegesWorkflow.Journal_Create,
  [WorkflowTypes.Journal_Assessment_Reverse_Transaction]:
    EPrivilegesWorkflow.Journal_Assessment_Reverse_Transaction,
  [WorkflowTypes.Journal_Assessment_Raise_Charges]:
    EPrivilegesWorkflow.Journal_Assessment_Raise_Charges,
  [WorkflowTypes.Journal_Assessment_Raise_Interest]:
    EPrivilegesWorkflow.Journal_Assessment_Raise_Interest,
  [WorkflowTypes.Journal_Assessment_Adjust_Charge]:
    EPrivilegesWorkflow.Journal_Assessment_Adjust_Charge,
  [WorkflowTypes.Journal_Assessment_Adjust_ChargeBalances]:
    EPrivilegesWorkflow.Journal_Assessment_Adjust_ChargeBalances,
  // missing[WorkflowTypes.Journal_Assessment_Transafer_Transaction]: EPrivilegesWorkflow.Journal_Assessment_Transafer_Transaction,
  [WorkflowTypes.Create_DeferredDuty]: EPrivilegesWorkflow.Create_DeferredDuty,
  // check me [WorkflowTypes.Journal_Add_DDTransaction]: EPrivilegesWorkflow.Journal_Add_DDTransaction,
  [WorkflowTypes.Modify_DeferredDuty]: EPrivilegesWorkflow.Modify_DeferredDuty,
  [WorkflowTypes.Journal_DD_Reverse_Transaction]:
    EPrivilegesWorkflow.Journal_DD_Reverse_Transaction,
  [WorkflowTypes.Journal_DD_Transfer_Transaction]:
    EPrivilegesWorkflow.Journal_DD_Transfer_Transaction,
  [WorkflowTypes.Journal_DD_Raise_Interest]:
    EPrivilegesWorkflow.Journal_DD_Raise_Interest,
  [WorkflowTypes.Journal_DD_Writeoff_Balance]:
    EPrivilegesWorkflow.Journal_DD_Writeoff_Balance,
  [WorkflowTypes.Create_Entity]: EPrivilegesWorkflow.Create_Entity,
  [WorkflowTypes.Modify_Entity]: EPrivilegesWorkflow.Modify_Entity,
  [WorkflowTypes.Certificate_Create]: EPrivilegesWorkflow.Certificate_Create,
  [WorkflowTypes.Certificate_Modify]: EPrivilegesWorkflow.Certificate_Modify,
  [WorkflowTypes.AreaOfResponsibility_Modify]:
    EPrivilegesWorkflow.AreaOfResponsibility_Modify,
  // missing from docs[WorkflowTypes.Certificate_Replace]: EPrivilegesWorkflow.Certificate_Replace,
  [WorkflowTypes.Create_ChargeRun]: EPrivilegesWorkflow.Create_ChargeRun,
  [WorkflowTypes.Update_ChargeRun]: EPrivilegesWorkflow.Update_ChargeRun,
  [WorkflowTypes.Create_Deferment]: EPrivilegesWorkflow.Create_Deferment,
  [WorkflowTypes.Modify_Deferment]: EPrivilegesWorkflow.Modify_Deferment,
  [WorkflowTypes.Create_RebateClaim]: EPrivilegesWorkflow.Create_RebateClaim,
  [WorkflowTypes.Update_LossOfEligibilityDate]:
    EPrivilegesWorkflow.Update_LossOfEligibilityDate,
  [WorkflowTypes.Modify_ChargeDetail]: EPrivilegesWorkflow.Modify_ChargeDetail,
  [WorkflowTypes.NoticeRun_Create]: EPrivilegesWorkflow.NoticeRun_Create,
  [WorkflowTypes.PIC_Create]: EPrivilegesWorkflow.PIC_Create,
  [WorkflowTypes.PIC_Modify]: EPrivilegesWorkflow.PIC_Modify,
  [WorkflowTypes.PIC_Reactivate]: EPrivilegesWorkflow.PIC_Reactivate,
  [WorkflowTypes.PIC_Amalgamate]: EPrivilegesWorkflow.PIC_Amalgamate,
  // missing [WorkflowTypes.Journal_PIC_AddTransaction]: EPrivilegesWorkflow.Journal_PIC_AddTransaction,
  [WorkflowTypes.Subdivisions_Consolidations]:
    EPrivilegesWorkflow.Subdivisions_Consolidations,
  [WorkflowTypes.ModifyGISReferenceTitle]:
    EPrivilegesWorkflow.ModifyGISReferenceTitle,
  [WorkflowTypes.New_Rebate]: EPrivilegesWorkflow.New_Rebate,
  [WorkflowTypes.Change_Of_Ownership_NoticeOfSale]:
    EPrivilegesWorkflow.Notice_Of_Sale_Update,
  [WorkflowTypes.ModifySupplementary]: EPrivilegesWorkflow.ModifySupplementary,
};

export type TMappingPrivilege = keyof typeof mappingPrivilege;
