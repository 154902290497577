import {
  BubbleUpIdentifier,
  BubbleUpType,
} from "@app/products/waste-water/[id]/model";
import { ICCRoute } from "@common/constants/ICCRoute";
import { DATE_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { formatDisplayValue } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { isHTML } from "@components/cc-input-picker/util";
import { IMainMenuItem, ISubItem } from "@components/layout/model";
import { FormRenderProps } from "@progress/kendo-react-form";
import { parse } from "date-fns";
import { find, isEmpty, isNil, isObject, isString } from "lodash";

export let newDate = new Date();

export const isBase64 = (str: string) => {
  if (str === "" || str.trim() === "") {
    return false;
  }
  var regexBase64 =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return regexBase64.test(str);
};

export const getUUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getWebAndEmail = (name: string) => {
  const getValue = name?.split(" ");
  const email = getValue[0];
  const website = getValue[getValue.length - 1];
  return { email, website };
};

export const sortByField = (arr: any, propertyField: string) => {
  let sortedArr = [...arr];
  return sortedArr.sort((a: any, b: any) =>
    a[propertyField].localeCompare(b[propertyField], "es", {
      sensitivity: "base",
    })
  );
};

export const createDefaultDropdownItem = (fieldName: string) => {
  return { Key: null, Value: `Select ${fieldName}...` };
};

export const checkUrlExist = (urlName: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return pattern.test(urlName);
};

export const getRandomHex = () => {
  // RANDOM LIGHT COLORS
  // 16777215 is the number of possible color from black to white (16^6)
  // const randomHex = '#' + Math.floor(Math.random()*16777215).toString(16);
  // return randomHex;

  // RANDOM DARK COLORS
  var color = "#";
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 10);
  }
  return color;
};

export const getCookie = (name: string) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const getMenuItems = (
  routes: ICCRoute[] | undefined,
  rootPath: string
) => {
  if (!routes || routes.length === 0) return [];
  let menu: IMainMenuItem[] = [];
  routes.forEach((route: ICCRoute) => {
    let subItems = getSubItems(route, rootPath);
    if (route.hasOwnProperty("name")) {
      menu.push({
        menuName: route.name || "",
        subItems: subItems,
      });
    }
  });
  return menu;
};

const getSubItems = (route: ICCRoute, rootPath: string) => {
  let items: ISubItem[] = [];
  route.children?.forEach((childRoute: ICCRoute) => {
    if (route.hasOwnProperty("name")) {
      items.push({
        name: childRoute.name || "",
        path: `${rootPath}/${route.path}${getDefaultPath(childRoute)}`,
      });
    }
  });
  return items;
};

const getDefaultPath = (
  route: ICCRoute,
  pathDefault: string = ""
): string | undefined => {
  let pathTemp: string = pathDefault;
  if (route.hasOwnProperty("component")) {
    return pathDefault + "/" + route.path;
  } else {
    if (route.children && route.children.length > 0) {
      pathTemp += "/" + route.path;
      return getDefaultPath(route.children[0], pathTemp);
    }
    return undefined;
  }
};

export function deserialize(data: any): any {
  try {
    return JSON.parse(data, reviveDateTime);
  } catch (e) {
    return data;
  }
}

export function reviveDateTime(key: any, value: any): any {
  const dateISOFormat =
    /(?:^(?!0)\d{4}-\d{2}-\d{2}[ T]\d{2}:\d{2}:\d{2})(?:[.,]\d{1,3})?(\+\d{2}:\d{2}|Z|)$/;
  if (typeof value === "string" && dateISOFormat.test(value)) {
    return new Date(value);
  }
  return value;
}

export const populateValue = (
  value: any,
  defaultValue: string = "---"
): string => {
  const valueType = typeof value;
  switch (valueType) {
    case "boolean":
      return value + "";

    case "number":
      return !isNaN(value) ? value : defaultValue;

    case "string":
      return value || defaultValue;

    default:
      return defaultValue;
  }
};

export const getDropdownValue = (
  keyFilter: string | number,
  arr: any,
  dataItemKey: string = "Key"
): any => {
  if (keyFilter === undefined) {
    return null;
  }
  const compareFunction = (item: any) => {
    if (isString(keyFilter) && isString(item?.[dataItemKey])) {
      return item[dataItemKey].toLowerCase() === keyFilter.toLowerCase();
    } else {
      return item?.[dataItemKey] === keyFilter;
    }
  };

  return find(arr ?? [], compareFunction) ?? null;
};

export const getDisplayTextWithDashes = (dataArray: any[]) => {
  if (dataArray.some((data) => isObject(data))) return "";
  const availableArray: string[] = [];
  dataArray.forEach((data) => {
    if (!isNil(data)) {
      if (!isString(data) || !isEmpty(data)) availableArray.push(data);
    }
  });
  return availableArray.length > 0 ? availableArray.join(" - ").trim() : "";
};

export const toBoolean = (data: string): boolean =>
  data.toLowerCase() === "true";

export const nameOfFactory =
  <T>() =>
  (name: keyof T) =>
    name;

export const removeTrailingSlash = (url?: string) => {
  if (url?.endsWith("/")) return url.replace(/\/+$/, "");
  return url;
};

export const isValidDate = (dateString: string): boolean => {
  const date = new Date(dateString);

  // Check if the date string is a valid date and it's not "Invalid Date"
  return !isNaN(date.getTime()) && date.toString() !== "Invalid Date";
};

export const isValidDateByRegex = (input: string): boolean => {
  // Define a regular expression to match the "dd/mm/yyyy" format
  const dateRegex = /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

  // Check if the input string matches the defined format
  return dateRegex.test(input);
};

export const generateParentValueField = (
  value: string,
  recordType: RECORDTYPE | undefined
) => {
  // Format date
  switch (recordType) {
    case RECORDTYPE.CORE_Invoice:
      if (isValidDateByRegex(value)) {
        const parsedDate = parse(value, "dd/MM/yyyy", new Date());
        return formatDisplayValue(parsedDate, DATE_FORMAT.DATE);
      }
      break;
    case RECORDTYPE.Animals_Kennel:
    case RECORDTYPE.CUSTOMERSERVICE_Event:
      if (isValidDate(value))
        return formatDisplayValue(value, DATE_FORMAT.DATE);
      break;
    default:
      if (isValidDateByRegex(value)) {
        const parsedDate = parse(value, "dd/MM/yyyy", new Date());
        return formatDisplayValue(parsedDate, DATE_FORMAT.DATE);
      }
      break;
  }

  if (isHTML(value)) return sanitizeHtml(value);
  return value;
};

export const clearHoursOfDate = (date?: Date) => {
  return date ? new Date(date.setHours(0, 0, 0, 0)) : date;
};

export const YesNoDropDownValue = [
  { Key: true, Value: "Yes" },
  { Key: false, Value: "No" },
];

export const getValueFromKeyValuePacket = (
  dropdownList: IKeyValuePacket[],
  key: number
) => {
  const item = (dropdownList ?? []).find(
    (item: IKeyValuePacket) => item.Key === key
  );
  return item ? item.Value : "";
};

export const getKeyFromKeyValuePacket = (
  dropdownList: IKeyValuePacket[],
  value: string
) => {
  const item = (dropdownList ?? []).find(
    (item: IKeyValuePacket) => item.Value === value
  );
  return item ? item.Key : null;
};

export const convertStrIdsToList = (stringIDs: string, delimiter: string) => {
  if (stringIDs.length === 0 || delimiter.length === 0) return [];
  var result = stringIDs.split(delimiter).map(function (item) {
    return parseInt(item);
  });
  return result;
};

export const onChanges = async (
  onChange: FormRenderProps["onChange"],
  dataUpdate: Record<string, any>
) => {
  for (const [key, value] of Object.entries(dataUpdate)) {
    onChange(key, {
      value: value,
    });
  }
};

//Render BubbleUps
export const renderBubbleUps = (
  ancestorId: number,
  ancestorType: string | number | undefined,
  parentId?: number,
  parentType?: string | number | undefined
): BubbleUpIdentifier[] => {
  return [
    {
      PKID: 0,
      SourceIdentifier: {
        _RecordSource_ID: ancestorId,
        _RecordSourceType_ENUM: mapEnum(ancestorType, RECORDTYPE),
        _RecordSource_ColumnName: "",
        _RecordSource_StringID: "",
      },
      BubbleUpType_ENUM: BubbleUpType.Ancestor,
      BubbleUpType_Name: BubbleUpType.Ancestor.toString(),
      Sys_CreatedDate: newDate,
      Sys_DeactivationDate: null,
    },
    {
      PKID: 0,
      SourceIdentifier: {
        _RecordSource_ID: parentId ?? ancestorId,
        _RecordSourceType_ENUM: mapEnum(parentType ?? ancestorType, RECORDTYPE),
        _RecordSource_ColumnName: "",
        _RecordSource_StringID: "",
      },
      BubbleUpType_ENUM: BubbleUpType.Parent,
      BubbleUpType_Name: BubbleUpType.Parent.toString(),
      Sys_CreatedDate: newDate,
      Sys_DeactivationDate: null,
    },
  ];
};

export const mapEnum = <T>(
  enumItem: string | number | undefined,
  enumList: any
) => {
  if (enumItem) {
    if (typeof enumItem === "number") {
      return enumItem;
    } else {
      return enumList?.[enumItem as keyof T];
    }
  }
};
