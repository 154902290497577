import { useIsNew } from "@common/hooks/useIsNew";
import { ILocalGovernmentParentSection } from "@common/pages/settings/lookups/local-government/model";
import { useLocalGovernmentStore } from "@common/pages/settings/lookups/local-government/store";
import { ExistedLocalGovernment } from "@common/pages/settings/lookups/local-government/[id]/components/forms/existed/_index";
import { NewLocalGovernment } from "@common/pages/settings/lookups/local-government/[id]/components/forms/new/_index";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageLocalGovernment = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const localGovernmentId = parseInt(params.id) || undefined;
  const { resetStore, loadLocalGovernment, setParentSection } =
    useLocalGovernmentStore();

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as ILocalGovernmentParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line
  }, [isNew]);

  useEffect(() => {
    loadLocalGovernment(localGovernmentId, isNew);
    // eslint-disable-next-line
  }, [localGovernmentId, isNew]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  if (isNew) {
    return <NewLocalGovernment />;
  }
  return <ExistedLocalGovernment />;
};

export default observer(ManageLocalGovernment);
