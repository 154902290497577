import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { TrimlHPECMApiCredentialsDescription } from "@common/pages/settings/system-admin/global-settings/_id/main/settings/records/forms/records-management-settings/components/trim/components/trim-api/model";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React from "react";
interface ITrimApi {
  formRenderProps: FormRenderProps;
}

export const TrimApi = observer(({ formRenderProps }: ITrimApi) => {
  const { isEditing } = useSettingGlobalManageStore();
  const { getConfigDataField } = useSettingGlobalStore();

  const { valueGetter } = formRenderProps;

  //Common field
  const fieldUseHPECMAPI = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_UseServiceAPI
  );
  const fieldHPECMApiUrl = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_ServiceAPIUrl
  );
  const fieldHPECMApiCredentials = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_ServiceAPICredentials
  );
  const fieldHPECMApiAuthType = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_ServiceAPIAuthType
  );
  const fieldUseNewHPECMSDK = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_UseNewHPEContentManagerSDK
  );
  const fieldTRIMUseImpersonation = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_UseImpersonation
  );
  const fieldTRIMClientBinariesLoadPath = getConfigDataField(
    ECorporateSettingsField.TRIMAdmin_ClientBinariesLoadPath
  );

  const trimlHPECMApiCredentialsDescription = () => {
    switch (valueGetter(fieldHPECMApiCredentials?.FieldName ?? "")) {
      case "0":
        return TrimlHPECMApiCredentialsDescription.Case0;
      case "1":
        return TrimlHPECMApiCredentialsDescription.Case1;
      case "2":
        return TrimlHPECMApiCredentialsDescription.Case2;
      case "3":
        return TrimlHPECMApiCredentialsDescription.Case3;
      case "4":
        return TrimlHPECMApiCredentialsDescription.Case4;
      case "5":
        return TrimlHPECMApiCredentialsDescription.Case5;
      default:
        return "";
    }
  };

  const isShowTrimApiSetting = valueGetter(fieldUseHPECMAPI?.FieldName ?? "");
  return (
    <>
      {fieldUseHPECMAPI && (
        <div className="cc-form-cols-1">
          <SwitchGlobalSettings
            data={fieldUseHPECMAPI}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      )}

      {isShowTrimApiSetting ? (
        <>
          {fieldHPECMApiUrl && (
            <div className="cc-form-cols-1">
              <InputGlobalSettings
                data={fieldHPECMApiUrl}
                isEditing={isEditing}
              />
            </div>
          )}

          {fieldHPECMApiCredentials && (
            <div className="cc-form-cols-1">
              <CCSearchComboBoxGlobalSettings
                data={fieldHPECMApiCredentials}
                isEditing={isEditing}
                customDescription={trimlHPECMApiCredentialsDescription()}
              />
            </div>
          )}

          {fieldHPECMApiAuthType &&
            (valueGetter(fieldHPECMApiCredentials?.FieldName ?? "") === "2" ||
              valueGetter(fieldHPECMApiCredentials?.FieldName ?? "") ===
                "4") && (
              <div className="cc-form-cols-1">
                <InputGlobalSettings
                  data={fieldHPECMApiAuthType}
                  isEditing={isEditing}
                />
              </div>
            )}
        </>
      ) : null}

      {fieldUseNewHPECMSDK && (
        <div className="cc-form-cols-1">
          <SwitchGlobalSettings
            data={fieldUseNewHPECMSDK}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
            disabled={isShowTrimApiSetting}
            customChecked={
              isShowTrimApiSetting
                ? true
                : valueGetter(fieldUseNewHPECMSDK?.FieldName)
            }
          />
        </div>
      )}

      {fieldTRIMUseImpersonation && (
        <div className="cc-form-cols-1">
          <SwitchGlobalSettings
            data={fieldTRIMUseImpersonation}
            isEditing={isEditing}
            formRenderProps={formRenderProps}
          />
        </div>
      )}

      {fieldTRIMClientBinariesLoadPath &&
        !valueGetter(fieldUseHPECMAPI?.FieldName ?? "") && (
          <div className="cc-form-cols-1">
            <InputGlobalSettings
              data={fieldTRIMClientBinariesLoadPath}
              isEditing={isEditing}
            />
          </div>
        )}
    </>
  );
});
