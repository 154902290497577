import { CENTRELINK_CUSTOMER_CONFIRMATION_VIEW_RESPONSE_ROUTE } from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/constant";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
import { VO_CentreLink_Response } from "@app/products/property/centrelink-customer-confirmation/view-responses/model";

const nameOf = nameOfFactory<VO_CentreLink_Response>();
export const colViewResponses: IColumnFields[] = [
  {
    field: nameOf("CRB_Request_DateTime"),
    title: "CentreLink Response Batch",
    format: DATETIME_FORMAT.DATETIME,
    locked: true,
    linkTo: (dataItem) =>
      `${CENTRELINK_CUSTOMER_CONFIRMATION_VIEW_RESPONSE_ROUTE}/${dataItem.Entity_Id}`,
  },
  {
    field: nameOf("ENA_Name"),
    title: "Name",
  },
  {
    field: nameOf("CR_CentreLink_Reference_Number"),
    title: "CentreLink Reference Number",
  },
  {
    field: nameOf("CMC_Description"),
    title: "Matched?",
  },
  {
    field: nameOf("CR_Confirmation_Date"),
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("CR_Pension_Concession_Card"),
    title: "Pension Concession",
  },
  {
    field: nameOf("CR_Postcode_Matched"),
    title: "Postcode Matched",
  },
  {
    field: nameOf("Assess_NumberX"),
    title: "Assessment Number",
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment Id",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Entity_Id"),
    title: "Entity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
