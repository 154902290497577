import { DeleteButton } from "@app/core/delete/buttons/_index";
import { InspectionContactsTab } from "@app/core/inspections/[id]/components/reference-sidebar/contacts/_index";
import { InspectionDetailsTab } from "@app/core/inspections/[id]/components/reference-sidebar/details/_index";
import { InspectionHistoryTab } from "@app/core/inspections/[id]/components/reference-sidebar/history/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { CrmsActionBarNavDropdown } from "@app/products/crms/components/action-bar/nav-dropdown/_index";
import { colByDate } from "@app/products/crms/inspections/by-date/config";
import { CRMSInspectionsByDateBookmark } from "@app/products/crms/inspections/by-date/util";
import {
  CRMS_INSPECTIONS_ROUTE,
  nameOfEventInspectionView,
} from "@app/products/crms/inspections/constant";
import { inspectionsRoute } from "@app/products/crms/inspections/route";
import {
  CRMSInspectionsBookmark,
  crmsInspectionsListViewURL,
} from "@app/products/crms/inspections/util";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { crmsRoute } from "@app/products/crms/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { getUUID } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";

const gridId = getUUID();

export default observer(() => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Inspection,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  useCCListViewActionBar({
    title: crmsRoute.name,
    leftComponents: [
      <CrmsActionBarNavDropdown category={inspectionsRoute.path} />,
    ],
    centerComponents: [
      // Implement later
      // <CCNavButton
      //   title="Change Inspection Type"
      //   disabled
      //   onClick={() => {}}
      // />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.CustomerService}
        recordType={RECORDTYPE.CORE_Inspection}
        dataSetFilter={MAILMERGEDATASET.CUSTOMERSERVICE_Event_Inspections} // check later
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <DeleteButton
        recordType={RECORDTYPE.CORE_Inspection}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        refreshGridIds={[gridId]}
        gridId={gridId}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={CRMS_INSPECTIONS_ROUTE}
        productType={PRODUCT_TYPE.CustomerService}
        recordType={RECORDTYPE.CORE_Inspection}
        detail={CRMSInspectionsBookmark.getBookmarkDetail}
        displayName={CRMSInspectionsBookmark.getBookmarkDisplayName}
        listViewDisplayName={
          CRMSInspectionsByDateBookmark.getBookmarkListViewDisplayName
        }
        listViewDetail={CRMSInspectionsByDateBookmark.getBookmarkListViewDetail}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <InspectionDetailsTab /> },
      {
        title: "Contacts",
        component: <InspectionContactsTab />,
      },
      {
        title: "History",
        component: <InspectionHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        gridId={gridId}
        columnFields={colByDate}
        primaryField={nameOfEventInspectionView("ID")}
        dataUrl={crmsInspectionsListViewURL(
          FormAction.CUSTOMERSERVICE_MENU_VIEW_INSPECTIONSBYDATE
        )}
      />
    </LoadingPermissionWrapper>
  );
});
