import { DTO_Journal_Summary } from "@app/products/property/journals/[id]/model";
import { eJournalType } from "@app/products/property/journals/list/components/dialogs/create-journal/components/form-elements/new-journal/model";
import {
  DATETIME_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCCurrencyInput } from "@components/cc-currency-input/_index";
import { CCDateTimePicker } from "@components/cc-date-time-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import {
  Field,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useMemo } from "react";

interface Props {
  formRenderProps: FormRenderProps;
}

export const PropertyJournalFormElement = observer(
  ({ formRenderProps }: Props): ReactElement => {
    const nameOf = nameOfFactory<DTO_Journal_Summary>();
    const { pushNotification } = useCCAppNotificationStore();
    const { valueGetter } = formRenderProps;
    const validateForm = (event: React.SyntheticEvent<any>) => {
      if (formRenderProps.modified) {
        formRenderProps.onSubmit(event);
        return;
      }
      if (!formRenderProps.valid) return;
      pushNotification({
        title: "The form is not modified",
        type: "warning",
      });
    };
    const isShowSupplementary = useMemo(() => {
      return valueGetter("Journal_Type") !== eJournalType["PIC Journal"];
    }, [valueGetter]);

    return (
      <FormElement className="cc-property-journal">
        {/* Reference by a label in WastewaterActionBar.tsx */}
        <input hidden type="submit" id="btn-submit" onClick={validateForm} />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Journal number</label>
              <Field
                name={nameOf("Journal_Number")}
                placeholder={"Journal number"}
                component={CCNumericTextBox}
                format={NUMBER_FORMAT.NUMBER2}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Date/Time</label>
              <Field
                name={nameOf("Jnl_DateTime")}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                component={CCDateTimePicker}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Reference</label>
              <Field
                name={nameOf("Jnl_Reference")}
                placeholder={"Reference"}
                component={CCInput}
                readOnly
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Description</label>
              <Field
                name={nameOf("Jnl_Description")}
                placeholder="Description"
                rows={3}
                component={CCTextArea}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Type</label>
              <Field
                name={nameOf("Journal_Type_Name")}
                placeholder={"Type"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Status</label>
              <Field
                name={nameOf("Journal_Status_Name")}
                component={CCInput}
                placeholder="Status"
                readOnly
              />
            </div>
          </div>
          {isShowSupplementary ? (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <label className="cc-label">Supplementary</label>
                <Field
                  name={nameOf("Supplementary_Id")}
                  placeholder={"Supplementary"}
                  component={CCInput}
                  readOnly
                />
              </div>
              <div className="cc-field">
                <label className="cc-label">Supplementary name</label>
                <Field
                  name={nameOf("Supp_Name")}
                  placeholder={"Supplementary name"}
                  component={CCInput}
                  readOnly
                />
              </div>
            </div>
          ) : null}
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Entered by</label>
              <Field
                name={nameOf("Officer_Name")}
                placeholder={"Entered by"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Authorised by</label>
              <Field
                name={nameOf("Authorised_By_Name")}
                placeholder={"Authorised by"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Authorised on</label>
              <Field
                name={nameOf("Jnl_Authorised_on")}
                format={DATETIME_FORMAT.DATETIME_CONTROL}
                component={CCDateTimePicker}
                disabled
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <label className="cc-label">Session</label>
              <Field
                name={nameOf("Session_Number")}
                placeholder={"Session"}
                component={CCInput}
                readOnly
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Number of items</label>
              <Field
                name={nameOf("Component_Number")}
                placeholder={"Number of items"}
                component={CCNumericTextBox}
                disabled
              />
            </div>
            <div className="cc-field">
              <label className="cc-label">Total</label>
              <Field
                name={nameOf("Journal_Total")}
                disabled
                defaultValue={0}
                component={CCCurrencyInput}
              />
            </div>
          </div>
        </section>
        <hr className="cc-divider" />
        <section className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <label className="cc-label">Notes</label>
              <Field
                name={nameOf("Jnl_Notes")}
                rows={3}
                component={CCTextArea}
                placeholder="Notes"
                readOnly
              />
            </div>
          </div>
        </section>
      </FormElement>
    );
  }
);
