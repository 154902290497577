import { loadReportItems } from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/api";
import CentrelinkCardsRequiringActions from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/components/child-screens/centrelink-cards-requiring-actions/_index";
import CentrelinkConcessionCards from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/components/child-screens/centrelink-concession-cards/_index";
import ExternalCardsRequiringActions from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/components/child-screens/external-cards-requiring-actions/_index";
import ExternalConcessionCards from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/components/child-screens/external-concession-cards/_index";
import { ViewResponseReferenceSideBarDetailTab } from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/components/reference-sidebar/detail/_index";
import {
  eChildScreenItem,
  ReportItemResponse,
  ViewResponse_Response,
} from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/model";
import {
  IPropertyPanelBar,
  PropertyPanelBar,
} from "@app/products/property/components/panel-bar/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { UpdateEntityDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/update-entity/_index";
import { eAccordion } from "@app/products/property/model";
import { DATETIME_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { AddActionButton } from "@common/pages/actions/components/action-bar/buttons/new-actions/_index";
import { formatDisplayValue } from "@common/utils/formatting";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCNotification } from "@components/cc-notification/_index";
import {
  ICCNotification,
  TYPE_NOTIFICATION,
} from "@components/cc-notification/components/cc-notification-item/model";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import React, { useEffect, useState } from "react";

interface IProps {
  isLoading: boolean;
  setIsLoading: (status: boolean) => void;
  viewResponseInfo: ViewResponse_Response | undefined;
}
const ExistedViewResponse = ({
  isLoading,
  setIsLoading,
  viewResponseInfo,
}: IProps) => {
  const [showSlideBar, setShowSlideBar] = useState(true);
  // Can use after for bookmark feature
  // const managePageUrl = window.location.pathname;
  // const getBookmarkDetail = useMemo(() => {
  //   const dynamicDetail = viewResponseInfo?.CentreLinkResponseBatch ?? "";
  //   return `${dynamicDetail}`;
  // }, [viewResponseInfo]);
  // const bookmarkList: IBookmarkItem[] = [
  //   {
  //     detail: getBookmarkDetail,
  //     url: managePageUrl,
  //     displayName: `Property - Centrelink Customer Confirmation - View Responses - ${id}`,
  //   },
  // ];
  const [reportItems, setReportItems] = useState<
    ReportItemResponse[] | undefined
  >();
  const [showUpdateEntityDialog, setShowUpdateEntityDialog] = useState(false);
  const [entityData, setEntityData] = useState<any>(undefined);
  const [notifications, setNotifications] = useState<ICCNotification[]>([]);
  const [itemExpanded, setItemExpanded] = useState(
    eChildScreenItem.CENTRELINK_CONCESSION_CARDS
  );

  useEffect(() => {
    loadReportItems(itemExpanded).then((data) => {
      if (!data) {
        return;
      }
      setReportItems(data);
    });
  }, [itemExpanded]);

  const getFormTitle = () => {
    return `${formatDisplayValue(
      viewResponseInfo?.CentreLinkResponseBatch,
      DATETIME_FORMAT.DATETIME
    )}`;
  };

  const handleOnClickName = (dataItem: any) => {
    setEntityData({
      ...entityData,
      Delivery: "Standard",
      Surname: dataItem?.Name?.split(", ")[1] || "",
      GivenName: dataItem?.Name?.split(",")[0] || "",
    });
    setShowUpdateEntityDialog(true);
  };

  const handleOnSelect = (_expandedState: boolean[], currentIndex?: number) => {
    let currentTitlePanel: eChildScreenItem =
      eChildScreenItem.CENTRELINK_CONCESSION_CARDS; //default -  CENTRELINK_CONCESSION_CARDS
    //Retrieve the title of the currently expanded panel
    const currentPanel = currentIndex && listPanelBar[currentIndex];
    if (currentPanel && !isNil(currentPanel.title)) {
      currentTitlePanel = currentPanel.title as eChildScreenItem;
    }
    setItemExpanded(currentTitlePanel);
  };

  const listPanelBar: IPropertyPanelBar[] = [
    {
      accordionId: eAccordion.Centrelink_Response_Centrelink_Concession_Cards,
      title: eChildScreenItem.CENTRELINK_CONCESSION_CARDS,
      component: (
        <CentrelinkConcessionCards handleOnClickName={handleOnClickName} />
      ),
      isExpanded: true,
    },
    {
      accordionId:
        eAccordion.Centrelink_Response_Centrelink_Cards_Requiring_Actions,
      title: eChildScreenItem.CENTRELINK_CARDS_REQUIRING_ACTIONS,
      component: (
        <CentrelinkCardsRequiringActions
          handleOnClickName={handleOnClickName}
        />
      ),
    },
    {
      accordionId: eAccordion.Centrelink_Response_External_Concession_Cards,
      title: eChildScreenItem.EXTERNAL_CONCESSION_CARDS,
      component: (
        <ExternalConcessionCards handleOnClickName={handleOnClickName} />
      ),
    },
    {
      accordionId:
        eAccordion.Centrelink_Response_External_Cards_Requiring_Actions,
      title: eChildScreenItem.EXTERNAL_CARDS_REQUIRING_ACTIONS,
      component: (
        <ExternalCardsRequiringActions handleOnClickName={handleOnClickName} />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading} />
      <FormNavigation title="CENTRELINK RESPONSE" />
      <FormTitle title={getFormTitle()} />
      <CCManagePageActionBar
        leftActions={[
          <CCNavButton
            title={"Workflow"}
            type="sub-workflow"
            className="cc-workflow-button"
          >
            <CCNavButton title={"Allocate to planner"} />
            <CCNavButton title={"Failure"} />
            <CCNavButton title={"Revision received"} />
            <CCNavButton title={"Withdraw application"} />
          </CCNavButton>,
          <CCNavButton title={"Save"} htmlFor={"btn-submit"} />,
          <CCNavButton title={"Add"} type="sub">
            <AddActionButton />
            <CCNavButton title={"Add comments"} />
            <CCNavButton title={"Add contacts"} />
            <CCNavButton title={"Add documents"} />
            <CCNavButton title={"Add fees"} />
            <CCNavButton title={"Add samples"} />
            <CCNavButton title={"Add inspection"} />
          </CCNavButton>,
          <CCNavButton title={"View"} />,
          <CCNavButton title={"Print"} />,
          <CCNavButton title={"Report"} type="sub" className="cc-report-button">
            {reportItems?.map((item) => {
              return (
                <CCNavButton title={item.Title} disabled={item.IsDisable} />
              );
            })}
          </CCNavButton>,
          <CCNavButton title={"More Options"} type="more">
            <CCNavButton title={"ePlanning - development"} />
            <CCNavButton title={"Portal links"} />
            <CCNavButton title={"Certification"} />
          </CCNavButton>,
        ]}
        rightActions={[
          <CCNavButton
            title={"Slide Bar"}
            iconClass="fal fa-info-circle"
            isActive={showSlideBar}
            onClick={() => {
              setShowSlideBar(!showSlideBar);
            }}
          />,
          //  Holding now but can use later
          // <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
          <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
        ]}
      />
      <div className="cc-manage-container">
        <div className="cc-form cc-manage-left">
          <CCNotification newNotifications={notifications} />
          <div className="cc-manage-form-body">
            <PropertyPanelBar
              initialPanels={listPanelBar}
              sort={false}
              onChangeExpandedState={handleOnSelect}
            />
          </div>
        </div>
        {showSlideBar && (
          <div className="cc-manage-right">
            <ReferenceSideBar
              components={[
                {
                  title: "Details",
                  component: <ViewResponseReferenceSideBarDetailTab />,
                },
                {
                  title: "History",
                  component: (
                    <PropertyHistoryTab
                      //TODO:Update enum when implement this page
                      recordType={
                        RECORDTYPE.CommunityProperty_CommunityCentral_Other
                      }
                    />
                  ),
                },
              ]}
            />
          </div>
        )}
        {showUpdateEntityDialog ? (
          <UpdateEntityDialog
            currentEntity={entityData}
            onClose={() => {
              setShowUpdateEntityDialog(false);
            }}
            onSubmit={() => {
              setShowUpdateEntityDialog(false);
              setNotifications([
                {
                  isAutoClose: true,
                  primaryContent: `Edit Entity successfully`,
                  type: TYPE_NOTIFICATION.SUCCESS,
                },
              ]);
            }}
            title={"Edit Entity"}
          />
        ) : null}
      </div>
    </>
  );
};

export default ExistedViewResponse;
