import { eventEmitter } from "@/App";
import { CCJournalEventType } from "@app/core/journal/util";
import { postViewDocument } from "@app/products/property/components/action-bar/button/view-document/api";
import { PropertyDocumentEventType } from "@app/products/property/components/child-screen/documents/constant";
import { useDocumentIssuedStore } from "@app/products/property/components/grid/document-issued-grid/store";
import { isSuccessResponse } from "@common/apis/util";
import { DownloadFile } from "@common/constants/downloadfile";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export const ViewDocumentButton = observer(() => {
  const { documentIssueSelectedRow } = useDocumentIssuedStore();
  const { pushNotification } = useCCAppNotificationStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnclick = async () => {
    if (!documentIssueSelectedRow?.Component_Document_Id) {
      return;
    }
    setIsLoading(true);
    const response = await postViewDocument(
      documentIssueSelectedRow?.Component_Document_Id
    );
    if (isSuccessResponse(response) && response?.data?.IsSuccess) {
      DownloadFile(
        response.data?.File ?? "",
        response.data?.ContentType ?? "",
        response.data?.FileName ?? "document"
      );
      //Refresh document accordion after viewed successfully
      eventEmitter.emit(PropertyDocumentEventType.RefreshData);
      // Refresh History
      eventEmitter.emit(CCJournalEventType.RefreshData);
      pushNotification({
        title: "Document download successfully.",
        type: "success",
      });
    } else {
      pushNotification({
        autoClose: false,
        description:
          response?.data?.ErrorMessage ??
          response?.error ??
          "Document download failed.",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <CCNavButton
      title="View document"
      disabled={isNil(documentIssueSelectedRow)}
      onClick={handleOnclick}
      isLoading={isLoading}
    />
  );
});
