import { loadViewConfiguresColumns } from "@app/products/property/api";
import { centrelinkCustomerConfirmationRoute } from "@app/products/property/centrelink-customer-confirmation/route";
import { ViewResponseReferenceSideBarDetailTab } from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/components/reference-sidebar/detail/_index";
import { DeleteBatchButton } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/buttons/delete-batch/_index";
import { ImportRejectionButton } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/buttons/import-rejection/_index";
import { ImportResponseButton } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/buttons/import-response/_index";
import { colViewResponses } from "@app/products/property/centrelink-customer-confirmation/view-responses/config";
import { CENTRELINKCUSTOMERCONFIRMATION_VIEWRESPONSES_LIST_VIEW_URL } from "@app/products/property/centrelink-customer-confirmation/view-responses/constant";
import { VO_CentreLink_Response } from "@app/products/property/centrelink-customer-confirmation/view-responses/model";
import { PropertyActionBarNavDropdown } from "@app/products/property/components/action-bar/nav-dropdown/_index";
import { PropertyHistoryTab } from "@app/products/property/components/reference-sidebar/property-history/_index";
import { ViewConfiguration } from "@app/products/property/model";
import { propertyRoute } from "@app/products/property/route";
import { APIResponseError } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import Loading from "@components/loading/Loading";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<VO_CentreLink_Response>();
export default () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responseLoadError, setResponseLoadError] = useState<
    APIResponseError | undefined
  >();
  const [colsConfigured, setColsConfigured] = useState<IColumnFields[]>([]);

  useCCListViewActionBar({
    title: propertyRoute.name,
    leftComponents: [
      <PropertyActionBarNavDropdown
        category={centrelinkCustomerConfirmationRoute.path}
      />,
    ],
    centerComponents: [
      <CCNavButton
        title={"Workflow"}
        type="sub-workflow"
        className="cc-workflow-button"
      >
        <ImportResponseButton />
        <ImportRejectionButton />
        <DeleteBatchButton />
      </CCNavButton>,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      //  Holding now but can use later
      // <ListViewBookmarkIcon
      //   url={CENTRELINK_CUSTOMER_CONFIRMATION_VIEW_RESPONSE_ROUTE}
      //   displayName={getBookmarkDisplayName}
      //   detail={getBookmarkDetail}
      // />,
      <CCNavButton iconClass="fal fa-bookmark" title="Bookmark" />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <ViewResponseReferenceSideBarDetailTab />,
      },
      {
        title: "History",
        component: (
          <PropertyHistoryTab
            //TODO:Update enum when implement this page
            recordType={RECORDTYPE.CommunityProperty_CommunityCentral_Other}
          />
        ),
      },
    ],
  });

  const loadViewConfig = async () => {
    setIsLoading(true);
    setResponseLoadError(undefined);
    const response = await loadViewConfiguresColumns(
      ViewConfiguration.CentrelinkCustomerConfirmation_ViewResponses,
      colViewResponses
    );
    if (Array.isArray(response)) {
      setColsConfigured(response);
    } else {
      setResponseLoadError(response);
    }
    setIsLoading(false);
  };

  useEffectOnce(() => {
    loadViewConfig();
  });

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (responseLoadError)
    return (
      <CCLoadFailed
        responseError={responseLoadError}
        onReload={() => {
          loadViewConfig();
        }}
      />
    );

  return (
    <CCProductListView
      dataUrl={CENTRELINKCUSTOMERCONFIRMATION_VIEWRESPONSES_LIST_VIEW_URL}
      columnFields={colsConfigured}
      primaryField={nameOf("Entity_Id")}
      state={{ sort: [{ field: nameOf("CRB_Request_DateTime"), dir: "desc" }] }}
    />
  );
};
