import { useDataSetting } from "@app/products/town-planning/ppr/system-admin/settings/_id/components/child-screens/assessment/hook/useDataSetting";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { HelpSettingMethod } from "@common/models/settings";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { IGlobalComponentFormProps } from "@common/pages/settings/system-admin/global-settings/_id/main/config";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { SwitchGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/switch/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export default observer(({ formRenderProps }: IGlobalComponentFormProps) => {
  const { valueGetter } = formRenderProps;
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();

  const data = useDataSetting(configData, [
    "CorporateSettings_HelpSettingMethod",
    "CorporateSettings_HelpSettingConfluence_WidgetID",
    "CorporateSettings_HelpSettingConfluence_ClientKey",
    "CorporateSettings_HelpSettingAirtime_IsUseURL",
    "CorporateSettings_HelpSettingAirtime_URL",
    "CorporateSettings_HelpSettingAirtime_WidgetId",
    "CorporateSettings_HelpSettingAirtime_ScriptSource",
    "CorporateSettings_HelpSettingExternal_URL",
  ]);

  const helpSettingMethod = valueGetter(
    ECorporateSettingsField.CorporateSettings_HelpSettingMethod.toString()
  );

  const airtimeIsUseURL = valueGetter(
    ECorporateSettingsField.CorporateSettings_HelpSettingAirtime_IsUseURL.toString()
  );

  return (
    <>
      <section className="cc-field-group">
        <div className="cc-form-cols-3">
          <CCSearchComboBoxGlobalSettings
            data={data.CorporateSettings_HelpSettingMethod}
            isEditing={isEditing}
          />
          {helpSettingMethod === HelpSettingMethod.Airtime.toString() && (
            <SwitchGlobalSettings
              data={data.CorporateSettings_HelpSettingAirtime_IsUseURL}
              isEditing={isEditing}
              formRenderProps={formRenderProps}
            />
          )}
        </div>
        {helpSettingMethod === HelpSettingMethod.Confluence.toString() && (
          <div className="cc-form-cols-3">
            <InputGlobalSettings
              data={data.CorporateSettings_HelpSettingConfluence_WidgetID}
              isEditing={isEditing}
            />
            <InputGlobalSettings
              data={data.CorporateSettings_HelpSettingConfluence_ClientKey}
              isEditing={isEditing}
            />
          </div>
        )}
        {helpSettingMethod === HelpSettingMethod.Airtime.toString() && (
          <div className="cc-form-cols-3">
            {airtimeIsUseURL ? (
              <InputGlobalSettings
                data={data.CorporateSettings_HelpSettingAirtime_URL}
                isEditing={isEditing}
              />
            ) : (
              <>
                <InputGlobalSettings
                  data={data.CorporateSettings_HelpSettingAirtime_WidgetId}
                  isEditing={isEditing}
                />
                <InputGlobalSettings
                  data={data.CorporateSettings_HelpSettingAirtime_ScriptSource}
                  isEditing={isEditing}
                />
              </>
            )}
          </div>
        )}
        {helpSettingMethod === HelpSettingMethod.External.toString() && (
          <div className="cc-form-cols-3">
            <InputGlobalSettings
              data={data.CorporateSettings_HelpSettingExternal_URL}
              isEditing={isEditing}
            />
          </div>
        )}
      </section>
    </>
  );
});
