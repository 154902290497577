import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { ANIMALS_ROUTE } from "@app/products/animals/[id]/constant";
import { useAnimalStore } from "@app/products/animals/[id]/store";
import { NewAnimalButton } from "@app/products/animals/components/action-bar/buttons/new-animal/_index";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { AnimalsContactsTab } from "@app/products/animals/components/reference-sidebar/contacts/_index";
import { AnimalDetailsTab } from "@app/products/animals/components/reference-sidebar/details/_index";
import { AnimalsHistoryTab } from "@app/products/animals/components/reference-sidebar/history/_index";
import {
  AnimalsApiUrl,
  IAddAdditionalAddressButton,
  generateAnimalsViewBySetting,
} from "@app/products/animals/config";
import { AnimalsRegister } from "@app/products/animals/model";
import { AnimalRegisterDangerousDogsBookmark } from "@app/products/animals/register/dangerous-dogs/util";
import { animalRoute } from "@app/products/animals/route";
import { AnimalBookmark } from "@app/products/animals/util";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useCommonCoreStore } from "@common/stores/core/store";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { MapTab } from "@components/cc-reference-sidebar/components/Map/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import { registerRoute } from "../route";
import { colAnimalsRegisterDangerousDog } from "./config";

const nameOf = nameOfFactory<AnimalsRegister>();
export default observer(() => {
  const { settings } = useCommonCoreStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Animals_Form_Registration,
    productType: PRODUCT_TYPE_NUMBER.Animals,
  });

  const { animalInfo } = useAnimalStore();

  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={registerRoute.path} />,
    ],
    centerComponents: [
      <NewAnimalButton
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        dataSetFilter={MAILMERGEDATASET.Animals_Registration}
        recordType={RECORDTYPE.Animals_Registration}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        recordType={RECORDTYPE.Animals_Registration}
        keyField={nameOf("ID")}
      />,
      /* TODO: Not implement yet
      <DeactivateButton />, */
      <DeleteButton
        recordType={RECORDTYPE.Animals_Registration}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={ANIMALS_ROUTE}
        productType={PRODUCT_TYPE.Animals}
        recordType={RECORDTYPE.Animals_Registration}
        detail={AnimalBookmark.getBookmarkDetail}
        displayName={AnimalBookmark.getBookmarkDisplayName}
        listViewDetail={
          AnimalRegisterDangerousDogsBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          AnimalRegisterDangerousDogsBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <AnimalDetailsTab /> },
      {
        title: "Map",
        component: (
          <MapTab
            address={animalInfo?.Address?.Formatted_SingleLine}
            zoom={18}
            recordType={RECORDTYPE.Animals_Registration}
            isInManagePage={false}
          />
        ),
      },
      { title: "Contacts", component: <AnimalsContactsTab /> },
      {
        title: "History",
        component: <AnimalsHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={generateAnimalsViewBySetting({
          settings,
          defaultConfig: colAnimalsRegisterDangerousDog,
        } as IAddAdditionalAddressButton)}
        state={{
          sort: [{ field: nameOf("ID"), dir: "desc" }],
        }}
        dataUrl={AnimalsApiUrl.GET_VIEW_ANIMALS_REGISTER_DANGEROUSDOGS}
        primaryField={nameOf("ID")}
      />
    </LoadingPermissionWrapper>
  );
});
