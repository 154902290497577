import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import {
  EPrivilegesWorkflow,
  WorkflowTypes,
} from "@app/products/property/model";
import { useSupplementaryRatesMasterPropertyStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/store";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const MasterPropertyAdjustmentButton = observer(() => {
  const { supplementaryAssessments } = useSupplementaryRatesStore();
  const { setListDialog } = usePropertyWorkflowStore();
  const { masterPropertySelected } = useSupplementaryRatesMasterPropertyStore();
  const isDisabled = useMemo(() => {
    return !(
      supplementaryAssessments?.SupplementaryDetail?.IsComplete === false &&
      masterPropertySelected.length === 1
    );
  }, [
    masterPropertySelected.length,
    supplementaryAssessments?.SupplementaryDetail?.IsComplete,
  ]);

  return (
    <PrivilegeWrapper
      dialogType={ePermissionPrivilegeTypeCheck.Workflow}
      privilegesEnum={EPrivilegesWorkflow.MasterPropertyAdjustment}
      // TODO: Add the correct privileges
    >
      <CCNavButton
        title="Master property adjustment"
        disabled={isDisabled}
        onClick={() =>
          setListDialog([
            {
              type: WorkflowTypes.MasterPropertyAdjustment,
              // TODO: Add the correct WorkflowTypes
              data: {},
            },
          ])
        }
      />
    </PrivilegeWrapper>
  );
});
