import { getParcelZones } from "@app/products/property/parcels/[id]/components/child-screens/zones/api";
import { mockParcelZones } from "@app/products/property/parcels/[id]/components/child-screens/zones/mock";
import { DTO_Parcel_Zones } from "@app/products/property/parcels/[id]/components/child-screens/zones/model";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";

configure({ enforceActions: "always" });
class ParcelZonesStore {
  private _parcelZones?: DTO_Parcel_Zones = undefined;
  private _isLoading: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  get isLoading() {
    return toJS(this._isLoading);
  }

  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get parcelZones() {
    return toJS(this._parcelZones);
  }

  setParcelZones = (parcelZones?: DTO_Parcel_Zones) => {
    runInAction(() => {
      this._parcelZones = parcelZones;
    });
  };

  //Action
  resetStore = () => {
    this.setParcelZones(undefined);
    this.setIsLoading(false);
  };

  loadParcelZones = async (parcelId: number) => {
    this.setIsLoading(true);
    const newZones = await getParcelZones(parcelId);
    // this.setParcelZones(newZones);
    /* ====  TODO: Write tmp mock for demo  ==== */
    this.setParcelZones({
      ...newZones,
      Zones: mockParcelZones,
    } as DTO_Parcel_Zones);
    /* ====  /TODO: Write tmp mock for demo  ==== */
    this.setIsLoading(false);
  };
}

const parcelZonesStoreContext = createContext(new ParcelZonesStore());
export const useParcelZonesStore = () => {
  return useContext(parcelZonesStoreContext);
};
