import { DTO_Supplementary_Details } from "@app/products/property/supplementary-rates/[id]/components/child-screens/detail/model";
import { getSupplementaryRatesDetailsTabById } from "@app/products/property/supplementary-rates/[id]/components/reference-sidebar/detail/api";
import { DATE_FORMAT } from "@common/constants/common-format";
import { formatDateByKendo } from "@common/utils/formatting";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export const SupplementaryRatesDetailTab = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [supplementaryRatesInfo, setSupplementaryRatesInfo] = useState<
    DTO_Supplementary_Details | undefined
  >();

  const { lastSelectedRow } = useCCProductListViewStore();
  const params: { id: string } = useParams();
  const supplementaryRatesId: string =
    params.id ?? lastSelectedRow?.Supplementary_Id?.toString();

  useEffect(() => {
    if (supplementaryRatesId) {
      setIsLoading(true);
      getSupplementaryRatesDetailsTabById(+supplementaryRatesId).then(
        (response) => {
          setSupplementaryRatesInfo(response.data);
          setIsLoading(false);
        }
      );
    }
  }, [supplementaryRatesId]);

  if (isLoading) return <Loading isLoading={isLoading} />;
  if (!supplementaryRatesInfo) return <NoData />;
  return (
    <ProductReferenceBody>
      <PanelBar expandMode={"single"}>
        <PanelBarItem
          expanded={true}
          title={
            <ProductReferenceHeading
              title={"Supplementary:"}
              value={supplementaryRatesInfo?.SupplementaryId?.toString() ?? ""}
              primary
            />
          }
        >
          <>
            <ProductReferenceBlock>
              <ProductReferenceRow
                title={"Type:"}
                value={supplementaryRatesInfo?.SupplementaryDetail?.Type}
              />
              <ProductReferenceRow
                title={"Name:"}
                value={supplementaryRatesInfo?.SupplementaryDetail?.Name}
              />
              <ProductReferenceRow
                title={"Reference:"}
                value={supplementaryRatesInfo?.SupplementaryDetail?.Reference}
              />
              <ProductReferenceRow
                title={"Date:"}
                value={formatDateByKendo(
                  supplementaryRatesInfo?.SupplementaryDetail?.Date,
                  DATE_FORMAT.DATE
                )}
              />
              <ProductReferenceRow
                title={"Journal number:"}
                value={
                  supplementaryRatesInfo?.SupplementaryDetail?.JournalNumber
                }
              />
            </ProductReferenceBlock>
          </>
        </PanelBarItem>
      </PanelBar>
    </ProductReferenceBody>
  );
});
