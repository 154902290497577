import { CCDialog } from "@components/cc-dialog/_index";
import { Button } from "@progress/kendo-react-buttons";
import React from "react";

interface ICreateRequestsDialog {
  onClose: () => void;
  onSubmit?: () => void;
}
export interface IDataGridDialog {
  SessionNumber: number;
  Name: string;
}
export const ConfirmDontVerifySelectedCards = ({
  onClose,
  onSubmit,
}: ICreateRequestsDialog) => {
  return (
    <div className="cc-confirm-dont-verify-selected-cards-dialog">
      <CCDialog
        height="auto"
        maxWidth="30%"
        unsetMinHeight={true}
        titleHeader={"Confirm Removal"}
        onClose={onClose}
        bodyElement={
          <div className="cc-form">
            <div className="cc-field-group">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  Are you sure you want to remove the selected cards?
                </div>
              </div>
            </div>
          </div>
        }
        footerElement={
          <div className={"cc-dialog-footer-actions-right"}>
            <Button className={"cc-dialog-button"} onClick={onClose}>
              Cancel
            </Button>

            <Button
              className={"cc-dialog-button"}
              themeColor="primary"
              onClick={onSubmit}
            >
              OK
            </Button>
          </div>
        }
      />
    </div>
  );
};
