import { FinanceFeeDetailTab } from "@app/core/fees/[id]/components/reference-sidebar/details/_index";
import { FinanceFeeHistoryTab } from "@app/core/fees/[id]/components/reference-sidebar/history/_index";
import { FINANCE_FEE_MANAGE_ROUTE } from "@app/core/fees/[id]/constant";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import { LL_FINANCE_GET_ALL_INVOICE_ITEM_URL } from "@app/products/local-laws/finance/constant";
import { nameOfPermitFees } from "@app/products/local-laws/finance/model";
import { financeRoute } from "@app/products/local-laws/finance/route";
import { llFinanceBookmark } from "@app/products/local-laws/finance/util";
import { localLawsRoute } from "@app/products/local-laws/route";
import { FINANCEMETHOD } from "@common/constants/enumerations";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import {
  getBoolValueSetting,
  getNumberValueSetting,
} from "@common/stores/products/util";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React from "react";
import { colLocalLawsFinanceFeesAll } from "./config";

export default observer(() => {
  const { settings } = useCommonCoreStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Fees,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  const financeMethod = getNumberValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_FinanceMethod]
  );
  const isEnableUpToGlassFinance = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableUpToGlassFinance]
  );
  const isEnableMultiLineInvoice = getBoolValueSetting(
    settings[ECorporateSettingsField.CorporateSettings_EnableMultiLineInvoice]
  );
  const enableUpToGlassFinance =
    isEnableUpToGlassFinance && financeMethod === FINANCEMETHOD.Direct;
  const viewName =
    enableUpToGlassFinance && isEnableMultiLineInvoice
      ? "Invoice Items"
      : "Fee History";

  const { listViewDisplayURL, recordDisplayURL, listViewDisplayTitle } =
    llFinanceBookmark(viewName);

  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={financeRoute.path} />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={FINANCE_FEE_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.LLPermits}
        recordType={RECORDTYPE.CORE_Fees}
        detail={listViewDisplayURL}
        displayName={recordDisplayURL}
        listViewDetail={listViewDisplayTitle}
        listViewDisplayName={listViewDisplayURL}
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <FinanceFeeDetailTab /> },
      {
        title: "History",
        component: <FinanceFeeHistoryTab />,
      },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        dataUrl={LL_FINANCE_GET_ALL_INVOICE_ITEM_URL}
        columnFields={colLocalLawsFinanceFeesAll}
        primaryField={nameOfPermitFees("ID")}
      />
    </LoadingPermissionWrapper>
  );
});
