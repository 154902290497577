import { useIsNew } from "@common/hooks/useIsNew";
import { useMeetingCompleteButtonStore } from "@common/pages/actions/components/action-bar/buttons/meeting-complete/store";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export const ActionMeetingCompleteButton = observer(() => {
  const { action, onSubmit, isMeeting } = useActionStore();
  const { isLoading } = useMeetingCompleteButtonStore();
  const isNew = useIsNew();

  const isVisible = useMemo(() => {
    const isApproval = action?.ActionType?.IsApproval;
    if (
      (isNew || action?.Status_ENUM === ACTIONSTATUS.NEW) &&
      !action?.ActionType?.IsRegulatoryAction
    ) {
      if (!isApproval && (isMeeting || action?.ActionType?.Flag_IsMeeting)) {
        return true;
      }
    }

    return false;
  }, [action, isNew, isMeeting]);

  return isVisible ? (
    <CCNavButton
      title="Meeting complete"
      onClick={onSubmit}
      name={
        isNew
          ? ActionSubmitActions.CreateAndMeetingComplete
          : ActionSubmitActions.MeetingComplete
      }
      isLoading={isLoading}
    />
  ) : null;
});
