import { FormatNameAndAddressDialog } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/change-of-ownership/components/dialogs/format-name-and-address/_index";
import { NewContactDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/new-contact/_index";
import { UpdateEntityDialog } from "@app/products/property/contacts-central-names/list/components/dialogs/update-entity/_index";
import { SearchName } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/names/components/search-name/_index";
import {
  colAccountNames,
  fieldName,
  listSearchOptions,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/names/config";
import { listRole } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/names/mock";
import {
  IAccountNames,
  NameRole,
} from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/names/model";
import { validatorNames } from "@app/products/property/registers/[id]/components/forms/existed/components/form-steps/change-of-name-addr/components/form-elements/names/util";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { nameOfFactory } from "@common/utils/common";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle,
} from "@components/cc-app-notification/_index";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { IColumnFields } from "@components/cc-grid/model";
import { moveGridRow } from "@components/cc-grid/util";
import { CCTextArea } from "@components/cc-text-area/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  DropDownList,
  DropDownListChangeEvent,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import { Field, FieldArray } from "@progress/kendo-react-form";
import React, { useCallback, useEffect, useRef, useState } from "react";

export const ACCOUNT_NAMES_FORM_STEP = "Names";

const nameOfAccountNames = nameOfFactory<IAccountNames>();

export const AccountNamesFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={validatorNames}
    />
  );
};
export const FormStepElement = ({
  formRenderProps,
  nameOf,
}: IFormStepElement) => {
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const { valueGetter, onChange } = formRenderProps;
  const getFieldValue = (name: string) => valueGetter(nameOf(name));
  const namesValue = getFieldValue("Names");
  const selectedName = getFieldValue("NamesSelected");
  const [formatNameAddressDialog, setFormatNameAddressDialog] =
    useState<boolean>(false);
  const [newContactData, setNewContactData] = useState<any>();
  const [updateEntityData, setUpdateEntityData] = useState<any>();

  const handleAddName = (values: any) => {
    if (values?.IsExist === false) {
      setNewContactData(values);
      return;
    }

    const newNamesValue = namesValue ? [...namesValue] : [];
    if (
      !newNamesValue.find(
        (name) => name[fieldName.PrimaryKey] === values[fieldName.PrimaryKey]
      )
    ) {
      newNamesValue.unshift({
        [fieldName.Role]: NameRole.RESERVATION_CONTACT,
        [fieldName.Name]: values[fieldName.Name],
        [fieldName.Address]: values[fieldName.Address],
        [fieldName.PrimaryKey]: values[fieldName.PrimaryKey],
      });
      onChange(nameOf(fieldName.Names), { value: newNamesValue });
    } else {
      notificationRef.current?.pushNotification({
        title: `${values.Name} already exists`,
        type: "warning",
      });
    }
    onChange(nameOf(fieldName.SearchName), { value: null });
  };

  const handleRemoveName = (name: any) => {
    if (name) {
      const newNames = namesValue.filter(
        (item: any) => item[fieldName.PrimaryKey] !== name[fieldName.PrimaryKey]
      );
      if (newNames[0]) {
        handleGridSelectionChange([newNames[0]], fieldName.NameSelected);
      } else {
        handleGridSelectionChange([], fieldName.NameSelected);
      }
      onChange(nameOf(fieldName.Names), { value: newNames });
    }
  };

  const handleGridSelectionChange = (dataItem: any, field: string) => {
    const newSelected = dataItem ? dataItem[0] : undefined;
    onChange(nameOf(field), {
      value: newSelected,
    });
    if (newSelected && field === fieldName.NameSelected) {
      onChange(nameOf(fieldName.Role), {
        value: getRoleBaseOnRoleName(newSelected[fieldName.Role]),
      });
    }
  };

  const getRoleBaseOnRoleName = (roleName: string) => {
    return listRole.find((item: IKeyValuePacket) => item.Value === roleName);
  };

  const handleNameInfoChange = (field: string, value: any) => {
    onChange(nameOf(field), {
      value: value,
    });

    if (selectedName) {
      const newNames = namesValue.map((name: any) => {
        if (name[fieldName.PrimaryKey] !== selectedName[fieldName.PrimaryKey])
          return name;
        name[field] = field === fieldName.Role ? value.Value : value;
        return name;
      });
      onChange(nameOf(fieldName.Names), { value: newNames });
    }
  };

  const handleChangeFormatted = useCallback(
    (gridData: any[], field: string) => {
      let newNames = "";
      let newAddress: string | null = null;
      if (!gridData) return;
      gridData.forEach((item: any) => {
        if (item[fieldName.Name]) {
          newNames += `\r\nand ` + item[fieldName.Name];
          if (!newAddress && item[fieldName.Address])
            newAddress = item[fieldName.Address].replace("\r\n", "");
        }
      });

      if (newNames.length) newNames = newNames.slice(6);

      onChange(nameOf(field), {
        value: newNames + `\r\n` + (newAddress || ""),
      });
    },
    [onChange, nameOf]
  );

  useEffect(() => {
    handleChangeFormatted(namesValue, fieldName.FormattedNameAndAddress);
  }, [namesValue, handleChangeFormatted]);

  const handleChangeEntity = (values: any, isNew?: boolean) => {
    const {
      GivenNames,
      Surname,
      HouseNo,
      StreetName,
      StreetType,
      AdditionalAddress,
      Locality,
      State,
      PostCode,
    } = values?.Person ?? {};

    const newName = {
      [fieldName.Role]: NameRole.RESERVATION_CONTACT,
      [fieldName.Name]:
        (GivenNames ? `${GivenNames}, ` : "") + (Surname ? `${Surname}` : ""),
      [fieldName.Address]:
        (HouseNo ? HouseNo : "") +
        (StreetName ? ` ${StreetName}` : "") +
        (StreetType ? ` ${StreetType}` : "") +
        (AdditionalAddress ? `${AdditionalAddress}` : "") +
        (Locality ? ` ${Locality}` : "") +
        (State ? ` ${State}` : "") +
        (PostCode ? ` ${PostCode}` : ""),
      [fieldName.PrimaryKey]: Math.random() * 100 + 100,
    };

    if (newName[fieldName.Name] === "") return;
    if (isNew) {
      handleAddName(newName);
    } else {
      handleNameInfoChange(fieldName.Name, newName[fieldName.Name]);
      handleNameInfoChange(fieldName.Address, newName[fieldName.Address]);
    }
  };

  const renderNamesColumn = (cols: IColumnFields[]) => {
    return cols.map((col: IColumnFields) => {
      if (col.field === fieldName.Name) {
        col.handleOnClick = (dataItem: any) => {
          onChange(nameOf(fieldName.NameSelected), {
            value: dataItem,
          });
          setUpdateEntityData(dataItem);
        };
      }
      return col;
    });
  };

  return (
    <>
      <CCLocalNotification ref={notificationRef} />
      <section className="cc-field-group">
        <div className="cc-form-cols-2">
          <SearchName
            fieldName={nameOf("SearchName")}
            onChange={handleAddName}
            formRenderProps={formRenderProps}
            nameOf={nameOf}
          />

          <div className="cc-field">
            <label className="cc-label">Search options</label>
            <Field
              name={nameOf("SearchOptions")}
              dataItemKey="Key"
              textField="Value"
              data={listSearchOptions}
              component={MultiSelect}
            />
          </div>
          {selectedName && (
            <div className="cc-field">
              <label className="cc-label">Role</label>
              <Field
                name={nameOf(fieldName.Role)}
                data={listRole}
                dataItemKey="Key"
                textField="Value"
                component={DropDownList}
                onChange={(event: DropDownListChangeEvent) => {
                  handleNameInfoChange(fieldName.Role, event.value);
                }}
              />
            </div>
          )}
        </div>
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <label className="cc-label">
              Account names
              <CCTooltip
                type="validator"
                position="right"
                content="You must have at least one record to go to the next step"
              />
            </label>
            <CCGrid
              data={namesValue}
              selectedRows={selectedName ? [selectedName] : undefined}
              primaryField={nameOfAccountNames("EntityNameAddrId")}
              selectableMode="single"
              columnFields={renderNamesColumn(colAccountNames)}
              onSelectionChange={(dataItem: any) => {
                handleGridSelectionChange(dataItem, fieldName.NameSelected);
              }}
              toolbar={
                <div className="cc-grid-tools-bar">
                  <Button
                    iconClass="fas fa-minus"
                    title="Remove Entity from list"
                    disabled={!selectedName}
                    onClick={() => {
                      handleRemoveName(selectedName);
                    }}
                  />
                  <Button
                    disabled={!selectedName}
                    iconClass="fas fa-chevron-up"
                    title="Move up"
                    onClick={() => {
                      onChange(nameOf(fieldName.Names), {
                        value: moveGridRow({
                          selectedItem: selectedName,
                          gridData: namesValue,
                          direction: "up",
                          primaryField: nameOfAccountNames("EntityNameAddrId"),
                        }),
                      });
                    }}
                  />
                  <Button
                    disabled={!selectedName}
                    iconClass="fas fa-chevron-down"
                    title="Move down"
                    onClick={() => {
                      onChange(nameOf(fieldName.Names), {
                        value: moveGridRow({
                          selectedItem: selectedName,
                          gridData: namesValue,
                          direction: "down",
                          primaryField: nameOfAccountNames("EntityNameAddrId"),
                        }),
                      });
                    }}
                  />
                </div>
              }
            />
          </div>
          <div className="cc-form-cols-1">
            <div className="cc-field">
              <div className="cc-label-control-between">
                <label className="cc-label">Formatted name and address</label>
                <Button
                  fillMode="flat"
                  themeColor="primary"
                  className={"cc-edit-field-button"}
                  onClick={() => {
                    setFormatNameAddressDialog(true);
                  }}
                  title="Edit Formatted Owners Name and Address"
                  iconClass="fa fa-edit"
                />
              </div>
              <Field
                name={nameOf("FormattedNameAndAddress")}
                rows={4}
                component={CCTextArea}
                placeholder="Formatted name and address"
              />
            </div>
          </div>
        </div>
      </section>
      {formatNameAddressDialog && (
        <FormatNameAndAddressDialog
          noticeGroupData={[]}
          onClose={() => {
            setFormatNameAddressDialog(false);
          }}
          onSubmit={() => {
            setFormatNameAddressDialog(false);
          }}
        />
      )}
      {newContactData && (
        <NewContactDialog
          isOpenedFromParentWorkflow
          isRedirectManagePage={false}
          currentEntity={{
            Surname: newContactData?.[fieldName.Name].split(",")[0] || "",
          }}
          onClose={() => {
            setNewContactData(undefined);
          }}
          handleSubmitDialog={(values: any) => {
            handleChangeEntity(values, true);
            setNewContactData(undefined);
          }}
        />
      )}
      {updateEntityData && (
        <UpdateEntityDialog
          currentEntity={{
            Delivery: "Standard",
            GivenNames: updateEntityData?.[fieldName.Name].split(",")[0] || "",
            Surname: updateEntityData?.[fieldName.Name].split(", ")[1] || "",
            AdditionalAddress: updateEntityData?.[fieldName.Address] || "",
          }}
          onClose={() => {
            setUpdateEntityData(undefined);
          }}
          onSubmit={(values: any) => {
            handleChangeEntity(values, false);
            setUpdateEntityData(undefined);
          }}
        />
      )}
    </>
  );
};
