import { CCSearchComboBoxGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/dropdown/_index";
import { InputGlobalSettings } from "@common/pages/settings/system-admin/global-settings/components/setting-fields/input/_index";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { getDropdownValue } from "@common/utils/common";
import { CCLabel } from "@components/cc-label/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { FormRenderProps } from "@progress/kendo-react-form";
import { InputChangeEvent } from "@progress/kendo-react-inputs";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

export type IMappingInterfaceProps = {
  formRenderProps: FormRenderProps;
};

const MappingInterface = ({ formRenderProps }: IMappingInterfaceProps) => {
  const { valueGetter, onChange } = formRenderProps;
  const { isEditing } = useSettingGlobalManageStore();
  const { configData } = useSettingGlobalStore();

  //Value getter
  const baseURL = configData
    ? valueGetter(configData["ProcessExternalURL"]?.FieldName)
    : "";
  const initExampleURL = configData ? configData["ExampleURL"]?.Value : "";
  const element = configData
    ? valueGetter(configData["Element"]?.FieldName)
    : "";
  const source = configData ? valueGetter(configData["Source"]?.FieldName) : "";
  const id = configData ? valueGetter(configData["ID"]?.FieldName) : "";

  //States
  const [exampleURL, setExampleURL] = useState<string>("");

  //Handle events
  const handleChangeExampleURL = (value: string, field: string) => {
    onChange(field, { value: value });
    if (!configData) return;

    const sourceId = field === configData["Source"]?.FieldName ? value : source;
    let sourceParam =
      getDropdownValue(sourceId, configData["Source"]?.LookupItems, "Id")
        ?.Name ?? "";

    const idParam = field === configData["ID"]?.FieldName ? value : id;
    let url: string = `${baseURL}${element}&source=${sourceParam
      .replace(" ", "")
      .toLowerCase()}&id=${idParam}`;

    setExampleURL(url);
  };

  return configData ? (
    <section className="cc-field-group">
      <CCValueField
        name="ProcessExternalURL"
        label={configData["ProcessExternalURL"]?.Title ?? ""}
        value={configData["ProcessExternalURL"]?.Value ?? ""}
      />
      <div className="cc-field">
        <CCLabel title="Parameters" />
      </div>
      <div className="cc-custom-sub-panel-bar cc-field-group">
        <div className="cc-form-cols-3">
          <CCValueField
            name={configData["Element"]?.FieldName ?? ""}
            label={configData["Element"]?.Title ?? ""}
            value={configData["Element"]?.Value ?? ""}
          />
        </div>
        <div className="cc-form-cols-3">
          <CCSearchComboBoxGlobalSettings
            data={configData["Source"]}
            isEditing={isEditing}
            onChange={(event: ComboBoxChangeEvent) => {
              handleChangeExampleURL(
                event.value,
                configData["Source"]?.FieldName
              );
            }}
          />
        </div>
        <div className="cc-form-cols-3">
          <InputGlobalSettings
            data={configData["ID"]}
            isEditing={isEditing}
            onChange={(event: InputChangeEvent) => {
              handleChangeExampleURL(event.value, configData["ID"]?.FieldName);
            }}
          />
        </div>
      </div>
      <CCValueField
        name="ExampleURL"
        label={configData["ExampleURL"]?.Title ?? ""}
        value={
          exampleURL
            ? //TODO: Disable the clickable link for now, will create ProcessExternal screen and link to it later
              exampleURL
            : initExampleURL
        }
      />
    </section>
  ) : null;
};

export default observer(MappingInterface);
