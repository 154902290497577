import { history } from "@/AppRoutes";
import { ICategoryPropertyConfig } from "@app/products/property/system-admin/settings/_category/config";
import {
  SettingField,
  SettingGridField,
  SettingsDataType,
  SettingsMap,
} from "@app/products/property/system-admin/settings/model";
import {
  CURRENCY_FORMAT,
  DATETIME_FORMAT,
  DATE_FORMAT,
  DECIMAL_FORMAT,
  ICON_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { IColumnFields } from "@components/cc-grid/model";
import { SortDescriptor } from "@progress/kendo-data-query";
import { cloneDeep, isNil, startCase } from "lodash";

export const prefixField = "cc-";
export const defaultLinkField = "cc-Name";
export const settingGridFieldColumn = (
  gridField: any,
  linkField?: string,
  isListView: boolean = false
): IColumnFields[] => {
  if (!linkField) linkField = defaultLinkField;
  else linkField = prefixField + linkField;
  if (!gridField) return [];
  const columnsMapping = cloneDeep(gridField);
  if (gridField.length) {
    // If the column doesn't have Title, show the Name instead.
    if (!columnsMapping[0].Title)
      columnsMapping.forEach(
        (column: any) =>
          // If the column Name contains no spaces (e.g. ColumnName), format it by adding spaces before capital letters (e.g. Column Name).
          // Otherwise, use the original Name as the Title.
          (column.Title = !column.Name.includes(" ")
            ? column.Name.replace(/([A-Z])/g, " $1").trim()
            : column.Name)
      );
  }
  columnsMapping.forEach((item: any) => {
    item.Name = prefixField + item.Name;
    item.Title = startCase(item.Title);
  });

  return columnsMapping.map((item: any) => {
    if (item.IsRemoveComma) {
      let formatField;
      if (item.DataType === SettingsDataType.Int) {
        formatField = NUMBER_FORMAT.NUMBER2;
      }
      return {
        field: item.Name,
        title: item.Title.toUpperCase(),
        format: formatField,
      };
    } else if (item.Name === linkField) {
      const formatField = getFormatColumn(item.DataType);
      const defaultItemLink = {
        field: item.Name,
        title: item.Title,
        disableFieldName: "IsEditable",
        width: 300,
        locked: true,
        format: formatField,
      };
      if (isListView) {
        return {
          ...defaultItemLink,
          linkTo: (dataItem: any) => {
            return {
              pathname: history.location.pathname,
              state: {
                requestUrl: `/${dataItem.ProductType_Enum}/${dataItem.SettingCategoryName}/${dataItem.Name}/${dataItem.ItemId}`,
                product: dataItem.ProductType_Enum,
                category: dataItem.SettingCategoryName,
              } as ICategoryPropertyConfig,
            };
          },
        };
      }
      return defaultItemLink;
    } else {
      const formatField = getFormatColumn(item.DataType);
      return {
        field: item.Name,
        title: item.Title,
        format: formatField,
      };
    }
  });
};

export const settingGridFieldValue = (value: any) => {
  if (!value) return;
  const newData = cloneDeep(value);
  return newData.map((itemGridFields: any) => {
    let result: any = {};
    for (var subItemGridFields of itemGridFields.GridFields) {
      subItemGridFields.Name = prefixField + subItemGridFields.Name;
      const elementTitle = subItemGridFields.Name;
      result[elementTitle] = subItemGridFields.Value;
    }
    return { ...itemGridFields, ...result };
  });
};

export const convertSettings = (settings: SettingField[]): SettingsMap => {
  let result: SettingsMap = {};
  if (settings && Array.isArray(settings)) {
    settings?.forEach((setting) => {
      if (!isNil(setting.CorporateSettingsField_Int)) {
        const corporateSetting =
          setting.CorporateSettingsField_Int as ECorporateSettingsField;
        result[corporateSetting] = setting;
      }
    });
  }
  return result;
};

export const settingSortingColumns = (
  gridFields: SettingGridField[]
): SortDescriptor[] => {
  return gridFields
    .filter(({ SortOrder }: SettingGridField) => SortOrder)
    .map(({ Name, SortOrder }: SettingGridField) => {
      return {
        field: prefixField + Name,
        dir: SortOrder!.toLowerCase(),
      } as SortDescriptor;
    });
};

const getFormatColumn = (dataType?: SettingsDataType) => {
  switch (dataType) {
    case SettingsDataType.Boolean:
      return ICON_FORMAT.ICON_BOOLEAN;
    case SettingsDataType.Date:
      return DATE_FORMAT.DATE;
    case SettingsDataType.DateTime:
      return DATETIME_FORMAT.DATETIME;
    case SettingsDataType.Int:
      return NUMBER_FORMAT.NUMBER2;
    case SettingsDataType.Decimal:
      return DECIMAL_FORMAT.DECIMAL1;
    case SettingsDataType.Money:
      return CURRENCY_FORMAT.CURRENCY1;
    case SettingsDataType.Percent:
      return PERCENTAGE_FORMAT.PERCENTAGE_1;
    default:
      break;
  }
};
