import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { colPPRRelated } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/config";
import {
  IPPRRelated,
  IRelatedParams,
} from "@app/products/town-planning/ppr/[id]/components/child-screens/related/model";
import { useRelatedAccordionStore } from "@app/products/town-planning/ppr/[id]/components/child-screens/related/store";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonProductStore } from "@common/stores/products/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";

export interface IPPRRelatedProp {
  relatedParam?: IRelatedParams;
}

const nameOf = nameOfFactory<IPPRRelated>();
export const PPRRelated = observer(({ relatedParam }: IPPRRelatedProp) => {
  const [gridSelectedRows, setGridSelectedRows] = useState<IPPRRelated[]>([]);
  const { isLoading, relatedRecords, setRelatedParam, loadRelatedRecords } =
    useRelatedAccordionStore();
  const { isDisabled } = useTabTableStore();
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );
  const { settings } = useCommonProductStore();
  const isEnablePPR = getBoolValueSetting(
    settings[ECorporateSettingsField.TP_Application_PPR]
  );
  const handleConfirmDelete = async (data: IDeleteConfirmation) => {
    setIsDeleting(true);
    const response = await deleteRefUsageByIdAndRecordType(
      gridSelectedRows?.map((item: IPPRRelated) => item.ID),
      RECORDTYPE.CORE_Related,
      data?.Reason
    );
    setIsDeleting(false);
    if (isSuccessResponse(response)) {
      setShowConfirmDeleteDialog(false);
      loadRelatedRecords();
      clearErrorNotification();
      setGridSelectedRows([]);
      pushNotification({
        title: "Relate deleted successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Relate deleted failed",
          type: "error",
          description: response.data?.Errors,
        },
      ]);
    }
  };
  useEffectOnce(() => {
    if (relatedParam) {
      setRelatedParam(relatedParam);
      loadRelatedRecords();
    }
  });

  return (
    <>
      <div className="cc-ppr-related">
        <CCGrid
          isLoading={isLoading}
          toolbar={
            <div className="cc-grid-tools-bar">
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={
                  isDisabled
                    ? isDisabled
                    : gridSelectedRows?.length !== 1 ||
                      gridSelectedRows[0].Relationship !== "Manual"
                }
                onClick={() => {
                  setShowConfirmDeleteDialog(true);
                }}
              />
            </div>
          }
          selectableMode="multiple"
          data={relatedRecords}
          primaryField={nameOf("Record_ID")}
          selectedRows={gridSelectedRows}
          onSelectionChange={(dataItem: IPPRRelated[]) => {
            setGridSelectedRows([...dataItem]);
          }}
          columnFields={colPPRRelated(isEnablePPR)}
        />
      </div>
      {showConfirmDeleteDialog && (
        <ConfirmDelete
          onClose={() => {
            setShowConfirmDeleteDialog(false);
          }}
          header={"Delete Confirmation"}
          contentDelete={gridSelectedRows[0].Type}
          handleSubmit={handleConfirmDelete}
          isDeleting={isDeleting}
          notifications={notifications}
        />
      )}
    </>
  );
});
