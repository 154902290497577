import { SettingsMap } from "@app/products/property/system-admin/settings/model";
import { Configuration } from "@azure/msal-browser";
import { CORPORATE_SETTINGS_FIELD } from "@common/constants/corporateSettingsField";
import { IColumnFields } from "@components/cc-grid/model";
export interface ISettings {
  systemSettingUrl?: string;
  oDataUrl?: string;
  baseAPIUrl?: string;
  apiKey?: string;
  authenticationProvider?: string;
  azureAD?: AzureAD;
  activityDetector: IActivityDetector;
  googleRecaptcha?: IGoogleRecaptcha;
  googleMap?: GoogleMapSettings;
  loggerOptions?: LoggerOptions;
  projectName?: string;
  version?: string;
}

export enum HelpSettingMethod {
  Confluence = 0,
  Airtime = 1,
  ReadyCommunityHelpDocs = 2,
  External = 3,
}

export interface AzureAD {
  scopes?: string[];
  azureADConfig?: Configuration;
}

export interface IActivityDetector {
  countDownTime: number;
  idleTime: number;
}

export interface GoogleMapSettings {
  key: string;
  language?: string;
  libraries?: string[];
  region?: string;
}

export interface IGoogleRecaptcha {
  siteKey: string;
  secretKey: string;
}

export interface LoggerOptions {
  piiLoggingEnabled?: boolean;
  logLevel?: number;
}

export interface ISystemLabel {
  DataType: number;
  IsVisible: boolean;
  Value: String;
}

export interface ICorporateSettingLogo {
  FieldName: string;
  Title: string;
  DataType: number;
  IsVisible: boolean;
  Value: string;
}

export interface MainSetting {
  ProductEnum: string;
  ProductName: ProductName;
  Categories: Category[];
}

export interface SiteBaseURL {
  DataType: number;
  IsVisible: boolean;
  Value: string;
}

export interface Category {
  ProductEnum: ProductEnum;
  CategoryName: string;
  Title: string;
}

export enum ProductEnum {
  AssetManager = "AssetManager",
  Core = "Core",
  Property = "Property",
}

export enum ProductName {
  Empty = "",
  Global = "Global",
}

export interface CorporateSettings {
  Sys_EnterpriseKey: string;
  Sys_CreatedDate: Date;
  Sys_CreatedBy: string;
  Sys_CreatedBy_ID: number;
  Sys_TimeStamp: string;
  Sys_TransactionDate: Date;
  CorporateSettingsField_ENUM: CORPORATE_SETTINGS_FIELD;
  Value: string;
  KeyValueBlob: string;
}

export interface IGenerateColFieldBySetting {
  defaultConfig: IColumnFields[];
  settings?: SettingsMap;
}
