import { ExistedRegion } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/forms/existed/_index";
import { NewRegion } from "@app/products/town-planning/ppr/system-admin/regions/[id]/components/forms/new/_index";
import { useRegionStore } from "@app/products/town-planning/ppr/system-admin/regions/[id]/store";
import { useIsNew } from "@common/hooks/useIsNew";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageRegion = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const regionID = parseInt(params.id) || undefined;
  const { resetStore, loadRegion } = useRegionStore();

  useEffect(() => {
    if (location.state) {
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffect(() => {
    loadRegion(regionID, isNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionID, isNew, loadRegion]);

  useEffectOnce(() => {
    return () => {
      resetStore();
    };
  });

  if (isNew) {
    return <NewRegion />;
  }
  return <ExistedRegion />;
};

export default observer(ManageRegion);
