import { FactorCell } from "@app/products/property/assessments/[id]/components/forms/existed/components/form-steps/split-assessment/components/form-elements/parcels-to-be-split/components/factor-cell/_index";
import { DateCustomCell } from "@app/products/property/components/grid/components/cc-date-range-limit/_index";
import {
  DTO_Assessment,
  DTO_CreateParcel_Title,
} from "@app/products/property/parcels/components/form-steps/create-parcel/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  PERCENTAGE_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields, ICustomEditCell } from "@components/cc-grid/model";
import React from "react";

const nameOfTitle = nameOfFactory<DTO_CreateParcel_Title>();
const nameOfAssessments = nameOfFactory<DTO_Assessment>();
const propsDateCustomCellTitle = {
  listFieldName: {
    fromField: nameOfTitle("Active_From"),
    toField: nameOfTitle("Active_To"),
  },
};
const propsDateCustomCellAssessment = {
  listFieldName: {
    fromField: nameOfAssessments("Active_From"),
    toField: nameOfAssessments("Active_To"),
  },
};
export const colTitles: IColumnFields[] = [
  {
    field: nameOfTitle("LegalDescription"),
    title: "Legal Description",
  },
  {
    field: nameOfTitle("AssociationFactorPercentage"),
    title: "Factor",
    minWidth: 120,
    editable: true,
    format: PERCENTAGE_FORMAT.PERCENTAGE_NOT_DECIMAL,
    editCell: FactorCell,
  },
  {
    field: nameOfTitle("Active_From"),
    title: "Effective From",
    format: DATE_FORMAT.DATE,
    minWidth: 150,
    editable: true,
    editCell: (props: ICustomEditCell) => (
      <DateCustomCell {...props} extraConditions={propsDateCustomCellTitle} />
    ),
  },
  {
    field: nameOfTitle("Active_To"),
    title: "Effective To",
    format: DATE_FORMAT.DATE,
    minWidth: 150,
    editable: true,
    editCell: (props: ICustomEditCell) => (
      <DateCustomCell {...props} extraConditions={propsDateCustomCellTitle} />
    ),
  },
  // Need to check again
  {
    field: nameOfTitle("NameAddress"),
    title: "Owners",
  },
  {
    field: nameOfTitle("TitleId"),
    title: "Title ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];

export const colAssessments: IColumnFields[] = [
  {
    field: nameOfAssessments("Assess_Number"),
    title: "Assessment Number",
  },
  {
    field: nameOfAssessments("Assess_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOfAssessments("AssociationFactorPercentage"),
    title: "Factor",
    minWidth: 120,
    editable: true,
    format: PERCENTAGE_FORMAT.PERCENTAGE_NOT_DECIMAL,
    editCell: FactorCell,
  },
  {
    field: nameOfAssessments("Active_From"),
    title: "Effective From",
    format: DATE_FORMAT.DATE,
    editable: true,
    minWidth: 150,
    editCell: (props: ICustomEditCell) => (
      <DateCustomCell
        {...props}
        extraConditions={propsDateCustomCellAssessment}
      />
    ),
  },
  {
    field: nameOfAssessments("Active_To"),
    title: "Effective To",
    format: DATE_FORMAT.DATE,
    minWidth: 150,
    editable: true,
    editCell: (props: ICustomEditCell) => (
      <DateCustomCell
        {...props}
        extraConditions={propsDateCustomCellAssessment}
      />
    ),
  },
  {
    field: nameOfAssessments("Ratepayer_Name"),
    title: "Ratepayer",
  },
  {
    field: nameOfAssessments("Unit_Entitlement"),
    title: "Unit Entitlement",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOfAssessments("Unit_Entitlement_Percentage"),
    title: "Unit Entitlement Percentage",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessments("Unit_Type"),
    title: "Unit Type",
  },
  {
    field: nameOfAssessments("Unit_Type_Percentage"),
    title: "Unit Type Percentage",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOfAssessments("Assessment_Id"),
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
