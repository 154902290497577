import { loadParcelLookup } from "@app/products/property/registers/[id]/components/dialogs/associate/api";
import { colAssociate } from "@app/products/property/registers/[id]/components/dialogs/associate/config";
import {
  mockDropdownSearchParcel,
  mockInitAssociate,
  mockSearchOptions,
} from "@app/products/property/registers/[id]/components/dialogs/associate/mock";
import {
  IAssociateParcelLookup,
  IParcelLookup,
  SearchParcelType,
  textParcelFieldMapping,
} from "@app/products/property/registers/[id]/components/dialogs/associate/model";
import { nameOfFactory } from "@common/utils/common";
import { CCComboBox } from "@components/cc-combo-box/_index";
import { CCDialog } from "@components/cc-dialog/_index";
import { CCGrid } from "@components/cc-grid/_index";
import { CCTooltip } from "@components/cc-tooltip/_index";
import { Button } from "@progress/kendo-react-buttons";
import {
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
  DropDownList,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React from "react";
interface IAssociateDialogProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
}

const nameOf = nameOfFactory<IAssociateParcelLookup>();
const nameOfGridData = nameOfFactory<IParcelLookup>();
export const AssociateDialog = ({
  onClose,
  onSubmit,
}: IAssociateDialogProps) => {
  const renderAssociateDialog = (formRenderProps: FormRenderProps) => {
    const { valueGetter, onChange, onSubmit } = formRenderProps;
    const searchParcelTypeValue = valueGetter("ParcelSearchBy");
    const handleSearchParcel = (event: ComboBoxFilterChangeEvent) => {
      let numLetters = 2;
      if (!searchParcelTypeValue) return;
      onChange(nameOf("ParcelSearchValue"), { value: null });
      if (searchParcelTypeValue.Value === SearchParcelType.ParcelId)
        numLetters = 1;
      if (event.filter.value.length >= numLetters) {
        onChange("_option.SearchParcel.Loading", { value: true });
        loadParcelLookup(event.filter).then((data) => {
          onChange("_option.SearchParcel.Data", { value: data });
          onChange("_option.SearchParcel.Loading", {
            value: false,
          });
        });
      } else {
        onChange("_option.SearchParcel.Data", { value: [] });
      }
    };
    const handleSearchParcelResult = (event: ComboBoxChangeEvent) => {
      if (!event.value) return;
      let newGirdData = [];
      newGirdData.push({
        ParcelReference: event.value.ParcelReference,
        ParcelId: event.value.ParcelId,
        Address: event.value.Address,
        Locality: event.value.Locality,
        SideOfStreet: event.value.SideOfStreet,
        MapNumber: event.value.MapNumber,
        MapReference: event.value.MapReference,
        LegalDescription: event.value.LegalDescription,
        ElectoralDivision: event.value.ElectoralDivision,
        LandArea: event.value.LandArea,
        Name: event.value.Name,
        LandCategory: event.value.LandCategory,
        Owners: event.value.Owners,
        Zoning: event.value.Zoning,
        LandUses: event.value.LandUses,
        Status: event.value.Status,
      });
      onChange(nameOf("ParcelLookup"), {
        value: newGirdData,
      });
      onChange(nameOf("ParcelSearchValue"), {
        value: event.value,
      });
    };
    return (
      <CCDialog
        titleHeader={"Parcel Lookup"}
        onClose={onClose}
        maxWidth={"60%"}
        height={"auto"}
        bodyElement={
          <FormElement className="cc-form">
            <section className="cc-field-group">
              <div className="cc-form-cols-1">
                <div className="cc-field">
                  <div className=" cc-form-cols-1">
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Search parcel by</label>
                        <Field
                          name={nameOf("ParcelSearchBy")}
                          data={mockDropdownSearchParcel || []}
                          dataItemKey="Key"
                          textField="Value"
                          component={DropDownList}
                        />
                      </div>
                      <div className="cc-field">
                        <label className="cc-label">
                          Parcel value
                          <CCTooltip
                            type="validator"
                            position="right"
                            content="You must have at least one record to go to the next step"
                          />
                        </label>
                        <Field
                          name={nameOf("ParcelSearchValue")}
                          filterable
                          suggest
                          textField={
                            textParcelFieldMapping[searchParcelTypeValue?.Value]
                          }
                          data={valueGetter("_option.SearchParcel.Data") ?? []}
                          loading={
                            valueGetter("_option.SearchParcel.Loading") ?? false
                          }
                          placeholder={`Type ${searchParcelTypeValue?.Value}`}
                          component={CCComboBox}
                          onFilterChange={handleSearchParcel}
                          onChange={handleSearchParcelResult}
                        />
                      </div>
                    </div>
                    <div className="cc-form-cols-2">
                      <div className="cc-field">
                        <label className="cc-label">Search options</label>
                        <Field
                          name={nameOf("SearchOptions")}
                          data={mockSearchOptions}
                          component={MultiSelect}
                          dataItemKey="Key"
                          textField="Value"
                          placeholder="Search options"
                        />
                      </div>
                    </div>
                    <div className=" cc-form-cols-1">
                      <CCGrid
                        data={valueGetter("ParcelLookup") ?? []}
                        columnFields={colAssociate}
                        primaryField={nameOfGridData("ParcelReference")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </FormElement>
        }
        footerElement={
          <div className="cc-dialog-footer-actions-right">
            <Button className="cc-dialog-button" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="cc-dialog-button"
              themeColor="primary"
              onClick={onSubmit}
              disabled={(valueGetter("ParcelLookup") ?? []).length === 0}
            >
              OK
            </Button>
          </div>
        }
      />
    );
  };
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={mockInitAssociate}
      render={(formRenderProps: FormRenderProps) =>
        renderAssociateDialog(formRenderProps)
      }
    />
  );
};
