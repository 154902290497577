import { colConcessionCards } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/components/detail/config";
import { DTO_Rebate_Concession_Card } from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { GridDetailRowProps } from "@progress/kendo-react-grid";
import React from "react";
interface IConcessionCardsProps extends GridDetailRowProps {
  options?: any;
  data: DTO_Rebate_Concession_Card[];
  dataItem: any;
  handleDataChange: (dataItem: DTO_Rebate_Concession_Card[]) => void;
}

const nameOfConcessionCard = nameOfFactory<DTO_Rebate_Concession_Card>();
export const ConcessionCards = ({
  options = {
    isReadOnly: false,
  },
  dataItem,
  handleDataChange,
}: IConcessionCardsProps) => {
  return (
    <CCGrid
      data={dataItem?.ConcessionCards ?? []}
      columnFields={colConcessionCards}
      primaryField={nameOfConcessionCard("Id")}
      editableMode={!options?.isReadOnly ? "cell" : undefined}
      onDataChange={handleDataChange}
    />
  );
};
