import { useIsNew } from "@common/hooks/useIsNew";
import { ExistedLookupKeyword } from "@common/pages/settings/lookups/keywords/_id/components/forms/existed/_index";
import { NewLookupKeyword } from "@common/pages/settings/lookups/keywords/_id/components/forms/new/_index";
import { ILookupKeywordsNotification } from "@common/pages/settings/lookups/keywords/_id/model";
import { useKeywordsStore } from "@common/pages/settings/lookups/keywords/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageLookupKeyword = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const keywordId = parseInt(params.id);
  const { resetStore, loadKeyword, setKeywordsNotification } =
    useKeywordsStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    if (location.state) {
      setKeywordsNotification(location.state as ILookupKeywordsNotification);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line
  }, [isNew]);

  useEffect(() => {
    loadKeyword(keywordId, isNew);
  }, [keywordId, isNew, loadKeyword]);

  if (isNew) {
    return <NewLookupKeyword />;
  }
  return <ExistedLookupKeyword />;
};

export default observer(ManageLookupKeyword);
