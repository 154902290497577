import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
export const colCentrelinkCardsRequiringActions: IColumnFields[] = [
  {
    field: "Name",
    title: "Name",
  },
  {
    field: "Action",
    title: "Action",
  },
  {
    field: "CentreLinkReferenceNumber",
    title: "Centrelink Reference Number",
  },
  {
    field: "Matched",
    title: "Matched?",
  },
  {
    field: "ConfirmationDate",
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: "PensionConcession",
    title: "Pension Concession",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "PostcodeMatched",
    title: "Postcode Matched",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "AssessmentNumber",
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: "EntityId",
    title: "Entity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
