import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { FeesForm } from "@app/core/fees/[id]/components/child-screens/general/_index";
import { FinanceFeeDetailTab } from "@app/core/fees/[id]/components/reference-sidebar/details/_index";
import { FinanceFeeHistoryTab } from "@app/core/fees/[id]/components/reference-sidebar/history/_index";
import { useFeesStore } from "@app/core/fees/[id]/store";
import { BubbleUpType } from "@app/core/inspections/[id]/model";
import { generateInvoiceItemTitle } from "@app/core/invoice/[id]/util";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { getFeeMailMergeDataSetENUM } from "@app/core/new-mail-merge/dialogs/util";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useGlobalStore } from "@common/stores/global/store";
import { mapEnum, renderBubbleUps } from "@common/utils/common";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedManageFees = observer(() => {
  const {
    isLoading,
    responseLoadError,
    onSubmit,
    loadFinanceFees,
    financeFee,
  } = useFeesStore();
  const { currentUserInfo } = useGlobalStore();
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;
  const [showSlideBar, setShowSlideBar] = useState(true);
  const { settings } = useCommonCoreStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Fees,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: financeFee?.FinanceFee?.Description ?? "",
      LinkUrl: managePageUrl,
      LinkText: `Fee - ${id ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE?.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Fees,
      Record_ID: id ? +id : 0,
    },
  ];

  const recordType = financeFee?.FinanceFee?._BubbleUps?.find(
    (item) =>
      mapEnum(item.BubbleUpType_ENUM, BubbleUpType) === BubbleUpType?.Parent
  )?.SourceIdentifier?._RecordSourceType_ENUM;
  const recordId = financeFee?.FinanceFee?._BubbleUps?.find(
    (item) =>
      mapEnum(item.BubbleUpType_ENUM, BubbleUpType) === BubbleUpType?.Parent
  )?.SourceIdentifier?._RecordSource_ID;

  const listPanelBar: ITabProps[] = [
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions id={parseInt(id)} recordType={RECORDTYPE.CORE_Fees} />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: (
        <Comments id={parseInt(id)} recordType={RECORDTYPE.CORE_Fees} />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.CORE_Fees} />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading || isLoadingPermission} isFullScreen />
      <FormNavigation
        title={generateInvoiceItemTitle(settings, RECORDTYPE.CORE_Invoice)}
      />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => loadFinanceFees(parseInt(id))}
        />
      ) : (
        <>
          <FormTitle
            title={displayBanner(
              financeFee?.FinanceFee?.Amount,
              financeFee?.TotalPaymentAmount
            )}
            badge={financeFee?.FinanceFee?.Flag_CreditNote ? "Credit Note" : ""}
          />
          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                disabled={
                  !checkPermissions([
                    FormAction.CORE_ALLOW_SAVE,
                    FormAction.CORE_ALLOW_EDIT,
                  ])
                }
                // Check disabled case - when no having parent
              />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Fees}
                  bubbleUps={financeFee?.FinanceFee?._BubbleUps}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ACTION)
                  }
                />
                <AddAttachmentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Fees}
                  bubbleUps={financeFee?.FinanceFee?.BubbleUps}
                  disabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_ATTACHMENT)
                  }
                />
                <AddCommentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Fees}
                  bubbleUps={renderBubbleUps(
                    recordId ?? 0,
                    recordType,
                    parseInt(id),
                    RECORDTYPE.CORE_Fees
                  )}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_COMMENT)
                  }
                />
                <AddDocumentButton
                  dataSetFilter={
                    getFeeMailMergeDataSetENUM(
                      mapEnum(
                        financeFee?.FinanceFee?._BubbleUps[0]?.SourceIdentifier
                          ?._RecordSourceType_ENUM,
                        RECORDTYPE
                      )
                    ) ?? MAILMERGEDATASET.CORE_Fee
                  }
                  recordType={RECORDTYPE.CORE_Fees}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  id={parseInt(id)}
                  bubbleUps={financeFee?.FinanceFee?._BubbleUps}
                  isDisabled={
                    !checkPermissions(FormAction.CORE_TOOLBAR_ADD_DOCUMENT)
                  }
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />

          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {financeFee && <FeesForm />}
                <TabTable
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Fees}
                  initialPanels={listPanelBar}
                />
              </div>
            </div>

            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    { title: "Details", component: <FinanceFeeDetailTab /> },
                    {
                      title: "History",
                      component: <FinanceFeeHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});

const displayBanner = (amount?: number, totalPaymentAmount?: number) => {
  if (
    !isNil(amount) &&
    !isNil(totalPaymentAmount) &&
    amount - totalPaymentAmount > 0
  )
    return "Outstanding Fee";
  else if (!isNil(amount) && amount === 0) return "New Fee";
  else return "Paid Fee";
};
