import { useIsNew } from "@common/hooks/useIsNew";
import { ExistedSiteUser } from "@common/pages/settings/security/site-users/_id/components/forms/existed/_index";
import { NewSiteUser } from "@common/pages/settings/security/site-users/_id/components/forms/new/_index";
import { useSiteUserStore } from "@common/pages/settings/security/site-users/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageSiteUsers = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const siteUserId = params.id;
  const { resetStore, loadSiteUser } = useSiteUserStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffect(() => {
    loadSiteUser(parseInt(siteUserId), isNew);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteUserId, isNew]);

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  if (isNew) {
    return <NewSiteUser />;
  }
  return <ExistedSiteUser />;
};

export default observer(ManageSiteUsers);
