import { useIsNew } from "@common/hooks/useIsNew";
import { ExistedSecurityOrgStructure } from "@common/pages/settings/security/org-structure/_id/components/forms/existed/_index";
import { NewSecurityOrgStructure } from "@common/pages/settings/security/org-structure/_id/components/forms/new/_index";
import { ISecurityOrgStructureNotification } from "@common/pages/settings/security/org-structure/_id/model";
import { useOrgStructureStore } from "@common/pages/settings/security/org-structure/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const ManageSecurityOrgStructure = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const location = useLocation();
  const orgUnitId = parseInt(params.id);
  const { resetStore, loadOrgUnit, setOrgStructureNotification } =
    useOrgStructureStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffectOnce(() => {
    return () => {
      resetMenu();
      resetStore();
    };
  });

  useEffect(() => {
    if (location.state) {
      setOrgStructureNotification(
        location.state as ISecurityOrgStructureNotification
      );
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line
  }, [isNew]);

  useEffect(() => {
    loadOrgUnit(orgUnitId, isNew);
  }, [orgUnitId, isNew, loadOrgUnit]);

  if (isNew) {
    return <NewSecurityOrgStructure />;
  }
  return <ExistedSecurityOrgStructure />;
};

export default observer(ManageSecurityOrgStructure);
