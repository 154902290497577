import { DATETIME_FORMAT } from "@common/constants/common-format";
import { nameOfScheduleJobsView } from "@common/pages/settings/system-admin/task-scheduling/model";
import { IColumnFields } from "@components/cc-grid/model";

export const jobsSchedulingCol: IColumnFields[] = [
  {
    field: nameOfScheduleJobsView("JobNameTitle"),
    title: "Job Name",
  },
  {
    field: nameOfScheduleJobsView("Action"),
    title: "Action",
  },
  {
    field: nameOfScheduleJobsView("JobDescription"),
    title: "Description",
  },
  {
    field: nameOfScheduleJobsView("JobType"),
    title: "Job Type",
  },
  {
    field: nameOfScheduleJobsView("JobState"),
    title: "Job State",
  },
  {
    field: nameOfScheduleJobsView("JobCreatedDateTime"),
    title: "Registered Date Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfScheduleJobsView("JobScheduleDateTime"),
    title: "Started Date Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfScheduleJobsView("JobExpireDateTime"),
    title: "Expire Date Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfScheduleJobsView("NextFireTime"),
    title: "Next Fire Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfScheduleJobsView("PreviousFireTime"),
    title: "Previous Fire Time",
    format: DATETIME_FORMAT.DATETIME,
  },
  {
    field: nameOfScheduleJobsView("JobCreateBy"),
    title: "Job Created By",
  },
];
