import { IAttachment } from "@app/core/attachment/components/buttons/add-attachment/model";
import { AddAttachmentDialog } from "@app/core/attachment/components/dialogs/_index";
import {
  eUploadMode,
  IAddAttachmentDialog,
} from "@app/core/attachment/components/dialogs/model";
import { convertToFileStreamEntry } from "@app/core/attachment/components/dialogs/util";
import { getDocumentById, saveDocument } from "@app/core/documents/api";
import { Svc_Attachment } from "@app/core/documents/model";
import ImageDefault from "@assets/img/image-default.svg";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useSettingGlobalStore } from "@common/pages/settings/system-admin/global-settings/_category/store";
import { useSettingGlobalManageStore } from "@common/pages/settings/system-admin/global-settings/_id/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { FormRenderProps } from "@progress/kendo-react-form";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import "./_index.scss";

export type ICommonFormProps = {
  formRenderProps: FormRenderProps;
};
export default observer(({ formRenderProps }: ICommonFormProps) => {
  const { configData, getConfigDataField } = useSettingGlobalStore();
  const { isEditing } = useSettingGlobalManageStore();
  const { valueGetter, onChange } = formRenderProps;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoadingUploadPhoto, setIsLoadingUploadPhoto] = useState(false);
  const { pushNotification, clearNotifications } = useCCAppNotificationStore();

  const fieldSignature = getConfigDataField(
    ECorporateSettingsField.CorporateSettings_MailMerge_GlobalMailMergeSignature
  );
  const fieldDisplayValue = valueGetter(
    `option.ValueDisplayDetails_${ECorporateSettingsField.CorporateSettings_MailMerge_GlobalMailMergeSignature}`
  )?.[0] as IAttachment;
  const handleSaveAttachmentPhoto = async (data: IAddAttachmentDialog) => {
    setIsOpenPopup(true);
    setIsLoadingUploadPhoto(true);
    if (fieldSignature) {
      switch (data?.UploadMode) {
        case eUploadMode.UPLOAD_FILE:
          if (!isNil(data?.File) && isNil(data?.File[0].validationErrors)) {
            const fileEntry = await convertToFileStreamEntry(data?.File[0]);
            const attachments = {
              FileEntry: fileEntry,
              Title: data.Title ?? data?.File[0]?.name,
              Attachment_ID: 0,
              Category_KWD: data?.Category?.Keyword_ID ?? null,
              FileNumber: "",
              TRIMUri: "",
            } as IAttachment;

            const requestBody = {
              ParentID: parseInt(fieldSignature.FieldName),
              ParentRecordType: RECORDTYPE.CORE_CorporateSetting,
              AttachmentList: [attachments],
              AddDateTimeToFileName: null,
            } as Svc_Attachment;

            const response = await saveDocument(requestBody);
            setIsLoadingUploadPhoto(false);
            if (isSuccessIdentityPacket(response)) {
              const responseDocument = await getDocumentById(
                response.data?.ID,
                false
              );
              if (isSuccessResponse(responseDocument)) {
                onChange(
                  `option.ValueDisplayDetails_${fieldSignature.FieldName}`,
                  {
                    value: [responseDocument.data],
                  }
                );
                onChange(fieldSignature.FieldName, {
                  value: responseDocument.data?.Attachment_ID,
                });

                setIsOpenPopup(false);
                clearNotifications();
                pushNotification({
                  title: "Photo uploaded successfully",
                  type: "success",
                });
              } else {
                pushNotification({
                  autoClose: false,
                  title: "Upload photo failed",
                  type: "error",
                });
              }
            } else {
              if (response?.status === APIResponseStatus.EXCEEDED_SIZE_LIMIT) {
                pushNotification({
                  autoClose: false,
                  title: "Exceeded the specified size limit",
                  type: "error",
                });
              } else {
                pushNotification({
                  autoClose: false,
                  title: "Upload photo failed",
                  description: response.data?.Errors ?? response.statusText,
                  type: "error",
                });
              }
            }
            setIsOpenPopup(false);
          }
          break;
      }
    }
    setIsLoadingUploadPhoto(false);
  };

  if (!configData) return <></>;

  return fieldSignature ? (
    <section className="cc-field-group">
      <div className="cc-form-cols-3">
        <div className="cc-field">
          <label className="cc-label">{fieldSignature?.Title ?? ""}</label>
          <div className="cc-image-upload">
            <div className="cc-image-upload-control">
              {fieldDisplayValue?.FileEntry?.FileAttachment
                ? isEditing && (
                    <i
                      className="fa fa-times fa-2x"
                      onClick={() => {
                        formRenderProps.onChange(
                          `option.ValueDisplayDetails_${fieldSignature?.FieldName}`,
                          {
                            value: null,
                          }
                        );
                        formRenderProps.onChange(
                          fieldSignature?.FieldName ?? "",
                          {
                            value: null,
                          }
                        );
                      }}
                      title="Remove"
                    />
                  )
                : isEditing && (
                    <i
                      className="fa fa-upload fa-2x"
                      onClick={() => {
                        setIsOpenPopup(true);
                      }}
                      title="Upload"
                    />
                  )}
            </div>
            <img
              src={
                fieldDisplayValue
                  ? !fieldDisplayValue?.FileEntry?.FileAttachment?.toString().includes(
                      "blob:"
                    )
                    ? `data:${fieldDisplayValue?.FileEntry?.ContentType};base64,` +
                      fieldDisplayValue.FileEntry?.FileAttachment
                    : fieldDisplayValue.FileEntry?.FileAttachment
                  : ImageDefault
              }
              className="cc-image-preview"
              alt={fieldSignature?.Title ?? "Home page logo"}
            />
          </div>
          {isOpenPopup && (
            <AddAttachmentDialog
              onClose={() => setIsOpenPopup(false)}
              onSubmit={handleSaveAttachmentPhoto}
              isLoadingButton={isLoadingUploadPhoto}
              isDisableWebLink
              isAllowMultiple={false}
              allowedFileExtensions={"jpg|png|jpeg|bmp|gif"}
            />
          )}
        </div>
      </div>
    </section>
  ) : null;
});
