export interface ViewResponse_Response {
  CentreLinkResponseBatch: Date | string;
  Id: string;
}
export interface ReportItemResponse {
  Title: string;
  IsDisable: boolean;
}

export enum eChildScreenItem {
  CENTRELINK_CONCESSION_CARDS = "Centrelink Concession Cards",
  CENTRELINK_CARDS_REQUIRING_ACTIONS = "Centrelink Cards Requiring Actions",
  EXTERNAL_CONCESSION_CARDS = "External Concession Cards",
  EXTERNAL_CARDS_REQUIRING_ACTIONS = "External Cards requiring Actions",
}
