import { HMPremisesRegisterView } from "@app/products/hm/premises/model";
import { getDisplayTextWithDashes } from "@common/utils/common";

// #region These codes are still use for another list view
export const getBookmarkDisplayName = (selectedRow: any) => {
  const dynamicDisplayName = selectedRow.ID ? `- ${selectedRow.ID}` : "";
  return `HM - Premises ${dynamicDisplayName}`;
};

export const getBookmarkDetail = (selectedRow: any) => {
  return getDisplayTextWithDashes([selectedRow.ID, selectedRow.PremisesType]);
};
// #endregion

export const HMPremisesBookmark = {
  getBookmarkDetailDisplayName() {
    return "Health Manager - Premises";
  },

  getBookmarkListViewDetail(viewName: string) {
    return `Health Manager - Premises - ${viewName}`;
  },

  getBookmarkListViewDetailRecord(selectedRow: HMPremisesRegisterView) {
    const dynamicDisplayName = selectedRow?.ID ? `- ${selectedRow.ID}` : "";
    return `Health Manager - Premises ${dynamicDisplayName}`;
  },
};
