import { useIsNew } from "@common/hooks/useIsNew";
import { ExistedLookupDocument } from "@common/pages/settings/lookups/documents/_id/components/forms/existed/_index";
import { NewLookupDocument } from "@common/pages/settings/lookups/documents/_id/components/forms/new/_index";
import { ILookupDocumentParentSection } from "@common/pages/settings/lookups/documents/_id/model";
import { useLookupDocumentStore } from "@common/pages/settings/lookups/documents/_id/store";
import { useCCSubActionBarStore } from "@components/cc-sub-action-bar/store";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

const ManageLookupDocument = () => {
  const isNew = useIsNew();
  const params: { id: string } = useParams();
  const documentId = params.id;
  const location = useLocation();
  const { resetStore, loadDocument, setParentSection } =
    useLookupDocumentStore();
  const { resetMenu } = useCCSubActionBarStore();

  useEffect(() => {
    if (location.state) {
      setParentSection(location.state as ILookupDocumentParentSection);
      window.history.replaceState({}, "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew]);

  useEffect(() => {
    loadDocument(parseInt(documentId), isNew);
    return () => {
      resetMenu();
      resetStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, isNew]);

  if (isNew) {
    return <NewLookupDocument />;
  }
  return <ExistedLookupDocument />;
};

export default observer(ManageLookupDocument);
