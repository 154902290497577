import { KeyValuePair } from "@app/core/documents/model";
import { nameOfKeyValuePacket } from "@app/products/hm/components/batch-update/util";
import { getNameOfPremises } from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { PlusKeywordsDialog } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/additional-details/components/plus-keywords-dialog/_index";
import {
  cbxTobCategories,
  optionPlusKeywordsTitle,
} from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/additional-details/utils";
import { PremisesRegisterLovs } from "@app/products/hm/premises/[id]/model";
import { getMultipleSelectValue } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/util";
import { DATE_FORMAT } from "@common/constants/common-format";
import { KEYWORD_TYPE } from "@common/constants/keywordType";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { Keyword } from "@common/models/keyword";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { nameOfFactory } from "@common/utils/common";
import { CCDatePicker } from "@components/cc-date-picker/_index";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { CCMultiSelectDropdown } from "@components/cc-multiple-selection-dropdown/_index";
import { CCNumericTextBox } from "@components/cc-numeric-text-box/_index";
import { CCSearchComboBox } from "@components/cc-search-combo-box/_index";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCTextArea } from "@components/cc-text-area/_index";
import { Button } from "@progress/kendo-react-buttons";
import { MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isEmpty, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useEffectOnce } from "react-use";

export interface IAdditionalDetailsSectionProps {
  formRenderProps: FormRenderProps;
  isInactive?: boolean;
}

const nameOfKeyValuePair = nameOfFactory<KeyValuePair<string, string>>();
export const AdditionalDetailsSection = observer(
  ({ formRenderProps, isInactive = false }: IAdditionalDetailsSectionProps) => {
    const { dataForms } = useFlexibleFormStore();
    const { valueGetter, onChange } = formRenderProps;

    const [dataTOBCategories, setDataTOBCategories] = useState<string[]>([]);
    const [plusKeywordDialog, setPlusKeywordDialog] = useState<
      KEYWORD_TYPE | undefined
    >(undefined);

    const premisesGeneralFormLovs =
      dataForms?.GeneralFormLovs as PremisesRegisterLovs;

    // #region GET VALUES
    let valueFlagLiquorLicence = valueGetter(
      getNameOfPremises("Flag_LiquorLicence")
    );
    let typeValueFlagEatInFacility = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_EatInFacility`
    );
    let typeValueFlagOutdoorDining = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_OutdoorDining`
    );
    let typeValueFlagTobaccoRetail = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_TobaccoRetail`
    );
    let typeValueFlagPrivateWaterSupply = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_PrivateWaterSupply`
    );
    let typeValueFlagWasteWater = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_WasteWater`
    );
    let typeValueFlagGamingVenue = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_GamingVenue`
    );
    let typeValueFlagLicencedNonGaming = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_LicencedNonGaming`
    );
    let typeValueFlagBingoCentre = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_BingoCentre`
    );
    // let typeValueFlagNoOfUnits = valueGetter(
    //   `${getNameOfPremises("PremisesType")}.Flag_NoOfUnits`
    // );
    let typeValueFlagNoOfSites = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_NoOfSites`
    );
    let typeValueFlagNoOfRooms = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_NoOfRooms`
    );
    let typeValueFlagNoOfBeds = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_NoOfBeds`
    );
    let typeValueFlagNoOfTables = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_NoOfTables`
    );
    // let typeValueFlagNoOfEmployees = valueGetter(
    //   `${getNameOfPremises("PremisesType")}.Flag_NoOfEmployees`
    // );
    let typeValueFlagFSP = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_FSP`
    );
    let typeValueFlagInfectionControl = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_InfectionControl`
    );
    let typeValueFlagNoOfFoodhandlers = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_NoOfFoodhandlers`
    );
    let typeValueFlagNoOfChairs = valueGetter(
      `${getNameOfPremises("PremisesType")}.Flag_NoOfChairs`
    );

    const checkHealthLicensing = useMemo(
      () => {
        return (
          valueGetter(
            `${getNameOfPremises(
              "PremisesType"
            )}.PremisesClassification.HealthLicensing`
          ) ?? false
        );
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(getNameOfPremises("PremisesType"))]
    );

    const checkFlagFoodPremises = useMemo(
      () => {
        return (
          valueGetter(
            `${getNameOfPremises(
              "PremisesType"
            )}.PremisesClassification.Flag_FoodPremises`
          ) ?? false
        );
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(getNameOfPremises("PremisesType"))]
    );
    // #endregion GET VALUES

    // #region HANDLERS
    const handleOnChangeCategories = (event: MultiSelectChangeEvent) => {
      const pcbaCategories =
        (event?.value as KeyValuePair<string, string>[]) ?? [];

      let newDataCategories: string[] = [];

      pcbaCategories.forEach((item) => {
        if (!isNil(item?.Key)) {
          newDataCategories.push(item.Key);
        }
      });

      onChange(getNameOfPremises("TOB_Categories"), {
        value: newDataCategories.join("|"),
      });
      setDataTOBCategories(newDataCategories);
    };

    const handleOnChangeTypeofAnaesthesia = (event: MultiSelectChangeEvent) => {
      const typeofAnaesthesia =
        (event?.value as KeyValuePair<string, string>[]) ?? [];

      let newTypeofAnaesthesiaIDs: number[] = [];
      let newTypeofAnaesthesiaName: string[] = [];

      typeofAnaesthesia.forEach((item) => {
        if (!isNil(item?.Key) && parseInt(item?.Key)) {
          newTypeofAnaesthesiaIDs.push(parseInt(item.Key));
        }
        if (!isNil(item?.Value)) {
          newTypeofAnaesthesiaName.push(item.Value);
        }
      });

      onChange(getNameOfPremises("TypeOfAnaesthesia"), {
        value: newTypeofAnaesthesiaName.join(", "),
      });
      onChange(getNameOfPremises("TypeOfAnaesthesia_IDs"), {
        value: newTypeofAnaesthesiaIDs,
      });
    };

    const handleOnSubmitPlusKeywords = (data: Keyword[]) => {
      switch (plusKeywordDialog) {
        case KEYWORD_TYPE.HealthManager_Foods:
          let newFood = valueGetter(getNameOfPremises("Foods")) ?? "";
          data.forEach((keyword: Keyword) => {
            if (isNil(newFood) || isEmpty(newFood)) {
              newFood = keyword.Name;
            } else if (!newFood.includes(keyword.Name)) {
              newFood += `\n${keyword.Name}`;
            }
          });
          onChange(getNameOfPremises("Foods"), { value: newFood });
          break;
        case KEYWORD_TYPE.HealthManager_ScopeOfService:
          let newScopeService =
            valueGetter(getNameOfPremises("ScopeOfService")) ?? "";
          data.forEach((keyword: Keyword) => {
            if (isNil(newScopeService) || isEmpty(newScopeService)) {
              newScopeService = keyword.Name;
            } else if (!newScopeService.includes(keyword.Name)) {
              newScopeService += `\n${keyword.Name}`;
            }
          });
          onChange(getNameOfPremises("ScopeOfService"), {
            value: newScopeService,
          });
          break;
        case KEYWORD_TYPE.HealthManager_SpecificInfrastructure:
          let newSI =
            valueGetter(getNameOfPremises("SpecificInfrastructure")) ?? "";
          data.forEach((keyword: Keyword) => {
            if (isNil(newSI) || isEmpty(newSI)) {
              newSI = keyword.Name;
            } else if (!newSI.includes(keyword.Name)) {
              newSI += `\n${keyword.Name}`;
            }
          });
          onChange(getNameOfPremises("SpecificInfrastructure"), {
            value: newSI,
          });
          break;
      }
      setPlusKeywordDialog(undefined);
    };
    // #endregion HANDLERS

    useEffectOnce(() => {
      if (valueGetter(getNameOfPremises("TOB_Categories"))) {
        setDataTOBCategories(
          valueGetter(getNameOfPremises("TOB_Categories")).split("|")
        );
      }
    });

    return (
      <>
        <section className="cc-field-group">
          {/* divLiquorLicence */}
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Liquor licence" />
              <Field
                name={getNameOfPremises("Flag_LiquorLicence")}
                component={CCSwitch}
                checked={valueGetter(getNameOfPremises("Flag_LiquorLicence"))}
                disabled={isInactive}
              />
            </div>
            {valueFlagLiquorLicence && (
              <>
                <div className="cc-field">
                  <CCLabel title="Licence type" />
                  <Field
                    name={getNameOfPremises("LiquorLicenceType_KWD")}
                    component={CCSearchComboBox}
                    data={premisesGeneralFormLovs?.LiquorLicenceType ?? []}
                    textField={nameOfKeyValuePacket("Value")}
                    dataItemKey={nameOfKeyValuePacket("Key")}
                    isUseDefaultOnchange
                    disabled={isInactive}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Liquor licence reference number" />
                  <Field
                    name={getNameOfPremises("LiquorLicenceRefNo")}
                    component={CCInput}
                    placeholder="Liquor licence reference number"
                    disabled={isInactive}
                  />
                </div>
              </>
            )}
          </div>

          {/* divEatInFacility */}
          <div className="cc-form-cols-3">
            {typeValueFlagEatInFacility && (
              <div className="cc-field">
                <CCLabel title="Indoor dining and drinking areas" />
                <Field
                  name={getNameOfPremises("Flag_EatInFacility")}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_EatInFacility"))}
                  disabled={isInactive}
                />
              </div>
            )}
            {typeValueFlagOutdoorDining && (
              <div className="cc-field">
                <CCLabel title="Outdoor dining and drinking" />
                <Field
                  name={getNameOfPremises("Flag_OutdoorDining")}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_OutdoorDining"))}
                  disabled={isInactive}
                />
              </div>
            )}
          </div>

          {/* divTobaccoRetail */}
          {typeValueFlagTobaccoRetail && (
            <>
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Tobacco" />
                  <Field
                    name={getNameOfPremises("Flag_TobaccoRetail")}
                    component={CCSwitch}
                    checked={valueGetter(
                      getNameOfPremises("Flag_TobaccoRetail")
                    )}
                    disabled={isInactive}
                  />
                </div>
                {valueGetter(getNameOfPremises("Flag_TobaccoRetail")) && (
                  <>
                    <div className="cc-field">
                      <CCLabel title="Number of POS" />
                      <Field
                        name={getNameOfPremises("TOB_NoOfPOS")}
                        component={CCNumericTextBox}
                        disabled={isInactive}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="POS licence to other" />
                      <Field
                        name={getNameOfPremises("TOB_OtherPOS")}
                        component={CCNumericTextBox}
                        disabled={isInactive}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Advertising location" />
                      <Field
                        name={getNameOfPremises("VM_AdvertisingLocation")}
                        component={CCInput}
                        placeholder="Advertising location"
                        disabled={isInactive}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Mobile van registration number" />
                      <Field
                        name={getNameOfPremises("VM_MobileVanRegNo")}
                        component={CCInput}
                        placeholder="Mobile van registration number"
                        disabled={isInactive}
                      />
                    </div>
                    <div className="cc-field cc-col-span-3">
                      <CCLabel title={"Categories"} />
                      <Field
                        disabled={isInactive}
                        name={getNameOfPremises("TOB_Categories")}
                        textField={nameOfKeyValuePair("Value")}
                        dataItemKey={nameOfKeyValuePair("Key")}
                        data={cbxTobCategories}
                        component={CCMultiSelectDropdown}
                        onChange={handleOnChangeCategories}
                        value={getMultipleSelectValue(
                          dataTOBCategories,
                          cbxTobCategories,
                          nameOfKeyValuePacket("Key")
                        )}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Outdoor area" />
                      <Field
                        name={getNameOfPremises("Flag_OutdoorArea")}
                        component={CCSwitch}
                        checked={valueGetter(
                          getNameOfPremises("Flag_OutdoorArea")
                        )}
                        disabled={isInactive}
                      />
                    </div>
                  </>
                )}
                <div className="cc-field">
                  <CCLabel title="E-Cigarette products" />
                  <Field
                    name={getNameOfPremises("Flag_ECigaretteProducts")}
                    component={CCSwitch}
                    checked={valueGetter(
                      getNameOfPremises("Flag_ECigaretteProducts")
                    )}
                    disabled={isInactive}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Shisha products" />
                  <Field
                    name={getNameOfPremises("Flag_ShishaProducts")}
                    component={CCSwitch}
                    checked={valueGetter(
                      getNameOfPremises("Flag_ShishaProducts")
                    )}
                    disabled={isInactive}
                  />
                </div>
              </div>

              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Vending machine" />
                  <Field
                    name={getNameOfPremises("Flag_VendingMachine")}
                    component={CCSwitch}
                    checked={valueGetter(
                      getNameOfPremises("Flag_VendingMachine")
                    )}
                    disabled={isInactive}
                  />
                </div>
                {valueGetter(getNameOfPremises("Flag_VendingMachine")) && (
                  <>
                    <div className="cc-field">
                      <CCLabel title="Number of vending machines" />
                      <Field
                        name={getNameOfPremises("VM_NoOfVendingMachine")}
                        component={CCNumericTextBox}
                        disabled={isInactive}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Licence displayed" />
                      <Field
                        name={getNameOfPremises("VM_LicenceDisplayed")}
                        component={CCSwitch}
                        checked={valueGetter(
                          getNameOfPremises("VM_LicenceDisplayed")
                        )}
                        disabled={isInactive}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Pres. notice displayed" />
                      <Field
                        name={getNameOfPremises("VM_PresNoticeDisplayed")}
                        component={CCSwitch}
                        checked={valueGetter(
                          getNameOfPremises("VM_PresNoticeDisplayed")
                        )}
                        disabled={isInactive}
                      />
                    </div>
                    <div className="cc-field">
                      <CCLabel title="Location of vending machine" />
                      <Field
                        name={getNameOfPremises("VM_Location")}
                        component={CCInput}
                        placeholder="Location of vending machine"
                        disabled={isInactive}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          {/* divPrivateWaterSupply */}
          {typeValueFlagPrivateWaterSupply && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Private water supply" />
                <Field
                  name={getNameOfPremises("Flag_PrivateWaterSupply")}
                  component={CCSwitch}
                  checked={valueGetter(
                    getNameOfPremises("Flag_PrivateWaterSupply")
                  )}
                  disabled={isInactive}
                />
              </div>
              {valueGetter(getNameOfPremises("Flag_PrivateWaterSupply")) && (
                <div className="cc-field">
                  <CCLabel title="Supply type" />
                  <Field
                    name={getNameOfPremises("PrivateWaterSupplyType_KWD")}
                    component={CCSearchComboBox}
                    data={premisesGeneralFormLovs?.PrivateWaterSupplyType ?? []}
                    textField={nameOfKeyValuePacket("Value")}
                    dataItemKey={nameOfKeyValuePacket("Key")}
                    isUseDefaultOnchange
                    disabled={isInactive}
                  />
                </div>
              )}
            </div>
          )}

          {/* divWasteWater */}
          {typeValueFlagWasteWater && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Wastewater" />
                <Field
                  name={getNameOfPremises("WasteWater_ENUM")}
                  component={CCSearchComboBox}
                  data={premisesGeneralFormLovs?.WasteWater ?? []}
                  textField={nameOfKeyValuePacket("Value")}
                  dataItemKey={nameOfKeyValuePacket("Key")}
                  isUseDefaultOnchange
                  disabled={isInactive}
                />
              </div>
            </div>
          )}

          {/* divGamingVenue */}
          {typeValueFlagGamingVenue && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Gaming venue" />
                <Field
                  name={getNameOfPremises("Flag_GamingVenue")}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_GamingVenue"))}
                  disabled={isInactive}
                />
              </div>
              {valueGetter(getNameOfPremises("Flag_GamingVenue")) && (
                <div className="cc-field">
                  <CCLabel title="Number of rooms" />
                  <Field
                    name={getNameOfPremises("GV_NoOfRooms")}
                    component={CCNumericTextBox}
                    disabled={isInactive}
                  />
                </div>
              )}
            </div>
          )}

          {/* divLicencedNonGaming */}
          {typeValueFlagLicencedNonGaming && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Licenced non gaming" />
                <Field
                  name={getNameOfPremises("Flag_LicencedNonGaming")}
                  component={CCSwitch}
                  checked={valueGetter(
                    getNameOfPremises("Flag_LicencedNonGaming")
                  )}
                  disabled={isInactive}
                />
              </div>
              {valueGetter(getNameOfPremises("Flag_LicencedNonGaming")) && (
                <div className="cc-field">
                  <CCLabel title="Number of Rooms" />
                  <Field
                    name={getNameOfPremises("LNG_NoOfRooms")}
                    component={CCNumericTextBox}
                    disabled={isInactive}
                  />
                </div>
              )}
            </div>
          )}

          <div className="cc-form-cols-3">
            {/* divBingoCentre */}
            {typeValueFlagBingoCentre && (
              <div className="cc-field">
                <CCLabel title="Bingo centre" />
                <Field
                  name={getNameOfPremises("Flag_BingoCentre")}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_BingoCentre"))}
                  disabled={isInactive}
                />
              </div>
            )}

            {/* divHomeBusiness */}
            {!checkHealthLicensing && (
              <div className="cc-field">
                <CCLabel title="Home business" />
                <Field
                  name={getNameOfPremises("Flag_HomeBusiness")}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_HomeBusiness"))}
                  disabled={isInactive}
                />
              </div>
            )}

            {/* divNoUnits - existed in general */}
            {/* {typeValueFlagNoOfUnits && (
              <div className="cc-field">
                <CCLabel title="Number of units" />
                <Field
                  name={getNameOfPremises("NoOfUnits")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                />
              </div>
            )} */}
          </div>

          {/* divNoSites */}
          {typeValueFlagNoOfSites && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Long term sites" />
                <Field
                  name={getNameOfPremises("NoOfLongTermSites")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Short term sites" />
                <Field
                  name={getNameOfPremises("NoOfShortTermSites")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                />
              </div>
            </div>
          )}

          <div className="cc-form-cols-3">
            {/* divNoRooms */}
            {typeValueFlagNoOfRooms && (
              <div className="cc-field">
                <CCLabel title="Number of rooms" />
                <Field
                  name={getNameOfPremises("NoOfRooms")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                />
              </div>
            )}

            {typeValueFlagNoOfBeds && (
              <>
                {/* divNoBeds - existed in general */}
                {/* <div className="cc-field">
                  <CCLabel title="No of beds" />
                  <Field
                    name={getNameOfPremises("NoOfBeds")}
                    component={CCNumericTextBox}
                    disabled={isInactive}
                  />
                </div> */}

                {/* divNoOccupants */}
                <div className="cc-field">
                  <CCLabel title="Number of occupants" />
                  <Field
                    name={getNameOfPremises("NoOfOccupants")}
                    component={CCNumericTextBox}
                    disabled={isInactive}
                  />
                </div>
              </>
            )}

            {/* divNoTables */}
            {typeValueFlagNoOfTables && (
              <div className="cc-field">
                <CCLabel title="Number of tables" />
                <Field
                  name={getNameOfPremises("NoOfTables")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                />
              </div>
            )}

            {/* divNoEmployees - existed in general */}
            {/* {typeValueFlagNoOfEmployees && (
              <div className="cc-field">
                <CCLabel title="Number of employees" />
                <Field
                  name={getNameOfPremises("NoOfEmployees")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                />
              </div>
            )} */}
          </div>

          {!checkHealthLicensing && (
            <>
              <div className="cc-form-cols-3">
                {/* divEmployeeTier */}
                <div className="cc-field">
                  <CCLabel title="Employee tier" />
                  <Field
                    name={getNameOfPremises("BusinessTier_KWD")}
                    component={CCSearchComboBox}
                    data={premisesGeneralFormLovs?.EmployeeTier ?? []}
                    textField={nameOfKeyValuePacket("Value")}
                    dataItemKey={nameOfKeyValuePacket("Key")}
                    isUseDefaultOnchange
                    disabled={isInactive}
                  />
                </div>

                {/* divArchiveBoxNo */}
                <div className="cc-field">
                  <CCLabel title="Archive box number" />
                  <Field
                    name={getNameOfPremises("ArchiveBoxNumber")}
                    component={CCInput}
                    placeholder="Archive box number"
                    disabled={isInactive}
                  />
                </div>

                {/* divTradingHours */}
                <div className="cc-field">
                  <CCLabel title="Trading hours" />
                  <Field
                    name={getNameOfPremises("TradingHours")}
                    component={CCTextArea}
                    placeholder="Trading hours"
                    rows={3}
                    disabled={isInactive}
                  />
                </div>
              </div>

              {/* divConsents */}
              <div className="cc-form-cols-3">
                <div className="cc-field">
                  <CCLabel title="Consent to disclose" />
                  <Field
                    name={getNameOfPremises("Flag_ConsentToDisclose")}
                    component={CCSwitch}
                    checked={valueGetter(
                      getNameOfPremises("Flag_ConsentToDisclose")
                    )}
                    disabled={isInactive}
                  />
                </div>
                <div className="cc-field">
                  <CCLabel title="Appointment required for inspection" />
                  <Field
                    name={getNameOfPremises(
                      "Flag_AppointmentRequiredForInspection"
                    )}
                    component={CCSwitch}
                    checked={valueGetter(
                      getNameOfPremises("Flag_AppointmentRequiredForInspection")
                    )}
                    disabled={isInactive}
                  />
                </div>

                {/* divConsentDate */}
                {valueGetter(getNameOfPremises("Flag_ConsentToDisclose")) && (
                  <div className="cc-field">
                    <CCLabel title="Date received" />
                    <Field
                      name={getNameOfPremises("Date_ConsentToDisclose")}
                      format={DATE_FORMAT.DATE_CONTROL}
                      component={CCDatePicker}
                      disabled={isInactive}
                    />
                  </div>
                )}
              </div>
            </>
          )}

          {/* divFSP */}
          {typeValueFlagFSP && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="FSP letter of engagement" />
                <Field
                  name={getNameOfPremises("Date_FSPLetterOfEngagement")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                  disabled={isInactive}
                />
              </div>
            </div>
          )}

          {/* divInfectionControl */}
          {typeValueFlagInfectionControl && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="IC officer" />
                <Field
                  name={getNameOfPremises("IC_ICOfficer")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Accreditation program" />
                <Field
                  name={getNameOfPremises("IC_AccredProgram")}
                  component={CCNumericTextBox}
                  disabled={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCLabel title="Expires" />
                <Field
                  name={getNameOfPremises("IC_Expires")}
                  format={DATE_FORMAT.DATE_CONTROL}
                  component={CCDatePicker}
                  disabled={isInactive}
                />
              </div>
            </div>
          )}

          {/* divFood */}
          {checkFlagFoodPremises && (
            <div className="cc-form-cols-1">
              <div className="cc-field">
                <div className="cc-label cc-justify-between cc-align-item">
                  <div className="cc-flex">High risk foods</div>
                  <Button
                    type="button"
                    iconClass="fas fa-plus"
                    onClick={() => {
                      setPlusKeywordDialog(KEYWORD_TYPE.HealthManager_Foods);
                    }}
                    disabled={isInactive}
                    title="Pick High Risk Foods"
                  />
                </div>
                <Field
                  name={getNameOfPremises("Foods")}
                  component={CCTextArea}
                  placeholder="High risk foods"
                  rows={3}
                  disabled={isInactive}
                />
              </div>
            </div>
          )}

          <div className="cc-form-cols-3">
            <>
              {/* divFoodhandlers */}
              {typeValueFlagNoOfFoodhandlers && (
                <div className="cc-field">
                  <CCLabel title="Number of food handlers" />
                  <Field
                    name={getNameOfPremises("NoOfFoodhandlers")}
                    component={CCNumericTextBox}
                    disabled={isInactive}
                  />
                </div>
              )}

              {/* divHealthLicensing */}
              {checkHealthLicensing && (
                <>
                  {/* divNoOfChairs */}
                  {typeValueFlagNoOfChairs && (
                    <div className="cc-field">
                      <CCLabel title="Number of chairs" />
                      <Field
                        name={getNameOfPremises("NoOfChairs")}
                        component={CCNumericTextBox}
                        disabled={isInactive}
                      />
                    </div>
                  )}
                  <div className="cc-field">
                    <CCLabel title="Provider number" />
                    <Field
                      name={getNameOfPremises("ProviderNumber")}
                      component={CCInput}
                      placeholder="Provider number"
                      disabled={isInactive}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="ICU" />
                    <Field
                      name={getNameOfPremises("Flag_ICU")}
                      component={CCSwitch}
                      checked={valueGetter(getNameOfPremises("Flag_ICU"))}
                      disabled={isInactive}
                    />
                  </div>
                  <div className="cc-field">
                    <CCLabel title="CSSD" />
                    <Field
                      name={getNameOfPremises("Flag_CSSD")}
                      component={CCSwitch}
                      checked={valueGetter(getNameOfPremises("Flag_CSSD"))}
                      disabled={isInactive}
                    />
                  </div>

                  <div className="cc-field cc-col-span-3">
                    <CCLabel title="Type of anaesthesia" />
                    <Field
                      disabled={isInactive}
                      name={getNameOfPremises("TypeOfAnaesthesia_IDs")}
                      textField={nameOfKeyValuePair("Value")}
                      dataItemKey={nameOfKeyValuePair("Key")}
                      data={premisesGeneralFormLovs?.TypeOfAnaesthesia ?? []}
                      component={CCMultiSelectDropdown}
                      onChange={handleOnChangeTypeofAnaesthesia}
                      value={getMultipleSelectValue(
                        valueGetter(getNameOfPremises("TypeOfAnaesthesia_IDs")),
                        premisesGeneralFormLovs?.TypeOfAnaesthesia ?? [],
                        nameOfKeyValuePacket("Key")
                      )}
                    />
                  </div>

                  <div className="cc-field">
                    <div className="cc-label cc-justify-between cc-align-item">
                      <div className="cc-flex">Scope of service</div>
                      <Button
                        type="button"
                        iconClass="fas fa-plus"
                        onClick={() => {
                          setPlusKeywordDialog(
                            KEYWORD_TYPE.HealthManager_ScopeOfService
                          );
                        }}
                        disabled={isInactive}
                        title="Pick Scope Of Service"
                      />
                    </div>
                    <Field
                      name={getNameOfPremises("ScopeOfService")}
                      component={CCTextArea}
                      placeholder="Scope of service"
                      disabled={isInactive}
                      rows={3}
                    />
                  </div>

                  <div className="cc-field">
                    <div className="cc-label cc-justify-between cc-align-item">
                      <div className="cc-flex">Specific infrastructure</div>
                      <Button
                        type="button"
                        iconClass="fas fa-plus"
                        onClick={() => {
                          setPlusKeywordDialog(
                            KEYWORD_TYPE.HealthManager_SpecificInfrastructure
                          );
                        }}
                        disabled={isInactive}
                        title="Pick Specific Infrastructure"
                      />
                    </div>
                    <Field
                      name={getNameOfPremises("SpecificInfrastructure")}
                      component={CCTextArea}
                      placeholder="Specific infrastructure"
                      disabled={isInactive}
                      rows={3}
                    />
                  </div>
                </>
              )}
            </>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Has applied for the patient service panel" />
              <Field
                name={getNameOfPremises("Flag_AppliedPatientServicePanel")}
                component={CCSwitch}
                checked={valueGetter(
                  getNameOfPremises("Flag_AppliedPatientServicePanel")
                )}
                disabled={isInactive}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Has been accepted for patient service panel" />
              <Field
                name={getNameOfPremises("Flag_AcceptedPatientServicePanel")}
                component={CCSwitch}
                checked={valueGetter(
                  getNameOfPremises("Flag_AcceptedPatientServicePanel")
                )}
                disabled={isInactive}
              />
            </div>
          </div>
        </section>

        {!isNil(plusKeywordDialog) && (
          <PlusKeywordsDialog
            productType={PRODUCT_TYPE_NUMBER.HealthManager}
            keywordType={plusKeywordDialog as KEYWORD_TYPE}
            optionsTitle={optionPlusKeywordsTitle(plusKeywordDialog)}
            onCloseDialog={() => setPlusKeywordDialog(undefined)}
            onSubmit={handleOnSubmitPlusKeywords}
            selectableMode="multiple"
          />
        )}
      </>
    );
  }
);
