export interface HMPremisesRegisterView {
  ID: number;
  TradingName: string;
  PremisesClassification: string;
  PremisesType: string;
  DateLodged: Date | null;
  DateRegistrationExpires: Date | null;
  StreetNo: string;
  Street: string;
  AddressLine1: string;
  Suburb: string;
  AssessmentNo: string;
  PostalAddress: string;
  PostalAddress2: string;
  RefNo: string;
  FileNumber: string;
  RegNo: string;
  DebtorNo: string;
  Officer: string;
  Proprietor: string;
  ProprietorABN: string;
  ProprietorACN: string;
  PrimaryContact: string;
  Area: string;
  Mobile: string;
  Phone: string;
  Email: string;
  Fax: string;
  Risk: string;
  FSP: string;
  NoEmployees: number | null;
  FSPContact: string;
  HasAlert: boolean;
  AlertText: string;
  LastInspection: Date | null;
  LastCompliance: string;
  AmountOS: number;
  HomeBusiness: boolean;
  Status_ENUM: number;
  Status: string;
  Flag_MultiTenancy: boolean;
  Flag_DeptOfEducation: boolean;
  PreviousCategory: string;
  LocationDesc: string;
  Jurisdiction: string;
}

export enum EnumPremisesColumns {
  TradingName = 0,
  RegNo = 1,
  AddressLine1 = 2,
  StreetNo = 3,
  Street = 4,
  Suburb = 5,
  Area = 6,
  Phone = 7,
  PremisesClassification = 8,
  PremisesType = 9,
  Risk = 10,
  Officer = 11,
  RefNo = 12,
  FileNumber = 13,
  DebtorNo = 14,
  AssessmentNo = 15,
  Email = 16,
  PostalAddress = 17,
  PostalAddress2 = 18,
  Proprietor = 19,
  ProprietorABN = 20,
  ProprietorACN = 21,
  NoEmployees = 22,
  PrimaryContact = 23,
  FSSContact = 24,
  DateLodged = 25,
  DateRegistrationExpires = 26,
  Fax = 27,
  FSPTemplate = 28,
  HomeBusiness = 29,
  Status = 30,
  StatusIcon = 31,
  Mobile = 32,
  LocationDesc = 33,
  Jurisdiction = 34,
}
