import {
  BOOLEAN_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { formatStyleUnRelevant } from "@common/utils/formatting";
import { IColumnFields } from "@components/cc-grid/model";
export const colExternalCardsRequiringActions: IColumnFields[] = [
  { field: "Name", title: "Name" },
  {
    field: "Action",
    title: "Action",
  },
  { field: "CustomerId", title: "Customer ID" },
  { field: "Org", title: "Org" },
  {
    field: "ConfirmationId",
    title: "Confirmation ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  { field: "Matched", title: "Matched?" },
  {
    field: "ConfirmationDate",
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
  { field: "Deceased", title: "Deceased", format: BOOLEAN_FORMAT.BOOLEAN },
  {
    field: "PensionConcession",
    title: "Pension Concession",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "GoldCard",
    title: "Gold Card",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "Disability",
    title: "Disability",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "FirstName",
    title: "First Name",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "Surname",
    title: "Surname",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  {
    field: "PostcodeMatched",
    title: "Postcode Matched",
    format: BOOLEAN_FORMAT.BOOLEAN,
  },
  { field: "Assessment103", title: "Assessment (10.3)" },
  {
    field: "AssessmentId",
    title: "Assessment ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
  {
    field: "EntityId",
    title: "Entity ID",
    format: NUMBER_FORMAT.NUMBER2,
    style: formatStyleUnRelevant,
  },
];
