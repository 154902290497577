export const mockReportItems = {
  CentreLinkConcessionCards: [
    { Title: "Report Centrelink Responses", IsDisable: false },
    { Title: "Report External (DVA) Responses", IsDisable: true },
    { Title: "Report Actions", IsDisable: true },
  ],
  CentreLinkCardsRequiringActions: [
    { Title: "Report Centrelink Responses", IsDisable: true },
    { Title: "Report External (DVA) Responses", IsDisable: true },
    { Title: "Report Actions", IsDisable: false },
  ],
  ExternalConcessionCards: [
    { Title: "Report Centrelink Responses", IsDisable: true },
    { Title: "Report External (DVA) Responses", IsDisable: false },
    { Title: "Report Actions", IsDisable: true },
  ],
  ExternalCardsRequiringActions: [
    { Title: "Report Centrelink Responses", IsDisable: true },
    { Title: "Report External (DVA) Responses", IsDisable: true },
    { Title: "Report Actions", IsDisable: false },
  ],
};
