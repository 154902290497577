import { colSupplementMasterProperty } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/config";
import { ISupplementaryRatesMasterProperty } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/model";
import { useSupplementaryRatesMasterPropertyStore } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/store";
import { processDynamicColumns } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";

const nameOf = nameOfFactory<ISupplementaryRatesMasterProperty>();
export const SupplementaryMasterProperties = observer(() => {
  const { id } = useParams<{ id: string }>();
  const {
    responseLoadError,
    isLoading,
    viewConfiguration,
    loadSupplementaryRatesMasterPropertyConfig,
    resetStore,
    setMasterPropertySelected,
    masterPropertySelected,
  } = useSupplementaryRatesMasterPropertyStore();

  useEffectOnce(() => {
    loadSupplementaryRatesMasterPropertyConfig();
    return () => {
      resetStore();
    };
  });

  return isLoading ? (
    <Loading isLoading={isLoading} />
  ) : responseLoadError ? (
    <CCLoadFailed
      responseError={responseLoadError}
      onReload={() => {
        loadSupplementaryRatesMasterPropertyConfig();
      }}
    />
  ) : (
    <div className="cc-supplementary-rates-master-property">
      <CCGrid
        onSelectionChange={(dataItem: ISupplementaryRatesMasterProperty[]) => {
          setMasterPropertySelected(dataItem);
        }}
        selectableMode="single"
        selectedRows={masterPropertySelected}
        dataUrl={`/odata/property/internal/supplementaryratesmasterpropertyregister/${id}?$count=true&`}
        columnFields={processDynamicColumns(
          colSupplementMasterProperty,
          viewConfiguration
        )}
        primaryField={nameOf("Master_Property_Id")}
      />
    </div>
  );
});
