import { DeleteButton } from "@app/core/delete/buttons/_index";
import { InspectionContactsTab } from "@app/core/inspections/[id]/components/reference-sidebar/contacts/_index";
import { InspectionDetailsTab } from "@app/core/inspections/[id]/components/reference-sidebar/details/_index";
import { InspectionHistoryTab } from "@app/core/inspections/[id]/components/reference-sidebar/history/_index";
import { INSPECTIONS_MANAGE_ROUTE } from "@app/core/inspections/[id]/constant";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { AnimalActionBarNavDropdown } from "@app/products/animals/components/action-bar/nav-dropdown/_index";
import { colAnimalsInspectionsByMonth } from "@app/products/animals/inspections/by-month/config";
import { AnimalInspectionByMonthBookmark } from "@app/products/animals/inspections/by-month/util";
import { InspectionsView } from "@app/products/animals/inspections/model";
import {
  AnimalInspectionsBookmark,
  getInspectionsByAreaView,
} from "@app/products/animals/inspections/util";
import { animalRoute } from "@app/products/animals/route";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
import { inspectionsRoute } from "../route";

const nameOf = nameOfFactory<InspectionsView>();
export default () => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Inspection,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });
  useCCListViewActionBar({
    title: animalRoute.name,
    leftComponents: [
      <AnimalActionBarNavDropdown category={inspectionsRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.Animals}
        dataSetFilter={MAILMERGEDATASET.Animals_Inspections}
        recordType={RECORDTYPE.CORE_Inspection}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <DeleteButton
        recordType={RECORDTYPE.CORE_Inspection}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={INSPECTIONS_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.Animals}
        recordType={RECORDTYPE.CORE_Inspection}
        detail={AnimalInspectionsBookmark.getBookmarkDetail}
        displayName={AnimalInspectionsBookmark.getBookmarkDisplayName}
        listViewDetail={
          AnimalInspectionByMonthBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          AnimalInspectionByMonthBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <InspectionDetailsTab /> },
      {
        title: "Contacts",
        component: <InspectionContactsTab />,
      },
      {
        title: "History",
        component: <InspectionHistoryTab />,
      },
    ],
  });
  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={colAnimalsInspectionsByMonth}
        state={{
          group: [{ field: nameOf("DateInspectedMonth") }],
          sort: [{ field: nameOf("DateInspected"), dir: "desc" }],
        }}
        dataUrl={getInspectionsByAreaView(false)}
        primaryField={nameOf("ID")}
      />
    </LoadingPermissionWrapper>
  );
};
