import { usePropertyWorkflowStore } from "@app/products/property/components/action-bar/property-workflow/store";
import { WorkflowTypes } from "@app/products/property/model";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

interface IRaiseInterestCommonButtonProps {
  isShowAssessmentInfo?: boolean;
  buttonName?: string;
  isDisabled?: boolean;
  assessmentID?: number;
}
export const RaiseAssessmentInterestCommonButton = ({
  isShowAssessmentInfo = false,
  buttonName = "Raise interest",
  isDisabled = false,
  assessmentID,
}: IRaiseInterestCommonButtonProps) => {
  const { setListDialog } = usePropertyWorkflowStore();
  const { id } = useParams<{ id: string }>();

  const assessmentId = useMemo(() => {
    return id ?? assessmentID;
  }, [assessmentID, id]);

  return (
    <CCNavButton
      title={buttonName}
      disabled={isDisabled}
      onClick={() => {
        setListDialog([
          {
            type: WorkflowTypes.Journal_Assessment_Raise_Interest,
            data: {},
            props: { isShowAssessmentInfo, assessmentId },
          },
        ]);
      }}
    />
  );
};
