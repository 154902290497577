import { isSuccessIdentityPacket } from "@common/apis/util";
import { putRescheduleAction } from "@common/pages/actions/components/action-bar/buttons/reschedule-action/api";
import {
  IRescheduleAction,
  RescheduleActionDialog,
} from "@common/pages/actions/components/action-bar/dialog/reschedule-action/_index";
import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

export const RescheduleActionButton = observer(() => {
  const { action, reLoadAction } = useActionStore();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clearErrorNotification, pushNotification } =
    useCCAppNotificationStore();

  const handleRescheduleAction = async (data: IRescheduleAction) => {
    clearErrorNotification();
    setIsLoading(true);
    const response = await putRescheduleAction(parseInt(id), data);
    setIsLoading(false);
    setIsShowDialog(false);
    if (isSuccessIdentityPacket(response)) {
      reLoadAction().then(() => {
        if (history.action !== "POP") {
          history.goBack();
        } else {
          reLoadAction().then(() => {
            pushNotification({
              type: "success",
              title: "Reschedule action successfully",
            });
          });
        }
      });
    } else {
      pushNotification({
        autoClose: false,
        title: "Reschedule action failed",
        type: "error",
      });
    }
  };

  const isVisible = useMemo(() => {
    return (
      (action?.Status_ENUM === ACTIONSTATUS.AWAITINGAPPROVAL ||
        action?.Status_ENUM === ACTIONSTATUS.ALLOCATED) &&
      action?.ActionRequired
    );
  }, [action]);

  return isVisible ? (
    <>
      <CCNavButton
        title="Reschedule action"
        onClick={() => setIsShowDialog(true)}
      />
      {isShowDialog && (
        <RescheduleActionDialog
          onClosePopup={() => setIsShowDialog(false)}
          onSubmit={handleRescheduleAction}
          isSubmitting={isLoading}
        />
      )}
    </>
  ) : null;
});
