import { RECORDTYPE } from "@common/constants/recordtype";
import { Action, IParentActionSection } from "@common/pages/actions/model";
import { ACTIONS_ROUTE } from "@common/pages/actions/[id]/constant";
import { ACTIONSTATUS } from "@common/pages/actions/[id]/model";
import { useActionStore } from "@common/pages/actions/[id]/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";

interface IAddFurtherMeetingRequiredButton {
  id?: number;
  recordType: RECORDTYPE;
}

export const FurtherMeetingRequiredButton = ({
  id,
  recordType,
}: IAddFurtherMeetingRequiredButton) => {
  const history = useHistory();
  const { action } = useActionStore();
  const isVisible = useMemo(() => {
    return (
      action?.ActionType &&
      !action?.ActionType?.IsRegulatoryAction &&
      (action?.Status_ENUM === ACTIONSTATUS.NEW ||
        action?.Status_ENUM === ACTIONSTATUS.APPROVED ||
        action?.Status_ENUM === ACTIONSTATUS.COMPLETE) &&
      action?.ActionType?.Flag_IsMeeting
    );
  }, [action]);
  return isVisible ? (
    <CCNavButton
      title="Further meeting required"
      onClick={() => {
        history.push(`${ACTIONS_ROUTE}/new`, {
          parent: {
            id: id,
            recordType: recordType,
            data: {
              ActionOfficer_ID: action?.ActionOfficer_ID,
              ActionOfficer: {
                DisplayName: action?.ActionOfficer?.DisplayName,
              },
            } as Action,
            actionType: {
              ID: action?.ActionType.ActionType_ID,
              Name: action?.ActionType.Name,
            },
          } as IParentActionSection,
        });
      }}
    />
  ) : null;
};
