import { eventEmitter } from "@/App";
import { odataCoreGetConditions } from "@app/core/components/common/utils";
import { renderConditionColumnConfig } from "@app/core/condition/condition-accordion/config";
import { ConditionView } from "@app/core/condition/condition-accordion/model";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { YieldNotificationPortal_DELETE_CONFIRMATION_DIALOG } from "@app/core/delete/dialogs/config";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { getBoolValueSetting } from "@common/stores/products/util";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { CCGridEventType } from "@components/cc-grid/constant";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IConditionsProps {
  id?: number | string;
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<ConditionView>();
const Conditions: React.FC<IConditionsProps> = observer(
  ({ id, recordType }) => {
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] =
      useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedRows, setSelectedRows] = useState<ConditionView[]>([]);
    const { settings } = useCommonCoreStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { isDisabled } = useTabTableStore();
    const isAdhocEnableAdvancedMode = getBoolValueSetting(
      settings[
        ECorporateSettingsField
          .CorporateSettings_Conditions_Adhoc_EnableAdvancedMode
      ]
    );

    const deleteConditionSlider = useFlexibleFetchData({
      slides: [
        {
          fetch: ({ initialData }) => {
            const { Reason } = initialData as IDeleteConfirmation;
            setIsDeleting(true);
            return deleteRefUsageByIdAndRecordType(
              selectedRows.map((row) => row.ID),
              RECORDTYPE.CORE_Condition,
              Reason
            );
          },
          handleSuccess: () => {
            eventEmitter.emit(CCJournalEventType.RefreshData);
            eventEmitter.emit(TabTableEventType.RefreshData);
            eventEmitter.emit(CCGridEventType.RefreshOData);
            setShowConfirmDeleteDialog(false);
            setSelectedRows([]);
            setIsDeleting(false);
            pushNotificationPortal({
              title: "Condition deleted successfully",
              type: "success",
            });
            setIsDeleting(false);
          },
          handleError: ({ errorFromApi }) => {
            setIsDeleting(false);
            pushNotificationPortal({
              placeId: YieldNotificationPortal_DELETE_CONFIRMATION_DIALOG,
              title: "Condition deleted failed",
              type: "error",
              description: errorFromApi?.error,
              autoClose: false,
            });
          },
        },
      ],
    });

    const handleDelete = (data: IDeleteConfirmation) => {
      deleteConditionSlider.fetchApi({ initialData: data });
    };

    return (
      <>
        <CCGrid
          selectableMode="single"
          editableMode={"cell"}
          columnFields={renderConditionColumnConfig(
            recordType,
            isAdhocEnableAdvancedMode
          )}
          primaryField={nameOf("ID")}
          dataUrl={id ? odataCoreGetConditions(id, recordType) : undefined}
          state={{ sort: [{ field: nameOf("SortIndex"), dir: "asc" }] }}
          onSelectionChange={setSelectedRows}
          toolbar={
            <div className="cc-grid-tools-bar">
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={selectedRows.length !== 1 || isDeleting || isDisabled}
                onClick={() => {
                  setShowConfirmDeleteDialog(true);
                }}
              />
            </div>
          }
        />
        {showConfirmDeleteDialog && (
          <ConfirmDelete
            onClose={() => {
              setShowConfirmDeleteDialog(false);
            }}
            header={"Delete Confirmation"}
            contentDelete={"selected condition"}
            handleSubmit={handleDelete}
            isDeleting={isDeleting}
          />
        )}
      </>
    );
  }
);

export default Conditions;
