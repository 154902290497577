import { IListDialog } from "@app/products/property/components/action-bar/property-workflow/model";
import {
  DTO_Request_Base,
  DTO_WorkflowDetail_Base,
  DTO_WorkflowHeader,
} from "@app/products/property/model";
import { DTO_LOV } from "@common/models/odataResponse";

export enum EKeysOfAddRebateSteps {
  Details = "Details",
  RebateEntitlement = "RebateEntitlement",
  ConcessionCard = "ConcessionCard",
  Calculations = "Calculations",
  Comments = "Comments",
  Documents = "Documents",
  SecondaryWorkflow = "SecondaryWorkflow",
}

export const keysOfAddRebateSendSteps = [
  EKeysOfAddRebateSteps.Details,
  EKeysOfAddRebateSteps.RebateEntitlement,
  EKeysOfAddRebateSteps.ConcessionCard,
  EKeysOfAddRebateSteps.SecondaryWorkflow,
];

export interface RequestNewRebateObj extends DTO_Request_Base {
  AssessmentId: number;
}

export interface DTO_Workflow_NewRebate {
  WorkflowHeader: DTO_WorkflowHeader;
  WorkflowDetail: DTO_WorkflowDetail_NewRebate;
}

export interface DTO_WorkflowDetail_NewRebate extends DTO_WorkflowDetail_Base {
  AssessmentId: number;
  Details: DTO_Detail;
  RebateEntitlement: DTO_NewRebate_Entitlement;
  ConcessionCard: DTO_ConcessionCard;
  LOVs: DTO_AssessmentRebate_LOVs;
  //Using for Finish feature => show secondary workflow
  SecondaryWorkflowSelectedList?: IListDialog[];
}

export interface DTO_Detail {
  ApplicantName: string;
  ReasonId: number;
  Reference: string;
  Holdings: DTO_Assessment[];
}

export interface DTO_Assessment {
  Official_Address_StringOID: number | null;
  LGA: string;
  Active_From: Date | null;
  Active_To: Date | null;
  RLP_Board_Id: number | null;
  RLP_Board_Name: string;
  Valuation_1: number | null;
  Valuation_2: number | null;
  Valuation_3: number | null;
  Valuation_4: number | null;
  Valuation_5: number | null;
  Valuation_6: number | null;
  Valuation_8: number | null;
  GurasId: number | null;
  Valuation_9: number | null;
  Valuation_10: number | null;
  Valuation_11: number | null;
  Valuation_12: number | null;
  Valuation_13: number | null;
  Valuation_14: number | null;
  Valuation_15: number | null;
  Valuation_16: number | null;
  Valuation_17: number | null;
  Valuation_18: number | null;
  Valuation_19: number | null;
  Valuation_7: number | null;
  Region_Name: string;
  PIC_Assessment_Id: number | null;
  Valuation_20: number | null;
  Assessment_Id: number;
  Assess_Number: number | null;
  Assess_NumberX: string;
  Assess_Reference: string;
  Assess_Property_Address: string;
  Ratepayer_Name: string;
  Assess_Legal_Description: string;
  Unit_Entitlement: number | null;
  Unit_Entitlement_Percentage: number | null;
  Unit_Type: string;
  Unit_Type_Percentage: number | null;
  Assess_Balance: number;
  Region_Id: number | null;
  Assess_Balance_Outstanding: number | null;
  Assessment_Group_Id: number;
  Assessment_Group_Name: string;
  Valuation_Group_Name: string;
  Assessment_Type: number;
  Assessment_Type_Name: string;
  Assessment_Status: number;
  Assessment_Status_Name: string;
  Primary_Assess_Land_Use_Id: number | null;
  Primary_Assess_Land_Use_Name: string;
  AS_Is_Assessment_Editable: boolean;
  LinkedTitles: DTO_Assessment_LinkedTitle[];
  Is_Primary_Assessment: boolean | null;
  Assess_Being_Postponed: boolean;
}

export interface DTO_Assessment_LinkedTitle {
  Title_Id: number;
  LinkedAssessments: number[];
}

export interface DTO_NewRebate_Entitlement {
  SA_PercentageOfRebate: number;
  EntitledToAlternateRebateCap: boolean | null;
  ApplicationDate: Date;
  EffectiveDate: Date;
  Notes: string;
  RebateType_RatingPeriod: DTO_NewRebate_RebateTypeRatingPeriod[];
}

export interface DTO_ConcessionCard {
  Owners: DTO_Owners[];
}

export interface DTO_NewRebate_RebateTypeRatingPeriod {
  RebateType_RatingPeriod?: DTO_RebateType_RatingPeriod;
  Parcels: DTO_Parcel[];
  Lots: DTO_Title[];
  Owners: DTO_Owners[];
}

export interface DTO_RebateType_RatingPeriod {
  OverrideCurtilage: boolean | null;
  ApplyCurtilage: boolean | null;
  Is_Entitle: boolean | null;
  RTRP_Default_Curtilage_Percentage: number | null;
  RTRP_Default_Curtilage: number | null;
  RTRP_Alternate_Amount: number | null;
  RTRP_Maximum: number | null;
  RTRP_Rate: number | null;
  Rating_Period_Id: number;
  Is_ConcessionHeld: boolean | null;
  RT_Is_ParcelBased: boolean;
  RT_Is_Active: boolean;
  RT_Description: string;
  RT_Code: string;
  RT_Name: string;
  Rebate_Authority_Id: number;
  Percentage: number | null;
  Rebate_Type_Id: number;
  CurtilageRate: number | null;
  CurtilateArea: number | null;
}

export interface DTO_Parcel {
  Status: string;
  LandUse: string;
  Zones: string;
  LandArea: string;
  FederalElectoralDivision: string;
  StateCodeElectoralDivision: string;
  ElectoralDivision: string;
  CensusDistrict: string;
  LegalDescription: string;
  Locality: string;
  ParcelReference: string;
  PropertyAddress: string;
  Parcel_Id: number;
  OwnerName: string;
  Is_Entitle: boolean | null;
}

export interface DTO_Title {
  IsAreaSurveyed: boolean;
  AssociatedPICAreaTotal: number | null;
  AssociatedPICArea: number | null;
  AssociatedPICAreaSet: number | null;
  Frontage: number | null;
  County: string;
  Parish: string;
  Section: string;
  GISReference: string;
  LinkedAssessments: DTO_Title_LinkedAssessment[];
  PIC_Title_Id: number | null;
  Active_To: Date | null;
  NameAddress: string;
  AssociationFactorPercentage: number | null;
  StatusId: number | null;
  CadId: number | null;
  LLS_Property_ID: number | null;
  PIC_ID: string;
  PIC: string;
  Title_Is_Part_of_Lot: boolean;
  GIS_GlobalID: string;
  isAddedToGridAfterSpatial: boolean;
  Is_Entitle: boolean | null;
  Active_From: Date | null;
  Coverage: string;
  DivisionDate: Date | null;
  Status: string;
  Folio: string;
  Volume: string;
  Lot: string;
  PlanNumber: string;
  PlanType: string;
  ReferenceNumber: number | null;
  LegalDescription: string;
  TitleId: number;
  LandArea: number | null;
}

export interface RequestTitleObj {
  TitleId: number;
}

export interface DTO_Title_LinkedAssessment {
  Assessment_Id: number;
  LinkedTitles: number[];
}

export interface mTitleDISInterface {
  GIS_Interface_Id: number;
  GIS_Reference: string;
  GIS_Change_DateTime: Date;
  GIS_Change_UserId: string;
}

export interface DTO_Owners {
  Entity_Id: number;
  Name: string;
  Ownership_Percentage: number | null;
  Is_Entitle: boolean | null;
  ConcessionCards?: DTO_Rebate_Concession_Card[];
}

export interface DTO_Rebate_Concession_Card {
  Id: number;
  Concession_Card: string;
  Card_Number: string;
  Is_Active: boolean;
  Presented_Date: Date | null;
  Confirmation_Date: Date | null;
}

export interface DTO_AssessmentRebate_LOVs {
  ReasonForCreate: DTO_LOV[];
  Officers: DTO_LOV[];
}
