import { EPrivilegesWorkflow } from "@app/products/property/model";
import { useSupplementaryRatesStore } from "@app/products/property/supplementary-rates/[id]/store";
import { PrivilegeWrapper } from "@common/hocs/privileges/privileges-wrapper";
import { ePermissionPrivilegeTypeCheck } from "@common/stores/products/util";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { observer } from "mobx-react-lite";
import React from "react";

export const AddSupplementaryMasterPropertyButton = observer(() => {
  const { supplementaryAssessments } = useSupplementaryRatesStore();

  return (
    <PrivilegeWrapper
      dialogType={ePermissionPrivilegeTypeCheck.Workflow}
      privilegesEnum={EPrivilegesWorkflow.AddSupplementaryMasterProperty}
    >
      <CCNavButton
        title="Add"
        disabled={supplementaryAssessments?.SupplementaryDetail?.IsComplete}
      />
    </PrivilegeWrapper>
  );
});
