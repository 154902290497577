import { PRODUCT_TYPE } from "@common/constants/productType";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { eMenuAction } from "@common/stores/products/enum";

export type SettingsMap = {
  [key in ECorporateSettingsField]?: SettingField;
};

export interface IGroupSettingField {
  groupId: number;
  name: string;
  settingFields: SettingField[];
  isEditing: boolean;
  status?: any;
}

export interface ICollapseState {
  [key: number]: boolean;
}

export interface ILookupItem {
  Id: string;
  Name: string;
}

export interface ISettingManagerForm {
  data: SettingFolder;
  isNew: boolean;
  lastID?: any;
}

export enum ESettingsCategoryNameProperty {
  Assessment = "Assessment",
  Certificate = "Certificate",
  ChangeOfOwnership = "ChangeOfOwnership",
  CollectionTypes = "CollectionTypes",
  DigitalMailbox = "DigitalMailbox",
  DeferredDuty = "DeferredDuty",
  Entity = "Entity",
  Enquiry = "Enquiry",
  GIS = "GIS",
  Integration = "Integration",
  Journal = "Journal",
  LeaseTypes = "LeaseTypes",
  Meter = "Meter",
  Notice = "Notice",
  Organisation = "Organisation",
  Options = "Options",
  Parcel = "Parcel",
  Register = "Register",
  Report = "Report",
  Scheme = "Scheme",
  Title = "Title",
  Tasks = "Tasks",
  Workflows = "Workflows",
  Documents = "Documents",
}

export enum ESettingsCategoryPropertyPathName {
  Assessment = "assessment",
  Certificate = "certificate",
  ChangeOfOwnership = "change-of-ownership",
  CollectionTypes = "collection-types",
  DigitalMailbox = "digital-mailbox",
  DeferredDuty = "deferred-duty",
  Entity = "entity",
  Enquiry = "enquiry",
  GIS = "gis",
  Integration = "integration",
  Journal = "journal",
  LeaseTypes = "lease-types",
  Meter = "meter",
  Notice = "notice",
  Organisation = "organisation",
  Options = "options",
  Parcel = "parcel",
  Register = "register",
  Report = "report",
  Scheme = "scheme",
  Title = "title",
  Tasks = "tasks",
  Workflows = "workflows",
  Documents = "documents",
}
export type TCategoryNamePropertySettingKey =
  keyof typeof mappingCategoryNamePropertySetting;
export const mappingCategoryNamePropertySetting = {
  [eMenuAction.SettingsCategory_Assessment]:
    ESettingsCategoryNameProperty.Assessment,
  [eMenuAction.SettingsCategory_Certificate]:
    ESettingsCategoryNameProperty.Certificate,
  [eMenuAction.SettingsCategory_ChangeOfOwnership]:
    ESettingsCategoryNameProperty.ChangeOfOwnership,
  [eMenuAction.SettingsCategory_CollectionTypes]:
    ESettingsCategoryNameProperty.CollectionTypes,
  [eMenuAction.SettingsCategory_DigitalMailbox]:
    ESettingsCategoryNameProperty.DigitalMailbox,
  [eMenuAction.SettingsCategory_DeferredDuty]:
    ESettingsCategoryNameProperty.DeferredDuty,
  [eMenuAction.SettingsCategory_Entity]: ESettingsCategoryNameProperty.Entity,
  [eMenuAction.SettingsCategory_Enquiry]: ESettingsCategoryNameProperty.Enquiry,
  [eMenuAction.SettingsCategory_GIS]: ESettingsCategoryNameProperty.GIS,
  [eMenuAction.SettingsCategory_Integration]:
    ESettingsCategoryNameProperty.Integration,
  [eMenuAction.SettingsCategory_Journal]: ESettingsCategoryNameProperty.Journal,
  [eMenuAction.SettingsCategory_LeaseTypes]:
    ESettingsCategoryNameProperty.LeaseTypes,
  [eMenuAction.SettingsCategory_Meter]: ESettingsCategoryNameProperty.Meter,
  [eMenuAction.SettingsCategory_Notice]: ESettingsCategoryNameProperty.Notice,
  [eMenuAction.SettingsCategory_Organisation]:
    ESettingsCategoryNameProperty.Organisation,
  [eMenuAction.SettingsCategory_Options]: ESettingsCategoryNameProperty.Options,
  [eMenuAction.SettingsCategory_Parcel]: ESettingsCategoryNameProperty.Parcel,
  [eMenuAction.SettingsCategory_Register]:
    ESettingsCategoryNameProperty.Register,
  [eMenuAction.SettingsCategory_Report]: ESettingsCategoryNameProperty.Report,
  [eMenuAction.SettingsCategory_Scheme]: ESettingsCategoryNameProperty.Scheme,
  [eMenuAction.SettingsCategory_Title]: ESettingsCategoryNameProperty.Title,
  [eMenuAction.SettingsCategory_Tasks]: ESettingsCategoryNameProperty.Tasks,
  [eMenuAction.SettingsCategory_Workflows]:
    ESettingsCategoryNameProperty.Workflows,
  [eMenuAction.SettingsCategory_Documents]:
    ESettingsCategoryNameProperty.Documents,
};

export interface ICategoryNameSetting {
  CategoryName: string;
  ProductType: PRODUCT_TYPE;
}

export interface SettingFolder {
  Name: string;
  Title: string;
  Description?: string;
  ItemId: string;
  ProductType_Enum: PRODUCT_TYPE;
  SettingCategoryName: string;
  IdField?: string;
  IdColumnHeader?: string;
  TitleColumnHeader?: string;
  DescriptionColumnHeader?: string;
  Mode?: SettingFolderMode;
  IsSelected?: boolean;
  IsEditable?: boolean;
  IsChildFolder?: boolean;
  UseIsActive?: boolean;
  IsActive?: boolean;
  IsNew?: boolean;
  CanClone?: boolean;
  CanDelete?: boolean;
  LinkField?: string;
  GridFields?: SettingGridField[];
  EditGroups?: SettingEditGroup[];
  ParentFolders?: SettingParentFolder[];
  SettingFields: SettingField[];
  SettingsFolders?: SettingFolder[];
  EditedItems?: SettingFolder[];
  ActionType?: SettingActionType;
  LoadFields?: boolean;
  ChildGridFields?: SettingGridField[];
  MinimumDeleteableId?: number;
  NameField?: string;
  CanRefreshCache?: boolean;
}

export interface SettingParentFolder {
  Name: string;
  Title: string;
}

export interface SettingGridField {
  Name: string;
  Title: string;
  Value: any;
  DataType?: SettingsDataType;
  MaxLength?: number;
  SortOrder?: SettingsSortOrder;
}

export interface AddDeleteIds {
  AddIds: number[];
  DeleteIds: number[];
  DeleteIdsString: string;
}

export interface AddDeleteStringIds {
  AddIds: string[];
  DeleteIds: string[];
  DeleteIdsString: string;
}

export interface SimpleSetting {
  Id: string;
  Name: string;
  Description: string;
  IsActive?: boolean;
}

export interface SelectOnlyItem {
  ChildId?: number;
  ChildName: string;
  IsSelected: boolean;
}

export enum SettingsDataType {
  String = "String",
  Int = "Int",
  Boolean = "Boolean",
  Date = "Date",
  Decimal = "Decimal",
  Lookup = "Lookup",
  List = "List",
  Label = "Label",
  Money = "Money",
  DateTime = "DateTime",
  Percent = "Percent",
  Button = "Button",
}

export enum SettingsSortOrder {
  None = "None",
  ASC = "ASC",
  DESC = "DESC",
}

export enum SettingFolderMode {
  SettingsFolder = "SettingsFolder",
  SettingsItem = "SettingsItem",
  ListField = "ListField",
  SelectItems = "SelectItems",
  AddDeleteItems = "AddDeleteItems",
}

export enum SettingActionType {
  Read = 0,
  Insert,
  Update,
  Delete,
}

export interface SettingEditGroup {
  Name: string;
  GroupId: number;
}

export interface SettingField {
  FieldName: string;
  Title: string;
  Description: string;
  EditGroupId?: number;
  DisplayOrder?: number;
  DataType: SettingsDataType;
  IsMandatory?: boolean;
  IsVisible?: boolean;
  IsBigField?: boolean;
  IsReadOnly?: boolean;
  IsReadOnlyOnEdit?: boolean;
  MinValue?: number;
  MaxValue?: number;
  MaxDecimalPlaces?: number;
  MaxLength?: number;
  LookupName: string;
  ListItems: SettingFolder;
  Value: any;
  ValueBlobs: ValueBlob[];
  KeyValueBlob: string;
  LookupItems: ILookupItem[];
  SelectedLookupDescription: any;
  CorporateSettingsField_ENUM: ECorporateSettingsField;
  CorporateSettingsField_Int: number;
  ValueDisplayDetails: any[];
  HelpText: string;
  IsKeyValueBlob?: boolean;
  DateTimeFormat: string;
  IsRemoveComma?: boolean;
}
export interface SettingLookup {
  LookupName: string;
  Items: any[];
}

export interface SettingLookupItem {
  Id: string;
  Name: string;
  IsActive?: boolean;
}

export interface ValueBlob {
  FileName: string;
  ContentType: string;
  FileContent: string;
}

export enum EPropertySettingOption {
  UseAssessRefAsPrimary = "UseAssessRefAsPrimary",
  UsingAssessReference = "UsingAssessReference",
}
