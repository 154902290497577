import { Addresses } from "@app/core/addresses/_index";
import { AddAttachmentButton } from "@app/core/attachment/components/buttons/add-attachment/_index";
import { Comments } from "@app/core/comments/_index";
import { AddCommentButton } from "@app/core/comments/components/buttons/add-comment/_index";
import { AdditionalContactButton } from "@app/core/contacts/components/buttons/additional-contact/_index";
import { ContactAccordion } from "@app/core/contacts/components/contact-accordion/_index";
import { Documents } from "@app/core/documents/_index";
import { AddDocumentButton } from "@app/core/documents/components/buttons/add-document/_index";
import { LocationRegisterForm } from "@app/core/location-register/[id]/components/forms/components/child-screens/general/_index";
import { useLocationRegisterStore } from "@app/core/location-register/[id]/store";
import { LocationRegisterDetailsTab } from "@app/core/location-register/components/reference-sidebar/details/_index";
import { LocationRegisterHistoryTab } from "@app/core/location-register/components/reference-sidebar/history/_index";
import { MAILMERGEDATASET } from "@app/core/mail-merge/buttons/constant";
import { ITabProps, TabTable } from "@app/core/tab-table/_index";
import { TabTableType } from "@app/core/tab-table/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { PPRActions } from "@app/products/town-planning/ppr/[id]/components/child-screens/actions/_index";
import { AddActionWithParentButton } from "@app/products/town-planning/ppr/components/action-bar/buttons/add/action/_index";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { useIsNew } from "@common/hooks/useIsNew";
import { ActionSubmitActions } from "@common/pages/actions/model";
import { useGlobalStore } from "@common/stores/global/store";
import { CCAppNotification } from "@components/cc-app-notification/_index";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { FormTitle } from "@components/cc-form/title/_index";
import { ActionBarBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/_index";
import { IBookmarkItem } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/model";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { CCManagePageActionBar } from "@components/cc-manage-page-action-bar/_index";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { ReferenceSideBar } from "@components/cc-reference-sidebar/_index";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export const ExistedLocationRegister = observer(() => {
  const isNew = useIsNew();
  const { currentUserInfo } = useGlobalStore();
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_ManageLocationRegister,
    productType: PRODUCT_TYPE_NUMBER.CustomerService,
  });
  const { id } = useParams<{ id: string }>();
  const managePageUrl = window.location.pathname;

  const {
    isLoading,
    responseLoadError,
    locationRegisterID,
    locationRegister,
    loadLocationRegister,
    onSubmit,
  } = useLocationRegisterStore();

  const [showSlideBar, setShowSlideBar] = useState(true);

  const bookmarkList: IBookmarkItem[] = [
    {
      Text: `Core - Location register`,
      LinkUrl: managePageUrl,
      LinkText: `Core - Location register ${locationRegisterID ?? 0}`,
      User_ID: currentUserInfo?.UserPKID ? +currentUserInfo.UserPKID : 0,
      ProductType_ENUM: PRODUCT_TYPE.Core,
      RecordType_ENUM: RECORDTYPE.CORE_Register,
      Record_ID: locationRegisterID ? +locationRegisterID : 0,
    },
  ];

  const listPanelBar: ITabProps[] = [
    {
      title: "Addresses",
      tabType: TabTableType.Addresses,
      component: (
        <Addresses id={parseInt(id)} recordType={RECORDTYPE.CORE_Register} />
      ),
    },
    {
      title: "Actions",
      tabType: TabTableType.Actions,
      component: (
        <PPRActions id={parseInt(id)} recordType={RECORDTYPE.CORE_Register} />
      ),
    },
    {
      title: "Comments",
      tabType: TabTableType.Comments,
      component: <Comments id={id} recordType={RECORDTYPE.CORE_Register} />,
    },
    {
      title: "Contacts",
      tabType: TabTableType.Contacts,
      component: (
        <ContactAccordion
          id={parseInt(id)}
          recordType={RECORDTYPE.CORE_Register}
        />
      ),
    },
    {
      title: "Documents",
      tabType: TabTableType.Documents,
      component: (
        <Documents id={parseInt(id)} recordType={RECORDTYPE.CORE_Register} />
      ),
    },
  ];

  return (
    <>
      <Loading isLoading={isLoading || isLoadingPermission} isFullScreen />
      {responseLoadError ? (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            loadLocationRegister(parseInt(id), isNew);
          }}
        />
      ) : (
        <>
          <FormNavigation title="Location Register" />
          <FormTitle title={`Location Register - ${id}`} />

          <CCManagePageActionBar
            leftActions={[
              <CCNavButton
                title={"Save"}
                onClick={onSubmit}
                name={ActionSubmitActions.Save}
                disabled={
                  !checkPermissions([
                    FormAction.CORE_ALLOW_SAVE,
                    FormAction.CORE_ALLOW_EDIT,
                  ])
                }
              />,
              <CCNavButton title={"Add"} type="sub">
                <AddActionWithParentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Register}
                  bubbleUps={locationRegister?.BubbleUps}
                />
                <AdditionalContactButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Register}
                />
                <AddAttachmentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Register}
                  bubbleUps={locationRegister?.BubbleUps}
                />
                <AddCommentButton
                  id={parseInt(id)}
                  recordType={RECORDTYPE.CORE_Register}
                />
                <AddDocumentButton
                  dataSetFilter={MAILMERGEDATASET.Register_Demolition}
                  recordType={RECORDTYPE.CORE_Register}
                  productType={PRODUCT_TYPE_NUMBER.Core}
                  id={parseInt(id)}
                  bubbleUps={locationRegister?.BubbleUps}
                />
              </CCNavButton>,
            ]}
            rightActions={[
              <CCNavButton
                title={"Slide Bar"}
                iconClass="fal fa-info-circle"
                isActive={showSlideBar}
                onClick={() => {
                  setShowSlideBar(!showSlideBar);
                }}
              />,
              <ActionBarBookmarkIcon bookmarkList={bookmarkList} />,
            ]}
          />
          <div className="cc-manage-container">
            <div className="cc-manage-left">
              <CCAppNotification />
              <div className="cc-manage-form-body">
                {locationRegister && (
                  <>
                    <LocationRegisterForm />
                    <TabTable
                      id={parseInt(id)}
                      recordType={RECORDTYPE.CORE_Register}
                      initialPanels={listPanelBar}
                    />
                  </>
                )}
              </div>
            </div>
            {showSlideBar && (
              <div className="cc-manage-right">
                <ReferenceSideBar
                  components={[
                    {
                      title: "Details",
                      component: <LocationRegisterDetailsTab />,
                    },
                    {
                      title: "History",
                      component: <LocationRegisterHistoryTab />,
                    },
                  ]}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
});
