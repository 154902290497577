import { importViewResponse } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/buttons/_shares/api";
import { ImportFileDialog } from "@app/products/property/centrelink-customer-confirmation/view-responses/components/action-bar/dialogs/imported-file/_index";
import { CENTRELINK_CUSTOMER_CONFIRMATION_VIEW_RESPONSE_ROUTE } from "@app/products/property/centrelink-customer-confirmation/view-responses/[id]/constant";
import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";
import { useHistory } from "react-router";

export const ImportResponseButton = () => {
  const [showDialogImportedFile, setShowDialogImportedFile] = useState(false);
  const [data, setData] = useState();
  const history = useHistory();

  const onSubmit = (fileInfo: any) => {
    importViewResponse(fileInfo).then((response: any) => {
      if (!response || response.length === 0) {
        return;
      }
      setData(response);
    });
    history.push(
      `${CENTRELINK_CUSTOMER_CONFIRMATION_VIEW_RESPONSE_ROUTE}/` + 1,
      data
    );
  };

  return (
    <>
      <CCNavButton
        title="Import response"
        onClick={() => setShowDialogImportedFile(true)}
      />
      {showDialogImportedFile && (
        <ImportFileDialog
          title="Import Response File"
          onClose={() => {
            setShowDialogImportedFile(false);
          }}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
