import { CommunicationButtonListView } from "@app/core/communication/buttons-list-view/_index";
import { DeleteButton } from "@app/core/delete/buttons/_index";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { NewPremiseButton } from "@app/products/hm/components/action-bar/buttons/new-premises/_index";
import { HMActionBarNavDropdown } from "@app/products/hm/components/action-bar/nav-dropdown/_index";
import { NOT_INSPECTED_VIEW } from "@app/products/hm/insp-schedule/config";
import { HMNotInspectedView } from "@app/products/hm/insp-schedule/model";
import { inspScheduleRoute } from "@app/products/hm/insp-schedule/route";
import { HMInspScheduleNotInpsBookmark } from "@app/products/hm/insp-schedule/utils";
import { PremiseContactsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/contacts/_index";
import { PremiseDetailsTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/details/_index";
import { PremiseHistoryTab } from "@app/products/hm/premises/[id]/components/reference-sidebar/history/_index";
import { HMPremisesTabMap } from "@app/products/hm/premises/[id]/components/reference-sidebar/map/_index";
import { PREMISES_MANAGE_ROUTE } from "@app/products/hm/premises/[id]/constant";
import { hmRoute } from "@app/products/hm/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { getUUID, nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { colHMInspScheduleNotInspected } from "./config";

const nameOfPremisesNotInspView = nameOfFactory<HMNotInspectedView>();
export default observer(() => {
  const gridArgs = useMemo(() => {
    const gridId = getUUID();
    return {
      gridId,
      refreshIdsAfterDeletion: [gridId],
    };
  }, []);
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.HealthManager_Form_Premises,
    productType: PRODUCT_TYPE_NUMBER.HealthManager,
  });

  useCCListViewActionBar({
    title: hmRoute.name,
    leftComponents: [
      <HMActionBarNavDropdown category={inspScheduleRoute.path} />,
    ],
    centerComponents: [
      <NewPremiseButton
        disabled={!checkPermissions(FormAction.CORE_ALLOW_NEW)}
      />,
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.HealthManager}
        dataSetFilter={MAILMERGEDATASET.HealthManager_Premises}
        recordType={RECORDTYPE.HealthManager_Premises}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <CommunicationButtonListView
        productType={PRODUCT_TYPE_NUMBER.HealthManager}
        recordType={RECORDTYPE.HealthManager_Premises}
        keyField={nameOfPremisesNotInspView("ID")}
      />,
      <DeleteButton
        recordType={RECORDTYPE.HealthManager_Premises}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
        gridId={gridArgs.gridId}
        refreshGridIds={gridArgs.refreshIdsAfterDeletion}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={PREMISES_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.HealthManager}
        recordType={RECORDTYPE.HealthManager_Premises}
        detail={HMInspScheduleNotInpsBookmark.getBookmarkDetailDisplayName}
        displayName={
          HMInspScheduleNotInpsBookmark.getBookmarkListViewDetailRecord
        }
        listViewDetail={() =>
          HMInspScheduleNotInpsBookmark.getBookmarkListViewDetail(
            "Not Inspected"
          )
        }
        listViewDisplayName={
          HMInspScheduleNotInpsBookmark.getBookmarkDetailDisplayName
        }
      />,
    ],
  });
  useReferenceSideBar({
    referenceComponents: [
      { title: "Details", component: <PremiseDetailsTab /> },
      {
        title: "Map",
        component: <HMPremisesTabMap />,
      },
      { title: "Contacts", component: <PremiseContactsTab /> },
      {
        title: "History",
        component: <PremiseHistoryTab />,
      },
    ],
  });
  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        gridId={gridArgs.gridId}
        columnFields={colHMInspScheduleNotInspected}
        dataUrl={NOT_INSPECTED_VIEW}
        primaryField={nameOfPremisesNotInspView("ID")}
        state={{
          sort: [
            {
              field: nameOfPremisesNotInspView("LastInspection"),
              dir: "desc",
            },
            {
              field: nameOfPremisesNotInspView("TradingName"),
              dir: "asc",
            },
          ],
        }}
      />
    </LoadingPermissionWrapper>
  );
});
