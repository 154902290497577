import { mockViewResponse } from "../mock";
import { mockReportItems } from "./mock";
import {
  eChildScreenItem,
  ReportItemResponse,
  ViewResponse_Response,
} from "./model";

export const loadViewResponseById = async (id: string) => {
  const dataResponse = mockViewResponse.find((item: any) => item?.Id === id);
  return dataResponse as ViewResponse_Response;
};
export const loadReportItems = async (item: eChildScreenItem) => {
  const reportItemsMapping: any = {
    [eChildScreenItem.CENTRELINK_CONCESSION_CARDS]:
      mockReportItems.CentreLinkConcessionCards,
    [eChildScreenItem.CENTRELINK_CARDS_REQUIRING_ACTIONS]:
      mockReportItems.CentreLinkCardsRequiringActions,
    [eChildScreenItem.EXTERNAL_CONCESSION_CARDS]:
      mockReportItems.ExternalConcessionCards,
    [eChildScreenItem.EXTERNAL_CARDS_REQUIRING_ACTIONS]:
      mockReportItems.ExternalCardsRequiringActions,
  };
  return reportItemsMapping[item] as ReportItemResponse[];
};
