import { CCNavButton } from "@components/cc-nav-button/_index";
import React, { useState } from "react";

export const ProduceRequestFileButton = () => {
  const [showProduceRequestFile, setShowProduceRequestFile] = useState(false);

  return (
    <>
      <CCNavButton
        title="Produce request file"
        onClick={() => setShowProduceRequestFile(true)}
      />

      {showProduceRequestFile && (
        <></>
        // <ProduceRequestFile
        //   onClose={() => {
        //     setShowProduceRequestFile(false);
        //   }}
        // />
      )}
    </>
  );
};
