import {
  DTO_Workflow_NewRebate,
  RequestNewRebateObj,
} from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import {
  ResponseMessageWorkflow,
  WorkflowProcessMode,
} from "@app/products/property/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { isNil } from "lodash";

export const postNewNewRebateEntitlementWorkflow = async (
  input: RequestNewRebateObj
): Promise<APIResponse<DTO_Workflow_NewRebate>> => {
  try {
    return await CoreAPIService.getClient().post(
      `/api/property/int/workflow/newrebateentitlement/new`,
      input
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getSavedNewRebateEntitlementWorkflow = async (
  workflowDraftId?: number
): Promise<APIResponse<DTO_Workflow_NewRebate>> => {
  try {
    return await CoreAPIService.getClient().get(
      `/api/property/int/workflow/newrebateentitlement/${workflowDraftId}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getInitialDataNewRebateEntitlement = async (
  payload: RequestNewRebateObj,
  workflowDraftId?: number
): Promise<APIResponse<DTO_Workflow_NewRebate>> => {
  try {
    return await (!isNil(workflowDraftId)
      ? getSavedNewRebateEntitlementWorkflow(workflowDraftId)
      : postNewNewRebateEntitlementWorkflow(payload));
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postProcessNewRebateEntitlement = async (
  mode: WorkflowProcessMode,
  data: DTO_Workflow_NewRebate
): Promise<APIResponse<ResponseMessageWorkflow<DTO_Workflow_NewRebate>>> => {
  try {
    const response = await CoreAPIService.getClient().post(
      `/api/property/int/workflow/newrebateentitlement/process/${mode}`,
      data
    );
    return response;
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
