import { CCGrid } from "@components/cc-grid/_index";
import React, { useState } from "react";
import { useEffectOnce } from "react-use";
import { loadExternalConcessionCards } from "./api";
import { colExternalConcessionCards } from "./config";

interface Props {
  handleOnClickName: (dataItem: any) => void;
}

const ExternalConcessionCards = ({ handleOnClickName }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);

  useEffectOnce(() => {
    setIsLoading(true);
    loadExternalConcessionCards().then((response) => {
      if (!response || response.length === 0) {
        setIsLoading(false);
        return;
      }

      setData(response);
      setIsLoading(false);
    });
  });

  return (
    <div className="cc-form">
      <div className="cc-field-group">
        <div className="cc-form-cols-1">
          <div className="cc-field">
            <CCGrid
              isLoading={isLoading}
              data={data || []}
              columnFields={colExternalConcessionCards.map((col) => {
                if (col.field === "Name") {
                  col.handleOnClick = (dataItem: any) => {
                    handleOnClickName(dataItem);
                  };
                }
                return col;
              })}
              primaryField="Id"
              selectableMode="multiple"
              itemPerPage={10}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExternalConcessionCards;
