import { ASSESSMENT_MASTER_PROPERTIES_ROUTE } from "@app/products/property/assessments/master-properties/[id]/constant";
import { ISupplementaryRatesMasterProperty } from "@app/products/property/supplementary-rates/[id]/components/child-screens/master-properties/model";
import {
  CURRENCY_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
} from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<ISupplementaryRatesMasterProperty>();
export const colSupplementMasterProperty: IColumnFields[] = [
  {
    field: nameOf("Master_Property_Id"),
    title: "Master Property ID",
    format: NUMBER_FORMAT.NUMBER2,
    linkTo: (dataItem: ISupplementaryRatesMasterProperty) =>
      `${ASSESSMENT_MASTER_PROPERTIES_ROUTE}/${dataItem.Master_Property_Id}`,
  },
  {
    field: nameOf("Assessment_Id"),
    title: "Assessment Number",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("MP_Property_Name"),
    title: "Property Name",
  },
  {
    field: nameOf("MP_Property_Address"),
    title: "Property Address",
  },
  {
    field: nameOf("Locality_Name"),
    title: "Property Locality",
  },
  {
    field: nameOf("MP_Description"),
    title: "Description",
  },
  {
    field: nameOf("MP_Assess_NumberX"),
    title: "MP Assess NumberX",
  },
  {
    field: nameOf("No_of_Assessments"),
    title: "Number of Assessments",
    format: NUMBER_FORMAT.NUMBER2,
  },
  {
    field: nameOf("Val_Date_of_Valuation"),
    title: "Date Of Valuation",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Val_Issue_Date"),
    title: "Valuation Issue Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Val_Effective_Date"),
    title: "Valuation Effect Date",
    format: DATE_FORMAT.DATE,
  },
  {
    field: nameOf("Val_Comment"),
    title: "Comments",
  },
  {
    field: nameOf("Valuation_1"),
    title: "Valuation_1",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_2"),
    title: "Valuation_2",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_3"),
    title: "Valuation_3",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_4"),
    title: "Valuation_4",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_5"),
    title: "Valuation_5",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_6"),
    title: "Valuation_6",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_7"),
    title: "Valuation_7",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
  {
    field: nameOf("Valuation_8"),
    title: "Valuation_8",
    format: CURRENCY_FORMAT.CURRENCY1,
  },
];
