import { TabTable } from "@app/core/tab-table/_index";
import { DBRowState } from "@app/products/crms/[id]/model";
import { AdditionalDetailsSection } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/additional-details/_index";
import { DatesSection } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/dates-section/_index";
import { GeneralSection } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/general-section/_index";
import { JurisdictionSection } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/jurisdiction-section/_index";
import { LeaseeSection } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/leasee-section/_index";
import { OfficersSection } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/officers-section/_index";
import { PremisesTypeSection } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/premises-type-section/_index";
import { HMPremisesRegister } from "@app/products/hm/premises/[id]/components/dialogs/register-premises/_index";
import {
  IPremisesFormSecurity,
  Premises,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useIsNew } from "@common/hooks/useIsNew";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { mapEnum, nameOfFactory } from "@common/utils/common";
import { FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";

export interface IPremiseFormElementProps {
  formRenderProps: FormRenderProps;
}
export const nameOfSvcPremises = nameOfFactory<Svc_Premises>();
export const nameOfPremises = nameOfFactory<Premises>();
export const getNameOfPremises = (premisePropsName: keyof Premises) => {
  return `${nameOfSvcPremises("Premises")}.${nameOfPremises(premisePropsName)}`;
};

export const PremiseFormElement = observer(
  ({ formRenderProps }: IPremiseFormElementProps) => {
    const { dataForms } = useFlexibleFormStore();
    const isNew = useIsNew();
    const { valueGetter } = formRenderProps;
    const parentSection = dataForms?.ParentSection as IPremisesFormSecurity;

    const {
      allowChangePremisesType,
      allowEditRegistration,
      allowOverrideInspFrequency,
    } = useMemo(() => {
      if (parentSection) {
        return {
          allowChangePremisesType: parentSection.AllowChangePremisesType,
          allowEditRegistration: parentSection.AllowEditRegistration,
          allowOverrideInspFrequency: parentSection.AllowOverrideInspFrequency,
        };
      }
      return {
        allowChangePremisesType: true,
        allowEditRegistration: true,
        allowOverrideInspFrequency: true,
      };
    }, [parentSection]);

    const premiseObj = dataForms?.GeneralForm as Svc_Premises;
    const premise = premiseObj?.Premises as Premises;

    const isInactive = useMemo(() => {
      return !!(
        mapEnum(premise?.Sys_DBRowState, DBRowState) === DBRowState.Inactive &&
        premise?.Premises_ID
      );
    }, [premise?.Sys_DBRowState, premise?.Premises_ID]);

    const isShowAdditionalDetails = useMemo(
      () => {
        return (
          valueGetter(
            `${getNameOfPremises("PremisesType")}.Flag_DisplayAdditionalDetails`
          ) ?? false
        );
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
      [valueGetter(getNameOfPremises("PremisesType"))]
    );

    return (
      <FormElement>
        <GeneralSection
          formRenderProps={formRenderProps}
          isInactive={isInactive}
        />
        <PremisesTypeSection
          formRenderProps={formRenderProps}
          isInactive={isInactive}
          allowChangePremisesType={allowChangePremisesType}
        />

        <OfficersSection
          formRenderProps={formRenderProps}
          isInactive={isInactive}
        />
        <LeaseeSection
          formRenderProps={formRenderProps}
          isInactive={isInactive}
        />
        <JurisdictionSection
          formRenderProps={formRenderProps}
          isInactive={isInactive}
        />

        <DatesSection
          formRenderProps={formRenderProps}
          isInactive={isInactive}
          allowOverrideInspFrequency={allowOverrideInspFrequency}
          allowEditRegistration={allowEditRegistration}
        />

        <HMPremisesRegister />

        {/* Additional Details */}
        {!isNew && (
          <>
            <br />
            <div>
              <TabTable
                recordType={RECORDTYPE.HealthManager_Premises}
                initialPanels={[
                  {
                    title: " Additional details",
                    isVisible: isShowAdditionalDetails,
                    component: (
                      <AdditionalDetailsSection
                        formRenderProps={formRenderProps}
                        isInactive={isInactive}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </>
        )}
      </FormElement>
    );
  }
);
