import { odataCoreAlertsAccordion } from "@app/core/components/common/utils";
import { colAlertAccordion } from "@app/core/contacts/components/accordions/alerts/config";
import { ALERT_ACCORDION_GRID_ID } from "@app/core/contacts/components/accordions/alerts/constant";
import { IAlert } from "@app/core/contacts/components/accordions/alerts/model";
import { useAlertsAccordionStore } from "@app/core/contacts/components/accordions/alerts/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { observer } from "mobx-react-lite";
import React from "react";
import { useUnmount } from "react-use";

interface IAlertsProps {
  recordID: number | null;
  recordType: RECORDTYPE;
}
const nameOf = nameOfFactory<IAlert>();
export const Alerts = observer(({ recordID, recordType }: IAlertsProps) => {
  const { alertSelectedRows, setAlertSelectedRows, resetStore } =
    useAlertsAccordionStore();

  //Reset selected values when component unmounted
  useUnmount(() => resetStore());

  return (
    <CCGrid
      dataUrl={odataCoreAlertsAccordion(recordID, recordType)}
      selectableMode="single"
      columnFields={colAlertAccordion}
      primaryField={nameOf("ID")}
      selectedRows={alertSelectedRows}
      onSelectionChange={(dataItem: IAlert[]) => {
        setAlertSelectedRows(dataItem ? [...dataItem] : []);
      }}
      state={{ sort: [{ field: nameOf("ID"), dir: "desc" }] }}
      gridId={ALERT_ACCORDION_GRID_ID}
    />
  );
});
