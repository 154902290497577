import { DateInputCell } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/components/custom-cells/date-input-cell/_index";
import { TextInputCell } from "@app/products/property/assessments/components/form-steps/add-rebate/components/form-elements/concession-cards/components/custom-cells/text-input-cell/_index";
import { DTO_Rebate_Concession_Card } from "@app/products/property/assessments/components/form-steps/add-rebate/model";
import { IconEditCell } from "@app/products/property/components/action-bar/property-workflow/component/form-steps/form-elements/workflow/components/icon-edit-cell/_index";
import { DATE_FORMAT, ICON_FORMAT } from "@common/constants/common-format";
import { nameOfFactory } from "@common/utils/common";
import { IColumnFields } from "@components/cc-grid/model";

const nameOf = nameOfFactory<DTO_Rebate_Concession_Card>();
export const colConcessionCards: IColumnFields[] = [
  { field: nameOf("Concession_Card"), title: "Concession Card" },
  {
    field: nameOf("Card_Number"),
    title: "Card Number",
    minWidth: 150,
    editable: true,
    editCell: TextInputCell,
  },
  {
    field: nameOf("Is_Active"),
    title: "Is Active",
    format: ICON_FORMAT.ICON_BOOLEAN,
    editable: true,
    editCell: IconEditCell,
  },
  {
    field: nameOf("Presented_Date"),
    title: "Date Presented",
    format: DATE_FORMAT.DATE,
    minWidth: 210,
    editable: true,
    editCell: DateInputCell,
  },
  {
    field: nameOf("Confirmation_Date"),
    title: "Confirmation Date",
    format: DATE_FORMAT.DATE,
  },
];
