import { DeleteButton } from "@app/core/delete/buttons/_index";
import { InspectionContactsTab } from "@app/core/inspections/[id]/components/reference-sidebar/contacts/_index";
import { InspectionDetailsTab } from "@app/core/inspections/[id]/components/reference-sidebar/details/_index";
import { InspectionHistoryTab } from "@app/core/inspections/[id]/components/reference-sidebar/history/_index";
import { INSPECTIONS_MANAGE_ROUTE } from "@app/core/inspections/[id]/constant";
import { MailMergeButtonListView } from "@app/core/new-mail-merge/buttons/_index";
import { MAILMERGEDATASET } from "@app/core/new-mail-merge/dialogs/model";
import { InspectionsView } from "@app/products/animals/inspections/model";
import { FormAction, FormIdentifier } from "@app/products/crms/model";
import { LocalLawsActionBarNavDropdown } from "@app/products/local-laws/components/toolbar/nav-dropdown/_index";
import { LocalLawsInspectionsByOfficerBookmark } from "@app/products/local-laws/inspections/by-officer/util";
import { colLocalLawsInspections } from "@app/products/local-laws/inspections/config";
import { LOCAL_LAWS_INSPECTIONS_LIST_VIEW_URL } from "@app/products/local-laws/inspections/constant";
import { inspectionsRoute } from "@app/products/local-laws/inspections/route";
import { LocalLawInspectionsBookmark } from "@app/products/local-laws/inspections/util";
import { localLawsRoute } from "@app/products/local-laws/route";
import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NUMBER,
} from "@common/constants/productType";
import { RECORDTYPE } from "@common/constants/recordtype";
import { LoadingPermissionWrapper } from "@common/hocs/permissions/check-security-loading-wrapper";
import { useCheckPermissions } from "@common/hooks/permissions/useCheckPermissions";
import { nameOfFactory } from "@common/utils/common";
import { ListViewBookmarkIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/bookmark/components/list-view-bookmark/_index";
import { ActionBarExportIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/export/_index";
import { ActionBarFilterIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/filter/_index";
import { ActionBarInfoIcon } from "@components/cc-list-view-action-bar/components/nav-icon-buttons/info/_index";
import { useCCListViewActionBar } from "@components/cc-list-view-action-bar/hooks/useCCListViewActionBar";
import { CCProductListView } from "@components/cc-product-list-view/_index";
import { useReferenceSideBar } from "@components/cc-reference-sidebar/hooks/useReferenceSideBar";
import React from "react";
const nameOf = nameOfFactory<InspectionsView>();
export default () => {
  const { checkPermissions, isLoadingPermission } = useCheckPermissions({
    formIdentifier: FormIdentifier.Core_Form_Inspection,
    productType: PRODUCT_TYPE_NUMBER.Core,
  });
  useCCListViewActionBar({
    title: localLawsRoute.name,
    leftComponents: [
      <LocalLawsActionBarNavDropdown category={inspectionsRoute.path} />,
    ],
    centerComponents: [
      <MailMergeButtonListView
        productType={PRODUCT_TYPE_NUMBER.LLPermits}
        dataSetFilter={MAILMERGEDATASET.LLP_Application_Inspections}
        recordType={RECORDTYPE.CORE_Inspection}
        isDisabled={!checkPermissions(FormAction.CORE_ALLOW_MAILMERGE)}
      />,
      <DeleteButton
        recordType={RECORDTYPE.CORE_Inspection}
        disabled={!checkPermissions(FormAction.CORE_ALLOW_DELETE)}
      />,
    ],
    rightComponents: [
      <ActionBarExportIcon
        disabled={!checkPermissions(FormAction.CORE_ALLOW_EXPORT)}
      />,
      <ActionBarFilterIcon />,
      <ActionBarInfoIcon />,
      <ListViewBookmarkIcon
        linkUrl={INSPECTIONS_MANAGE_ROUTE}
        productType={PRODUCT_TYPE.LLPermits}
        recordType={RECORDTYPE.CORE_Inspection}
        detail={LocalLawInspectionsBookmark.getBookmarkDetail}
        displayName={LocalLawInspectionsBookmark.getBookmarkDisplayName}
        listViewDetail={
          LocalLawsInspectionsByOfficerBookmark.getBookmarkListViewDetail
        }
        listViewDisplayName={
          LocalLawsInspectionsByOfficerBookmark.getBookmarkListViewDisplayName
        }
      />,
    ],
  });

  useReferenceSideBar({
    referenceComponents: [
      {
        title: "Details",
        component: <InspectionDetailsTab />,
      },
      { title: "Contacts", component: <InspectionContactsTab /> },
      { title: "History", component: <InspectionHistoryTab /> },
    ],
  });

  return (
    <LoadingPermissionWrapper isLoadingPermission={isLoadingPermission}>
      <CCProductListView
        columnFields={colLocalLawsInspections}
        dataUrl={LOCAL_LAWS_INSPECTIONS_LIST_VIEW_URL}
        primaryField={nameOf("ID")}
        state={{
          group: [{ field: nameOf("Officer"), dir: "asc" }],
          sort: [{ field: nameOf("DateInspected"), dir: "desc" }],
        }}
      />
    </LoadingPermissionWrapper>
  );
};
