import { ContactClassification } from "@app/core/contacts/_id/model";
import { ContactRelationshipType } from "@app/products/town-planning/ppr/[id]/model";

//Contact Classification Filters
export const contactFiltersTownPlanning = [
  ContactClassification.Person,
  ContactClassification.Company,
  ContactClassification.Partnership,
];
//Relationship Filters
export const relationshipFiltersTownPlanning = [
  ContactRelationshipType.AdditionalContact,
  ContactRelationshipType.AdditionalContact_Accountant,
  ContactRelationshipType.AdditionalContact_AdditionalApplicant,
  ContactRelationshipType.AdditionalContact_Architect,
  ContactRelationshipType.AdditionalContact_Builder,
  ContactRelationshipType.AdditionalContact_Conveyancer,
  ContactRelationshipType.AdditionalContact_Draftsman,
  ContactRelationshipType.AdditionalContact_Designer,
  ContactRelationshipType.AdditionalContact_Engineer,
  ContactRelationshipType.AdditionalContact_EstateAgent,
  ContactRelationshipType.AdditionalContact_InterestedParty,
  ContactRelationshipType.AdditionalContact_Landlord,
  ContactRelationshipType.AdditionalContact_Practitioner,
  ContactRelationshipType.AdditionalContact_PreferredContact,
  ContactRelationshipType.AdditionalContact_PrivateCertifier,
  ContactRelationshipType.AdditionalContact_PrivateInspector,
  ContactRelationshipType.AdditionalContact_PropertyOwner,
  ContactRelationshipType.AdditionalContact_Solicitor,
  ContactRelationshipType.AdditionalContact_Surveyor,
  ContactRelationshipType.AdditionalContact_Tenant,
  ContactRelationshipType.AdditionalContact_Witness,
  ContactRelationshipType.AdditionalContact_NominatedLevyPayer,
  ContactRelationshipType.AdditionalContact_Payee,
  ContactRelationshipType.Plumber,
];
