import { InspectionType } from "@app/core/inspections/[id]/model";
import { MailMergeDialog } from "@app/core/new-mail-merge/dialogs/_index";
import {
  IDocument,
  MAILMERGEDATASET,
  MAILMERGESAVEFORMAT,
  MailMerge_SelectMergeDocument_Ext,
} from "@app/core/new-mail-merge/dialogs/model";
import { setUpMailMergeFromForm } from "@app/core/new-mail-merge/dialogs/util";
import { APIResponse } from "@common/apis/model";
import { PRODUCT_TYPE_NUMBER } from "@common/constants/productType";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { IIdentityPacket } from "@common/models/identityPacket";
import { postLoadDocByIds } from "@common/pages/settings/lookups/inspection-types/_id/components/documents-selection/api";
import { colDocumentsSelection } from "@common/pages/settings/lookups/inspection-types/_id/components/documents-selection/config";
import { IInspectionTypeDocumentSelection } from "@common/pages/settings/lookups/inspection-types/_id/components/documents-selection/model";
import { nameOfFactory } from "@common/utils/common";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { FormRenderProps } from "@progress/kendo-react-form";
import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";

interface IDocumentsSelectionProps {
  formRenderProps: FormRenderProps;
}

const nameOf = nameOfFactory<InspectionType>();
const nameOfDocumentSelection =
  nameOfFactory<IInspectionTypeDocumentSelection>();

export const DocumentsSelection = ({
  formRenderProps,
}: IDocumentsSelectionProps) => {
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [initialMailMerge, setInitialMailMerge] =
    useState<MailMerge_SelectMergeDocument_Ext>();
  const [selectedDocumentData, setSelectedDocumentData] = useState<
    IInspectionTypeDocumentSelection[]
  >([]);
  const [gridSelectedRows, setGridSelectedRows] = useState<
    IInspectionTypeDocumentSelection[]
  >([]);
  const { onChange, valueGetter } = formRenderProps;

  const handleOnClick = () => {
    let mailMerge = setUpMailMergeFromForm();

    const mailMergeDataSets = [
      MAILMERGEDATASET.HealthManager_Inspection,
      MAILMERGEDATASET.CORE_ComplaintInspection,
      MAILMERGEDATASET.CORE_Complaint_Interview,
    ];

    mailMergeDataSets.forEach((dataset) => {
      mailMerge.Dataset_Filters.push(dataset);
    });

    setInitialMailMerge({
      ...mailMerge,
      _options: {
        SaveAsPDF: mailMerge.SaveFormat === MAILMERGESAVEFORMAT.Pdf,
      },
    });
    setIsShowDialog(true);
  };

  const handleOnsubmit = async (
    mailMerge: MailMerge_SelectMergeDocument_Ext
  ) => {
    const inspectionTypeSelectedDocuments = valueGetter(
      nameOf("Selected_Documents")
    )?.split("|");

    if (mailMerge?.SelectedDocumentIDs?.length > 0) {
      var selectedDocIDs = "";
      mailMerge.SelectedDocumentIDs.forEach((docId) => {
        if (
          inspectionTypeSelectedDocuments?.length > 0 &&
          !inspectionTypeSelectedDocuments?.includes(docId.toString())
        ) {
          if (!isEmpty(selectedDocIDs)) {
            selectedDocIDs = selectedDocIDs + "|" + docId;
          } else {
            selectedDocIDs = docId.toString();
          }
        } else if (
          isNil(inspectionTypeSelectedDocuments) ||
          inspectionTypeSelectedDocuments?.length === 0
        ) {
          if (!isEmpty(selectedDocIDs)) {
            selectedDocIDs = selectedDocIDs + "|" + docId;
          } else {
            selectedDocIDs = docId.toString();
          }
        }
      });

      if (
        !isEmpty(valueGetter(nameOf("Selected_Documents"))) &&
        !isEmpty(selectedDocIDs)
      ) {
        onChange(nameOf("Selected_Documents"), {
          value:
            valueGetter(nameOf("Selected_Documents")) + "|" + selectedDocIDs,
        });
      } else if (
        isEmpty(valueGetter(nameOf("Selected_Documents"))) &&
        !isEmpty(selectedDocIDs)
      ) {
        onChange(nameOf("Selected_Documents"), {
          value: selectedDocIDs,
        });
      }
    }

    setIsShowDialog(false);
  };

  const handleRemoveRow = () => {
    let inspectionTypeSelectedDocuments = valueGetter(
      nameOf("Selected_Documents")
    )?.split("|");

    const gridSelectedIds = gridSelectedRows?.map(
      (gridSelectedRow: IInspectionTypeDocumentSelection) =>
        gridSelectedRow.DocumentID
    );

    if (inspectionTypeSelectedDocuments.length > 0) {
      gridSelectedIds.forEach((id) => {
        inspectionTypeSelectedDocuments =
          inspectionTypeSelectedDocuments.filter(
            (inspectionTypeSelectedDocument: string) =>
              inspectionTypeSelectedDocument !== id.toString()
          );
      });
    }

    let newInspectionTypeSelectedDocuments = "";
    inspectionTypeSelectedDocuments.forEach((element: string) => {
      if (!isEmpty(newInspectionTypeSelectedDocuments)) {
        newInspectionTypeSelectedDocuments =
          newInspectionTypeSelectedDocuments + "|" + element;
      } else {
        newInspectionTypeSelectedDocuments = element.toString();
      }
    });
    setGridSelectedRows([]);

    onChange(nameOf("Selected_Documents"), {
      value: newInspectionTypeSelectedDocuments,
    });
  };

  const loadDocumentDataSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: ({ initialData }) => postLoadDocByIds(initialData),
        handleSuccess: ({ dataFromApi }) => {
          const response: APIResponse<IIdentityPacket<IDocument[]>> =
            dataFromApi;
          var respData = response?.data?.ReturnObj;
          const selectedDocumentData = respData.map((doc: IDocument) => {
            return {
              DocumentID: doc.Document_ID,
              Name: doc.Name,
            } as IInspectionTypeDocumentSelection;
          });
          setSelectedDocumentData(selectedDocumentData);
        },
      },
    ],
  });

  useEffect(() => {
    if (!isEmpty(valueGetter(nameOf("Selected_Documents")))) {
      const selectedDocuments = valueGetter(
        nameOf("Selected_Documents")
      )?.split("|");

      loadDocumentDataSlider.fetchApi({ initialData: selectedDocuments });
    } else {
      setSelectedDocumentData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueGetter(nameOf("Selected_Documents"))]);

  return (
    <>
      <CCGrid
        selectableMode="single"
        isLoading={loadDocumentDataSlider.isFetching}
        errorComponent={loadDocumentDataSlider.errorComponent}
        columnFields={colDocumentsSelection}
        primaryField={nameOfDocumentSelection("DocumentID")}
        data={selectedDocumentData ?? []}
        selectedRows={gridSelectedRows}
        onSelectionChange={(dataItem: IInspectionTypeDocumentSelection[]) => {
          setGridSelectedRows([...dataItem]);
        }}
        toolbar={
          <div className="cc-grid-tools-bar">
            <Button
              type="button"
              iconClass="fas fa-plus"
              title="Add fee type"
              onClick={handleOnClick}
            />
            <Button
              type="button"
              iconClass="fas fa-minus"
              title="Remove"
              disabled={gridSelectedRows?.length === 0}
              onClick={handleRemoveRow}
            />
          </div>
        }
      />
      {isShowDialog ? (
        <MailMergeDialog
          initialMailMerge={initialMailMerge}
          onClose={() => {
            setIsShowDialog(false);
          }}
          onSubmit={handleOnsubmit}
          productType={PRODUCT_TYPE_NUMBER.Core}
          isMultiple
        />
      ) : null}
    </>
  );
};
