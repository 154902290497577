export const mockCentrelinkCardsRequiringActions = [
  {
    Action: "Mismatching Postcode",
    CentreLinkReferenceNumber: "201106528V",
    Name: "GRAZIOTTO, Gino Mario",
    Matched:
      "CRN provided for this record is valid, the record is located and result returned",
    ConfirmationDate: "22-Feb-2021",
    PensionConcession: true,
    PostcodeMatched: false,
    AssessmentNumber: 679395,
    EntityId: 5775,
    Id: "0",
  },
  {
    Action: "Mismatching Postcode",
    CentreLinkReferenceNumber: "204233269H",
    Name: "LJUBA, Svetlana",
    Matched:
      "CRN provided for this record is valid, the record is located and result returned",
    ConfirmationDate: "22-Feb-2021",
    PensionConcession: true,
    PostcodeMatched: false,
    AssessmentNumber: 2852590,
    EntityId: 912,
    Id: "1",
  },
  {
    Action: "Missing Pension Concession",
    CentreLinkReferenceNumber: "206211243L",
    Matched:
      "CRN provided for this record is valid, the record is located and result returned",
    ConfirmationDate: "22-Feb-2021",
    PensionConcession: true,
    PostcodeMatched: true,
    AssessmentNumber: 680511,
    EntityId: 6139,
    Id: "2",
  },
  {
    Action: "Mismatching Postcode",
    CentreLinkReferenceNumber: "206838375K",
    Name: "HICKS, Darren James",
    Matched:
      "CRN provided for this record is valid, the record is located and result returned",
    ConfirmationDate: "22-Feb-2021",
    PensionConcession: true,
    PostcodeMatched: false,
    AssessmentNumber: 580543,
    EntityId: 8911,
    Id: "3",
  },
  {
    Action: "Mismatching Postcode",
    CentreLinkReferenceNumber: "206838376H",
    Name: "GRECH, Clementa",
    Matched:
      "CRN provided for this record is valid, the record is located and result returned",
    ConfirmationDate: "22-Feb-2021",
    PensionConcession: true,
    PostcodeMatched: false,
    AssessmentNumber: 580543,
    EntityId: 10279,
    Id: "4",
  },
  {
    Action: "Mismatching Postcode",
    CentreLinkReferenceNumber: "208470321A",
    Name: "LJUBIC, Anna",
    Matched:
      "CRN provided for this record is valid, the record is located and result returned",
    ConfirmationDate: "22-Feb-2021",
    PensionConcession: true,
    PostcodeMatched: false,
    AssessmentNumber: 2852590,
    EntityId: 913,
    Id: "5",
  },
  {
    Action: "Mismatching Postcode",
    CentreLinkReferenceNumber: "208823025J",
    Name: "GIBLIN, Shaun Howard",
    Matched:
      "CRN provided for this record is valid, the record is located and result returned",
    ConfirmationDate: "22-Feb-2021",
    PensionConcession: true,
    PostcodeMatched: false,
    AssessmentNumber: 681332,
    EntityId: 8639,
    Id: "6",
  },
  {
    Action: "Mismatching Postcode",
    CentreLinkReferenceNumber: "421886501C",
    Name: "CATANIA, Victoria",
    Matched:
      "CRN provided for this record is valid, the record is located and result returned",
    ConfirmationDate: "22-Feb-2021",
    PensionConcession: true,
    PostcodeMatched: false,
    AssessmentNumber: 682565,
    EntityId: 7171,
    Id: "7",
  },
];
