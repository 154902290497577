import {
  ContactClassification,
  ContactMethodPreferred,
} from "@app/core/contacts/_id/model";
import { KeyValuePair } from "@app/core/documents/model";
import { DBRowState } from "@app/products/crms/[id]/model";
import { PCBACategories } from "@app/products/hm/model";
import {
  Frequency,
  Premises,
  Svc_Premises,
} from "@app/products/hm/premises/[id]/model";
import { getAlertByStringArray } from "@app/products/property/util";
import { SEPARATOR_CHARACTER } from "@common/constants/characters";
import { DATE_FORMAT } from "@common/constants/common-format";
import { Contact } from "@common/models/contact";
import { CCFormOptions } from "@common/stores/flexible-form/constant";
import { flexibleFormStoreInstance } from "@common/stores/flexible-form/store";
import { mapEnum } from "@common/utils/common";
import { formatDateByKendo } from "@common/utils/formatting";
import { INotificationPortalContentExcludeId } from "@components/cc-notification-portal/components/notification-portal-content/model";
import { RadioButtonProps } from "@progress/kendo-react-inputs";

export const numbericValidator = (value: string) => {
  if (!value) return "";
  const regexNumber = new RegExp(/^[0-9]+$/);
  if (!regexNumber.test(value)) {
    return "This value must be numberic";
  }
  return "";
};

export const generateText = (result: string, value?: string) => {
  if (value) {
    if (result) return result + "<br/>" + value;
    else return value;
  }
  return result;
};

export const getProprietorContactDisplay = (proprietorContact: Contact) => {
  let result = "";
  if (
    proprietorContact?.Extension?.Organisation &&
    proprietorContact?.Extension?.Organisation !==
      proprietorContact?.DisplayName
  ) {
    result = generateText(result, proprietorContact?.Extension?.Organisation);
  }
  if (proprietorContact?.ABN) {
    result = generateText(result, "ABN: " + proprietorContact.ABN);
  }
  if (
    proprietorContact?.ContactClassification_ENUM ===
      ContactClassification.Company &&
    proprietorContact?.ACN
  ) {
    result = generateText(result, "ACN: " + proprietorContact.ACN);
  }
  if (proprietorContact?.PreferredMethod_ENUM !== ContactMethodPreferred.None) {
    result = generateText(
      result,
      proprietorContact?.PreferredMethod_Formatted ??
        proprietorContact?._PreferredMethod_Formatted ??
        ""
    );
  } else {
    result = generateText(result, proprietorContact?.WorkPhone ?? "");
  }

  return result;
};

export const getPrimaryContactDisplay = (contact: Contact) => {
  let result = "";
  if (
    contact?.Extension?.Organisation &&
    contact?.Extension?.Organisation !== contact?.DisplayName
  ) {
    result = generateText(result, contact?.Extension?.Organisation);
  }
  if (contact?.PreferredMethod_ENUM !== ContactMethodPreferred.None) {
    result = generateText(
      result,
      contact?.PreferredMethod_Formatted ??
        contact?._PreferredMethod_Formatted ??
        ""
    );
  } else {
    result = generateText(result, contact?.WorkPhone ?? "");
  }

  if (contact?.Extension?.SecondLanguage) {
    result = generateText(
      result,
      contact?.Extension?.SecondLanguage
        ? "Lang: " + contact?.Extension?.SecondLanguage
        : ""
    );
  }
  return result;
};

export const getFSSContactDisplay = (contact: Contact) => {
  let result = "";
  if (!contact) return result;

  if (contact?.Extension?.FSSCertificate === "1") {
    if (contact?.Extension?.FSSCertificateNo) {
      result = generateText(result, "(Certified)");
    } else {
      if (contact?.Extension?.FSSCertificateExpiryDate) {
        result = generateText(
          result,
          formatDateByKendo(
            contact?.Extension?.FSSCertificateExpiryDate,
            DATE_FORMAT.DATE
          )
        );
      }
      if (contact?.Extension?.FSSCertificateNo) {
        result = generateText(result, contact?.Extension?.FSSCertificateNo);
      }
    }
  } else {
    result = generateText(result, "(Not Certified)");
    if (
      contact?.Extension?.Organisation &&
      contact?.Extension?.Organisation !== contact?.DisplayName
    ) {
      result = generateText(result, contact?.Extension?.Organisation);
    }
    if (contact?.PreferredMethod_ENUM !== ContactMethodPreferred.None) {
      result = generateText(
        result,
        contact?.PreferredMethod_Formatted ??
          contact?._PreferredMethod_Formatted
      );
    } else {
      result = generateText(result, contact?.WorkPhone);
    }
  }

  return result;
};

export const refactorListData = (data: string) => {
  const newData = data
    ?.split(SEPARATOR_CHARACTER)
    ?.reduce((dataList: Array<RadioButtonProps>, currentItem: string) => {
      if (currentItem)
        dataList.push({
          label: currentItem,
          value: currentItem,
        });
      return dataList;
    }, []);

  return newData?.filter(
    (dataItem, index, array) =>
      index === array.findIndex((item) => item.label === dataItem.label)
  );
};

export const getLastInspectionDisplay = (
  lastInspection: string,
  lastInspectionResult: string
) => {
  let result = "";

  if (lastInspection) {
    result = formatDateByKendo(lastInspection, DATE_FORMAT.DATE);
  } else {
    result = "Not yet inspected";
  }
  if (lastInspectionResult) {
    result = generateText(result, lastInspectionResult);
  }
  return result;
};

export const getFriendlyNameFrequency = (type: Frequency) => {
  switch (type) {
    case Frequency.Unspecified:
      return "Unspecified";
    case Frequency.Monthly:
      return "1 Monthly";
    case Frequency.ThreeMonthly:
      return "3 Monthly";
    case Frequency.SixMonthly:
      return "6 Monthly";
    case Frequency.NineMonthly:
      return "9 Monthly";
    case Frequency.EighteenMonthly:
      return "18 Monthly";
    case Frequency.Yearly:
      return "12 Monthly";
    case Frequency.TwoYearly:
      return "24 Monthly";
    case Frequency.ThreeYearly:
      return "36 Monthly";
    case Frequency.FourYearly:
      return "48 Monthly";
    case Frequency.FiveYearly:
      return "60 Monthly";
  }
};

type TPremises = Premises & {
  [CCFormOptions]?: {
    forceModified?: boolean;
  };
};
export const changePremisesGeneralForm = (nameOfField: Partial<TPremises>) =>
  flexibleFormStoreInstance.updateFormFields("GeneralForm", nameOfField);

export const getPremisesGeneralForm = (
  nameOfField?: keyof TPremises | Array<keyof TPremises>
) => flexibleFormStoreInstance.getFormFields("GeneralForm", nameOfField);

export const checkIsInactivePremises = (): boolean => {
  const data = flexibleFormStoreInstance?.dataForms
    ?.GeneralForm as Svc_Premises;
  return (data?.Premises?.Sys_DBRowState as DBRowState) === DBRowState.Inactive;
};

export const generatePCBACategoriesList = (strPCBACategories: string) => {
  // generate PCAndBA categories
  if (!strPCBACategories || strPCBACategories === "") return [];

  const pcbaCategories = strPCBACategories.split(SEPARATOR_CHARACTER);
  const pcpaCategoriesList: KeyValuePair<string, string>[] =
    pcbaCategories?.reduce(
      (currentValue: KeyValuePair<string, string>[], item: string) => {
        const keyItem = item?.replace(/\s+/g, "")?.replace(/"/g, "");
        if (keyItem && keyItem !== PCBACategories[PCBACategories.Unspecified])
          currentValue.push({
            Key: keyItem,
            Value: item,
          } as KeyValuePair<string, string>);

        return currentValue;
      },
      []
    );
  return pcpaCategoriesList;
};

// #region Add alert notification
export const getPremisesAlerts = (premises: Premises) => {
  const alerts: INotificationPortalContentExcludeId[] = [];
  if (!premises) return alerts;

  const premisesAlerts = premises?.Alerts ?? [];
  const proprietorContactAlert =
    premises?.Proprietor?.Contact?.ContactAlert ?? "";
  const alternateAddressAlert =
    premises?.Proprietor?.Contact?.AlternateAddress?.PropertyAlert ?? "";
  const primaryContactAlert =
    premises?.PrimaryContact?.Contact?.ContactAlert ?? "";
  const premisesAddressAlert = premises?.SiteAddress?.PropertyAlert ?? "";
  const postalAddressAlert = premises?.PostalAddress?.PropertyAlert ?? "";
  const fspContactAlert = premises?.FSPContact?.Contact?.ContactAlert ?? "";
  const propertyOwnerAlert =
    premises?.MultiTenancyRegister?.Contact?.Contact?.ContactAlert ?? "";
  const subcontractorAlert =
    premises?.Subcontractor?.Contact?.ContactAlert ?? "";
  const leaseeAlert = premises?.Leasee?.Contact?.ContactAlert ?? "";

  if (leaseeAlert) {
    alerts.push({
      title: `Leasee alert: ${leaseeAlert}`,
      type: "warning",
      autoClose: false,
    });
  }
  if (subcontractorAlert) {
    alerts.push({
      title: `Subcontractor alert: ${subcontractorAlert}`,
      type: "warning",
      autoClose: false,
    });
  }
  if (propertyOwnerAlert) {
    alerts.push({
      title: `Property owner alert: ${propertyOwnerAlert}`,
      type: "warning",
      autoClose: false,
    });
  }
  if (fspContactAlert) {
    alerts.push({
      title: `FSS contact alert: ${fspContactAlert}`,
      type: "warning",
      autoClose: false,
    });
  }
  if (postalAddressAlert) {
    alerts.push({
      title: `Postal address alert: ${postalAddressAlert}`,
      type: "warning",
      autoClose: false,
    });
  }
  if (premisesAddressAlert) {
    alerts.push({
      title: `Premises address alert: ${premisesAddressAlert}`,
      type: "warning",
      autoClose: false,
    });
  }
  if (primaryContactAlert) {
    alerts.push({
      title: `Primary contact alert: ${primaryContactAlert}`,
      type: "warning",
      autoClose: false,
    });
  }
  if (alternateAddressAlert) {
    alerts.push({
      title: `Alternate address alert: ${alternateAddressAlert}`,
      type: "warning",
      autoClose: false,
    });
  }
  if (proprietorContactAlert) {
    alerts.push({
      title: `Proprietor alert: ${proprietorContactAlert}`,
      type: "warning",
      autoClose: false,
    });
  }
  if (premisesAlerts?.length > 0) {
    for (const alert of premisesAlerts) {
      alerts.push({
        title: `Premises alert: ${alert}`,
        type: "warning",
        autoClose: false,
      });
    }
  }
  if (
    mapEnum(premises?.Sys_DBRowState, DBRowState) === DBRowState.Inactive &&
    premises?.Premises_ID
  ) {
    alerts.push({
      title: `Important Note: You are viewing a deleted record. You are not allowed to perform any Workflow functions or make changes to this record.`,
      type: "info",
      autoClose: false,
    });
  }
  return alerts;
};
// #endregion Add alert notification

/**
 * To separate with Normal Notification, prepare to override if getAlertBySringArray get changes
 */
export const getAlertForNotificationPortal = (
  arr: (string | null)[],
  prefixTitle: string = "Premises alert: "
) => {
  return getAlertByStringArray<INotificationPortalContentExcludeId>(
    arr,
    prefixTitle
  );
};
