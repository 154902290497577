import { alertDeleteRecord } from "@app/products/direct-debit/constant";
import { APIResponse } from "@common/apis/model";
import { useFlexibleFetchData } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import { useCancelRequest } from "@common/hooks/useCancelRequest";
import { useIsNew } from "@common/hooks/useIsNew";
import {
  getFeeTypeById,
  getFeeTypeLOVs,
} from "@common/pages/settings/lookups/fee-types/_id/api";
import { ExistManageFeeType } from "@common/pages/settings/lookups/fee-types/_id/existed/_index";
import { NewManageFeeType } from "@common/pages/settings/lookups/fee-types/_id/new/_index";
import { TitleFeeTypeManagePage } from "@common/pages/settings/lookups/fee-types/constant";
import {
  FeeType,
  Svc_FeeTypeLOVs,
  TApiFeeType,
} from "@common/pages/settings/lookups/fee-types/model";
import { checkIsInactiveFeeType } from "@common/pages/settings/lookups/fee-types/util";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import { FormNavigation } from "@components/cc-form/navigation/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import Loading from "@components/loading/Loading";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

const ManageFeeType = () => {
  //#region STORE
  const { setInitialDataForms, isLoadingForm } = useFlexibleFormStore();
  //#endregion STORE

  //#region HOOK
  const { cancelToken } = useCancelRequest();
  const {
    listenNotificationPortal,
    pushNotificationPortal,
    clearNotifications,
  } = useNotificationPortalStore();
  const isNew = useIsNew();
  const history = useHistory();
  //#endregion HOOK

  //#region Variable ========/
  let { id } = useParams<{ id: string }>();
  const feeTypeId = useMemo(() => {
    return isNew ? "0" : id;
  }, [isNew, id]);
  //#endregion Variable =====/

  const manageFeeTypeSlider = useFlexibleFetchData({
    slides: [
      {
        fetch: () => [
          getFeeTypeLOVs(cancelToken()),
          getFeeTypeById(+feeTypeId, cancelToken()),
        ],
        handleSuccess: ({ dataFromApi }) => {
          const [_feeTypeLovsIdentityPacket, _feeTypeIdentityPacket]: [
            APIResponse<TApiFeeType["Lovs"]>,
            APIResponse<TApiFeeType["Value"]>
          ] = dataFromApi;

          const _feeTypeLovs = _feeTypeLovsIdentityPacket.data?.ReturnObj;
          const _feeType = _feeTypeIdentityPacket.data?.ReturnObj;
          _feeType.Fee = _feeType?.Fee ?? false;
          _feeType.Refund = _feeType?.Refund ?? false;
          _feeType.Bond = _feeType?.Bond ?? false;
          _feeType.Subsidy = _feeType?.Subsidy ?? false;

          //#region save data to store
          setInitialDataForms({
            GeneralFormLovs: _feeTypeLovs as Svc_FeeTypeLOVs,
            GeneralForm: _feeType as FeeType,
          });
          //#endregion save data to store
          listenNotificationPortal();

          if (checkIsInactiveFeeType()) {
            pushNotificationPortal(alertDeleteRecord, true);
          }
        },
      },
    ],
  });

  useEffect(() => {
    manageFeeTypeSlider.fetchApi();
    return () => clearNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  return (
    <>
      <Loading
        isLoading={manageFeeTypeSlider.isFetching || isLoadingForm}
        isFullScreen
      />
      <FormNavigation title={TitleFeeTypeManagePage} />
      {manageFeeTypeSlider.errorComponent ? (
        manageFeeTypeSlider.errorComponent
      ) : isNew ? (
        <NewManageFeeType />
      ) : (
        <ExistManageFeeType />
      )}
    </>
  );
};

export default observer(ManageFeeType);
