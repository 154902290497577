import { ICCRoute } from "@common/constants/ICCRoute";
import { eMenuAction } from "@common/stores/products/enum";
import { viewResponsesRoute } from "./view-responses/route";

export const centrelinkCustomerConfirmationRoute: ICCRoute = {
  path: "centrelink-customer-confirmation",
  name: "Centrelink Customer Confirmation",
  enum: eMenuAction.CentreLink_Customer_Confirmation_Folder,
  children: [
    {
      path: "view-request-file",
      name: "View Request File",
      enum: eMenuAction.CentreLink_View_Request_File,
      component: require("./view-request-file/_index").default,
    },
    viewResponsesRoute,
  ],
};
