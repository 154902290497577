import {
  apiCoreCalculateTargetDate,
  apiCoreGetActiontypes,
  apiCoreGetActiontypesLovs,
  apiCorePostActionBatchUpdate,
} from "@app/core/components/common/utils";
import { Svc_ActionTypes } from "@app/products/town-planning/ppr/actions/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { ActionType } from "@common/models/actionType";
import { IIdentityPacket, IdentityPacket } from "@common/models/identityPacket";
import { IRespActionType } from "@common/pages/actions/[id]/components/input-picker/action-type-picker/model";
import { ActionBatchUpdate } from "@common/pages/actions/components/action-bar/dialog/batch-update-action/model";
import { Action } from "@common/pages/actions/model";

export const getActionById = async (
  id?: number
): Promise<APIResponse<Action | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<Action>(
      `api/core/internal/actions/details/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const postAction = async (
  action: Action,
  id: number | null = null,
  recordType?: RECORDTYPE | null,
  saveAsAction?: boolean | null
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      `api/core/internal/actions`,
      action,
      {
        params: {
          id,
          recordType,
          saveAsAction,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getActionsTypes = async (
  productTypes: string,
  userSelection?: boolean | null,
  showRegulatory?: boolean | null
): Promise<APIResponse<IRespActionType[]>> => {
  try {
    return await CoreAPIService.getClient().get<IRespActionType[]>(
      apiCoreGetActiontypes(),
      {
        params: {
          productTypes,
          userSelection,
          showRegulatory,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getMeetingActionTypes = async (): Promise<
  APIResponse<IRespActionType[]>
> => {
  try {
    return await CoreAPIService.getClient().get<IRespActionType[]>(
      `/api/core/internal/actions/actiontypes/meeting`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getActionsTypeDetail = async (
  id?: number
): Promise<APIResponse<ActionType | undefined>> => {
  try {
    return await CoreAPIService.getClient().get<any>(
      `api/core/internal/actions/actiontypes/${id}`
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getActionTypeLovs = async (): Promise<
  APIResponse<IIdentityPacket<Svc_ActionTypes | undefined>>
> => {
  try {
    return await CoreAPIService.getClient().get<
      IIdentityPacket<Svc_ActionTypes>
    >(apiCoreGetActiontypesLovs());
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

export const getCalculateTargetDate = async (
  dateRecorded: string,
  targetDays: number,
  includeWeekendDays: boolean
): Promise<APIResponse<Date>> => {
  try {
    return await CoreAPIService.getClient().get<Date>(
      apiCoreCalculateTargetDate(),
      {
        params: {
          dateRecorded,
          targetDays,
          includeWeekendDays,
        },
      }
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};

//#region Action batch update
export const postActionBatchUpdate = async (
  batchUpdate: ActionBatchUpdate
): Promise<APIResponse<IdentityPacket>> => {
  try {
    return await CoreAPIService.getClient().post<IdentityPacket>(
      apiCorePostActionBatchUpdate(),
      batchUpdate
    );
  } catch (error) {
    const errorResponse: APIResponse = error as any;
    return {
      ...errorResponse,
      error: errorResponse.data?.Message,
    };
  }
};
//#endregion
