import { Svc_BatchRecallRequest } from "@app/core/recall/model";
import { CoreAPIService } from "@common/apis/coreAPIService";
import { APIResponse } from "@common/apis/model";
import { handleCatchResponse } from "@common/apis/util";
import { RecordCountPacket } from "@common/models/identityPacket";
import { CancelToken } from "axios";

export const postSaveRecallsInBatch = async (
  data: Svc_BatchRecallRequest,
  cancelToken?: CancelToken
): Promise<APIResponse<RecordCountPacket>> => {
  try {
    return await CoreAPIService.getClient().post<RecordCountPacket>(
      "api/core/internal/recalls/batch",
      data,
      { cancelToken }
    );
  } catch (error) {
    return handleCatchResponse(error);
  }
};
