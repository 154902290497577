import { postSetting } from "@app/products/property/system-admin/settings/api";
import { useSettingNewButtonStore } from "@app/products/property/system-admin/settings/components/action-bar/buttons/new/store";
import {
  IGroupSettingField,
  ILookupItem,
  SettingField,
  SettingFolder,
  SettingsDataType
} from "@app/products/property/system-admin/settings/model";
import { useSettingPropertyStore } from "@app/products/property/system-admin/settings/_category/store";
import { useSettingManageFormActionBarStore } from "@app/products/property/system-admin/settings/_id/components/action-bar/store";
import { useSettingConfirmExitStore } from "@app/products/property/system-admin/settings/_id/components/dialogs/confirm-exit/store";
import {
  assignValueOnchange,
  dataFieldValidator,
  getFormInitialValueNew,
  getIdFirstGroup,
  messageCurrencyValidator,
  messageDateValidator,
  messageDropDownValidator,
  messageInputValidator,
  messageListValidator,
  messagePercentValidator,
  processDataAfterAdd,
  propsValuesCurrency,
  propsValuesDate,
  propsValuesDrop,
  propsValuesInput,
  propsValuesList,
  propsValuesNumeric,
  propsValuesSwitch,
  resultPreprocessData,
  setNullValueNew
} from "@app/products/property/system-admin/settings/_id/components/forms/utils";
import { ICheckMandatoryList } from "@app/products/property/system-admin/settings/_id/model";
import { usePropertySettingManagePageStore } from "@app/products/property/system-admin/settings/_id/store";
import { APIResponseStatus } from "@common/constants/response-status";
import { IResponseData } from "@common/models/service";
import { DATA_TYPE } from "@common/pages/settings/constant";
import {
  CCLocalNotification,
  ICCLocalNotificationHandle
} from "@components/cc-app-notification/_index";
import Loading from "@components/loading/Loading";
import { SettingCurrencyInput } from "@components/setting/setting-currency-input/_index";
import { SettingDateInput } from "@components/setting/setting-date-input/_index";
import { SettingDateTimeInput } from "@components/setting/setting-date-time-input/_index";
import { SettingComboboxList } from "@components/setting/setting-drop-down-list/_index";
import { SettingListOption } from "@components/setting/setting-list-option/_index";
import { SettingNumericInput } from "@components/setting/setting-numeric-input/_index";
import { SettingPercentInput } from "@components/setting/setting-percent-input/_index";
import { SettingSwitchInput } from "@components/setting/setting-switch-input/_index";
import { SettingTextInput } from "@components/setting/setting-text-input/_index";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from "@progress/kendo-react-form";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { cloneDeep, isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useEffectOnce } from "react-use";
import "./_index.scss";

const NewSettingManage = (props: any) => {
  const isNew = true;
  const history = useHistory();
  const { setConfirmExit } = useSettingConfirmExitStore();
  const [data, setData] = useState<IGroupSettingField[]>([]);
  const { setIsLoadingSubmitNew } = useSettingManageFormActionBarStore();
  const notificationRef = useRef<ICCLocalNotificationHandle | null>(null);
  const location = useLocation();
  const urlAfterSaving: any = location.state;
  let initialNewData = cloneDeep(props.data.SettingFields);
  const { setShowNotification } = usePropertySettingManagePageStore();
  const { setIsNew } = useSettingPropertyStore();
  const [checkHitSaveButton, setCheckHitSaveButton] = useState(false);

  let listFieldNoneGroup: IGroupSettingField[] = [];
  const [checkMandatoryList, setCheckMandatoryList] = useState<
    ICheckMandatoryList | undefined
  >();
  const { setIsExistAfterCreated } = useSettingNewButtonStore();

  useEffectOnce(() => {
    return () => {
      setConfirmExit(false);
      setIsNew(false);
    };
  });

  useEffect(() => {
    if (!props.data) return;
    if (props.data.SettingFields) {
      const processedData = preprocessData(
        setNullValueNew(props.data.SettingFields)
      );
      setData(cloneDeep(processedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  /**
   * preprocess Data
   * process fields to manage by group and render data
   * @param data
   * @returns
   */
  const preprocessData = (data: any): IGroupSettingField[] => {
    let result: any = null;
    if (!data) return result;
    result = resultPreprocessData(data, props.data.EditGroups, true);
    return result;
  };

  /**
   * Set the first group to expand default
   * @param resultData
   * @returns
   */
  const collapseFirstGroup = (resultData: IGroupSettingField[]): number => {
    if (!resultData) return 0;
    return +getIdFirstGroup(resultData);
  };

  /**
   * Handle Change Input Fields
   * Handle Change Switch
   * @param event
   */
  const handleInputChange = (
    event: any,
    groupId: number,
    formRenderProps: FormRenderProps
  ): void => {
    const { name, value } = event.target;
    let valueField = value;
    if (typeof valueField === "string")
      valueField = value.length === 0 ? null : value;
    const cloneData: IGroupSettingField[] = data.map(
      (group: IGroupSettingField) => {
        if (group.groupId === groupId) {
          group.settingFields.forEach((field: SettingField) => {
            if (field.FieldName === name) field.Value = valueField;
          });
        }
        return group;
      }
    );
    formRenderProps.onChange(name, {
      value: value,
    });
    setData(cloneData);
  };

  /**
   * Handle Change Input Percentage Fields
   * @param event
   */
  const handleInputPercentageChange = (
    event: any,
    groupId: number,
    formRenderProps: FormRenderProps
  ): void => {
    const { name, value } = event.target;
    let valueField: number | undefined;
    if (!isNil(value)) {
      valueField = value * 100;
    }
    const cloneData: IGroupSettingField[] = data.map(
      (group: IGroupSettingField) => {
        if (group.groupId === groupId) {
          group.settingFields.forEach((field: SettingField) => {
            if (field.FieldName === name) field.Value = valueField;
          });
        }
        return group;
      }
    );
    formRenderProps.onChange(name, {
      value: valueField,
    });
    setData(cloneData);
  };

  /**
   *
   * Handle Change List Options
   * @param event
   * @param groupId
   * @param formRenderProps
   */
  const handleListChange = (
    event: any,
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps,
    gridFieldsData: any
  ): void => {
    const dataList = event.every((i: any) => i.IsSelected === false);
    const listCheckValidSettingList = !isNil(checkMandatoryList)
      ? checkMandatoryList
      : {};
    if (dataList && item.IsMandatory)
      setCheckMandatoryList({
        ...listCheckValidSettingList,
        [item.FieldName]: true,
      });
    else
      setCheckMandatoryList({
        ...listCheckValidSettingList,
        [item.FieldName]: false,
      });
    const dataListItems = event?.filter((i: any) => i.IsSelected === true)?.map((itemList: any) => {
      const removePrefixGridFields = itemList.GridFields.map((field: any) => {
        const fieldName = field.Name.split("-")[1];
        return {
          ...field,
          Name: fieldName,
        };
      });

      return {
        Name: itemList.Name,
        Title: itemList.Title,
        ItemId: itemList.ItemId,
        ProductType_Enum: itemList.ProductType_Enum,
        SettingCategoryName: itemList.SettingCategoryName,
        Mode: itemList.Mode,
        IsNew: itemList?.IsNew,
        IsSelected: itemList.IsSelected,
        IsEditable: itemList.IsEditable,
        IsChildFolder: itemList.IsChildFolder,
        GridFields: removePrefixGridFields,
        EditGroups: itemList.EditGroups,
        SettingFields: itemList.SettingFields,
      };
    }) ?? [];
    let gridFields = gridFieldsData;
    const cloneData: IGroupSettingField[] = data.map(
      (group: IGroupSettingField) => {
        if (group.groupId === groupId) {
          group.settingFields.forEach((field: SettingField) => {
            if (field.FieldName === item.FieldName) {
              const listItems: SettingFolder = field.ListItems;
              gridFields = listItems.GridFields;
              const newListItems = {
                ...listItems,
                GridFields: gridFields,
                SettingsFolders: dataListItems,
              };
              field.ListItems = newListItems;
            }
          });
        }
        return group;
      }
    );
    formRenderProps.onChange(item.FieldName, {
      value: event,
    });
    setData(cloneData);
  };

  /**
   *
   * Handle Change Dropdown Fields
   * @param event
   */
  const handleDropDownChange = (
    event: any,
    groupId: number,
    formRenderProps: FormRenderProps
  ): void => {
    const { name, value } = event.target;
    const cloneData: IGroupSettingField[] | undefined = assignValueOnchange(
      data,
      name,
      value,
      groupId
    );
    if (!cloneData) return;
    formRenderProps.onChange(name, {
      value: value?.Id,
    });
    setData(cloneData);
  };

  /**
   * Handle submit form
   * show notification when users submit
   * @param values
   * @param event
   */
  const handleSubmitNew = async () => {
    const resultSubmit = processDataAfterAdd(data, props);
    if (!resultSubmit) {
      notificationRef.current?.pushNotification({
        title: `Some thing went wrong`,
        autoClose: false,
        type: "error",
      });
    }

    if (resultSubmit) {
      setIsLoadingSubmitNew(true);
      await postSetting(resultSubmit)
        .then((response: IResponseData) => {
          if (response.status === APIResponseStatus.SUCCESS) {
            const id = response.data.ItemId;
            setShowNotification(true, {
              title: "Created successfully",
              type: "success",
            });
            const pathName = history.location.pathname.split("/new")[0];
            const newUrl = urlAfterSaving.requestUrl.split("/-1")[0] + `/${id}`;
            setIsExistAfterCreated(true);
            setConfirmExit(false);
            setIsNew(false);
            history.replace(pathName, {
              requestUrl: newUrl,
              createdNew: true,
            });
          } else if (response.status === APIResponseStatus.BAD_REQUEST) {
            const res = response.data;
            const messages = res.Messages ? res.Messages : [];
            const typeMessage =
              res.MessageType === "Flash Information"
                ? "info"
                : res.MessageType.toLowerCase();
            const isClose =
              res.MessageType === "Flash Information" ? true : false;
            notificationRef.current?.pushNotification({
              title: res.HeaderMessage,
              autoClose: isClose,
              description: messages,
              type: typeMessage,
            });
          } else {
            notificationRef.current?.pushNotification({
              title: `Error occurred`,
              autoClose: false,
              type: "error",
            });
          }
        })
        .catch(() => {
          notificationRef.current?.pushNotification({
            title: `Error occurred`,
            autoClose: false,
            type: "error",
          });
        });
      setIsLoadingSubmitNew(false);
    }
  };

  /**
   * Check validation input fields
   * check mandatory and max length
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldInputValidator = (
    value: string,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const field = dataFieldValidator(data, fieldProps.name);
    if (!field) return undefined;
    return messageInputValidator(field, value);
  };
  /**
   * Check validation number input fields
   * check mandatory and max length
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldNumericValidator = (
    value: number,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const field = dataFieldValidator(data, fieldProps.name);
    if (!field) return undefined;
    return messageInputValidator(field, value);
  };

  /**
   * Check validation dropdown fields
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldDropDownValidator = (
    value: ILookupItem,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const field = dataFieldValidator(data, fieldProps.name);
    if (!field) return undefined;
    return messageDropDownValidator(field, value);
  };
  /**
   * Check validation for list
   * check mandatory and value is not empty
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldListValidator = (
    value: SettingFolder[],
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const field = dataFieldValidator(data, fieldProps.name);
    if (!field) return undefined;
    const messageError = messageListValidator(field, value);
    if (field.IsMandatory && messageError && checkHitSaveButton) {
      const listCheckValidSettingList = !isNil(checkMandatoryList)
        ? checkMandatoryList
        : {};
      setCheckMandatoryList({
        ...listCheckValidSettingList,
        [fieldProps.name]: true,
      });
    }
    return messageError;
  };

  /**
   * Check validation currency input fields
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldCurrencyValidator = (
    value: number,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const field = dataFieldValidator(data, fieldProps.name);
    if (!field) return undefined;
    return messageCurrencyValidator(field, value);
  };

  /**
   * Check validation date fields
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldDateValidator = (
    value: number,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const field = dataFieldValidator(data, fieldProps.name);
    if (!field) return undefined;
    return messageDateValidator(field, value);
  };

  /**
   * Check validation percent input fields
   * @param value
   * @param valueGetter
   * @param fieldProps
   * @returns
   */
  const fieldPercentValidator = (
    value: number,
    valueGetter: (name: string) => any,
    fieldProps: {
      name: string;
    }
  ): string | undefined => {
    const field = dataFieldValidator(data, fieldProps.name);
    if (!field) return undefined;
    return messagePercentValidator(field, value);
  };
  /**
   * Generate list option
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateList = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const props_values_List = propsValuesList({
      item,
      groupId,
      formRenderProps,
      data,
      isNew,
    });
    const props_handle_List = {
      onChangeCustom: ({ event, fieldsData }: any) =>
        handleListChange(event, item, groupId, formRenderProps, fieldsData),
      checkMandatoryList,
      setCheckMandatoryList,
    };
    const props_List = { ...props_values_List, ...props_handle_List };
    return (
      <Field
        {...props_List}
        component={SettingListOption}
        validator={fieldListValidator}
      />
    );
  };

  /**
   * generate Input
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateInput = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const props_values_Input = propsValuesInput({
      item,
      groupId,
      formRenderProps,
      data,
      isNew,
    });
    const props_handle_Input = {
      onChange: (event: any) =>
        handleInputChange(event, groupId, formRenderProps),
    };
    const props_Input = { ...props_values_Input, ...props_handle_Input };

    return (
      <Field
        {...props_Input}
        validator={fieldInputValidator}
        component={SettingTextInput}
      />
    );
  };

  /**
   * generate Currency
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateCurrency = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const props_values_Currency = propsValuesCurrency({
      item,
      groupId,
      formRenderProps,
      data,
      isNew,
    });
    const props_handle_Currency = {
      onChange: (event: any) =>
        handleInputChange(event, groupId, formRenderProps),
    };
    const props_Currency = {
      ...props_values_Currency,
      ...props_handle_Currency,
    };

    return (
      <Field
        {...props_Currency}
        component={SettingCurrencyInput}
        validator={fieldCurrencyValidator}
      />
    );
  };

  /**
   * generate Numeric
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateNumeric = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const props_values_Numeric = propsValuesNumeric({
      item,
      groupId,
      formRenderProps,
      data,
      isNew,
    });
    const props_handle_Numeric = {
      onChange: (event: any) => {
        if (item.DataType === SettingsDataType.Percent) {
          handleInputPercentageChange(event, groupId, formRenderProps);
        } else {
          handleInputChange(event, groupId, formRenderProps);
        }
      },
    };
    const props_Numeric = { ...props_values_Numeric, ...props_handle_Numeric };

    if (item.DataType === SettingsDataType.Percent) {
      return (
        <Field
          {...props_Numeric}
          validator={fieldPercentValidator}
          component={SettingPercentInput}
        />
      );
    }

    return (
      <Field
        {...props_Numeric}
        validator={fieldNumericValidator}
        component={SettingNumericInput}
      />
    );
  };
  /**
   * generate switch
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateSwitch = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const props_values_Switch = propsValuesSwitch({
      item,
      groupId,
      formRenderProps,
      data,
      isNew,
    });
    const props_handle_Switch = {
      onChange: (event: any) =>
        handleInputChange(event, groupId, formRenderProps),
    };
    const props_Switch = { ...props_values_Switch, ...props_handle_Switch };
    return <Field {...props_Switch} component={SettingSwitchInput} />;
  };

  /**
   * generate Dropdown
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateDrop = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const props_values_Drop = propsValuesDrop({
      item,
      groupId,
      formRenderProps,
      data,
      isNew,
    });
    const props_handle_Drop = {
      onChange: (event: any) =>
        handleDropDownChange(event, groupId, formRenderProps),
    };
    const props_Drop = { ...props_values_Drop, ...props_handle_Drop };

    return (
      <Field
        {...props_Drop}
        component={SettingComboboxList}
        validator={fieldDropDownValidator}
      />
    );
  };
  /**
   * generate Date
   * @param item
   * @param groupId
   * @param formRenderProps
   * @returns
   */
  const generateDate = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement => {
    const props_values_Date = propsValuesDate({
      item,
      groupId,
      formRenderProps,
      data,
      isNew,
    });
    const props_handle_Date = {
      onChange: (event: any) =>
        handleInputChange(event, groupId, formRenderProps),
    };
    const props_Date = { ...props_values_Date, ...props_handle_Date };
    let component;
    if (item.DataType === SettingsDataType.Date) component = SettingDateInput;
    else component = SettingDateTimeInput;

    return (
      <Field
        {...props_Date}
        validator={fieldDateValidator}
        component={component}
      />
    );
  };

  /**
   * Function use for generate dynamic field types
   * Prop for fields to pass value
   * @param item
   * @returns
   */
  const getForm = (
    item: SettingField,
    groupId: number,
    formRenderProps: FormRenderProps
  ): ReactElement | null => {
    if (!item.IsVisible) return null;
    switch (item.DataType) {
      case SettingsDataType.List:
        return generateList(item, groupId, formRenderProps);
      case SettingsDataType.String:
        return generateInput(item, groupId, formRenderProps);
      case SettingsDataType.Boolean:
        return generateSwitch(item, groupId, formRenderProps);
      case SettingsDataType.Lookup:
        return generateDrop(item, groupId, formRenderProps);
      case SettingsDataType.Int:
      case SettingsDataType.Decimal:
      case SettingsDataType.Percent:
        return generateNumeric(item, groupId, formRenderProps);
      case SettingsDataType.Date:
      case SettingsDataType.DateTime:
        return generateDate(item, groupId, formRenderProps);
      case SettingsDataType.Money:
        return generateCurrency(item, groupId, formRenderProps);
    }
    return null;
  };

  /**
   * Check validation if form is not modified
   * @param event
   * @param formRenderProps
   * @returns
   */
  const validateForm = (
    event: React.SyntheticEvent<any>,
    formRenderProps: FormRenderProps
  ) => {
    if (formRenderProps.modified) {
      formRenderProps.onSubmit(event);
      return;
    }
    if (!formRenderProps.valid) return;
    notificationRef.current?.pushNotification({
      title: `The form is not modified`,
      autoClose: false,
      type: "warning",
    });
  };

  /**
   * checkBeforeElementNoneGroup
   * check to render three columns
   * @param id
   * @returns
   */
  const checkBeforeElementNoneGroup = (id: number) => {
    const position = id - 1;
    if (position >= 0 && position < data.length - 1) {
      const beforeElement = data[position];
      if (beforeElement.groupId === -1) return true;
    }
    return false;
  };

  /**
   * checkNextElementNoneGroup
   * check to render three columns
   * @param id
   * @param element
   * @returns
   */
  const checkNextElementNoneGroup = (
    id: number,
    element: IGroupSettingField
  ) => {
    listFieldNoneGroup.push(element);
    const position = id + 1;
    if (position < data.length) {
      const nextElement = data[id + 1];
      if (nextElement.groupId === -1) {
        return true;
      }
    }
    return false;
  };
  if (!data || data.length === 0) return <Loading isLoading isFullScreen />;
  return (
    <>
      <div className="cc-new-setting-manage-form">
        <CCLocalNotification ref={notificationRef} />
        <Form
          onSubmit={handleSubmitNew}
          initialValues={getFormInitialValueNew(initialNewData)}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              {setConfirmExit(formRenderProps.modified)}
              <input
                hidden
                type="submit"
                id="btn-submit"
                onClick={(e) => {
                  validateForm(e, formRenderProps);
                  setCheckHitSaveButton(true);
                }}
              />
              {data &&
                data.map((element: IGroupSettingField, index: number) => {
                  if (element.groupId !== -1) {
                    if (checkBeforeElementNoneGroup(index))
                      listFieldNoneGroup = [];
                    return (
                      <PanelBar
                        expandMode={"single"}
                        className="cc-panel-bar"
                        key={index}
                      >
                        <PanelBarItem
                          expanded={
                            collapseFirstGroup(data) === element.groupId
                          }
                          title={
                            <div className="cc-collapse-header">
                              <h6>{element.name}</h6>
                              <hr />
                            </div>
                          }
                        >
                          <div className="cc-cols-3">
                            {element.settingFields?.map(
                              (i: SettingField, id: number) => {
                                if (!i.IsVisible) return null;
                                else if (
                                  i.DataType &&
                                  i.DataType === SettingsDataType.Label
                                )
                                  return (
                                    <div key={id} className="cc-col-span-3">
                                      <label className="cc-label">
                                        {i.Title}
                                      </label>
                                    </div>
                                  );
                                return (
                                  <div
                                    key={id}
                                    className={`${
                                      i.IsBigField
                                        ? "cc-col-span-3"
                                        : "cc-col-span-1"
                                      }`}
                                  >
                                    {getForm(
                                      i,
                                      element.groupId,
                                      formRenderProps
                                    )}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </PanelBarItem>
                      </PanelBar>
                    );
                  }
                  if (!checkBeforeElementNoneGroup(index))
                    listFieldNoneGroup = [];
                  if (checkNextElementNoneGroup(index, element)) return null;
                  return (
                    <>
                      <div className="cc-cols-3 cc-none-group" key={index}>
                        {listFieldNoneGroup.map(
                          (itemField: IGroupSettingField, idx: number) => {
                            return (
                              <div
                                key={idx}
                                className={`${
                                  itemField.settingFields[0].IsBigField
                                    ? "cc-col-span-3"
                                    : "cc-col-span-1"
                                  }`}
                              >
                                {itemField.settingFields?.map(
                                  (i: any, id: number) => {
                                    if (!i.IsVisible) return null;
                                    else if (
                                      i.DataType &&
                                      i.DataType === DATA_TYPE.LABEL
                                    )
                                      return (
                                        <label key={id} className="cc-label">
                                          {i.Title}
                                        </label>
                                      );
                                    return (
                                      <div key={id}>
                                        {getForm(
                                          i,
                                          itemField.groupId,
                                          formRenderProps
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </>
                  );
                })}
            </FormElement>
          )}
        />
      </div>
    </>
  );
};

export default observer(NewSettingManage);
