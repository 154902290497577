import { useIsNew } from "@common/hooks/useIsNew";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { loadViewResponseById } from "./api";
import ExistedViewResponse from "./components/forms/existed/_index";
import NewViewResponse from "./components/forms/new/_index";
import { ViewResponse_Response } from "./model";

const ManagePropertyCentreLinkCustomerConfirmationViewResponse = () => {
  const history: any = useHistory();
  const params: { id: string } = useParams();
  const viewResponseId = params.id;
  const [isLoading, setIsLoading] = useState(false);

  const [viewResponseInfo, setViewResponseInfo] = useState<
    ViewResponse_Response | undefined
  >();

  useEffectOnce(() => {
    if (isNew) return;
    setIsLoading(true);
    loadViewResponseById(viewResponseId).then((data) => {
      if (!data) {
        return history.goBack();
      }
      setViewResponseInfo(data);
      setIsLoading(false);
    });
  });

  const isNew = useIsNew();
  if (isNew) {
    return <NewViewResponse />;
  }

  return (
    <ExistedViewResponse
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      viewResponseInfo={viewResponseInfo}
    />
  );
};

export default ManagePropertyCentreLinkCustomerConfirmationViewResponse;
