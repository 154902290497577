import { HMFieldMapping } from "@app/products/hm/model";
import { DATE_FORMAT, NUMBER_FORMAT } from "@common/constants/common-format";
import { PREMISES_MANAGE_ROUTE } from "../[id]/constant";

export const colHMPremisesByCategory = [
  {
    field: HMFieldMapping.TradingName,
    title: "Trading Name",
    locked: true,
    linkTo: (dataItem: { ID: string }) => {
      return `${PREMISES_MANAGE_ROUTE}/` + dataItem.ID;
    },
    width: 150,
  },
  {
    field: HMFieldMapping.RegNo,
    title: "Registration Number",
    width: 150,
  },
  {
    field: HMFieldMapping.Address,
    title: "Address",
    width: 150,
  },
  {
    field: HMFieldMapping.Area,
    title: "Area",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesClassification,
    title: "Classification",
    width: 150,
  },
  {
    field: HMFieldMapping.PremisesType,
    title: "Type",
    width: 150,
  },
  {
    field: HMFieldMapping.Risk,
    title: "Risk",
    width: 150,
  },
  {
    field: HMFieldMapping.Phone,
    title: "Phone",
    width: 150,
  },
  {
    field: HMFieldMapping.Proprietor,
    title: "Proprietor",
    width: 150,
  },
  {
    field: HMFieldMapping.PrimaryContact,
    title: "Contact",
    width: 150,
  },
  {
    field: HMFieldMapping.Email,
    title: "Email",
    width: 150,
  },
  {
    field: HMFieldMapping.DateLodged,
    title: "Lodged",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.DateRegistrationExpires,
    title: "Due Date",
    width: 150,
    format: DATE_FORMAT.DATE,
  },
  {
    field: HMFieldMapping.AmountOS,
    title: "Outstanding",
    width: 150,
    format: NUMBER_FORMAT.NUMBER2,
  },
];
