import { history } from "@/AppRoutes";
import { getParentSummary } from "@app/core/further-info/[id]/api";
import { Ancestor } from "@app/core/further-info/[id]/model";
import { BubbleUpType } from "@app/core/inspections/[id]/model";
import { getBubbleUpDetailsByType } from "@app/core/inspections/[id]/util";
import {
  getNoticesLOVs,
  postCheckIsSuperAdmin,
  postNotice,
  postNoticesById,
} from "@app/core/notices/[id]/api";
import { NOTICES_ROUTE } from "@app/core/notices/[id]/constant";
import {
  IParentNoticesSection,
  Notice,
  NoticesLovs,
} from "@app/core/notices/[id]/model";
import { getSourceIdentifier } from "@app/core/notices/[id]/util";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessIdentityPacket, isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { APIResponseStatus } from "@common/constants/response-status";
import { mapEnum } from "@common/utils/common";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";
import { appNotificationStore } from "@components/cc-app-notification/store";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });
class NoticesStore {
  private _notices?: Notice = undefined;
  private _noticesLOVs?: NoticesLovs = undefined;
  private _responseLoadError?: APIResponseError = undefined;
  private _isLoading: boolean = false;
  private _isLoadingSuperAdmin: boolean = false;
  private _isLoadingDetails: boolean = false;
  private _parent?: IParentNoticesSection = undefined;
  private _notification?: IAppNotificationItemAddProps = undefined;
  private _onSubmit?: (event: React.SyntheticEvent<any>) => void = undefined;
  private _noticeCategory?: any[] = [];
  private _ancestor?: Ancestor = undefined;
  private _isSuperAdmin: boolean = false;
  private _isDisableSaveButton = false;
  private _parentBubbleUpDetails?: BubbleUpIdentifier = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get isSuperAdmin() {
    return toJS(this._isSuperAdmin);
  }
  setIsSuperAdmin = (isSuperAdmin: boolean) => {
    runInAction(() => {
      this._isSuperAdmin = isSuperAdmin;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get notification() {
    return this._notification;
  }
  setNotification = (
    notification: IAppNotificationItemAddProps | undefined
  ) => {
    runInAction(() => {
      this._notification = notification;
    });
  };

  get isDisableSaveButton() {
    return this._isDisableSaveButton;
  }
  setIsDisableSaveButton = (isDisableSaveButton: boolean) => {
    runInAction(() => {
      this._isDisableSaveButton = isDisableSaveButton;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get isLoadingSuperAdmin() {
    return this._isLoadingSuperAdmin;
  }
  setIsLoadingSuperAdmin = (isLoadingSuperAdmin: boolean) => {
    runInAction(() => {
      this._isLoadingSuperAdmin = isLoadingSuperAdmin;
    });
  };

  get isLoadingDetails() {
    return this._isLoadingDetails;
  }
  setIsLoadingDetails = (isLoadingDetails: boolean) => {
    runInAction(() => {
      this._isLoadingDetails = isLoadingDetails;
    });
  };

  get onSubmit() {
    return this._onSubmit;
  }
  setOnSubmit = (onSubmit: (event: React.SyntheticEvent<any>) => void) => {
    runInAction(() => {
      this._onSubmit = onSubmit;
    });
  };

  get parent() {
    return this._parent;
  }
  setParent = (parent?: IParentNoticesSection) => {
    runInAction(() => {
      this._parent = parent;
    });
  };

  get notices() {
    return toJS(this._notices);
  }
  setNotices = (notices?: Notice) => {
    runInAction(() => {
      this._notices = notices;
    });
  };

  get noticesLOVs() {
    return toJS(this._noticesLOVs);
  }
  setNoticesLOVs = (noticesLOVs?: NoticesLovs) => {
    runInAction(() => {
      this._noticesLOVs = noticesLOVs;
    });
  };

  get noticeCategory() {
    return this._noticeCategory;
  }
  setNoticeCategory = (noticeCategory: any[]) => {
    runInAction(() => {
      this._noticeCategory = noticeCategory;
    });
  };

  get ancestor() {
    return this._ancestor;
  }
  setAncestor = (parentLine?: Ancestor) => {
    runInAction(() => {
      this._ancestor = parentLine;
    });
  };

  get parentBubbleUpDetails() {
    return this._parentBubbleUpDetails;
  }
  setParentBubbleUpDetails = (parentBubbleUpDetails?: BubbleUpIdentifier) => {
    runInAction(() => {
      this._parentBubbleUpDetails = parentBubbleUpDetails;
    });
  };

  resetStore = () => {
    runInAction(() => {
      this._notices = undefined;
      this._noticesLOVs = undefined;
      this._isLoading = false;
      this._isLoadingDetails = false;
      this._isLoadingSuperAdmin = false;
      this._parent = undefined;
      this._noticeCategory = [];
      this._ancestor = undefined;
      this._isDisableSaveButton = false;
      this._responseLoadError = undefined;
      this._onSubmit = undefined;
      this._isSuperAdmin = false;
      this._notification = undefined;
      this._parentBubbleUpDetails = undefined;
    });
  };

  reloadNotices = (noticeID: number, isNew: boolean) => {
    this.loadNotices(noticeID, isNew);
  };

  checkIsSuperAdmin = async (userID: number) => {
    this.setResponseLoadError(undefined);
    this.setIsLoadingSuperAdmin(true);
    const response = await postCheckIsSuperAdmin(userID);
    if (isSuccessResponse(response)) {
      this.setIsSuperAdmin(response.data ?? false);
    } else {
      this.setResponseLoadError({
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      });
    }
    this.setIsLoadingSuperAdmin(false);
  };

  loadNotices = async (noticeID: number, isNew: boolean) => {
    this.setIsLoading(true);
    let errorResponse = undefined;
    let newNotice: Notice | undefined;

    const response = await postNoticesById(
      noticeID,
      isNew ? this.parent?.bubbleUps : undefined
    );
    if (isSuccessResponse(response) && response.data) {
      newNotice = response.data?.ReturnObj;
      this.setNotices(newNotice);
      if (!isNew) {
        this.loadNoticesLOVs(newNotice?._BubbleUps);
        if (newNotice) {
          this.setParentBubbleUpDetails(
            getBubbleUpDetailsByType(newNotice._BubbleUps, BubbleUpType.Parent)
          );
        }
      } else {
        if (this.parent?.bubbleUps) {
          this.loadNoticesLOVs(this.parent?.bubbleUps ?? []);
        } else {
          this.setIsDisableSaveButton(true);
          appNotificationStore.pushNotification({
            type: "error",
            autoClose: false,
            title:
              "This notice doesn't have parent info. Please go back to parent page and create again!",
          });
        }
      }
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }

    const sourceIdentifier = getSourceIdentifier(
      newNotice?._BubbleUps,
      BubbleUpType.Parent
    );

    if (sourceIdentifier) {
      const newParent = {
        ...this.parent,
        id: sourceIdentifier._RecordSource_ID,
        recordType: mapEnum(
          sourceIdentifier._RecordSourceType_ENUM,
          RECORDTYPE
        ),
      };
      await this.loadParent(newParent);
    } else if (this.parent) {
      await this.loadParent(this.parent);
    }
    this.setResponseLoadError(errorResponse);
    this.setIsLoading(false);

    // Push notification
    if (this.notification && !errorResponse) {
      appNotificationStore.pushNotification(this.notification);
      this.setNotification(undefined);
    }
    if (this.parent?.notification && !errorResponse) {
      this.parent?.notification.forEach(
        (notification: IAppNotificationItemAddProps) => {
          appNotificationStore.pushNotification(notification);
        }
      );
      this.setParent({ ...this.parent, notification: [] });
      //Cleanup noti parent - In case: new -> existed notice (state only have noti)
      window.history.replaceState({}, "");
    }
  };

  loadNoticesLOVs = async (
    bubbleUpsParent: BubbleUpIdentifier[] | undefined
  ) => {
    const responseLOVs = await getNoticesLOVs(bubbleUpsParent);
    let newNoticesLOVs = undefined;
    if (isSuccessResponse(responseLOVs) && responseLOVs?.data) {
      newNoticesLOVs = responseLOVs.data?.ReturnObj;
    } else {
      this.setIsDisableSaveButton(true);
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: "Load fail.",
        description: responseLOVs?.data?.Errors ?? responseLOVs?.error,
      });
    }
    this.setNoticesLOVs(newNoticesLOVs);
  };

  loadParent = async (parent: IParentNoticesSection) => {
    this.setParent(parent);
    const response = await getParentSummary(parent.id, parent.recordType);
    if (isSuccessResponse(response) && response.data) {
      this.setAncestor(response.data);
    } else {
      appNotificationStore.pushNotification({
        type: "error",
        autoClose: false,
        title: response?.error ?? "Load parent action data failed",
      });
    }
  };

  loadNoticeDetails = async (noticeID: number) => {
    this.setIsLoadingDetails(true);
    let errorResponse = undefined;
    let newNotice: Notice | undefined;
    const response = await postNoticesById(noticeID);
    if (
      isSuccessResponse(response) &&
      response.data &&
      response.data?.ReturnObj
    ) {
      newNotice = response.data.ReturnObj;
      this.setNotices(newNotice);

      this.setParentBubbleUpDetails(
        getBubbleUpDetailsByType(newNotice._BubbleUps, BubbleUpType.Parent)
      );
    } else {
      errorResponse = {
        status: APIResponseStatus.INTERNAL_SERVER_ERROR,
        error: "Server error",
      };
    }
    const sourceIdentifier = getSourceIdentifier(
      newNotice?._BubbleUps,
      BubbleUpType.Parent
    );
    if (sourceIdentifier) {
      const newParent = {
        ...this.parent,
        id: sourceIdentifier._RecordSource_ID,
        recordType: mapEnum(
          sourceIdentifier._RecordSourceType_ENUM,
          RECORDTYPE
        ),
      };
      await this.loadParent(newParent);
    }
    this.setIsLoadingDetails(false);
    this.setResponseLoadError(errorResponse);
  };

  saveNotices = async (notice: Notice, isNew: boolean) => {
    this.setIsLoading(true);
    let dataBubbleUps = undefined;
    if (notice._BubbleUps?.length === 0) {
      dataBubbleUps = { ...notice, _BubbleUps: this.parent?.bubbleUps };
    }
    const response = await postNotice((dataBubbleUps as Notice) ?? notice);

    this.setIsLoading(false);
    if (isSuccessIdentityPacket(response)) {
      if (isNew) {
        window.history.replaceState({}, "");
        history.replace(`${NOTICES_ROUTE}/${response.data?.ID}`, {
          parent: {
            notification: [
              {
                autoClose: true,
                title: "Record successfully saved.",
                type: "success",
              },
            ],
          },
        });
      } else {
        this.setNotification({
          autoClose: true,
          title: "Record successfully saved.",
          type: "success",
        });
        this.loadNotices(response?.data?.source?._RecordSource_ID, false);
      }
    } else {
      appNotificationStore.pushNotification({
        autoClose: false,
        title: response?.error ?? "Notices create failed",
        type: "error",
        description: response?.data?.Errors ?? "",
      });
    }
  };
}

const NoticesStoreContext = createContext(new NoticesStore());
export const useNoticesStore = () => {
  return useContext(NoticesStoreContext);
};
