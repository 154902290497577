import {
  contactFiltersTownPlanning,
  relationshipFiltersTownPlanning,
} from "@app/core/contacts/components/dialogs/additional-contact/config";
import { ContactClassification } from "@app/core/contacts/_id/model";
import { ContactRelationshipType } from "@app/products/town-planning/ppr/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";

export const processAdditionalContactOption = (
  recordType: RECORDTYPE
): {
  contactFilters: ContactClassification[];
  relationshipFilters: ContactRelationshipType[];
} => {
  switch (recordType) {
    case RECORDTYPE.TP_PPRApplication:
      return {
        contactFilters: contactFiltersTownPlanning,
        relationshipFilters: relationshipFiltersTownPlanning,
      };
    default:
      return {
        contactFilters: [],
        relationshipFilters: [],
      };
  }
};
