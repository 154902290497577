import { eComponent } from "@app/products/property/components/associations/model";
import { getDocumentIssued } from "@app/products/property/components/grid/document-issued-grid/api";
import { DTO_Document } from "@app/products/property/components/grid/document-issued-grid/model";
import { APIResponseError } from "@common/apis/model";
import { isSuccessResponse } from "@common/apis/util";
import { isNil } from "lodash";
import { configure, makeAutoObservable, runInAction, toJS } from "mobx";
import { createContext, useContext } from "react";
configure({ enforceActions: "always" });

class DocumentsIssuedStore {
  private _documentsIssued?: DTO_Document[] = undefined;
  private _documentIssueSelectedRow?: DTO_Document | undefined = undefined;
  private _isLoading: boolean = false;
  private _responseLoadError?: APIResponseError = undefined;
  //Used for reload
  private _componentNumber?: eComponent = undefined;
  private _componentId?: number | string = undefined;
  private _documentTypeId?: number | string = undefined;
  constructor() {
    makeAutoObservable(this);
  }
  get documentsIssued() {
    return this._documentsIssued;
  }
  setDocumentsIssued = (documentsIssued?: DTO_Document[]) => {
    runInAction(() => {
      this._documentsIssued = documentsIssued;
    });
  };

  get responseLoadError() {
    return toJS(this._responseLoadError);
  }
  setResponseLoadError = (responseLoadError?: APIResponseError) => {
    runInAction(() => {
      this._responseLoadError = responseLoadError;
    });
  };

  get isLoading() {
    return this._isLoading;
  }
  setIsLoading = (isLoading: boolean) => {
    runInAction(() => {
      this._isLoading = isLoading;
    });
  };

  get documentIssueSelectedRow() {
    return toJS(this._documentIssueSelectedRow);
  }
  setDocumentIssueSelectedRow = (documentIssueSelectedRow?: DTO_Document) => {
    this._documentIssueSelectedRow = documentIssueSelectedRow;
  };

  setComponentNumber = (componentNumber?: eComponent) => {
    runInAction(() => {
      this._componentNumber = componentNumber;
    });
  };
  setComponentId = (componentId?: number | string) => {
    runInAction(() => {
      this._componentId = componentId;
    });
  };
  setDocumentTypeId = (documentTypeId?: number | string) => {
    runInAction(() => {
      this._documentTypeId = documentTypeId;
    });
  };

  //Action
  resetStore = () => {
    runInAction(() => {
      this._documentsIssued = undefined;
      this._isLoading = false;
      this._documentIssueSelectedRow = undefined;
      this._responseLoadError = undefined;
    });
  };
  loadDocumentsIssued = async (
    componentNumber: eComponent,
    componentId: number | string,
    documentTypeId?: number | string
  ) => {
    if (!componentId) return;
    this.setComponentNumber(componentNumber);
    this.setComponentId(componentId);
    this.setDocumentTypeId(documentTypeId);
    this.setIsLoading(true);
    const response = await getDocumentIssued(
      componentNumber,
      componentId,
      documentTypeId
    );
    let errorResponse = undefined;
    let documentsIssued: DTO_Document[] | undefined;
    if (isSuccessResponse(response) && response?.data?.Documents) {
      documentsIssued = response.data.Documents || [];
    } else {
      errorResponse = {
        status: response.status,
        error: response.error,
      };
    }
    this.setResponseLoadError(errorResponse);
    this.setDocumentsIssued(documentsIssued);
    this.setIsLoading(false);
  };

  reLoadDocumentsIssued = async () => {
    if (isNil(this._componentNumber) || isNil(this._componentId)) return;
    await this.loadDocumentsIssued(
      this._componentNumber,
      this._componentId,
      this._documentTypeId
    );
  };
}

const documentsIssuedStoreContext = createContext(new DocumentsIssuedStore());
export const useDocumentIssuedStore = () => {
  return useContext(documentsIssuedStoreContext);
};
