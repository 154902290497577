export const mockExternalConcessionCards = [
  {
    CustomerId: "MSH04875A ",
    Org: "DVA",
    Name: "LYNCH, Thomas",
    Matched: "No record found fo non Centrelink reference number provided",
    FirstName: true,
    Surname: true,
    Assessment103: 4450250013,
    AssessmentId: 136067,
    EntityId: 165234,
    Id: "0",
  },
  {
    CustomerId: "NA000460A ",
    Org: "DVA",
    ConfirmationId: "138412",
    Name: "ANGIOLINO, Nunzia",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5570450001.019,
    AssessmentId: 144861,
    EntityId: 101089,
    Id: "1",
  },
  {
    CustomerId: "NA000740 ",
    Org: "DVA",
    ConfirmationId: "72435",
    Name: "RISLEVSKI, Karolina",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7101560005,
    AssessmentId: 242010,
    EntityId: 231838,
    Id: "2",
  },
  {
    CustomerId: "NA000740A ",
    Org: "DVA",
    ConfirmationId: "88079",
    Name: "RISTESKA, Ristana",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7101560005,
    AssessmentId: 242010,
    EntityId: 231839,
    Id: "3",
  },
  {
    CustomerId: "NA001362A ",
    Org: "DVA",
    ConfirmationId: "139796",
    Name: "JONES, Craig Andrew",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 3280400021,
    AssessmentId: 122238,
    EntityId: 230699,
    Id: "4",
  },
  {
    CustomerId: "NA002016A ",
    Org: "DVA",
    ConfirmationId: "140764",
    Name: "KNUCKEY, Joan Elizabeth",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5660710001.007,
    AssessmentId: 244934,
    EntityId: 154590,
    Id: "5",
  },
  {
    CustomerId: "NA002483 ",
    Org: "DVA",
    ConfirmationId: "1455006",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 6074190019,
    AssessmentId: 174068,
    EntityId: 97623,
    Id: "6",
  },
  {
    CustomerId: "NA002503",
    Org: "DVA",
    ConfirmationId: "141638",
    Name: "BOYES, Wendy Lorraine ",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 6015120012,
    AssessmentId: 167443,
    EntityId: 92010,
    Id: "7",
  },
  {
    CustomerId: "NA002503A",
    Org: "DVA",
    ConfirmationId: "141639",
    Name: "BOYES, Maviell",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 6015120012,
    AssessmentId: 167443,
    EntityId: 92009,
    Id: "8",
  },
  {
    CustomerId: "NA002523 ",
    Org: "DVA",
    ConfirmationId: "141685",
    Name: "GOODMAN, Kay Frances",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7070850016,
    AssessmentId: 232663,
    EntityId: 118532,
    Id: "9",
  },
  {
    CustomerId: "NA002543A ",
    Org: "DVA",
    ConfirmationId: "1497985",
    Name: "JUST, Andrew William",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7070080007,
    AssessmentId: 227067,
    EntityId: 164011,
    Id: "10",
  },
  {
    CustomerId: "NA003109A ",
    Org: "DVA",
    ConfirmationId: "143189",
    Name: "GEORGIADIS, John",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 4430530022,
    AssessmentId: 134388,
    EntityId: 117768,
    Id: "11",
  },
  {
    CustomerId: "NA003247 ",
    Org: "DVA",
    ConfirmationId: "143511",
    Name: "JOYNSON, Graeme Leslie",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 6030020004,
    AssessmentId: 195693,
    EntityId: 167153,
    Id: "12",
  },
  {
    CustomerId: "NA003247A ",
    Org: "DVA",
    ConfirmationId: "1461625",
    Name: "HART, Jo-Ann",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 6030020004,
    AssessmentId: 195693,
    EntityId: 170460,
    Id: "13",
  },
  {
    CustomerId: "NA003300 ",
    Org: "DVA",
    ConfirmationId: "143665",
    Name: "KALVE, Terrence",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7131000001,
    AssessmentId: 255067,
    EntityId: 205738,
    Id: "14",
  },
  {
    CustomerId: "NA003300A ",
    Org: "DVA",
    ConfirmationId: "1475005",
    Name: "BURGOYNE, Joyce Marianne",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7131000001,
    AssessmentId: 255067,
    EntityId: 205737,
    Id: "15",
  },
  {
    CustomerId: "NA003469 ",
    Org: "DVA",
    ConfirmationId: "144101",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7110180018,
    AssessmentId: 243228,
    EntityId: 128637,
    Id: "16",
  },
  {
    CustomerId: "NA003536 ",
    Org: "DVA",
    ConfirmationId: "144261",
    Name: "HANSON, Elizabeth Maree",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 6080340030,
    AssessmentId: 182254,
    EntityId: 20145,
    Id: "17",
  },
  {
    CustomerId: "NA003536A ",
    Org: "DVA",
    ConfirmationId: "144262",
    Name: "GILBERT, Jodie Belinda",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 6080340030,
    AssessmentId: 182254,
    EntityId: 168052,
    Id: "18",
  },
  {
    CustomerId: "NA003568 ",
    Org: "DVA",
    ConfirmationId: "144348",
    Name: "PLANCKE, Brian William",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7190420007,
    AssessmentId: 262182,
    EntityId: 268345,
    Id: "19",
  },
  {
    CustomerId: "NA003568A ",
    Org: "DVA",
    ConfirmationId: "1455428",
    Name: "PLANKEN, Linda Pauline",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7190420007,
    AssessmentId: 262182,
    EntityId: 268346,
    Id: "20",
  },
  {
    CustomerId: "NA003746 ",
    Org: "DVA",
    ConfirmationId: "144807",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5601360005,
    AssessmentId: 179297,
    EntityId: 118655,
    Id: "21",
  },
  {
    CustomerId: "NA003746A ",
    Org: "DVA",
    ConfirmationId: "144808",
    Name: "GRANADENO, Oscar",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5601360005,
    AssessmentId: 179297,
    EntityId: 118738,
    Id: "22",
  },
  {
    CustomerId: "NA003959 ",
    Org: "DVA",
    ConfirmationId: "145352",
    Name: "GAGE, Lynette Patricia",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7130510002,
    AssessmentId: 251532,
    EntityId: 197813,
    Id: "23",
  },
  {
    CustomerId: "NA004125 ",
    Org: "DVA",
    ConfirmationId: "145775",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 6128260020,
    AssessmentId: 188775,
    EntityId: 167319,
    Id: "24",
  },
  {
    CustomerId: "NA004296 ",
    Org: "DVA",
    ConfirmationId: "146110",
    Name: "HALL, Joan",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 1010960012,
    AssessmentId: 185813,
    EntityId: 168081,
    Id: "25",
  },
  {
    CustomerId: "NA004411A ",
    Org: "DVA",
    ConfirmationId: "1590920",
    Name: "VOSER, Fritz",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 7070200024,
    AssessmentId: 224500,
    EntityId: 90088,
    Id: "26",
  },
  {
    CustomerId: "NA0044411 ",
    Org: "DVA",
    Name: "GIRONDOUDAS, Nikolas",
    Matched: "No record found fo non Centrelink reference number provided",
    FirstName: true,
    Surname: true,
    Assessment103: 7070200024,
    AssessmentId: 224500,
    EntityId: 118585,
    Id: "27",
  },
  {
    CustomerId: "NA004458 ",
    Org: "DVA",
    ConfirmationId: "1407126",
    Name: "MAHER, Helen",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5670010028,
    AssessmentId: 151608,
    EntityId: 128627,
    Id: "28",
  },
  {
    CustomerId: "NA004458A ",
    Org: "DVA",
    ConfirmationId: "1475744",
    Name: "GRANT, Barbara Gloria",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 5670010028,
    AssessmentId: 151608,
    EntityId: 118763,
    Id: "29",
  },
  {
    CustomerId: "NA004467 ",
    Org: "DVA",
    ConfirmationId: "1406774",
    Name: "GRAHAM, Daniella Louisa",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 6190250014,
    AssessmentId: 195450,
    EntityId: 118697,
    Id: "30",
  },
  {
    CustomerId: "NA004467A",
    Org: "DVA",
    ConfirmationId: "1608710",
    Name: "GOEDEMOED, Harry",
    Matched:
      "Credentials provided for this record are valid, the record is located an",
    ConfirmationDate: "22-Jul-2017",
    Deceased: false,
    PensionConcession: true,
    GoldCard: true,
    Disability: true,
    FirstName: true,
    Surname: true,
    PostcodeMatched: true,
    Assessment103: 6190250014,
    AssessmentId: 195450,
    EntityId: 118423,
    Id: "31",
  },
];
