export const mockViewResponse = [
  {
    CentreLinkResponseBatch: new Date("13-Jan-2016 08:42"),
    Id: "1",
  },
  {
    CentreLinkResponseBatch: new Date("13-Jan-2016 08:51"),
    Id: "2",
  },
  {
    CentreLinkResponseBatch: new Date("17-Apr-2016 12:42"),
    Id: "3",
  },
  {
    CentreLinkResponseBatch: new Date("17-Apr-2016 12:44"),
    Id: "4",
  },
  {
    CentreLinkResponseBatch: new Date("18-Jun-2016 12:00"),
    Id: "5",
  },
  {
    CentreLinkResponseBatch: new Date("18-Jun-2016 12:37"),
    Id: "6",
  },
  {
    CentreLinkResponseBatch: new Date("18-Jun-2016 18:39"),
    Id: "7",
  },
  {
    CentreLinkResponseBatch: new Date("08-Oct-2016 09:52"),
    Id: "8",
  },
  {
    CentreLinkResponseBatch: new Date("08-Oct-2016 09:54"),
    Id: "9",
  },
  {
    CentreLinkResponseBatch: new Date("15-Jan-2017 14:52"),
    Id: "10",
  },
  {
    CentreLinkResponseBatch: new Date("15-Jan-2017 14:53"),
    Id: "11",
  },
  {
    CentreLinkResponseBatch: new Date("08-Apr-2017 10:52"),
    Id: "12",
  },
  {
    CentreLinkResponseBatch: new Date("08-Apr-2017 11:26"),
    Id: "13",
  },
  {
    CentreLinkResponseBatch: new Date("19-Jun-2017 15:27"),
    Id: "14",
  },
  {
    CentreLinkResponseBatch: new Date("19-Jun-2017 15:28"),
    Id: "15",
  },
  {
    CentreLinkResponseBatch: new Date("19-Sep-2017 09:01"),
    Id: "16",
  },
  {
    CentreLinkResponseBatch: new Date("20-Sep-2017 14:17"),
    Id: "17",
  },
  {
    CentreLinkResponseBatch: new Date("16-Jan-2018 14:10"),
    Id: "18",
  },
  {
    CentreLinkResponseBatch: new Date("16-Jan-2018 14:13"),
    Id: "19",
  },
  {
    CentreLinkResponseBatch: new Date("04-Apr-2018 14:17"),
    Id: "20",
  },
  {
    CentreLinkResponseBatch: new Date("04-Apr-2018 16:14"),
    Id: "21",
  },
  {
    CentreLinkResponseBatch: new Date("11-Jun-2018 15:20"),
    Id: "22",
  },
  {
    CentreLinkResponseBatch: new Date("11-Jun-2018 15:22"),
    Id: "23",
  },
  {
    CentreLinkResponseBatch: new Date("08-Oct-2018 10:45"),
    Id: "24",
  },
  {
    CentreLinkResponseBatch: new Date("08-Oct-2018 10:46"),
    Id: "25",
  },
  {
    CentreLinkResponseBatch: new Date("19-Dec-2018 17:20"),
    Id: "26",
  },
  {
    CentreLinkResponseBatch: new Date("06-Jan-2019 11:19"),
    Id: "27",
  },
  {
    CentreLinkResponseBatch: new Date("24-Mar-2019 11:00"),
    Id: "28",
  },
  {
    CentreLinkResponseBatch: new Date("24-Mar-2019 15:55"),
    Id: "29",
  },
  {
    CentreLinkResponseBatch: new Date("15-Jun-2019 10:40"),
    Id: "30",
  },
  {
    CentreLinkResponseBatch: new Date("15-Jun-2019 11:29"),
    Id: "31",
  },
  {
    CentreLinkResponseBatch: new Date("15-Jun-2019 12:27"),
    Id: "32",
  },
  {
    CentreLinkResponseBatch: new Date("17-Jun-2019 14:41"),
    Id: "33",
  },
  {
    CentreLinkResponseBatch: new Date("10-Sep-2019 14:02"),
    Id: "34",
  },
  {
    CentreLinkResponseBatch: new Date("10-Sep-2019 14:03"),
    Id: "35",
  },
  {
    CentreLinkResponseBatch: new Date("15-Dec-2019 16:14"),
    Id: "36",
  },
  {
    CentreLinkResponseBatch: new Date("15-Dec-2019 16:16"),
    Id: "37",
  },
  {
    CentreLinkResponseBatch: new Date("23-Mar-2020 16:25"),
    Id: "38",
  },
  {
    CentreLinkResponseBatch: new Date("23-Mar-2020 16:27"),
    Id: "39",
  },
  {
    CentreLinkResponseBatch: new Date("22-Jun-2020 12:17"),
    Id: "40",
  },
  {
    CentreLinkResponseBatch: new Date("22-Jun-2020 12:18"),
    Id: "41",
  },
  {
    CentreLinkResponseBatch: new Date("10-Oct-2020 16:39"),
    Id: "42",
  },
  {
    CentreLinkResponseBatch: new Date("10-Oct-2020 16:40"),
    Id: "43",
  },
];
