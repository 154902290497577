import { eventEmitter } from "@/App";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { CCSettingListEventType } from "@app/products/property/system-admin/settings/_category/constant";
import { useSettingPropertyStore } from "@app/products/property/system-admin/settings/_category/store";
import { handleConfirmDelete } from "@app/products/property/system-admin/settings/_id/components/forms/utils";
import { deleteSetting } from "@app/products/property/system-admin/settings/api";
import { useSettingDeleteButtonStore } from "@app/products/property/system-admin/settings/components/action-bar/buttons/delete/store";
import { APIResponseStatus } from "@common/constants/response-status";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCNavButton } from "@components/cc-nav-button/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const PropertyActionBarSettingDelete = observer(() => {
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] = useState(false);
  const [isEnabledButton, setIsEnabledButton] = useState(false);
  const { setGridSelectedRows, gridSelectedRows } = useCCProductListViewStore();
  const { pushNotification } = useCCAppNotificationStore();
  const { isEditable, setIsDelete } = useSettingPropertyStore();
  const { isLoading, setIsLoading } = useSettingDeleteButtonStore();
  const location = useLocation();
  const state: any = location.state;

  useEffect(() => {
    const listSelectedRecords = [...gridSelectedRows];
    let isEnabledDelete = false;
    if (!listSelectedRecords) return;
    if (listSelectedRecords.length) {
      isEnabledDelete = listSelectedRecords.every(
        (i: any) => i.CanDelete === true
      );
    }
    if (isEnabledDelete) {
      setIsEnabledButton(true);
    } else {
      setIsEnabledButton(false);
    }
  }, [gridSelectedRows]);

  const handleDeleteButton = () => {
    setIsShowDeleteConfirm(gridSelectedRows.length >= 1);
  };

  const handleSubmit = async () => {
    setIsShowDeleteConfirm(false);
    if (isEditable) {
      setIsLoading(true);
      const deletedData = handleConfirmDelete(gridSelectedRows);

      if (!deletedData) return;
      else {
        await deleteSetting(deletedData)
          .then((response: any) => {
            if (
              response.data &&
              response.status === APIResponseStatus.SUCCESS
            ) {
              setIsDelete(true);
              setGridSelectedRows([]);
              eventEmitter.emit(CCSettingListEventType.RefreshOData, {
                notificationContentSuccess: "Deleted successfully",
                stateData: state,
              });
            } else if (
              response.data &&
              response.status === APIResponseStatus.BAD_REQUEST
            ) {
              const res = response.data;
              const typeMessage =
                res.MessageType === "Flash Information"
                  ? "information"
                  : res.MessageType.toLowerCase();
              const isClose =
                res.MessageType === "Flash Information" ? true : false;
              pushNotification({
                title: res.HeaderMessage,
                type: typeMessage,
                autoClose: isClose,
              });
            } else {
              pushNotification({
                type: "error",
                title: "Error Occurred",
              });
            }
          })
          .catch(() => {
            pushNotification({
              title: "Error occurred",
              type: "error",
            });
          });
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      {isEditable && (
        <CCNavButton
          title="Delete"
          disabled={isLoading || !isEnabledButton}
          iconClass={isLoading ? "fas fa-spinner fa-spin" : ""}
          onClick={handleDeleteButton}
        />
      )}
      {isShowDeleteConfirm && (
        <ConfirmDelete
          onClose={() => {
            setIsShowDeleteConfirm(false);
          }}
          header={"Delete Confirmation"}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
});
