import { ContactsTab } from "@app/core/contacts/components/contact-tab/_index";
import { useInspectionsStore } from "@app/core/inspections/[id]/store";
import { RECORDTYPE } from "@common/constants/recordtype";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router-dom";

interface IInspectionContactsTabProps {
  // Get the data by this field
  fieldName?: string;
}
export const InspectionContactsTab = observer(
  ({ fieldName }: IInspectionContactsTabProps) => {
    const { id } = useParams<{ id: string }>();
    const { inspections } = useInspectionsStore();
    const { lastSelectedId, lastSelectedRow } = useCCProductListViewStore();
    const inspectionsId =
      fieldName &&
      !isNil(lastSelectedRow?.[fieldName]) &&
      lastSelectedRow[fieldName] !== ""
        ? lastSelectedRow[fieldName]
        : id ?? lastSelectedId;

    return (
      <ContactsTab
        id={parseInt(inspectionsId)}
        recordType={RECORDTYPE.CORE_Inspection}
        triggerReload={inspections}
      />
    );
  }
);
