import { MultiTenancyView } from "@app/core/location-register/[id]/model";
import { setupPremisesPickSiteAddress } from "@app/products/hm/premises/[id]/api";
import {
  getNameOfPremises,
  nameOfSvcPremises,
} from "@app/products/hm/premises/[id]/components/child-screens/general/components/premise-form-element/_index";
import { propertyOwnerOptions } from "@app/products/hm/premises/[id]/components/child-screens/general/components/sections/general-section/config";
import { checkSecondaryMode } from "@app/products/hm/premises/[id]/config";
import { HM_PREMISES_HANDLER_SLIDER } from "@app/products/hm/premises/[id]/constant";
import {
  IHMHandlerEventInitialData,
  PremiseHandlerRequest,
  Premises,
  PremisesRegisterLovs,
  PremisesUIControl,
  PremisesUpdateTriggers,
  SecondaryModeCheckField,
  Svc_FormAction_Premise,
} from "@app/products/hm/premises/[id]/model";
import {
  getFSSContactDisplay,
  getPrimaryContactDisplay,
  getProprietorContactDisplay,
} from "@app/products/hm/premises/[id]/util";
import { ContactPicker } from "@app/products/town-planning/ppr/[id]/components/input-picker/contact-picker/_index";
import { InputPickerSearch } from "@app/products/town-planning/ppr/[id]/components/input-picker/input-picker-search/_index";
import { PropertyDetail } from "@app/products/town-planning/ppr/[id]/components/input-picker/property-details/_index";
import { isSuccessResponse } from "@common/apis/util";
import { AddressClassification } from "@common/constants/enumerations";
import { fetchApiByAlias } from "@common/hooks/flexible-fetch-data/useFlexibleFetchData";
import {
  Address_BuildAddress,
  SetupAddress,
} from "@common/input-pickers/address/model";
import { ECorporateSettingsField } from "@common/models/corporateSettingsField";
import { useCommonCoreStore } from "@common/stores/core/store";
import { useFlexibleFormStore } from "@common/stores/flexible-form/store";
import {
  getBoolValueSetting,
  getStringValueSetting,
} from "@common/stores/products/util";
import { requiredValidator } from "@common/utils/field-validators";
import { capitalizeFirstLetter } from "@common/utils/formatting";
import { sanitizeHtml } from "@common/utils/sanitized-parser";
import { isHTML } from "@components/cc-input-picker/util";
import { CCInput } from "@components/cc-input/_index";
import { CCLabel } from "@components/cc-label/_index";
import { useNotificationPortalStore } from "@components/cc-notification-portal/store";
import { CCSwitch } from "@components/cc-switch/_index";
import { CCValueField } from "@components/cc-value-field/_index";
import Loading from "@components/loading/Loading";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import { Field, FormRenderProps } from "@progress/kendo-react-form";
import { isFunction } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useRef, useState } from "react";

export interface IHMPremisesGeneralSectionProps {
  isInactive?: boolean;
  formRenderProps: FormRenderProps;
}
export const GeneralSection = observer(
  ({ formRenderProps, isInactive = false }: IHMPremisesGeneralSectionProps) => {
    const { dataForms, setInitialDataForms } = useFlexibleFormStore();
    const { pushNotificationPortal } = useNotificationPortalStore();
    const { settings } = useCommonCoreStore();
    const { valueGetter, onChange } = formRenderProps;

    const premisesObj = valueGetter(nameOfSvcPremises("Premises")) as Premises;
    const uiControlStore = dataForms?.GeneralUIControl as PremisesUIControl;
    const lovs = dataForms?.GeneralFormLovs as PremisesRegisterLovs;

    const prevProprietorDisplayName = useRef<string>("");
    const prevPrimaryContactDisplayName = useRef<string>("");
    const prevAlternateAddressDisplayName = useRef<string>("");
    const prevSiteAddressDisplayName = useRef<string>("");
    const prevPostalAddressDisplayName = useRef<string>("");
    const prevFSPContactDisplayName = useRef<string>("");
    const prevPropertyOwnerDisplayName = useRef<string>("");

    const [initialBuildAddress, setInitialBuildAddress] =
      useState<Address_BuildAddress>();
    const [isLoadingOpenDialog, setIsLoadingOpenDialog] = useState(false);

    // #region GET SETTING VALUES
    const isShowMultiTenancy =
      getBoolValueSetting(
        settings[ECorporateSettingsField.HealthManager_MultiTenancy]
      ) ?? false;
    const isFastChoice =
      getBoolValueSetting(
        settings[ECorporateSettingsField.HealthManager_FastChoice]
      ) ?? false;
    const isShowScoresEndorse =
      getBoolValueSetting(
        settings[ECorporateSettingsField.HealthManager_ShowScoresEndorse]
      ) ?? false;
    const isUseSimpleAddressDialogHM =
      getBoolValueSetting(
        settings[ECorporateSettingsField.HealthManager_SimpleAddressDialog]
      ) ?? false;
    const alternateAddressLabelSetting = getStringValueSetting(
      settings[ECorporateSettingsField.CorporateSettings_AlternateAddressLabel]
    );
    const isContactSplitAddressName = getBoolValueSetting(
      settings[ECorporateSettingsField.Global_Contact_SplitAddressName]
    );
    const isShowProprietorAddress = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_ShowProprietorAddress]
    );
    const isFSSRequired = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_FSSRequired]
    );
    const enableSecondaryMode = getBoolValueSetting(
      settings[ECorporateSettingsField.HealthManager_EnableSecondaryMode]
    );
    const sFieldMapping =
      getStringValueSetting(
        settings[ECorporateSettingsField.HealthManager_TechOneFieldMapping]
      ) ?? "";
    // #endregion GET SETTING VALUES

    // #region GET LABEL
    const alternateAddressLabel =
      capitalizeFirstLetter(alternateAddressLabelSetting) ??
      "Residential address";
    const tradingNameLabel = useMemo(() => {
      if (
        valueGetter(getNameOfPremises("PremisesType"))?.Flag_SwimmingPool ||
        valueGetter(getNameOfPremises("PremisesType"))?.Flag_CoolingTower
      ) {
        return "Business name";
      }
      return "Trading name";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOfPremises("PremisesType"))]);
    // #endregion GET LABEL

    const isVisibleFSSContact =
      valueGetter(getNameOfPremises("PremisesType"))?.Flag_FSP ||
      (!valueGetter(getNameOfPremises("PremisesType"))?.Flag_FSP &&
        valueGetter(getNameOfPremises("PremisesType"))?.Flag_EnableFSS);

    const getInitialPremisesHandlerRequest = (
      formAction: Svc_FormAction_Premise
    ) => {
      return {
        FormAction: formAction,
        Premises: premisesObj,
        Args: {},
        IsFirstTimeLoad: false,
        UIControl: uiControlStore,
        PremiseLOVs: lovs,
      } as PremiseHandlerRequest;
    };

    // #region Proprietor Field
    const proprietorContactDisplay = useMemo(() => {
      return getProprietorContactDisplay(
        valueGetter(getNameOfPremises("Proprietor"))?.Contact
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOfPremises("Proprietor"))?.Contact]);
    const handleChangeProprietor = (event: ComboBoxChangeEvent | null) => {
      const contact = event?.value;
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickProprietor
        );
      if (contact) {
        premisesHandlerRequest.Args = {
          Proprietor: {
            Contact: contact,
          },
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select proprietor contact failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValueProprietor = () => {
      if (uiControlStore) {
        prevProprietorDisplayName.current =
          uiControlStore?.LitProprietor?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitProprietor: {
              ...uiControlStore?.LitProprietor,
              Value: premisesObj?.Proprietor?.Contact?.DisplayName,
            },
          },
        });
      }
    };
    const restoreDisplayValueProprietor = () => {
      if (
        uiControlStore &&
        isHTML(prevProprietorDisplayName?.current) &&
        valueGetter(getNameOfPremises("Proprietor"))?.Contact
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitProprietor: {
              ...uiControlStore?.LitProprietor,
              Value: prevProprietorDisplayName.current,
            },
          },
        });
      }
    };
    // #endregion Proprietor Field

    // #region Primary Contact Field
    const handleOnChangePrimaryContact = (
      event: ComboBoxChangeEvent | null
    ) => {
      const contact = event?.value;
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickPrimaryContact
        );
      if (contact) {
        premisesHandlerRequest.Args = {
          PrimaryContact: {
            Contact: contact,
          },
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select primary contact failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValuePrimaryContact = () => {
      if (uiControlStore) {
        prevPrimaryContactDisplayName.current =
          uiControlStore?.LitContact?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitContact: {
              ...uiControlStore?.LitContact,
              Value: premisesObj?.PrimaryContact?.Contact?.DisplayName,
            },
          },
        });
      }
    };
    const restoreDisplayValuePrimaryContact = () => {
      if (
        uiControlStore &&
        isHTML(prevPrimaryContactDisplayName.current) &&
        valueGetter(getNameOfPremises("PrimaryContact"))?.Contact
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitContact: {
              ...uiControlStore?.LitContact,
              Value: prevPrimaryContactDisplayName.current,
            },
          },
        });
      }
    };
    const primaryContactDisplay = useMemo(() => {
      return getPrimaryContactDisplay(
        valueGetter(getNameOfPremises("PrimaryContact"))?.Contact
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOfPremises("PrimaryContact"))?.Contact]);
    // #endregion Primary Contact Field

    // #region Residential Address Field
    const handleOpenAlternateAddressDialog = async (
      setIsShowDialog?: (value: React.SetStateAction<boolean>) => void
    ) => {
      if (!premisesObj) return;
      setIsLoadingOpenDialog(true);
      const setupAddress: SetupAddress = {
        Address: valueGetter(getNameOfPremises("Proprietor"))?.Contact
          ?.AlternateAddress,
        ClassificationFilters: [
          AddressClassification.Generic,
          AddressClassification.POBox,
          AddressClassification.Consultant_Address,
          AddressClassification.International,
        ],
        IsHasFlag_ForceUse_StreetType: isContactSplitAddressName,
      };
      const response = await setupPremisesPickSiteAddress(setupAddress);
      setIsLoadingOpenDialog(false);
      if (isSuccessResponse(response) && response?.data) {
        setInitialBuildAddress(response?.data?.ReturnObj);

        if (setIsShowDialog && isFunction(setIsShowDialog))
          setIsShowDialog(true);
      } else {
        pushNotificationPortal({
          autoClose: false,
          title: "Setup address failed.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };
    const handleChangeAlternateAddress = (
      buildAddress: Address_BuildAddress | null
    ) => {
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickProprietorAddress
        );
      if (buildAddress) {
        premisesHandlerRequest.Args = {
          Proprietor: {
            Contact: {
              AlternateAddress: buildAddress?.Address,
            },
          },
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: `Select ${alternateAddressLabel} failed.`,
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValueAlternateAddress = () => {
      if (uiControlStore) {
        prevAlternateAddressDisplayName.current =
          uiControlStore?.LitProprietorResidentialAddress?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitProprietorResidentialAddress: {
              ...uiControlStore?.LitProprietorResidentialAddress,
              Value:
                premisesObj?.Proprietor?.Contact?.AlternateAddress
                  ?.Formatted_SingleLine,
            },
          },
        });
      }
    };
    const restoreDisplayValueAlternateAddress = () => {
      if (
        uiControlStore &&
        isHTML(prevAlternateAddressDisplayName.current) &&
        valueGetter(getNameOfPremises("Proprietor"))?.Contact?.AlternateAddress
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitProprietorResidentialAddress: {
              ...uiControlStore?.LitProprietorResidentialAddress,
              Value: prevAlternateAddressDisplayName.current,
            },
          },
        });
      }
    };
    const isAlternateAddressDisabled =
      isInactive ||
      !valueGetter(getNameOfPremises("Proprietor")) ||
      !valueGetter(getNameOfPremises("Proprietor"))?.Contact;
    // #endregion Residential Address Field

    // #region Premises Address Field
    const handleSiteAddressOpenDialog = async () => {
      if (!premisesObj) return;
      setIsLoadingOpenDialog(true);
      const setupAddress: SetupAddress = {
        Address: valueGetter(getNameOfPremises("SiteAddress")),
        ClassificationFilters: [AddressClassification.Property],
        IsHasFlag_ForceUse_StreetType: false,
      };
      const response = await setupPremisesPickSiteAddress(setupAddress);
      setIsLoadingOpenDialog(false);
      if (isSuccessResponse(response) && response?.data) {
        setInitialBuildAddress(response?.data?.ReturnObj);
      } else {
        pushNotificationPortal({
          autoClose: false,
          title: "Setup address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };
    const handleChangePremisesAddressHandler = (
      buildAddress: Address_BuildAddress | null
    ) => {
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickPremisesAddress
        );
      if (buildAddress) {
        premisesHandlerRequest.Args = {
          SiteAddress: buildAddress?.Address,
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select premises address failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValuePremisesAddress = () => {
      if (uiControlStore) {
        prevSiteAddressDisplayName.current = uiControlStore?.LitAddress?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitAddress: {
              ...uiControlStore?.LitAddress,
              Value: premisesObj?.SiteAddress?.Formatted_SingleLine,
            },
          },
        });
      }
    };
    const restoreDisplayValuePremisesAddress = () => {
      if (
        uiControlStore &&
        isHTML(prevSiteAddressDisplayName?.current) &&
        valueGetter(getNameOfPremises("SiteAddress"))
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitAddress: {
              ...uiControlStore?.LitAddress,
              Value: prevSiteAddressDisplayName.current,
            },
          },
        });
      }
    };
    const requiredValidatorPremisesAddress = useCallback(() => {
      return requiredValidator(uiControlStore?.LitAddress?.Value);
    }, [uiControlStore?.LitAddress?.Value]);
    // #endregion Premises Address Field

    // #region Postal address Field
    const handleOpenPostalAddressDialog = async (
      setIsShowDialog?: (value: React.SetStateAction<boolean>) => void
    ) => {
      if (!premisesObj) return;
      setIsLoadingOpenDialog(true);
      const setupAddress: SetupAddress = {
        Address: valueGetter(getNameOfPremises("PostalAddress")),
        ClassificationFilters: [
          AddressClassification.International,
          AddressClassification.Generic,
          AddressClassification.POBox,
        ],
        IsHasFlag_ForceUse_StreetType: false,
      };
      const response = await setupPremisesPickSiteAddress(setupAddress);
      setIsLoadingOpenDialog(false);
      if (isSuccessResponse(response) && response?.data) {
        setInitialBuildAddress(response?.data?.ReturnObj);

        if (setIsShowDialog && isFunction(setIsShowDialog))
          setIsShowDialog(true);
      } else {
        pushNotificationPortal({
          autoClose: false,
          title: "Setup address fail.",
          type: "error",
          description: response.data?.Errors ?? response.statusText,
        });
      }
    };
    const handleChangePostalAddressHandler = (
      buildAddress: Address_BuildAddress | null
    ) => {
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickPostalAddress
        );
      if (buildAddress) {
        premisesHandlerRequest.Args = {
          PostalAddress: buildAddress?.Address,
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: `Select postal address failed.`,
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValuePostalAddress = () => {
      if (uiControlStore) {
        prevPostalAddressDisplayName.current =
          uiControlStore?.LitPostalAddress?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitPostalAddress: {
              ...uiControlStore?.LitPostalAddress,
              Value: premisesObj?.PostalAddress?.Formatted_SingleLine,
            },
          },
        });
      }
    };
    const restoreDisplayValuePostalAddress = () => {
      if (
        uiControlStore &&
        isHTML(prevPostalAddressDisplayName?.current) &&
        valueGetter(getNameOfPremises("PostalAddress"))
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitPostalAddress: {
              ...uiControlStore?.LitPostalAddress,
              Value: prevPostalAddressDisplayName.current,
            },
          },
        });
      }
    };
    // #endregion Postal address Field

    // #region FSS Contact Field
    const handleChangeFSSContact = (event: ComboBoxChangeEvent | null) => {
      const contact = event?.value;
      let premisesHandlerRequest: PremiseHandlerRequest =
        getInitialPremisesHandlerRequest(
          Svc_FormAction_Premise.Form_PickFSPContact
        );
      if (contact) {
        premisesHandlerRequest.Args = {
          FSPContact: {
            Contact: contact,
          },
        };
      }
      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select FSS contact failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const removeDisplayValueFSSContact = () => {
      if (uiControlStore) {
        prevFSPContactDisplayName.current =
          uiControlStore?.LitFSPContact?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitFSPContact: {
              ...uiControlStore?.LitFSPContact,
              Value: premisesObj?.FSPContact?.Contact?.DisplayName,
            },
          },
        });
      }
    };
    const restoreDisplayValueFSSContact = () => {
      if (
        uiControlStore &&
        isHTML(prevFSPContactDisplayName?.current) &&
        valueGetter(getNameOfPremises("FSPContact"))?.Contact
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitFSPContact: {
              ...uiControlStore?.LitFSPContact,
              Value: prevFSPContactDisplayName.current,
            },
          },
        });
      }
    };
    const fssContactDisplay = useMemo(() => {
      return getFSSContactDisplay(
        valueGetter(getNameOfPremises("FSPContact"))?.Contact
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueGetter(getNameOfPremises("FSPContact"))?.Contact]);
    // #endregion FSS Contact Field

    // #region Property Owner Field
    const handleOnChangeOwnerProperty = (data: MultiTenancyView) => {
      let premisesHandlerRequest: PremiseHandlerRequest = {
        FormAction: Svc_FormAction_Premise.Form_PickMultiTenancyRegister,
        Premises: premisesObj,
        Args: data?.ID ? [data?.ID] : [],
        IsFirstTimeLoad: false,
        UIControl: uiControlStore,
        PremiseLOVs: lovs,
      };

      const handlerInitialData: IHMHandlerEventInitialData = {
        premisesRegisterHandlerRequest: premisesHandlerRequest,
        errorMsg: "Select property owner failed.",
      };
      fetchApiByAlias(HM_PREMISES_HANDLER_SLIDER, {
        initialData: handlerInitialData,
      });
    };
    const propertyOwnerOrganisationDisplay = useMemo(() => {
      const contact = valueGetter(getNameOfPremises("MultiTenancyRegister"))
        ?.Contact?.Contact;
      if (
        contact?.Extension?.Organisation &&
        contact?.Extension?.Organisation !== contact?.DisplayName
      ) {
        return contact?.Extension?.Organisation;
      }
      return "";
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      // eslint-disable-next-line react-hooks/exhaustive-deps
      valueGetter(getNameOfPremises("MultiTenancyRegister"))?.Contact?.Contact,
    ]);
    const restoreDisplayValuePropertyOwner = () => {
      if (
        uiControlStore &&
        isHTML(prevPropertyOwnerDisplayName?.current) &&
        valueGetter(getNameOfPremises("MultiTenancyRegister"))?.Contact?.Contact
      ) {
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitMultiTenancy: {
              ...uiControlStore?.LitMultiTenancy,
              Value: prevPropertyOwnerDisplayName.current,
            },
          },
        });
      }
    };
    const removeDisplayValuePropertyOwner = () => {
      if (uiControlStore) {
        prevPropertyOwnerDisplayName.current =
          uiControlStore?.LitMultiTenancy?.Value;
        setInitialDataForms({
          GeneralUIControl: {
            ...uiControlStore,
            LitMultiTenancy: {
              ...uiControlStore?.LitMultiTenancy,
              Value:
                premisesObj?.MultiTenancyRegister?.Contact?.Contact
                  ?.DisplayName,
            },
          },
        });
      }
    };
    // #endregion Property Owner Field

    // #region Secondary Mode
    const checkSecondaryModeTxtTradingName = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.TxtTradingName
    );

    const checkSecondaryModeLbtProprietor = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.LbtProprietor
    );

    const checkSecondaryModeLbtAddress = checkSecondaryMode(
      enableSecondaryMode,
      sFieldMapping,
      SecondaryModeCheckField.LbtAddress
    );
    // #endregion Secondary Mode

    const updateSaveTriggers = (triggers: PremisesUpdateTriggers) => {
      let saveTriggers: PremisesUpdateTriggers[] =
        valueGetter(getNameOfPremises("SaveTriggers")) ?? [];

      if (!Array.isArray(saveTriggers)) saveTriggers = [];

      if (!saveTriggers?.some((item) => item === triggers)) {
        saveTriggers?.push(triggers);
        onChange(getNameOfPremises("SaveTriggers"), {
          value: saveTriggers,
        });
      }
    };

    // #region UI
    return (
      <>
        {isLoadingOpenDialog && <Loading isLoading isFullScreen />}
        <section className="cc-field-group">
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title={tradingNameLabel} isMandatory />
              <Field
                component={CCInput}
                name={getNameOfPremises("TradingName")}
                validator={requiredValidator}
                disabled={isInactive || checkSecondaryModeTxtTradingName}
              />
            </div>
            <div className="cc-field">
              <CCLabel title="Proprietor" isMandatory />
              <Field
                uniqueKey="HMPremisesProprietorContactPicker"
                name={`${getNameOfPremises("Proprietor")}.Contact`}
                component={ContactPicker}
                onError={(error: any) => {
                  pushNotificationPortal({
                    type: "error",
                    title: "Pick proprietor contact failed.",
                    description: error,
                    autoClose: false,
                  });
                }}
                displayValue={uiControlStore?.LitProprietor?.Value ?? ""}
                placeholder="Select proprietor"
                onChange={handleChangeProprietor}
                validator={requiredValidator}
                removeDisplayValue={removeDisplayValueProprietor}
                restoreDisplayValue={restoreDisplayValueProprietor}
                disabled={isInactive || checkSecondaryModeLbtProprietor}
                disabledButton={isInactive || checkSecondaryModeLbtProprietor}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Contact"
                value={
                  isHTML(proprietorContactDisplay)
                    ? sanitizeHtml(proprietorContactDisplay)
                    : proprietorContactDisplay
                }
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Proprietor address"
                value={
                  isHTML(uiControlStore?.LitProprietorAddress?.Value)
                    ? sanitizeHtml(uiControlStore?.LitProprietorAddress?.Value)
                    : uiControlStore?.LitProprietorAddress?.Value
                }
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Postal address"
                value={
                  isHTML(uiControlStore?.LitProprietorPostalAddress?.Value)
                    ? sanitizeHtml(
                        uiControlStore?.LitProprietorPostalAddress?.Value
                      )
                    : uiControlStore?.LitProprietorPostalAddress?.Value
                }
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            {valueGetter(getNameOfPremises("PremisesType"))
              ?.Flag_DisplayProprietorAddress &&
              isShowProprietorAddress && (
                <>
                  <div className="cc-field">
                    <CCLabel title={alternateAddressLabel} />
                    <Field
                      uniqueKey="HMPremisesAlternateAddress"
                      name={`${getNameOfPremises(
                        "Proprietor"
                      )}.Contact.AlternateAddress`}
                      component={PropertyDetail}
                      isShowFullResultSearch
                      isHaveStreetLookup
                      formRenderProps={formRenderProps}
                      isSearchPropertyAddresses
                      initialData={initialBuildAddress}
                      placeholder="Select location"
                      onButtonClick={handleOpenAlternateAddressDialog}
                      onBeforeOpenDialog={handleOpenAlternateAddressDialog}
                      onChangeEventHandler={handleChangeAlternateAddress}
                      onSubmit={handleChangeAlternateAddress}
                      removeDisplayValue={removeDisplayValueAlternateAddress}
                      restoreDisplayValue={restoreDisplayValueAlternateAddress}
                      value={
                        uiControlStore?.LitProprietorResidentialAddress
                          ?.Value || ""
                      }
                      updateSaveTriggers={() => {
                        updateSaveTriggers(
                          PremisesUpdateTriggers.UpdateProprietor
                        );
                      }}
                      onError={(error: any) => {
                        pushNotificationPortal({
                          type: "error",
                          title: `Select ${alternateAddressLabel} failed.`,
                          description: error,
                          autoClose: false,
                        });
                      }}
                      forceIsUseSimpleAddressDialog={isUseSimpleAddressDialogHM}
                      disabled={isAlternateAddressDisabled}
                      disabledButton={isAlternateAddressDisabled}
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label="Assessment number"
                      value={
                        valueGetter(getNameOfPremises("Proprietor"))?.Contact
                          ?.AlternateAddress?.AssessmentNo ?? ""
                      }
                    />
                  </div>
                </>
              )}
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Primary contact" isMandatory />
              <Field
                uniqueKey="HMPremisesPrimaryContactPicker"
                name={`${getNameOfPremises("PrimaryContact")}.Contact`}
                component={ContactPicker}
                onError={(error: any) => {
                  pushNotificationPortal({
                    type: "error",
                    title: "Pick primary contact failed.",
                    description: error,
                    autoClose: false,
                  });
                }}
                displayValue={uiControlStore?.LitContact?.Value ?? ""}
                placeholder="Select primary contact"
                onChange={handleOnChangePrimaryContact}
                validator={requiredValidator}
                removeDisplayValue={removeDisplayValuePrimaryContact}
                restoreDisplayValue={restoreDisplayValuePrimaryContact}
                disabled={isInactive}
                disabledButton={isInactive}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Contact"
                value={
                  isHTML(primaryContactDisplay)
                    ? sanitizeHtml(primaryContactDisplay)
                    : primaryContactDisplay
                }
              />
            </div>
          </div>

          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Premises address" isMandatory />
              <Field
                uniqueKey="PremisesSiteAddress"
                name={getNameOfPremises("SiteAddress")}
                component={PropertyDetail}
                initialData={initialBuildAddress}
                placeholder={"Select premises address"}
                formRenderProps={formRenderProps}
                validator={requiredValidatorPremisesAddress}
                isSearchPropertyAddresses={true}
                onError={(error: any) => {
                  pushNotificationPortal({
                    type: "error",
                    title: "Select premises address failed.",
                    description: error,
                    autoClose: false,
                  });
                }}
                value={uiControlStore?.LitAddress?.Value || ""}
                onChangeEventHandler={handleChangePremisesAddressHandler}
                onSubmit={handleChangePremisesAddressHandler}
                onButtonClick={handleSiteAddressOpenDialog}
                removeDisplayValue={removeDisplayValuePremisesAddress}
                restoreDisplayValue={restoreDisplayValuePremisesAddress}
                disabled={isInactive || checkSecondaryModeLbtAddress}
                disabledButton={isInactive || checkSecondaryModeLbtAddress}
                updateSaveTriggers={() => {
                  updateSaveTriggers(PremisesUpdateTriggers.UpdateSiteAddress);
                }}
                isShowFullResultSearch
                isHaveStreetLookup
                forceIsUseSimpleAddressDialog={isUseSimpleAddressDialogHM}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Assessment number"
                value={
                  valueGetter(getNameOfPremises("SiteAddress"))?.AssessmentNo ??
                  ""
                }
              />
            </div>
          </div>
          <div className="cc-form-cols-3">
            <div className="cc-field">
              <CCLabel title="Postal address" />
              <Field
                uniqueKey="HMPremisesPostalAddress"
                name={getNameOfPremises("PostalAddress")}
                component={PropertyDetail}
                isShowFullResultSearch
                isSearchPropertyAddresses
                isHaveStreetLookup
                formRenderProps={formRenderProps}
                initialData={initialBuildAddress}
                placeholder="Select location"
                onButtonClick={handleOpenPostalAddressDialog}
                onBeforeOpenDialog={handleOpenPostalAddressDialog}
                onChangeEventHandler={handleChangePostalAddressHandler}
                onSubmit={handleChangePostalAddressHandler}
                value={uiControlStore?.LitPostalAddress?.Value || ""}
                onError={(error: any) => {
                  pushNotificationPortal({
                    type: "error",
                    title: `Select postal address failed.`,
                    description: error,
                    autoClose: false,
                  });
                }}
                updateSaveTriggers={() => {
                  updateSaveTriggers(
                    PremisesUpdateTriggers.UpdatePostalAddress
                  );
                }}
                removeDisplayValue={removeDisplayValuePostalAddress}
                restoreDisplayValue={restoreDisplayValuePostalAddress}
                disabled={isInactive}
                disabledButton={isInactive}
                forceIsUseSimpleAddressDialog={isUseSimpleAddressDialogHM}
              />
            </div>
            <div className="cc-field">
              <CCValueField
                label="Assessment number"
                value={
                  valueGetter(getNameOfPremises("PostalAddress"))
                    ?.AssessmentNo ?? ""
                }
              />
            </div>
          </div>

          {isVisibleFSSContact && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="FSS contact" />
                <Field
                  uniqueKey="HMPremisesFSSContactPicker"
                  name={`${getNameOfPremises("FSPContact")}.Contact`}
                  component={ContactPicker}
                  onError={(error: any) => {
                    pushNotificationPortal({
                      type: "error",
                      title: "Pick FSS contact errors",
                      description: error,
                      autoClose: false,
                    });
                  }}
                  placeholder="Select FSS contact"
                  onChange={handleChangeFSSContact}
                  displayValue={uiControlStore?.LitFSPContact?.Value ?? ""}
                  removeDisplayValue={removeDisplayValueFSSContact}
                  restoreDisplayValue={restoreDisplayValueFSSContact}
                  disabled={isInactive}
                  disabledButton={isInactive}
                />
              </div>
              <div className="cc-field">
                <CCValueField
                  label="Contact"
                  value={
                    isHTML(fssContactDisplay)
                      ? sanitizeHtml(fssContactDisplay)
                      : fssContactDisplay
                  }
                />
              </div>
            </div>
          )}

          <div className="cc-form-cols-3">
            {isFastChoice && (
              <div className="cc-field">
                <CCLabel title="Fast choice" isMandatory />
                <Field
                  name={getNameOfPremises("Flag_FastChoice")}
                  disabled={isInactive}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_FastChoice"))}
                  validator={requiredValidator}
                />
              </div>
            )}

            {valueGetter(getNameOfPremises("PremisesType"))?.Flag_EnableFSS &&
              isFSSRequired && (
                <div className="cc-field">
                  <CCLabel title="FSS required" />
                  <Field
                    name={getNameOfPremises("Flag_FSSRequired")}
                    disabled={isInactive}
                    component={CCSwitch}
                    checked={valueGetter(getNameOfPremises("Flag_FSSRequired"))}
                  />
                </div>
              )}
          </div>

          {isShowMultiTenancy && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel title="Is multi tenancy?" />
                <Field
                  name={getNameOfPremises("Flag_MultiTenancy")}
                  disabled={isInactive}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_MultiTenancy"))}
                />
              </div>
              {valueGetter(getNameOfPremises("Flag_MultiTenancy")) && (
                <>
                  <div className="cc-field">
                    <CCLabel title="Property owner" isMandatory />
                    <Field
                      uniqueKey="HMPremisesPropertyOwnerContactPicker"
                      component={InputPickerSearch}
                      name={getNameOfPremises("MultiTenancyRegister")}
                      disabled={isInactive}
                      value={uiControlStore?.LitMultiTenancy?.Value ?? ""}
                      options={propertyOwnerOptions}
                      onChange={handleOnChangeOwnerProperty}
                      removeDisplayValue={removeDisplayValuePropertyOwner}
                      restoreDisplayValue={restoreDisplayValuePropertyOwner}
                      validator={requiredValidator}
                    />
                  </div>
                  <div className="cc-field">
                    <CCValueField
                      label="Organisation"
                      value={
                        isHTML(propertyOwnerOrganisationDisplay)
                          ? sanitizeHtml(propertyOwnerOrganisationDisplay)
                          : propertyOwnerOrganisationDisplay
                      }
                    />
                  </div>
                </>
              )}

              <div className="cc-field">
                <CCLabel title="Is DoE?" />
                <Field
                  name={getNameOfPremises("Flag_DeptOfEducation")}
                  disabled={isInactive}
                  component={CCSwitch}
                  checked={valueGetter(
                    getNameOfPremises("Flag_DeptOfEducation")
                  )}
                />
              </div>
            </div>
          )}

          {isShowScoresEndorse && (
            <div className="cc-form-cols-3">
              <div className="cc-field">
                <CCLabel
                  title="Scores on doors certificate issued"
                  isMandatory
                />
                <Field
                  name={getNameOfPremises("Flag_ScoresEndorse")}
                  disabled={isInactive}
                  component={CCSwitch}
                  checked={valueGetter(getNameOfPremises("Flag_ScoresEndorse"))}
                  validator={requiredValidator}
                />
              </div>
            </div>
          )}
        </section>
      </>
    );
    // #endregion UI
  }
);
