import { UITownPlanning } from "@app/products/town-planning/model";
import { Application_Status } from "@app/products/town-planning/ppr/constant";
import { Register } from "@app/products/town-planning/ppr/enquiries/_id/model";
import {
  Address,
  AddressClassification,
  BubbleUpIdentifier,
  Contact_Relation,
  SiteUser,
} from "@app/products/waste-water/[id]/model";
import { RECORDTYPE } from "@common/constants/recordtype";
import { DTOBaseClass_Standard } from "@common/models/baseClassStandard";
import { IdentityPacket } from "@common/models/identityPacket";
import { IKeyValuePacket } from "@common/models/keyValuePacket";
import { Keyword } from "@common/models/keyword";
import { Attachment } from "@common/pages/settings/security/site-users/_id/model";
import { IAppNotificationItemAddProps } from "@components/cc-app-notification/components/notification-item/model";

export enum PPRSubmitActions {
  New = "New",
  NewPSAR = "NewPSAR",
  Save = "Save",
  LodgeApplication = "LodgeApplication",
  CreateAndLodgeApplication = "CreateAndLodgeApplication",
  LodgePSARApplication = "LodgePSARApplication",
  WithdrawnApplication = "WithdrawnApplication",
  WithdrawnPSARApplication = "WithdrawnPSARApplication",
  FiNotRequired = "FiNotRequired",
  PSARFiNotRequired = "PSARFiNotRequired",
  RequestFI = "RequestFI",
  Appealed = "Appealed",
  Withdrawn = "Withdrawn",
  OfficerAssessment = "OfficerAssessment",
  AllocateToOfficer = "AllocateToOfficer",
  AllocateToOfficerPSAR = "AllocateToOfficerPSAR",
  AppealComplete = "AppealComplete",
  AmendApplication = "AmendApplication",
  SendReptAndRespLetterForApproval = "SendReptAndRespLetterForApproval",
  ApproveReportAndResponse = "ApproveReportAndResponse",
  ReportAndResponseNotApproved = "ReportAndResponseNotApproved",
  SendForTLApproval = "SendForTLApproval",
  ResendForTLApproval = "ResendForTLApproval",
  RequestAction = "RequestAction",
  PlansToComply = "PlansToComply",
  AmendPermit = "AmendPermit",
  AppealWithdrawn = "AppealWithdrawn",
  SendLetterToRAandApplicant = "SendLetterToRAandApplicant",
  NoAppeal = "NoAppeal",
  ChangePickOfficer = "ChangePickOfficer",
}

export const pprSubmitWithValidateActions: PPRSubmitActions[] = [
  PPRSubmitActions.ApproveReportAndResponse,
  PPRSubmitActions.ReportAndResponseNotApproved,
  PPRSubmitActions.SendForTLApproval,
  PPRSubmitActions.ResendForTLApproval,
  PPRSubmitActions.PlansToComply,
  PPRSubmitActions.AmendPermit,
  PPRSubmitActions.SendLetterToRAandApplicant,
];

export interface Application extends DTOBaseClass_Standard {
  Application_ID: number;
  RefNo: string;
  FileNo: string;
  Legacy_LID: string;
  ApplicationNumber: number;
  ApplicationYear: number;
  ApplicationNumberDisplay: string;
  Building_PermitNumber: string;
  RevisionReference: string;
  IsAmendment: boolean;
  AmendmentReference: number | null;
  PlansToComplyReference: number | null;
  PrivateSurveyorRegID: string;
  PlanningPrivateSurveyorRegID: string;
  PermitType_ID: number | null;
  PermitType: PermitType;
  ReferenceNumber: string;
  EnforcementNumber: string;
  PermitTitle: string;
  Date_Created: Date;
  RevisionDate: Date | null;
  RevisionDateAccepted: Date | null;
  LodgedDate: Date | null;
  PreApplicationMeeting_Date: Date | null;
  EnforcementIssue_Date: Date | null;
  PrivateSurveyorDate: Date | null;
  PlanningPrivateSurveyorDate: Date | null;
  DecisionDate: Date | null;
  FinalDecisionDate: Date | null;
  BuildingDecisionDate: Date | null;
  LandDivisionDecisionDate: Date | null;
  EffectiveFromDate: Date | null;
  EffectiveToDate: Date | null;
  Secondary_EffectiveFromDate: Date | null;
  Secondary_EffectiveToDate: Date | null;
  Metro_Date_Issue: Date | null;
  Metro_Date_Expiry: Date | null;
  Metro_Date_CertRec: Date | null;
  AllocatedToPlanner: Date | null;
  Date_IssuedCertificateOfCompletion: Date | null;
  Date_FeesPaid: Date | null;
  SubmittedDate: Date | null;
  WrittenDate: Date | null;
  DateCertifierReceived: Date | null;
  TasWaterCertificateDate: Date | null;
  CancelledDate: Date | null;
  Rating_KWD: number | null;
  Rating_Name: string;
  ApplicationCategories: ApplicationCategory[];
  FeeSchedules: FeeSchedule[];
  Fees: Fee[];
  ApplicationCategories_Namelist: string[];
  ApplicationCategories_Display: string;
  FeeSchedules_Namelist: string[];
  FeeSchedules_Display: string;
  ApplicationDescription: Description[];
  ApplicationDescription_Namelist: string[];
  ApplicationDescription_Display: string;
  StatDays: StatutoryDays;
  OwnerBuilder_KWD: number | null;
  LevyPayer_Type: string;
  OwnerBuilder_KWD_Name: string;
  UserSubStatus_KWD: number | null;
  ApplicationType_KWD: number | null;
  PrivateSurveyorNoOfConditions: number | null;
  PlanningPrivateSurveyorNoOfConditions: number | null;
  CostOfWorks: number;
  Metro_AmountPaid: number | null;
  GenericLevy1_Percent: number | null;
  GenericLevy1_Amount: number | null;
  GenericLevy2_Percent: number | null;
  GenericLevy2_Amount: number | null;
  BAMS_Levy_Amount: number | null;
  Flag_LandDivision: boolean;
  Flag_IsTownPlanning: boolean;
  Flag_IsBuilding: boolean;
  Flag_IsDevApps: boolean;
  Flag_PreApplicationMeeting: boolean | null;
  Flag_EnforcementIssue: boolean | null;
  Flag_PrivateSurveyor: boolean | null;
  Flag_PlansToComply: boolean | null;
  Flag_PlanningPrivateSurveyor: boolean | null;
  Flag_EOT: boolean | null;
  Flag_Historic: boolean | null;
  Flag_SwimmingPool: boolean | null;
  Flag_Metro: boolean | null;
  Flag_ConcernsAddInfoReq: boolean | null;
  Flag_AdvertisingRequired: boolean | null;
  Flag_FeesPaid: boolean | null;
  Flag_FeesPaidWorkflowClicked: boolean | null;
  Flag_LodgementFeeRequired: boolean | null;
  Has_CILFeeFound: boolean | null;
  Is_ReferralNotRequired: boolean | null;
  RecordType: RECORDTYPE;
  ApplicationTypeFlags: ApplicationTypeFlags;
  ApplicationTypeNames: string;
  Status_ENUM: Application_Status;
  Status_Name: string;
  DueStatus: string;
  RevisionStatus_ENUM: Application_Status | null;
  Decision_ENUM: Application_Decision | null;
  Decision_Name: string;
  DecisionType_ENUM: Application_DecisionType | null;
  DecisionType_Name: string;
  BuildingDecision_ENUM: Application_Decision | null;
  BuildingDecision_Name: string;
  BuildingDecisionType_ENUM: Application_DecisionType | null;
  BuildingDecisionType_Name: string;
  OfficerDecision_ENUM: Application_Decision | null;
  OfficerDecision_Name: string;
  LandDivisionDecision_ENUM: Application_Decision | null;
  LandDivisionDecision_Name: string;
  LandDivisionDecisionType_ENUM: Application_DecisionType | null;
  LandDivisionDecisionType_Name: string;
  FinalDecision_ENUM: Application_Decision | null;
  FinalDecision_Name: string;
  FinalDecisionType_ENUM: Application_DecisionType | null;
  FinalDecisionType_Name: string;
  AssessmentAuthority_ENUM: Application_DecisionType | null;
  AssessmentAuthority_Name: string;
  HistoricStatus: string;
  Planner_ID: number | null;
  Planner: string;
  BuildingOfficer_ID: number | null;
  BuildingOfficer: string;
  Applicant_RID: number | null;
  Applicant: Contact_Relation;
  Council_Applicants: Contact_Relation[];
  Builder_RID: number | null;
  Builder: Contact_Relation;
  PreferredContact_RID: number | null;
  PreferredContact: Contact_Relation;
  LevyPayer_RID: number | null;
  LevyPayer: Contact_Relation;
  PrivateSurveyor_RID: number | null;
  PrivateSurveyor: Contact_Relation;
  SiteAddress_ID: number | null;
  SiteAddress: Address;
  AdditionalAddresses: Address[];
  ReasonForPermit: string;
  LongDescription: string;
  AllocationComments: string;
  DecisionGrounds: string;
  EOT_Reason: string;
  PreApplicationMeeting_CouncilOfficer: string;
  EnforcementIssue_CouncilOfficer: string;
  Metro_CertNumber: string;
  ReferralNotes_Internal: string;
  ReferralNotes_External: string;
  DateReferral: Date | null;
  ReferralDescription: string;
  PrivateCertifier: string;
  AppealTribunalDecision: string;
  AppealTribunalDecisionDate: Date | null;
  ConcernsAddInfoReqNotes: string;
  AdvertisingRequiredNotes: string;
  LD_CheckListForm1: string;
  LD_CheckListComments: string;
  LD_CheckListOwnerConsent: string;
  LD_CheckListHeavyWeightPlans: string;
  LD_CheckListFeesPaid: string;
  LD_CheckListPPIssuedEndorsed: string;
  LD_CheckListAuthConsent01: string;
  LD_CheckListAuthConsent02: string;
  LD_CheckListAuthConsent03: string;
  LD_CheckListAuthConsent04: string;
  LD_CheckListAuthConsent05: string;
  LD_CheckListAuthConsent06: string;
  LD_CheckListAuthConsent07: string;
  LD_CheckListAuthConsent08: string;
  LD_CheckListAuthConsent09: string;
  LD_CheckListCertCheck01: string;
  LD_CheckListCertCheck02: string;
  LD_CheckListCertCheck03: string;
  LD_CheckListCertCheck04: string;
  LD_CheckListCertCheck05: string;
  LD_CheckListCertCheck06: string;
  LD_CheckListCertCheck07: string;
  LD_CheckListCertCheck08: string;
  LD_CheckListCertCheck09: string;
  LD_CheckListReCertCheck01: string;
  LD_CheckListReCertCheck02: string;
  LD_CheckListReCertCheck03: string;
  LD_CheckListReCertCheck04: string;
  LD_CheckListSOCCheck01: string;
  LD_CheckListSOCCheck02: string;
  LD_CheckListSOCCheck03: string;
  LD_CheckListSOCCheck04: string;
  LD_CheckListSOCCheck05: string;
  LD_CheckListSOCCheck06: string;
  LD_CheckListSOCCheck07: string;
  LD_CheckListSOCCheck08: string;
  LD_CheckListSOCCheck09: string;
  LD_CheckListSOCCheck10: string;
  LD_CheckListSOCCheck11: string;
  LD_CheckListSOCCheck12: string;
  LD_CheckListSOCCheck13: string;
  ProposedFinalOutcome: string;
  DebtorNumber: string;
  BuildingDetails_ID: number | null;
  BuildingDetails: ApplicationBuildingDetails;
  AssessmentPlanner_ID: number | null;
  AssessmentPlanner: ApplicationAssessmentPlanner;
  EPlanning_ID: number | null;
  Flag_EPlanning_Update: boolean | null;
  EPlanning_Username: string;
  EPlanning_DisplayName: string;
  EPlanning_Categories: string;
  EPlanning_FeeSchedules: string;
  EPlanning_ProposalDescription: string;
  EPlanning_ExistingConditions: string;
  EPlanning_Comments: string;
  Portal_CorrelationId: string | null;
  CDQuestion_ENUM: YESNONA | null;
  CDQuestion_Name: string;
  LD_PlanOfSubdivisionNo: string;
  LD_PlanVersion: string;
  LD_CellRef: string;
  LD_SPEARRef: string;
  LD_EstateName: string;
  LD_EstateStage: string;
  LD_Allotments: number | null;
  LD_CommercialLots: number | null;
  LD_CertificationExpiryDate: Date | null;
  LD_AvgLotSize: number | null;
  LD_OpenSpaceRequirements: string;
  LD_Valuation: number | null;
  LD_POSDue: number | null;
  LD_HWPlansReceived: Date | null;
  LD_PeggingStatementReceived: Date | null;
  LD_Commenced: Date | null;
  LD_Certified: Date | null;
  LD_Recertified: Date | null;
  LD_StatementOfCompliance: Date | null;
  LD_TitleOfficeReceived: Date | null;
  LD_Refused: Date | null;
  LD_Withdrawn: Date | null;
  LD_Expired: Date | null;
  LD_CouncilRoad_Reserve_Easement: boolean | null;
  LD_ReferedtoSPEARDate: Date | null;
  Flag_IsPSA: boolean;
  ApplicantType_ENUM: PSA_ApplicantType | null;
  ApplicantType_Name: string;
  SiteConditions: string;
  SiteAnalysis: string;
  AvailabilityOfServices: string;
  MinisterAuthorisationDate: Date | null;
  AdoptAmendmentDate: Date | null;
  MinisterApprovalDate: Date | null;
  Applicant_SiteUser_ID: number | null;
  Applicant_SiteUser: SiteUser;
  Applicant_SiteUser_Description: string;
  SendToCouncilForAuthorisation: Date | null;
  Withdraw_Comments: string;
  PermitNotRequired_Comments: string;
  SendToMinisterForApproval: Date | null;
  SendToCouncilForApproval: Date | null;
  PublishAmendmentDate: Date | null;
  CouncilAuthorisationDate: Date | null;
  MinisterAuthorisationRequested: Date | null;
  SendToCouncilForConsideration: Date | null;
  PSANoticeInNewspaper: Date | null;
  WithdrawnStatus_ENUM: WithdrawnApplication_Status | null;
  WithdrawnStatus_Name: string;
  PSACouncilDecision_ENUM: Application_Decision | null;
  PSACouncilDecision_Name: string;
  PSAMinisterDecision_ENUM: Application_Decision | null;
  PSAMinisterDecision_Name: string;
  PSACouncilDecisionType_ENUM: Application_DecisionType | null;
  PSACouncilDecisionType_Name: string;
  CouncilConsideration_Decision: string;
  Flag_IsOA: boolean;
  OAInPrincipleSupportAssessmentDate: Date | null;
  OAInPrincipleSupportDecisionDate: Date | null;
  OAInPrincipleSupportAssessmentDecisionType_ENUM: Application_DecisionType | null;
  OAInPrincipleSupportAssessmentDecisionType_Name: string;
  OAInPrincipleSupportDecisionType_ENUM: Application_DecisionType | null;
  OAInPrincipleSupportDecisionType_Name: string;
  EOT_TestResult: string;
  HasExtensionGranted: boolean | null;
  BAMS_LodgedDateTime: Date | null;
  BAMS_Processed: boolean | null;
  BAMS_BatchNo: number | null;
  BatchNoDisplay: string;
  BAMS_ReferenceNo: string;
  PrescribeEvents: ApplicationPrescribeEvents;
  RecommendationType_ENUM: Application_DecisionType | null;
  Recommendations_KWD: number | null;
  Recommendations_Name: string;
  RecommendationType_Name: string;
  TargetDateExtendedReason: string;
  ValidatedDate: Date | null;
  BuildingApplicationType_ENUM: Building_ApplicationType | null;
  Plumber_RID: number | null;
  Plumber: Contact_Relation;
  Date_Form3: Date | null;
  Practitioners: ValuePickerPacket;
  PPRApplicationDetails_ID: number | null;
  PPRDetails: PPRApplicationDetails;
  Flag_IsPPR: boolean;
  ParentID: string;
  IsTasWaterExcepted: boolean | null;
  CertificateofCertifiableNo: string;
  Flag_MultiUnitDevelopment: boolean | null;
  MultiUnitDevelopment_NoOfUnits: number | null;
  MultiUnitDevelopment_Class: string;
  BuildingWorks: BuildingWork;
  Flag_IsStateGovtApp: boolean | null;
  StateGovtAppNo: string;
  PropertyOwners: Contact_Relation[];
  AssessmentDetails_ID: number | null;
  AssessmentDetails: ApplicationAssessmentDetails;
  CRMS_EventID: number | null;
  Attachments: Attachment[];
  RejectReason: string;
  EPlanningUserComments: string;
  Flag_ComplyDevelopmentCertificate: boolean | null;
  Flag_IsExternalPortalCreated: boolean | null;
  IsAllRequiredDocumentsRecieved: boolean | null;
  Flag_IsSecondaryConsent: boolean | null;
  CDCType_ENUM: CDCType | null;
  CDCType_Name: string;
  PlumbingClass_ENUM: PlumbingClass | null;
  PlumbingClass_Name: string;
  Comments: string;
  ApplicationCategoriesIDs: number[];
  Council_ApplicantIDs: number[];
  SaveTriggers: ApplicationUpdateTriggers[];
}
export interface PermitType extends DTOBaseClass_Standard {
  PermitType_ID: number;
  Name: string;
  StatDays: number;
  TargetDays: number;
  // PortalDocumentChecklist: Portal_DocumentChecklist[];
  PermitTypeCode: string;
  Description: string;
  NumberFormat: number | null;
  Prefix: string;
  Suffix: string;
  Length: number | null;
  LastNumberUsed: number | null;
  InheritfromSystemNumbering: boolean | null;
  InheritPermitNoFromApplicationNo: boolean | null;
  AppliesTo: number | null;
  PermitFeeType_ID: number | null;
  PermitFeeType: FeeType;
  PermitTypeFee_Amount: number | null;
  InheritApplicationFeeType: boolean | null;
  PermitTypeCalculatedFee_ID: number | null;
  PermitTypeFees: PermitTypeFee[];
  Flag_PrivateSurveyor: boolean | null;
  PrivateSurveyorPermitOption: string;
  PrivateSurveyorCertificateOption: string;
  Flag_StatDays: boolean | null;
  Flag_PrivateSurveyorEditable: boolean | null;
  // PSPermitAutoActionTypes: ActionType[];
  // PSCertificateAutoActionTypes: ActionType[];
}

/**
 * @deprecated Use FeeType use DTOBaseClass_Standard String
 */
export interface FeeType extends DTOBaseClass_Standard {
  FeeType_ID: number;
  Name: string;
  Description: string;
  Code: string;
  Category: string;
  GSTCode: string;
  OrganisationCode: string;
  Flag_GSTApplies: boolean;
  Fee: boolean | null;
  Refund: boolean | null;
  Flag_CalculatedFee: boolean;
  Flag_AllowZeroAmount: boolean;
  DefaultAmount_ExGST: number | null;
  DefaultAmount_GSTPercentage: number;
  DefaultAmount: number | null;
  Council_ID: number | null;
  NextEffectiveDate: Date | null;
  NextAmount_ExGST: number | null;
  NextAmount_GSTPercentage: number;
  NextAmount: number | null;
  SystemFilter_ENUMS: string;
  SystemFilter_Names: string;
  BondType: string;
  Bond: boolean | null;
  BondType_Id: number | null;
}
export interface ApplicationCategory extends DTOBaseClass_Standard {
  ApplicationCategory_ID: number;
  ApplicationCategory_Name: string;
  ApplicationCategory_Description: string;
  SortIndex: number;
  TownPlanning: boolean | null;
  Building: boolean | null;
  LandDivision: boolean | null;
  Code: string;
  OverrideTargetDays: boolean | null;
  TargetDays: number | null;
  isPSA: boolean | null;
  IsOA: boolean | null;
  IsPNF: boolean | null;
  IsPublishToWeb: boolean | null;
  PublishName: string;
  PublishDescription: string;
  IsPPR: boolean | null;
  WorkFlow_ID: number | null;
  // WorkFlowAutoActionTypes: ActionType[];
  ApplicationCategoryFeeType_ID: number | null;
  ApplicationCategoryFeeType: FeeType;
  ApplicationCategoryFee_Amount: number | null;
  InheritApplicationFeeType: boolean | null;
  ApplicationCategoryCalculatedFee_ID: number | null;
  ChecklistQuestion_ID: number | null;
  ApplicationCategoryFees: ApplicationCategoryFee[];
  // DevAppsStatClockStart_ENUM: DevAppsStatClockStartDate | null;
  DevAppsStatClockStart_Name: string;
  // DevAppsStatClockStop_ENUM: DevAppsStatClockStopDate | null;
  DevAppsStatClockStop_Name: string;
  IsLandManagement: boolean | null;
  IsCertification: boolean | null;
  IsNotification: boolean | null;
  // ApplicationAssessment: DynamicQuestionList_Lightweight[];
}
export interface FeeSchedule extends DTOBaseClass_Standard {
  FeeSchedule_ID: number;
  Regulation: string;
  Class: number;
  Description: string;
  ApplyTo_ENUM: number;
  ApplyTo_Name: string;
  SortIndex: number;
  TownPlanning: boolean | null;
  Building: boolean | null;
  LandDivision: boolean | null;
}
export interface Fee extends DTOBaseClass_Standard {
  Fee_ID: number;
  Legacy_LID: number | null;
  FeeType: FeeType;
  FeeType_ID: number;
  Description: string;
  CostCode: string;
  DebtorNumber: string;
  GST: number;
  AmountEXGST: number;
  Amount: number;
  Quantity: number | null;
  UnitPrice: number | null;
  LineItem1Description: string;
  LineItem1Amount: number | null;
  LineItem1FeeType_ID: number | null;
  LineItem2Description: string;
  LineItem2Amount: number | null;
  LineItem2FeeType_ID: number | null;
  LineItem3Description: string;
  LineItem3Amount: number | null;
  LineItem3FeeType_ID: number | null;
  LineItem4Description: string;
  LineItem4Amount: number | null;
  LineItem4FeeType_ID: number | null;
  LineItem5Description: string;
  LineItem5Amount: number | null;
  LineItem5FeeType_ID: number | null;
  LineItem6Description: string;
  LineItem6Amount: number | null;
  LineItem6FeeType_ID: number | null;
  LineItem7Description: string;
  LineItem7Amount: number | null;
  LineItem7FeeType_ID: number | null;
  LineItem8Description: string;
  LineItem8Amount: number | null;
  LineItem8FeeType_ID: number | null;
  LineItem9Description: string;
  LineItem9Amount: number | null;
  LineItem9FeeType_ID: number | null;
  LineItem10Description: string;
  LineItem10Amount: number | null;
  LineItem10FeeType_ID: number | null;
  ServiceDate: Date | null;
  Invoice_ID: number | null;
  InvoiceNumber: string;
  InvoicedDate: Date | null;
  InvoicePayByDate: Date | null;
  InvoiceDescription: string;
  InvoicePeriod: string;
  InvoiceInfo1: string;
  InvoiceInfo2: string;
  InvoiceInfo3: string;
  Invoice_Attachment_ID: number | null;
  ExportedToFinance: boolean | null;
  ExportedToFinanceDate: Date | null;
  ExportedToFinanceBatch: number | null;
  BPayBillerCode: string;
  BPayReference: string;
  Internal_BPayReference: string;
  BPAY_iCRN: string;
  AusPostReference: string;
  BillPayReference: string;
  APOBarcode: string;
  KeyLineReference: string;
  OCRReference: string;
  SecurePay_Reference: string;
  Flag_ShowInPortal: boolean;
  oPayment: Payment;
  UpdateFinanceStaging_Processed: boolean | null;
  // UpdateFinanceStagingAction_ENUM: UPDATEFINANCESTAGINGSTATUS | null;
  UpdateFinanceStagingAction_Name: string;
  UpdateFinanceStagingProcessed_Date: Date | null;
  UpdateFinanceStaging_Response: string;
  Flag_PortalFee: boolean | null;
  Bond: boolean | null;
  BT_Name: string;
  CreateJournal: boolean;
  InvoiceRequired: boolean | null;
  Flag_CreditNote: boolean | null;
  // Opted_PaymentMethod_ENUM: PAYMENTTYPE | null;
  Opted_PaymentMethod_Name: string;
  // BubbleUps: BubbleUpIdentifier[];
  SaveTriggers: FeeUpdateTriggers[];
}
export interface Payment extends DTOBaseClass_Standard {
  Payment_ID: number;
  AmountPaid: number | null;
  ReceiptBy: string;
  DatePaid: Date | null;
  ReceiptNumber: string;
  MethodPayment_ENUM: PAYMENTTYPE;
  MethodPayment_Name: string;
  ChequeNumber: string;
  ChequeDrawer: string;
  Comments: string;
  Reference: string;
  BSB: string;
  BankName: string;
  Fee_ID: number;
  Fee: Fee;
  ExportedToFinance: boolean | null;
  ExportedToFinanceDate: Date | null;
  ExportedToFinanceBatch: number | null;
  PaymentGateway: string;
  GatewayTransaction: string;
  ReceiptItem_ID: number | null;
  Session_ID: number | null;
  Session_Name: string;
}
export interface PPRApplicationDetails extends DTOBaseClass_Standard {
  PPRApplicationDetails_ID: number;
  Application_ID: number;
  ResponsibleAuthority_ID: number | null;
  ResponsibleAuthority: Council;
  PlanScheme: string;
  CommunicationMethod_KWD: number | null;
  CommunicationMethodName: string;
  CouncilReferenceNo: string;
  VRStreetID: number | null;
  VRStreetName: string;
  VRStreet: StateRoad;
  VR_OfficeID: number | null;
  VROffice: Region;
  TeamLeader_ID: number | null;
  TeamLeader: SiteUser;
  Referraltrigger_KWD: number | null;
  ReferralTrigger: Keyword;
  Referraltrigger_NoOfDays: number | null;
  Referraltrigger_ResponseDue: Date | null;
  ReportLetter_AttachmentID: number | null;
  ResponseLetter_AttachmentID: number | null;
  PanelReportLetter_AttachmentID: number | null;
  Flag_Adequate: boolean | null;
  Flag_Inadequate: boolean | null;
  DateSentForTeamLeaderApproval: Date | null;
  OfficerRecommendationNotesForApproval: string;
  DateApprovedMaterialAdequate: Date | null;
  DateApprovedMaterialInadequate: Date | null;
  DateDisApprovedMaterialAdequate: Date | null;
  DateDisApprovedMaterialInadequate: Date | null;
  DateSentToRAandApplicant: Date | null;
  VROfficeName: string;
  Origin_KWD: number | null;
  OriginName: string;
  OriginReferral_KWD: number | null;
  OriginReferralName: string;
  Council_Officer: string;
  Flag_IsReferralRequired: boolean | null;
  ProponentDecisionDate: Date | null;
  SubmissionDueDate: Date | null;
  DirectionsHearingDate: Date | null;
  ProponentDecision_ENUM: Application_Decision | null;
  ProponentDecision_Name: string;
  ProponentDecisionType_ENUM: Application_DecisionType | null;
  ProponentDecisionType_Name: string;
  RequestToHeardPanel: Date | null;
  PanelHearingReportCreated: Date | null;
  PanelReportSentToTL: Date | null;
  PanelReportTLDecision: Date | null;
  ReportSentToPanelHearing: Date | null;
  PanelHearingDate: Date | null;
  PanelHearingDecisionDate: Date | null;
  Municipality: string;
  RelatedFileNumber: string;
  TeamLeader_In_Charge: string;
  Planner_In_Charge: string;
  OfficerInCharge_DOTHearingSubmissionCreated: string;
  OfficerInCharge_DOTHearingSubmissionSent: string;
  OfficerInCharge_DOTHearingSubmissionSentToTL: string;
  PublicHearing: Date | null;
  PlannerHearingReportDue: Date | null;
  DOTSubmissionScheduled: Date | null;
  Flag_DirectionsHearingDotToAttend: boolean | null;
  Flag_RequestHeardPanelDotToAttend: boolean | null;
  Flag_PublicHearingDotToAttend: boolean | null;
  Flag_ProposedApplicationDetailsFilled: boolean | null;
  TotalNumberOfAdditionalDwellings_Proposed: number | null;
  TotalNumberOfAdditionalBicycleSpaces_Proposed: number | null;
  TotalNumberOfShowers_Proposed: number | null;
  TotalNumberOfAdditionalCarParks_Proposed: number | null;
  TotalNumberOfAdditionalCrossovers_Proposed: number | null;
  TotalAdditionalFloorSpace_Commercial_Proposed: number | null;
  TotalAdditionalFloorSpace_Retail_Proposed: number | null;
  TotalAdditionalFloorSpace_Industrial_Proposed: number | null;
  TotalAdditionalFloorSpace_Government_Proposed: number | null;
  TotalAdditionalFloorSpace_Education_Proposed: number | null;
  TotalAdditionalFloorSpace_Health_Proposed: number | null;
  TotalAdditionalFloorSpace_Other_Proposed: number | null;
  TotalNumberOfAdditionalDwellings: number | null;
  TotalNumberOfAdditionalBicycleSpaces: number | null;
  TotalNumberOfShowers: number | null;
  TotalNumberOfAdditionalCarParks: number | null;
  TotalNumberOfAdditionalCrossovers: number | null;
  TotalAdditionalFloorSpace_Commercial: number | null;
  TotalAdditionalFloorSpace_Retail: number | null;
  TotalAdditionalFloorSpace_Industrial: number | null;
  TotalAdditionalFloorSpace_Government: number | null;
  TotalAdditionalFloorSpace_Education: number | null;
  TotalAdditionalFloorSpace_Health: number | null;
  TotalAdditionalFloorSpace_Other: number | null;
  Flag_DecisionCompletedByRA: boolean | null;
  Flag_EndorsedPermitSavedInPPR: boolean | null;
  RADecision_ENUM: Application_Decision | null;
  RADecision_Int: number | null;
  RADecision_Name: string;
  DOTPosition_ENUM: Application_Decision | null;
  DOTPosition_Name: string;
  DOTHearingSubmissionPosition_ENUM: Application_Decision | null;
  DOTHearingSubmissionPosition_Name: string;
  DOTNotifiedOfFinalDecision: Date | null;
  AmendmentNumber: number | null;
  PSARAmendmentReference: string;
  SPEARReference: string;
  ProposedNoOfLots: number | null;
}
export interface Council extends DTOBaseClass_Standard {
  Council_ID: number;
  CouncilName: string;
  LGA: string;
  Region: string;
  State: string;
  BVO_CouncilCode: number | null;
  FSR_CODE: string;
  HM_CouncilPrefix: string;
  Council_RID: number | null;
  CouncilDetails: Contact_Relation;
  Officer_RID: number | null;
  Officer: Contact_Relation;
  SaveTriggers: ApplicationUpdateTriggers[];
}
export interface StateRoad extends DTOBaseClass_Standard {
  StateRoad_ID: number;
  RoadName: string;
  RoadNumber: string;
  RoadIDNo: string;
  RoadClass: string;
  RoadStartNo: string;
  RoadStartName: string;
  RoadStartType: string;
  RoadStartLocality: string;
  RoadEnd: string;
  RoadEndName: string;
  RoadEndType: string;
  RoadEndLocality: string;
  RoadState: string;
}
export interface Region extends DTOBaseClass_Standard {
  Region_ID: number;
  Region_Name: string;
  Suburb: string;
  Council_ID: number;
  Council_Name: string;
  PostCode: string;
  State: string;
  State_Code: string;
  Coordinator_ID: number | null;
  Coordinator: SiteUser;
}
export interface PermitTypeFee {
  CoreLinkId: number | null;
  Index: number;
  PermitFeeType_ID: number | null;
  PermitFeeType_Name: string;
  PermitTypeCalculatedFee_ID: number | null;
  PermitTypeCalculatedFee_Name: string;
  PermitTypeFee_Amount: number | null;
  InheritApplicationFeeType: boolean | null;
}
export interface StatutoryDays {
  StatDays?: number;
  GrossDays?: number;
  AppealDays?: number;
  TargetDays?: number;
  StartDateDescription?: string;
  EndDateDescription?: string;
  StartDate?: Date | null;
  EndDate?: Date | null;
  OriginalTargetDate?: Date | null;
  TargetDate?: Date | null;
  Flag_TargetDateExtended?: boolean | null;
}
export interface ApplicationAssessmentPlanner {
  AssessmentPlanner_ID: number;
  Application_ID: number;
  AssessmentPlanner_Demolition_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_Demolition_Name: string;
  AssessmentPlanner_BuildingHeight: string;
  AssessmentPlanner_CurrentLandUse_KWD: number | null;
  AssessmentPlanner_CurrentLandUse_Name: string;
  AssessmentPlanner_ProposedLandUse_KWD: number | null;
  AssessmentPlanner_ProposedLandUse_Name: string;
  AssessmentPlanner_Precinct_KWD: number | null;
  AssessmentPlanner_Precinct_Name: string;
  AssessmentPlanner_HousingType_KWD: number | null;
  AssessmentPlanner_HousingType_Name: string;
  AssessmentPlanner_Zones: Zone[];
  AssessmentPlanner_Zones_Display: string;
  AssessmentPlanner_Overlays: Overlay[];
  AssessmentPlanner_Overlays_Display: string;
  AssessmentPlanner_PermitTriggers: PermitTrigger[];
  AssessmentPlanner_PermitTriggers_Display: string;
  AssessmentPlanner_CouncilMeetingReq_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_CouncilMeetingReq_Name: string;
  AssessmentPlanner_IncreaseOfDwellings: string;
  AssessmentPlanner_EffectParkingScheme_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_EffectParkingScheme_Name: string;
  AssessmentPlanner_CulturalHeritageManagementPlant_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_CulturalHeritageManagementPlant_Name: string;
  AssessmentPlanner_CulturalHeritageSensitively_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_CulturalHeritageSensitively_Name: string;
  AssessmentPlanner_Sensitive_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_Sensitive_Name: string;
  AssessmentPlanner_DateSupplied: Date | null;
  AssessmentPlanner_HeritageDetails: string;
  AssessmentPlanner_InspectionDate: Date | null;
  AssessmentPlanner_InspectionComment: string;
  AssessmentPlanner_NoOfLots: number | null;
  AssessmentPlanner_TotalFloorArea: number | null;
  AssessmentPlanner_Flag_VicSmart: boolean | null;
  AssessmentPlanner_DistanceFromWaterway: number | null;
  Flag_SocialOrAffordableHousing: boolean | null;
  IsAppSolelyPlansToComply: boolean | null;
  ESD_TotalDwellings_KWD: number | null;
  ESD_NonResFloorSpace_KWD: number | null;
  ESD_GrossFloorArea_KWD: number | null;
  WSUD_NewBuilding_ENUM: GENERIC_YESNO | null;
  WSUD_NewBuilding_Name: string;
  WSUD_ExtensionGreater50_ENUM: GENERIC_YESNO | null;
  WSUD_ExtensionGreater50_Name: string;
  WSUD_CommercialLD_ENUM: GENERIC_YESNO | null;
  WSUD_CommercialLD_Name: string;
  AssessmentPlanner_RetailNoOfRetailUnits: number | null;
  AssessmentPlanner_RetailFloorSpace: number | null;
  AssessmentPlanner_RetailNoOfSeats: number | null;
  AssessmentPlanner_RetailParkingSpacesWaived: number | null;
  AssessmentPlanner_RetailParkingSpacesPermitted: number | null;
  AssessmentPlanner_ResidentialBedroomsPermitted: number | null;
  AssessmentPlanner_ResidentialParkingSpacesWaived: number | null;
  AssessmentPlanner_ResidentialParkingSpacesPermitted: number | null;
  AssessmentPlanner_OfficeFloorSpace: number | null;
  AssessmentPlanner_OfficeParkingSpacesWaivedPermitted: number | null;
  AssessmentPlanner_MasterPlansLots: number | null;
  AssessmentPlanner_MasterPlansDevelopmentSite: string;
  AssessmentPlanner_MasterPlansPSPCode: string;
  AssessmentPlanner_CarParkingRequired_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_CarParkingRequired_Name: string;
  AssessmentPlanner_CarParkingShopFloorSpace: number | null;
  AssessmentPlanner_CarParkingShopCarParkProvided: number | null;
  AssessmentPlanner_CarParkingShopCarParkWaived: number | null;
  AssessmentPlanner_CarParkingIndustryFloorSpace: number | null;
  AssessmentPlanner_CarParkingIndustryCarParkProvided: number | null;
  AssessmentPlanner_CarParkingIndustryCarParkWaived: number | null;
  AssessmentPlanner_CarParkingWarehouseFloorSpace: number | null;
  AssessmentPlanner_CarParkingWarehouseCarParkProvided: number | null;
  AssessmentPlanner_CarParkingWarehouseCarParkWaived: number | null;
  AssessmentPlanner_CarParkingOfficeFloorSpace: number | null;
  AssessmentPlanner_CarParkingOfficeCarParkProvided: number | null;
  AssessmentPlanner_CarParkingOfficeCarParkWaived: number | null;
  AssessmentPlanner_CarParkingRestaurantSeats: number | null;
  AssessmentPlanner_CarParkingRestaurantCarParkProvided: number | null;
  AssessmentPlanner_CarParkingRestaurantCarParkWaived: number | null;
  AssessmentPlanner_CarParkingOtherSeats: number | null;
  AssessmentPlanner_CarParkingOtherFloorSpace: number | null;
  AssessmentPlanner_CarParkingOtherCarParkProvided: number | null;
  AssessmentPlanner_CarParkingOtherCarParkWaived: number | null;
  AssessmentPlanner_ChecklistApplicationFormSignedAndCompleted_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistApplicationFormSignedAndCompleted_Name: string;
  AssessmentPlanner_ChecklistIsBotanical_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistIsBotanical_Name: string;
  AssessmentPlanner_ChecklistRecentTitleCopyAndTitleParticularsHasBeenSupplied_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistRecentTitleCopyAndTitleParticularsHasBeenSupplied_Name: string;
  AssessmentPlanner_ChecklistIsZoological_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistIsZoological_Name: string;
  AssessmentPlanner_ChecklistAddressOfTheLandCorrect_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistAddressOfTheLandCorrect_Name: string;
  AssessmentPlanner_ChecklistDescriptionOfTheUseCorrect_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistDescriptionOfTheUseCorrect_Name: string;
  AssessmentPlanner_ChecklistAnyOtherControl_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistAnyOtherControl_Name: string;
  AssessmentPlanner_ChecklistAnyOtherControlDetails: string;
  AssessmentPlanner_StrategicObjectives: StrategicObjective[];
  AssessmentPlanner_StrategicObjectives_Display: string;
  AssessmentPlanner_ChecklistStrategicObjectivesMustBeConsideredDetails: string;
  AssessmentPlanner_ChecklistAnyRelevantHistoryToTheApplication_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistAnyRelevantHistoryToTheApplication_Name: string;
  AssessmentPlanner_ChecklistAnyRelevantHistoryToTheApplicationDetails: string;
  AssessmentPlanner_ChecklistAuthorisationLetterRequired_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistAuthorisationLetterRequired_Name: string;
  AssessmentPlanner_ChecklistSitePlanRequired_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistSitePlanRequired_Name: string;
  AssessmentPlanner_ChecklistElevationsProvided_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistElevationsProvided_Name: string;
  AssessmentPlanner_ChecklistFloorPlansProvided_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistFloorPlansProvided_Name: string;
  AssessmentPlanner_ChecklistPropertyHeritageListed_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistPropertyHeritageListed_Name: string;
  AssessmentPlanner_ChecklistTrafficImpactAssessmentRequired_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistTrafficImpactAssessmentRequired_Name: string;
  AssessmentPlanner_ChecklistWasteWaterReportRequired_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistWasteWaterReportRequired_Name: string;
  AssessmentPlanner_ChecklistBushFireHazardReportRequired_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistBushFireHazardReportRequired_Name: string;
  AssessmentPlanner_ChecklistGeoTechnicalReportRequired_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistGeoTechnicalReportRequired_Name: string;
  AssessmentPlanner_ChecklistCoastalHazardReportRequired_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistCoastalHazardReportRequired_Name: string;
  AssessmentPlanner_ChecklistAgriculturalConsultantReportRequired_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistAgriculturalConsultantReportRequired_Name: string;
  AssessmentPlanner_ChecklistFloraFaunaStudyRequired_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_ChecklistFloraFaunaStudyRequired_Name: string;
  AssessmentPlanner_FormSignedByLandOwner_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_FormSignedByLandOwner_Name: string;
  AssessmentPlanner_RestrictionImplications_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_RestrictionImplications_Name: string;
  AssessmentPlanner_ApplicationTo_ENUM: GENERIC_ENDAMEND | null;
  AssessmentPlanner_ApplicationTo_Name: string;
  AssessmentPlanner_EndOnPartOfLand_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_EndOnPartOfLand_Name: string;
  AssessmentPlanner_AgreementAgreed_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_AgreementAgreed_Name: string;
  Utilities_ExistingFloorArea: number | null;
  Utilities_ProposedFloorArea: number | null;
  Utilities_ExistingParkingSpaces: number | null;
  Utilities_ProposedParkingSpaces: number | null;
  Utilities_IsProposedBusinessHourDifferent: boolean | null;
  Utilities_WorkingHours_KWD: number | null;
  Utilities_ExistingNoOfEmployees: number | null;
  Utilities_ProposedNoOfEmployees: number | null;
  Utilities_CommercialVehiclesAccessing_KWD: number | null;
  Utilities_IsOutdoorStorageProposed: boolean | null;
  Utilities_IsApplyingForPermittedVisitorAccomm: boolean | null;
  Utilities_NoOfSigns: number | null;
  Utilities_NoOfHotelRooms: number | null;
  Utilities_IsLandLordConsentRequired: boolean | null;
  AssessmentPlanner_FIDaysCulturalHeritage: number | null;
  AssessmentPlanner_BushfireManagementPlan_ENUM: GENERIC_YESNO | null;
  AssessmentPlanner_BushfireManagementPlan_Name: string;
  AssessmentPlanner_ExecutiveSummary: string;
  AssessmentPlanner_SiteDetails: string;
  AssessmentPlanner_Proposal: string;
  AssessmentPlanner_Background: string;
  AssessmentPlanner_Conserns: string;
  AssessmentPlanner_Discussion: string;
  AssessmentPlanner_Conclusion: string;
  PSARPlanningDecisionType_ENUM: PSAR_PlannerDecision | null;
  PSARPlanningDecisionType_Name: string;
  AssessmentPlanner_Images: Attachment[];
}
export interface ApplicationCategoryFee {
  CoreLinkId: number | null;
  Index: number;
  ApplicationCategoryFeeType_ID: number | null;
  ApplicationCategoryFeeType_Name: string;
  ApplicationCategoryCalculatedFee_ID: number | null;
  ApplicationCategoryCalculatedFee_Name: string;
  ApplicationCategoryFee_Amount: number | null;
  InheritApplicationFeeType: boolean | null;
}
export interface Fee_LineItem {
  Description: string;
  FeeType_ID: number | null;
  CostCode: string;
  Quantity: number;
  UnitPrice: number;
  GST: number;
  Amount_ExGST: number;
  Amount: number;
}
export interface FeeTotals {
  TotalFees: number;
  TotalPayments: number;
}
export interface FeeLists {
  FeesToApply: Fee[];
  PaymentsToApply: Payment[];
  TotalFeeAmount: number;
  TotalPaymentAmount: number;
}
export interface FeeAmountDetails {
  CostCode: string;
  GST: number;
  Amount_ExGST: number;
  Amount: number;
}
export interface PortalPayment {
  AncestorRecordSource_ID: number;
  AncestorRecordSource_Type: number;
  AmountPaid: number | null;
  InvoiceNumber: string;
}
export interface ApplicationBuildingDetails {
  BuildingDetails_ID: number;
  Application_ID: number;
  Building_BSNumber: string;
  Building_StageNumber: string;
  BuilderRegNo: string;
  PlanningPermitNo: string;
  PlanningPermitID: number | null;
  Date_PlanningIssued: Date | null;
  PlumbingAppNo: string;
  PlumbingAppID: number | null;
  Date_PlumbingAppIssued: Date | null;
  InspectionsRequired: InspectionsRequired[];
  InspectionsRequired_Display: string;
  Building_UseOfBuildings: UseOfBuilding[];
  Building_UseOfBuildings_Display: string;
  Building_UseOfBuildings_Display_FreeText: string;
  Building_BCA: BCA[];
  Building_BCAs_Display: string;
  Building_SecondaryBCA: BCA[];
  Building_SecondaryBCAs_Display: string;
  Building_BCAVersion: BCAVersion[];
  Building_BCAVersions_Display: string;
  OwnerBuilder: boolean | null;
  ComplianceInspections: boolean | null;
  BuildingConsent: boolean | null;
  Building_OwnerBuildingNumber: string;
  Building_AllotmentArea: number | null;
  Building_TotalFloorArea: number | null;
  Building_GarageArea: number | null;
  Building_VerandahArea: number | null;
  Building_DwellingLivingArea: number | null;
  Building_AlfrescoEntertainmentArea: number | null;
  Building_OtherAreas: number | null;
  Building_DwellingExtArea: number | null;
  Building_ShedArea: number | null;
  Building_BalconyArea: number | null;
  Building_Storeys: number | null;
  Building_FloorLoad: number | null;
  Building_PermitIssued: Date | null;
  Building_WorkCommencing: Date | null;
  Building_WorkCommenced: Date | null;
  Building_WorkDueToComplete: Date | null;
  Building_WorkCompleted: Date | null;
  Building_InsuranceCompany_KWD: number | null;
  Building_InsuranceCompany_Name: string;
  Building_PolicyNumber: string;
  Building_ExtWallMaterial_KWD: number | null;
  Building_ExtWallMaterial_Name: string;
  Building_RoofCladdingMaterial_KWD: number | null;
  Building_RoofCladdingMaterial_Name: string;
  Building_FrameMaterial_KWD: number | null;
  Building_FrameMaterial_Name: string;
  Building_FloorMaterial_KWD: number | null;
  Building_FloorMaterial_Name: string;
  Building_ProjectID: string;
  Building_Demolishing_ENUM: GENERIC_YESNO | null;
  Building_Demolishing_Name: string;
  Building_DemolishedDwellings: number | null;
  Building_ExistingDwellings: number | null;
  Building_NewDwellings: number | null;
  Building_SubjectToTermites_ENUM: GENERIC_YESNO | null;
  Building_SubjectToTermites_Name: string;
  Building_BAL_KWD: number | null;
  Building_BAL_Name: string;
  Building_EnergyRating_KWD: number | null;
  Building_EnergyRating_Name: string;
  Building_SubjectToFlooding_ENUM: GENERIC_YESNO | null;
  Building_SubjectToFlooding_Name: string;
  Building_SpecFloodLevel: string;
  Building_SubjectToSnowfall_ENUM: GENERIC_YESNO | null;
  Building_SubjectToSnowfall_Name: string;
  Building_SolarHotWater_ENUM: GENERIC_YESNO | null;
  Building_SolarHotWater_Name: string;
  Building_RainWaterTank_ENUM: GENERIC_YESNO | null;
  Building_RainWaterTank_Name: string;
  Building_LandType_KWD: number | null;
  Building_LandType_Name: string;
  Building_LandOwnership_KWD: number | null;
  Building_LandOwnership_Name: string;
  Building_EstimateCostOfProject: number | null;
  Building_Determination_ENUM: GENERIC_YESNO | null;
  Building_Determination_Name: string;
  Building_EstimateCostOfDomesticWork: number | null;
  Building_ApplicantRequiresFinalCertificate_ENUM: Application_ApplicantRequiresFinalCertificate | null;
  Building_ApplicantRequiresFinalCertificate_Name: string;
  Building_TotalApplicantRequiresFinalCertificate: number | null;
  Building_FinalCertificateInspectionDate_1: Date | null;
  Building_FinalCertificateNumber_1: string;
  Building_FinalCertificatePermitType_1_ENUM: Application_FinalCertificatePermitType | null;
  Building_FinalCertificatePermitType_1_Name: string;
  Building_FinalCertificateInspectionDate_2: Date | null;
  Building_FinalCertificateNumber_2: string;
  Building_FinalCertificatePermitType_2_ENUM: Application_FinalCertificatePermitType | null;
  Building_FinalCertificatePermitType_2_Name: string;
  Building_FinalCertificateInspectionDate_3: Date | null;
  Building_FinalCertificateNumber_3: string;
  Building_FinalCertificatePermitType_3_ENUM: Application_FinalCertificatePermitType | null;
  Building_FinalCertificatePermitType_3_Name: string;
  Building_FinalCertificateInspectionDate_4: Date | null;
  Building_FinalCertificateNumber_4: string;
  Building_FinalCertificatePermitType_4_ENUM: Application_FinalCertificatePermitType | null;
  Building_FinalCertificatePermitType_4_Name: string;
  Building_FinalCertificateInspectionDate_5: Date | null;
  Building_FinalCertificateNumber_5: string;
  Building_FinalCertificatePermitType_5_ENUM: Application_FinalCertificatePermitType | null;
  Building_FinalCertificatePermitType_5_Name: string;
  Building_FinalCertificateInspectionDate_6: Date | null;
  Building_FinalCertificateNumber_6: string;
  Building_FinalCertificatePermitType_6_ENUM: Application_FinalCertificatePermitType | null;
  Building_FinalCertificatePermitType_6_Name: string;
  Building_FinalCertificateInspectionDate_7: Date | null;
  Building_FinalCertificateNumber_7: string;
  Building_FinalCertificatePermitType_7_ENUM: Application_FinalCertificatePermitType | null;
  Building_FinalCertificatePermitType_7_Name: string;
  Building_FinalCertificateInspectionDate_8: Date | null;
  Building_FinalCertificateNumber_8: string;
  Building_FinalCertificatePermitType_8_ENUM: Application_FinalCertificatePermitType | null;
  Building_FinalCertificatePermitType_8_Name: string;
  Building_FinalCertificateInspectionDate_9: Date | null;
  Building_FinalCertificateNumber_9: string;
  Building_FinalCertificatePermitType_9_ENUM: Application_FinalCertificatePermitType | null;
  Building_FinalCertificatePermitType_9_Name: string;
  Building_FinalCertificateInspectionDate_10: Date | null;
  Building_FinalCertificateNumber_10: string;
  Building_FinalCertificatePermitType_10_ENUM: Application_FinalCertificatePermitType | null;
  Building_FinalCertificatePermitType_10_Name: string;
  Building_CertificateOfDesignDate: Date | null;
  Building_CertificateOfDesignNo: string;
  Building_CLCDate: Date | null;
  CLCNumber: string;
  Building_EnforcementDate: Date | null;
  Flag_Section80: boolean;
  Flag_HistoricalSite: boolean;
  Date_Abandoned: Date | null;
  Date_EssentialServicesCert: Date | null;
  Date_Section80Received: Date | null;
  HistoricFileNumber: string;
  Flag_CILRequired: boolean | null;
  Flag_CILPaid: boolean | null;
  CIL_FeeType: string;
  CIL_Amount: number | null;
  CIL_ReceiptNumber: string;
  CIL_ReceiptDate: Date | null;
  CIL_Letter1: string;
  CIL_Letter2: string;
  CIL_Letter3: string;
  CIL_Date_Letter1: Date | null;
  CIL_Date_Letter2: Date | null;
  CIL_Date_Letter3: Date | null;
  Building_Type: ApplicationBuildingDetails_Type | null;
  IsFireRelatedPerformanceSolution: boolean | null;
  FireEngineerRegistration: string;
  FireEngineerName: string;
  ReportingAuthorities: ReportingAuthority[];
  ReportingAuthorities_Display: string;
  ReportingAuthorities_KWD: number | null;
  ConsentedTo: string;
  RegulationNo: string;
  NextInspectionDate: Date | null;
  Building_PolicyCovered: number;
  Building_RiseInstories: number | null;
  Building_EffectiveHeight: string;
  Building_TypeOfConstruction_KWD: number | null;
  Building_TypeOfConstruction_Name: string;
  Building_WholePart_KWD: number | null;
  Building_WholePart_Name: string;
  Building_SpecificWork: SpecificBuildingWork[];
  Building_SpecificWork_Display: string;
  Building_BuildingWorks_Display: BuildingWorksView[];
  Flag_StageIndicator: boolean | null;
  BuildingStageDescription_KWD: number | null;
  BuildingStageDescription_KWD_Name: string;
  Flag_ProtectionWorkRequired: boolean | null;
  Flag_CombinedAllotment: boolean | null;
  Flag_PerformanceSolution_PartBGHIJ: boolean | null;
  Flag_PerformanceSolution_PartC: boolean | null;
  Flag_PerformanceSolution_PartD: boolean | null;
  Flag_PerformanceSolution_PartE: boolean | null;
  Flag_PerformanceSolution_PartF: boolean | null;
  Flag_Regulation268ExemptionApply: boolean | null;
  Flag_CertificateOfConsentRequired: boolean | null;
  ConsentNo: string;
  Notes_PerformanceSolution_PartBGHIJ: string;
  Notes_PerformanceSolution_PartC: string;
  Notes_PerformanceSolution_PartD: string;
  Notes_PerformanceSolution_PartE: string;
  Notes_PerformanceSolution_PartF: string;
  Building_SubjectToBushFire_ENUM: GENERIC_YESNO | null;
  Building_SubjectToBushFire_NAME: string;
  Building_SoilClassification_KWD: number | null;
  Building_SoilClassification_NAME: string;
  Building_BuildingAct: string;
  OPLocation: string;
  MaxNoOfOccupants: number | null;
  AssessmentNotes: string;
  Flag_PerformanceSolution: boolean | null;
  PerformanceSolutionDetails: string;
  MaintainableFeatureDetails: string;
  CertificateDetails: string;
}
export interface UseOfBuilding {
  Application_UseOfBuilding_ID: number;
  Application_ID: number;
  UseOfBuilding_KWD: number;
  UseOfBuilding_Name: string;
  UseOfBuilding_Description: string;
}
export interface BCA {
  Application_BCA_ID: number;
  Application_ID: number;
  BCA_KWD: number;
  BCA_Name: string;
  BCASecondary_KWD: number | null;
  BCASecondary_Name: string;
  BCA_Fees: number | null;
  BCA_Class: string;
  BCA_SecondaryClass: string;
}
export interface InspectionsRequired {
  Application_InspectionsRequired_ID: number;
  Application_ID: number;
  InspectionType_ID: number;
  InspectionType_Name: string;
}
export interface Description {
  Application_Description_ID: number;
  Application_ID: number;
  Description_KWD: number;
  Description_Name: string;
}
export interface Zone {
  Application_Zone_ID: number;
  Application_ID: number;
  Zone_KWD: number;
  Zone_Name: string;
}
export interface Overlay {
  Application_Overlay_ID: number;
  Application_ID: number;
  Overlay_KWD: number;
  Overlay_Name: string;
}
export interface PermitTrigger {
  Application_PermitTrigger_ID: number;
  Application_ID: number;
  PermitTrigger_KWD: number;
  PermitTrigger_Name: string;
}
export interface StrategicObjective {
  Application_StrategicObjective_ID: number;
  Application_ID: number;
  StrategicObjective_KWD: number;
  StrategicObjective_Name: string;
}
export interface PPARS_Result {
  Success: boolean;
  ResponseMessage: string;
  ExceptionMessage: string;
  Base64Encoded: string;
  FileName: string;
  ResponseFileString: string;
  PostFileString: string;
}
export interface SubdivisionDetails {
  DisplayText: string;
  PlanSubDivNo: string;
}
export interface ApplicationDetails {
  DisplayText: string;
  Decision: string;
}
export interface ReportingAuthority {
  CoreLinkId: number;
  ReportingAuthorities_KWD: number | null;
  ReportingAuthorities_Name: string;
  ConsentedTo: string;
  RegulationNo: string;
}
export interface BCAVersion {
  Application_BCA_ID: number;
  Application_ID: number;
  BCAVerion_KWD: number;
  BCAVerion_Name: string;
}
export interface SpecificBuildingWork {
  Application_SpecificBuildingWork_ID: number;
  Application_ID: number;
  SpecificBuildingWork_KWD: number;
  SpecificBuildingWork_Name: string;
  SpecificBuildingWork_Desc: string;
}
export interface BuildingWork {
  ID: number;
  Nature: ApplicationCategory;
  Use: UseOfBuilding;
  Class: BCA;
  BCAVersionID: number;
}
export interface BuildingWorksView {
  CoreLinkId: number;
  NatureOfWork: string;
  NatureOfWork_Code: string;
  UseOfBuilding: string;
  BCA: string;
  BCA_From_UseOfBuilding: string;
  BCAFee: number | null;
  BCAClass: string;
  BCASecondaryClass: string;
}
export interface ApplicationImportantDates {
  Event: string;
  Dates: string;
  Description: string;
}
export interface ApplicationAssessmentDetails {
  AssessmentDetails_ID: number;
  Application_ID: number;
  IsPoolSpa40KlitersProposed: boolean | null;
  DwellingsUnitsProposed: number | null;
  StoreysProposed: number | null;
  PreExistingDwellings: number | null;
  DwellingsDemolished: number | null;
  ExistingLots: number | null;
  ProposedLots: number | null;
  StaffCount: number | null;
  ParkingSpaces: number | null;
  LoadingBays: number | null;
  IsNewRoadProposed: boolean | null;
  ProposedRoadWorkDescription: string;
  IsStagedDevelopment: boolean | null;
  StagedDevelopmentDescription: string;
  IsCrownDevelopment: boolean | null;
  CrownDevelopmentAgency: string;
  DoesImpactHabitat: boolean | null;
  IsLGAS68ApprovalReq: boolean | null;
  S68ReqDescription: string;
  IncludeHeritageArea: boolean | null;
  IncludeHeritageBuilding: boolean | null;
  IncludeHeritageTreeRemoval: boolean | null;
  IsApplicantCouncilMember: boolean | null;
  ApplicantCouncilRoleDescription: string;
  IsApplicantRelatedToCouncil: boolean | null;
  ApplicantCouncilRelationDecription: string;
  ExecutiveSummary: string;
  SiteDetails: string;
  ProposalDescription: string;
  Background: string;
  Concerns: string;
  Discussion: string;
  Conclusion: string;
  ConclusionDecision_ENUM: Application_Decision | null;
  ConclusionDecision_Name: string;
  // AssessmentDetails_Images: Attachment[];
  ApplicationAssessmentAdditionalDetails: ApplicationAssessmentAdditionalDetails;
}
export interface LEPVariation {
  VariationRequest: string;
  RelevantEPI: string;
  LandZone: string;
  ZoneObjectives: string;
  DevelopmentStandardVariations: DevelopmentStandardVariation[];
}
export interface DevelopmentStandardVariation {
  DevelopmentStandard: string;
  Objectives: string;
  Epi: string;
  DevelopmentStandardsVaried: number | null;
  DevelopmentStandardsProposed: number | null;
  Circumstances: string;
  Justification: string;
  OtherInformation: string;
}
export interface VPADetail {
  VpaDescription: string;
  VpaStatus: VPA_Status | null;
}
export interface Donation {
  Donation_Type: string;
  Donation_To: string;
  Donation_Date: Date | null;
  Donation_Amount: number | null;
}
export interface PoliticalDonation {
  DonationDeclarationPersonName: string;
  EntityNameDonatedTo: string;
  ABNDonatedTo: string;
  AddressDonatedTo: string;
  Donation: Donation[];
}
export interface Payer {
  PayerPerson: Contact_Relation;
  BillingAddress: Address;
  PayerPerson_ID: number | null;
  BillingAddress_ID: number | null;
}
export interface ApplicationAssessmentAdditionalDetails {
  IsIntegratedDevelopment: boolean | null;
  IntegratedDevelopmentTypes: IntegratedDevelopmentType[];
  DoesVaryLEP: boolean | null;
  LEPVariation: LEPVariation;
  IsApplicationVPA: boolean | null;
  VpaDetails: VPADetail[];
  AnyPoliticalDonation: string;
  PoliticalDonation: PoliticalDonation;
  Payer: Payer;
  SaveTriggers: ApplicationAssessmentAdditionalDetailsUpdateTriggers[];
}
export interface ApplicationPrescribeEvents extends DTOBaseClass_Standard {
  PrescribeEvents_ID: number;
  Application_ID: number;
  ApplicationNumber: string;
  PermitNo: string;
  Date_Sent: Date | null;
  Sent_Counter: number | null;
  IsSentToBAMS: boolean | null;
  PrescribeEvent_KWD: number | null;
  PrescribeEvent_KWD_Name: string;
  Surveyor_RegistrationNo: string;
  Building_StageNumber: string;
  CertificateNo: string;
  InvolvedPerson_FirstName: string;
  InvolvedPerson_LastName: string;
  Notes: string;
  ProjectID_Name: string;
  Person_RegistrationNo: string;
  Inspection_Status: boolean | null;
  BAMS_PrescribeEvent_LodgedDateTime: Date | null;
  BAMS_PrescribeEvent_BatchNo: number | null;
  Date_BuildingPermit_Issue: Date | null;
  Code: number | null;
  BatchNoDisplay: string;
  Compliance_KWD: number | null;
  Compliance_KWD_Name: string;
}
export interface ValuePickerPacket {
  KeyValueDataSource: IKeyValuePacket[];
  SelectedValues: number[];
}

//Mock model for settings
export interface IApplicationSettings {
  _FTDDays: number;
  _ApplicationBannerShowAppealDays: boolean;
  _TargetDaysEnabled: boolean;
  _IsStatBasedOnValidation: boolean;
  _DisplayControlLine: boolean;
  _DisplayPropertyZoneOverlay: boolean;
  _RecordsSystem: boolean;
}

//Enum
export enum PSAR_PlannerDecision {
  Unspecified = "Unspecified", // 0
  Support_Submission = "Support_Submission", // 1
  Support_Submission_Amendments_Sought = "Support_Submission_Amendments_Sought", // 2
  Dont_support_Submission = "Dont_support_Submission", // 3
}
export enum GENERIC_ENDAMEND {
  Unspecified = "Unspecified", // 0
  END = "END", // 1
  AMEND = "AMEND", // 2
}
export enum VPA_Status {
  Unspecified = "Unspecified", // 0
  Proposed = "Proposed", // 1
  OnExhibition = "OnExhibition", // 2
  Executed = "Executed", // 3
}
export enum Application_FinalCertificatePermitType {
  Unspecified = "Unspecified", //0
  Unconditional = "Unconditional", //1
  Conditional = "Conditional", //2
}
export enum Application_ApplicantRequiresFinalCertificate {
  Unspecified = "Unspecified", // 0
  CertificateOfFinalInspection = "CertificateOfFinalInspection", // 1
  CertificateOfOccupancy = "CertificateOfOccupancy", // 2
  CertificateOfOccupancyAndFinalInspection = "CertificateOfOccupancyAndFinalInspection", // 3
  CertificateOfCompletion = "CertificateOfCompletion", // 4
}
export enum ApplicationBuildingDetails_Type {
  Unspecified = "Unspecified", // 0
  Detached = "Detached", // 1
  Attached = "Attached", // 2
  SemiAttached = "SemiAttached", // 3
}
export enum GENERIC_YESNO {
  Unspecified = "Unspecified", // 0
  YES = "YES", // 1
  NO = "NO", // 2
  NA = "NA", // 3
  UNKNOWN = "UNKNOWN", // 4
}
export enum PAYMENTTYPE {
  NONE = "NONE", //0
  CASH = "CASH", //1
  CHEQUE = "CHEQUE", //2
  EFT = "EFT", //3
  CREDITCARD = "CREDITCARD", //4
  WRITEOFF = "WRITEOFF", //5
  CREDITNOTE = "CREDITNOTE", //6
  FINANCE = "FINANCE", //7
  BANKDEPOSIT = "BANKDEPOSIT", //8
  DISCOUNT = "DISCOUNT", //9
  AWAITINGPAYMENT = "AWAITINGPAYMENT", //10
  PAYMENTGATEWAY = "PAYMENTGATEWAY", //11
  BPAY = "BPAY", //12
  MONEYORDER = "MONEYORDER", //13
  ONLINE = "ONLINE", //14
  NOTREQUIRED = "NOTREQUIRED", //15
  JOURNAL = "JOURNAL", //16
}
export enum Application_Decision {
  Unspecified = "Unspecified", // 0
  Issued = "Issued", // 1
  NOD = "NOD", // 2
  Refused = "Refused", // 3
  Withdrawn = "Withdrawn", // 4
  Lapsed = "Lapsed", // 5
  Failure = "Failure", // 6
  RefusedByPlanning = "RefusedByPlanning", // 7
  PSASupported = "PSASupported", // 8
  PSANotSupported = "PSANotSupported", // 9
  PSADeferred = "PSADeferred", // 10
  PSAAdopted = "PSAAdopted", // 11
  PSAAbandoned = "PSAAbandoned", // 12
  Authorised = "Authorised", // 13
  AuthorisedWithCondition = "AuthorisedWithCondition", // 14
  AuthorisationRefused = "AuthorisationRefused", // 15
  PSAAdoptedWithChanges = "PSAAdoptedWithChanges", // 16
  OBJECTION = "OBJECTION", // 17
  NOOBJECTION = "NOOBJECTION", // 18
  NOOBJECTIONSUBJECTTOCONDITIONS = "NOOBJECTIONSUBJECTTOCONDITIONS", // 19
  VRRESPONDED = "VRRESPONDED", // 20
  Approved = "Approved", // 21
  ApprovedSubjectToCondition = "ApprovedSubjectToCondition", // 22
  ApprovedSubjectToStandardCondition = "ApprovedSubjectToStandardCondition", // 23
  PermitNotRequired = "PermitNotRequired", // 24
  PanelReportRefused = "PanelReportRefused", // 25
  SentToPanelHearing = "SentToPanelHearing", // 26
  PSARSupportedWithChanges = "PSARSupportedWithChanges", // 27
  Gazetted = "Gazetted", // 28
  GrantPermit = "GrantPermit", // 29
  GrantPermitWithConditions = "GrantPermitWithConditions", // 30
  PermitRefused = "PermitRefused", // 31
  DeferredCommencement = "DeferredCommencement", // 32
  Cancelled = "Cancelled", // 33
}
export enum Application_Decision_Int {
  Unspecified = 0,
  Issued = 1,
  NOD = 2,
  Refused = 3,
  Withdrawn = 4,
  Lapsed = 5,
  Failure = 6,
  RefusedByPlanning = 7,
  PSASupported = 8,
  PSANotSupported = 9,
  PSADeferred = 10,
  PSAAdopted = 11,
  PSAAbandoned = 12,
  Authorised = 13,
  AuthorisedWithCondition = 14,
  AuthorisationRefused = 15,
  PSAAdoptedWithChanges = 16,
  OBJECTION = 17,
  NOOBJECTION = 18,
  NOOBJECTIONSUBJECTTOCONDITIONS = 19,
  VRRESPONDED = 20,
  Approved = 21,
  ApprovedSubjectToCondition = 22,
  ApprovedSubjectToStandardCondition = 23,
  PermitNotRequired = 24,
  PanelReportRefused = 25,
  SentToPanelHearing = 26,
  PSARSupportedWithChanges = 27,
  Gazetted = 28,
  GrantPermit = 29,
  GrantPermitWithConditions = 30,
  PermitRefused = 31,
  DeferredCommencement = 32,
  Cancelled = 33,
}

export enum Application_DecisionType {
  Unspecified = "Unspecified", // 0
  Council = "Council", // 1
  Delegate = "Delegate", // 2
  Minister = "Minister", // 3
  Tribunal = "Tribunal", // 4
  CDAP = "CDAP", // 5
  DAC = "DAC", // 6
  ERD = "ERD", // 7
  PrivateCertifier = "PrivateCertifier", // 8
  DPC = "DPC", // 9
  DelegateManager = "DelegateManager", // 10
  PlanningCommittee = "PlanningCommittee", // 11
  ChiefExecutiveOfficer = "ChiefExecutiveOfficer", // 12
  PPC = "PPC", // 13
  BPC = "BPC", // 14
  CAP = "CAP", // 15
  SCAP = "SCAP", // 16
  ActingCEO = "ActingCEO", // 17
  CouncilStaff = "CouncilStaff", // 18
  Director = "Director", // 19
  Proponent = "Proponent", // 20
  SeniorStatutoryPlanner = "SeniorStatutoryPlanner", // 21
  Committee = "Committee", // 22
  DevelopmentControlUnit = "DevelopmentControlUnit", // 23
  JRPP = "JRPP", // 24
  LocalPlanningPanel = "LocalPlanningPanel", // 25
  LandandEnvironmentCourt = "LandandEnvironmentCourt", // 26
}
export enum ApplicationTypeFlags {
  Unspecified = "Unspecified", //0
  Flag_IsTownPlanning = "Flag_IsTownPlanning", //1 << 0
  Flag_IsBuilding = "Flag_IsBuilding", //1 << 1
  Flag_LandDivision = "Flag_LandDivision", //1 << 2
  Flag_IsPSA = "Flag_IsPSA", //1 << 3
  Flag_IsOA = "Flag_IsOA", //1 << 4
  Flag_IsPPR = "Flag_IsPPR", //1 << 5
  Flag_IsDevApps = "Flag_IsDevApps", //1 << 6
  Flag_IsPNF = "Flag_IsPNF", //1 << 7
  Flag_IsLandManagement = "Flag_IsLandManagement", //1 << 8
}
export enum PPRApplicationUpdateTriggers {
  UpdateTeamLeader = "UpdateTeamLeader",
  UpdateVROfficer = "UpdateVROfficer",
  UpdateVROfficeRegion = "UpdateVROfficeRegion",
}
export enum ApplicationUpdateTriggers {
  UpdateSiteAddress,
  UpdateApplicant,
  UpdatePreferredContact,
  UpdateBuilder,
  UpdatePrivateSurveyor,
  UpdateApplicationCategory,
  UpdateFeeSchedule,
  UpdateBuildingDetails,
  UpdateAssessmentPlanner,
  UpdateApplicationDescription,
  TargetDateExtended,
  PermitNotRequired,
  PermitComplete,
  IssueCertificateOfCompletion,
  FeesPaidWorkflow,
  StartStatClock,
  UpdatePlumber,
  UpdatePlumbingDetails,
  UpdatePractitioner,
  UpdatePPRDetails,
  UpdateTeamLeader,
  UpdateVROfficeRegion,
  UpdateSubmitWorkFlowFee,
  UpdateBuildingReportingAuthorities,
  UpdateAdditionalAddresses,
  UpdateBuildingWorksView,
  UpdateLevyPayer,
  UpdatePrescribeEvents,
  UpdatePropertyOwners,
  UpdateAssessmentDetails,
  UpdateAttachments,
  UpdateWithDrawalToNSWEPortal,
  UpdateIssueToNSWEPortal,
  UpdateRefuseToNSWEPortal,
  UpdatePNFDetails,
  UpdateAssessmentDueDiligenceTabDetails,
  UpdateAssessmentDueDiligenceTabTSData,
  UpdateLots,
  UpdateTSData,
  UpdateLMDetails,
  UpdateAHIMS,
  UpdateDueDiligence,
  LodgeClick,
  ValidateClick,
  AdvertiseClick,
  AdvertiseNotReqClick,
  UpdateLEPData,
  OfficerReallocated,
}
export enum FeeUpdateTriggers {
  UpdateFee = "UpdateFee",
  AdjustFee = "AdjustFee",
  CancelFee = "CancelFee",
}
export enum ApplicationAssessmentAdditionalDetailsUpdateTriggers {
  UpdatePayer = "UpdatePayer",
  UpdateBillingAddress = "UpdateBillingAddress",
}
export enum IntegratedDevelopmentType {
  Unspecified = "Unspecified", // 0
  CoalMineSubsidenceCompensationAct2017 = "CoalMineSubsidenceCompensationAct2017", // 1
  FisheriesManagementAct1994 = "FisheriesManagementAct1994", // 2
  HeritageAct1977 = "HeritageAct1977", // 3
  MiningAct1992 = "MiningAct1992", // 4
  NationalParksAndWildlifeAct1974 = "NationalParksAndWildlifeAct1974", // 5
  PetroleumOnshoreAct1991 = "PetroleumOnshoreAct1991", // 6
  ProtectionOfTheEnvironmentOperationsAct1997 = "ProtectionOfTheEnvironmentOperationsAct1997", // 7
  RoadsAct1993 = "RoadsAct1993", // 8
  RuralFiresAct1997 = "RuralFiresAct1997", // 9
  WaterManagementAct2000 = "WaterManagementAct2000", // 10
}
export enum YESNONA {
  SystemInitialise = 0,
  Yes = 1,
  No = 2,
  NA = 3,
}
export enum PSA_ApplicantType {
  Unspecified = 0,
  Council = 1,
  Private = 2,
  Councillor = 3,
  Advisory_Committee = 4,
  Minister = 5,
  EES = 6,
  LGA = 7,
}
export enum WithdrawnApplication_Status {
  Unspecified = 0,
  ByApplicant = 1,
  RefusedByMinister = 2,
  AbandonedbyCouncil = 3,
  MinisterTakenOver = 4,
}
export enum Building_ApplicationType {
  Unspecified = 0,
  Private = 1,
  Council = 2,
  Plumber = 3,
}
export enum CDCType {
  Unspecified = 0,
  PrivateSurveyor = 1,
  Council = 2,
}
export enum PlumbingClass {
  Unspecified = 0,
  Class1 = 1,
  Class10 = 2,
  Class1And10 = 3,
  Class2To9 = 4,
  MultiUnit = 5,
}

export enum RECORDSSYSTEM {
  None,
  TRIMContext,
  infoXpert,
  ECM,
  BluePointContentPlus,
  Objective,
  ECMCMIS,
  SharePoint,
  InternalFileSystem,
  Fileit,
}

export enum PROPERTYSYSTEM {
  None,
  Lynx,
  PropertyGov,
  Authority,
  Pathway,
  Proclaim,
  Custom,
}

export enum TRIMSTORAGEMETHOD {
  SELECT,
  CREATE,
  NONE,
  PROPERTYNUMBER,
}
export interface Address_BuildAddress {
  Address: Address;
  BubbleUps?: BubbleUpIdentifier[];
  ZoneList?: IKeyValuePacket[];
  Zones_Display?: string;
  OverlayList?: IKeyValuePacket[];
  Overlay_Display?: string;
  Filters?: Address_Filters;
  Flag_ForceUse_StreetType?: boolean | null;
  bAddressChanged?: boolean;
  Module?: string;
  Layer?: string;
  AddressNominated?: AddressNominated;
}

export interface Address_Filters {
  ClassificationFilters: AddressClassification[];
  bAllowPickAddressType: boolean;
}

export enum AddressNominated {
  FirstAddress,
  SecondAddress,
  OtherAddress,
}

export enum ContactRelationshipType {
  SystemInitialise = 0,
  AdditionalContact = 1,
  Officer = 2,
  RecordedBy = 3,
  AssessedBy = 4,
  Previous_AdditionalContact = 5,
  PrivateCertifier = 198,
  Builder = 199,
  PrimaryContact = 200,
  InChargeContact = 202,
  AdditionalContact_Supervisor = 203,
  AdditionalContact_Manager = 204,
  AdditionalContact_RegionManager = 205,
  AdditionalContact_Secretary = 206,
  AdditionalContact_EventCoordinator = 207,
  AdditionalContact_ManagementCompany = 208,
  AdditionalContact_President = 209,
  AdditionalContact_PreviousPrimaryContact = 210,
  Other = 211,
  AdditionalContact_Director = 212,
  AdditionalContact_PublicOfficer = 213,
  Applicant = 224,
  Owner = 226,
  Plumber = 230,
  Contractor = 231,
  Operator = 232,
  AdditionalContact_Auditor = 233,
  Complainant = 234,
  Prosecution_Defendant = 235,
  Prosecution_DefendantLegalRep = 236,
  Prosecution_Informant = 237,
  Prosecution_InformantLegalRep = 238,
  Respondant = 239,
  Partner = 240,
  LicenceHolder = 241,
  AdditonalContact_AuthorisedContact = 242,
  AdditonalContact_NotificationRecipient = 243,
  AdditonalContact_Client = 244,
  AdditonalContact_ClientContact = 245,
  Appellant = 246,
  AppellantRepresentation = 247,
  Witness = 248,
  AdditionalContact_PropertyOwner = 249,
  AdditionalContact_Landlord = 250,
  AdditionalContact_EstateAgent = 251,
  AdditionalContact_InterestedParty = 252,
  AdditionalContact_Conveyancer = 253,
  AdditionalContact_Tenant = 254,
  AdditionalContact_Surveyor = 255,
  AdditionalContact_Builder = 256,
  AdditionalContact_Solicitor = 257,
  AdditionalContact_Draftsman = 258,
  AdditionalContact_Practitioner = 259,
  AdditionalContact_Architect = 260,
  AdditionalContact_Accountant = 261,
  AdditionalContact_Engineer = 262,
  Appointment_AppointmentWith = 263,
  AdditionalContact_PrivateCertifier = 264,
  AdditionalContact_AdditionalApplicant = 265,
  AdditionalContact_PreviousApplicant = 266,
  AdditionalContact_PreferredContact = 267,
  AdditionalContact_ReferralAuthority = 268,
  ReferralAuthority = 269,
  EnforcementAgency = 270,
  Manufacturer = 271,
  ReferredCouncil = 272,
  Laboratory = 273,
  AdditionalContact_BusinessOwner = 274,
  LGA = 275,
  Distributor = 276,
  Patient = 277,
  Supplier = 278,
  Pharmacist = 279,
  Business = 280,
  BusinessOwner = 281,
  AdditionalContact_Witness = 282,
  CouncilRepresentation = 283,
  ApplicantRepresentation = 284,
  EmergencyContact = 285,
  EmergencyContact2 = 286,
  AdditionalContact_PreviousPharmacist = 287,
  AdditionalContact_PreviousLicenceHolder = 288,
  AdditionalContact_PropertyManager = 289,
  Installer = 290,
  TeamLeader = 291,
  CEO = 292,
  LevyPayer = 293,
  AdditionalContact_NominatedLevyPayer = 294,
  Interviewee = 295,
  Supervisor = 296,
  AdditionalContact_PreviousSupervisor = 297,
  Researcher = 298,
  AdditionalContact_PreviousResearcher = 299,
  CRS_ParentGuardian1 = 300,
  CRS_ParentGuardian2 = 301,
  CRS_Child = 302,
  CRS_Contact1 = 303,
  CRS_Contact2 = 304,
  CRS_ManagementContact1 = 305,
  CRS_ManagementContact2 = 306,
  CRS_President = 307,
  CRS_Secretary = 308,
  CRS_Treasurer = 309,
  CRS_PublicOfficer = 310,
  CRS_EmergencyFirstContact = 311,
  CRS_EmergencySecondContact = 312,
  CRS_Carer = 313,
  CRS_FamilyFriend = 314,
  CRS_RegistrationContact = 315,
  CRS_Relative = 316,
  CRS_Centre_Cleaner = 317,
  CRS_Centre_Janitor = 318,
  CRS_Centre_Security = 319,
  CRS_Centre_CentreContact = 320,
  CRS_AdditionalContactParentGuardian = 321,
  CRS_AdditionalEmergencyContact = 322,
  Consultant = 323,
  VIP = 324,
  AdditionalContact_SubmitterObjecting = 325,
  AdditionalContact_SubmitterSupporting = 326,
  AdditionalContact_SubmitterSupportingWithChanges = 327,
  AdditionalContact_SubmitterWithdrawn = 328,
  AdditionalContact_SystemOwner = 329,
  AdditionalContact_SystemManager = 330,
  AdditionalContact_BuildingOwner = 331,
  AdditionalContact_BuildingManager = 332,
  AlternativeContact = 333,
  AdditionalContact_PreviousAlternativeContact = 334,
  AdditionalContact_PreviousOwner = 335,
  CouncilConsent_Surveyor = 336,
  ApplicantManager = 337,
  SRU_AdditionalFundedContact = 338,
  SRU_AdditionalEnforcementContact = 339,
  SRU_IRBPrimaryContact = 340,
  AdditionalContact_CertifyingAuthority = 341,
  AdditionalContact_PreviousPartner = 342,
  AdditionalContact_AfterHoursContact = 343,
  CEM_AdditionalRegisterationContact = 344,
  InvestigationSubject = 345,
  InvestigationServiceProvider = 346,
  VCATBoardRepresentative = 347,
  ActionAgainst = 348,
  AdditionalContact_Payee = 349,
  Manager = 350,
  AdditionalContact_PreviousManager = 351,
  PSC = 352,
  AdditionalContact_PreviousDirector = 353,
  Register_PreferredContact = 354,
  AdditionalContact_PrivateInspector = 355,
  AdditionalAssessor = 356,
  AsessmentWorker = 357,
  Assessor = 358,
  NominatedPractionerPsychologist = 359,
  Leasee = 360,
  Subcontractor = 361,
  AdditionalContact_Designer = 362,
  WASTEWATER_OWNER = 600,
  HM_Proprietor = 700,
  HM_Proprietor2 = 701,
  HM_Proprietor3 = 702,
  HM_Proprietor4 = 703,
  HM_AdditionalContact_PreviousProprietor = 710,
  HM_AdditionalContact_FSSSecondary = 712,
  HM_AdditionalContact_FSSPrevious = 713,
  HM_AdditionalContact_InfectionControlOfficer = 714,
  HM_AdditionalContact_PropertyOwner = 715,
  HM_AdditionalContact_RecallContact = 716,
  HM_Inspection_PersonContacted = 717,
  HM_AdditionalContact_Supervisor = 718,
  HM_AdditionalContact_InChargeContact = 719,
  HM_AdditionalContact_AlternateContact = 720,
  HM_FSSContact = 721,
  HM_AdditionalContact_FSSContactPrevious = 722,
  HM_Inspection_TobaccoBuyer = 723,
  EPLAN_Applicant = 1000,
  EPLAN_PreferredContact = 1001,
  EPLAN_Objector = 1002,
  CUSTOMERSERVICE_RequestedBy = 2001,
  CUSTOMERSERVICE_OnBehalfOf = 2002,
  CUSTOMERSERVICE_CEO = 2003,
  CUSTOMERSERVICE_ActionOfficer = 2004,
  CUSTOMERSERVICE_Coordinator = 2005,
  CUSTOMERSERVICE_OwnerAnimalAttack = 2006,
  CUSTOMERSERVICE_ColdChain_BreachReportedBy = 2010,
  CUSTOMERSERVICE_Councillor = 2011,
  Complainee = 2012,
  PreviousComplainee = 2013,
  Provider = 2014,
  PreviousProvider = 2015,
  AdditionalWorker = 2016,
  AdditionalProvider = 2017,
  RequestorProvider = 2018,
  PreviousRequestorProvider = 2019,
  MCH_ResponsiblePerson = 3001,
  MCH_PrimaryContact = 3002,
  MCH_CentreContact1 = 3003,
  MCH_CentreContact2 = 3004,
  PoundRegister_ImpoundedByExternal = 3101,
  AdditionalContact_PreviousImpoundedByExternal = 3102,
  Animals_Delegate = 3103,
  Animals_AdditionalContact_KennelOwner = 3104,
  DisRes_PermitHolder = 4001,
  DisRes_PermitAddressCareOf = 4002,
  DisRes_PermitHolderContactPerson = 4003,
  DisRes_PostalAddressCareOf = 4004,
  AMS_AppointmentWith = 6001,
  EMS_RequestedBy = 6501,
  EMS_Debtor = 6502,
  EMS_Organiser = 6503,
  EMS_Trainer = 6504,
  EMS_AdditionalContact = 6505,
  EMS_PreviousRequestedBy = 6506,
  TM_CourseProvider = 7001,
  TM_CourseTrainer = 7002,
  TM_Attendees = 7003,
  TM_WaitingList = 7004,
  TM_TrainingRequest = 7005,
  RAD_Applicant = 7501,
  RAD_Owner = 7502,
  RAD_Occupier = 7503,
  RAD_AuthorisedContact = 7504,
  SSMS_CouncilEngineer = 8001,
  SSMS_CouncilLeader = 8002,
  SSMS_TrafficEngineer = 8003,
  SSMS_TeamLeader = 8004,
  SSMS_InspectionRequestedBy = 8005,
  SSMS_InspectedBy = 8006,
  SSMS_NOCReceivedBy = 8007,
  SSMS_MOCCreatedBy = 8008,
  SSMS_MOCSentBy = 8009,
  SSMS_MOCCancelledBy = 8010,
  SSMS_InstalledBy = 8011,
  LLP_Supervisor = 8501,
  LLP_PreferredContact = 8502,
  LLP_AdditionalContact1 = 8503,
  LLP_AdditionalContact2 = 8504,
  SRU_SeizureContact = 9001,
  SRU_Administrator = 9002,
  SRU_ReportableTransaction_Contact = 9003,
  SRU_IncidentContact = 9004,
  SRU_FacilityPSC = 9005,
  SRU_AdditionalContact_PreviousFacilityPSC = 9006,
  SRU_FacilityLandlord = 9007,
  SRU_AdditionalContact_PreviousFacilityLandlord = 9008,
  SRU_LegalPersonalRepresentative = 9009,
  SRU_ComplaintsNominee = 9010,
  SRU_AdditionalContact_PreviousComplaintsNominee = 9011,
  SRU_AdditionalContact_PreviousLegalPersonalRepresentative = 9012,
  SRU_Previous_Complainant = 9013,
  DWCMS_Applicant = 9501,
  DWCMS_ServiceProvider = 9502,
  DWCMS_EmploymentProvider = 9503,
  DWCMS_PreviousApplicant = 9504,
  DWCMS_RegistrationApplicant = 9505,
  TP_ApplicationPayer = 10001,
  PNF_Owners = 10002,
  PNF_Contractor = 10003,
  PNF_Logger = 10004,
  PNF_Sawmill = 10005,
  PNF_LGACouncil = 10006,
  PNF_Consultant = 10007,
  PNF_PropertyManager = 10008,
  PNF_HolderOfTimberRights = 10011,
  PNF_PowerOfAttorney = 10012,
  PNF_Other = 10013,
  PNF_Deceased = 10014,
  PNF_PriorOwner = 10015,
  LM_PrimaryOrganisation = 10016,
  LM_ProjectSponsor = 10017,
  LM_EventManager = 10018,
  LM_ProjectManager = 10019,
  LM_RegionalServicesOfficer = 10020,
  LM_ProjectMember = 10021,
  LM_LandManager = 10022,
  CEM_FuneralDirector = 10501,
  CEM_NextofKin = 10502,
  Infringement_Offender = 10051,
  FLEET_RequestedBy = 11001,
  FLEET_Debtor = 11002,
  FLEET_Organiser = 11003,
  FLEET_Trainer = 11004,
  FLEET_AdditionalContact = 11005,
  FLEET_PreviousRequestedBy = 11006,
  CSM_AuthorisedContact = 11051,
  CSM_Shareholder = 11052,
  CSM_Trustbeneficiary = 11053,
  CSM_Owner = 11054,
  CSM_AdditionalContact_Researcher = 11055,
  CSM_AdditionalContact_Dispensary_Contact = 11056,
}
export interface Region extends DTOBaseClass_Standard {
  region_ID: number;
  region_Name: string;
  suburb: string;
  council_ID: number;
  council_Name: string;
  postCode: string;
  state: string;
  state_Code: string;
  coordinator_ID: number | null;
  coordinator: SiteUser;
}

export interface PPRApplicationIdentityPacket extends IdentityPacket {
  Application: Application;
}

export class ApplicationBuildingDetails {
  BuildingDetails_ID: number = 0;
  Application_ID: number = 0;
  Building_BSNumber: string = "";
  Building_StageNumber: string = "";
  BuilderRegNo: string = "";
  PlanningPermitNo: string = "";
  PlanningPermitID: number | null = 0;
  Date_PlanningIssued: Date | null = null;
  PlumbingAppNo: string = "";
  PlumbingAppID: number | null = 0;
  Date_PlumbingAppIssued: Date | null = null;
  InspectionsRequired: InspectionsRequired[] = [];
  InspectionsRequired_Display: string = "";
  Building_UseOfBuildings: UseOfBuilding[] = [];
  Building_UseOfBuildings_Display: string = "";
  Building_UseOfBuildings_Display_FreeText: string = "";
  Building_BCA: BCA[] = [];
  Building_BCAs_Display: string = "";
  Building_SecondaryBCA: BCA[] = [];
  Building_SecondaryBCAs_Display: string = "";
  Building_BCAVersion: BCAVersion[] = [];
  Building_BCAVersions_Display: string = "";
  OwnerBuilder: boolean | null = null;
  ComplianceInspections: boolean | null = null;
  BuildingConsent: boolean | null = null;
  Building_OwnerBuildingNumber: string = "";
  Building_AllotmentArea: number | null = 0;
  Building_TotalFloorArea: number | null = 0;
  Building_GarageArea: number | null = 0;
  Building_VerandahArea: number | null = 0;
  Building_DwellingLivingArea: number | null = 0;
  Building_AlfrescoEntertainmentArea: number | null = 0;
  Building_OtherAreas: number | null = 0;
  Building_DwellingExtArea: number | null = 0;
  Building_ShedArea: number | null = 0;
  Building_BalconyArea: number | null = 0;
  Building_Storeys: number | null = 0;
  Building_FloorLoad: number | null = 0;
  Building_PermitIssued: Date | null = null;
  Building_WorkCommencing: Date | null = null;
  Building_WorkCommenced: Date | null = null;
  Building_WorkDueToComplete: Date | null = null;
  Building_WorkCompleted: Date | null = null;
  Building_InsuranceCompany_KWD: number | null = 0;
  Building_InsuranceCompany_Name: string = "";
  Building_PolicyNumber: string = "";
  Building_ExtWallMaterial_KWD: number | null = 0;
  Building_ExtWallMaterial_Name: string = "";
  Building_RoofCladdingMaterial_KWD: number | null = 0;
  Building_RoofCladdingMaterial_Name: string = "";
  Building_FrameMaterial_KWD: number | null = 0;
  Building_FrameMaterial_Name: string = "";
  Building_FloorMaterial_KWD: number | null = 0;
  Building_FloorMaterial_Name: string = "";
  Building_ProjectID: string = "";
  Building_Demolishing_ENUM: GENERIC_YESNO | null = null;
  Building_Demolishing_Name: string = "";
  Building_DemolishedDwellings: number | null = 0;
  Building_ExistingDwellings: number | null = 0;
  Building_NewDwellings: number | null = 0;
  Building_SubjectToTermites_ENUM: GENERIC_YESNO | null = null;
  Building_SubjectToTermites_Name: string = "";
  Building_BAL_KWD: number | null = 0;
  Building_BAL_Name: string = "";
  Building_EnergyRating_KWD: number | null = 0;
  Building_EnergyRating_Name: string = "";
  Building_SubjectToFlooding_ENUM: GENERIC_YESNO | null = null;
  Building_SubjectToFlooding_Name: string = "";
  Building_SpecFloodLevel: string = "";
  Building_SubjectToSnowfall_ENUM: GENERIC_YESNO | null = null;
  Building_SubjectToSnowfall_Name: string = "";
  Building_SolarHotWater_ENUM: GENERIC_YESNO | null = null;
  Building_SolarHotWater_Name: string = "";
  Building_RainWaterTank_ENUM: GENERIC_YESNO | null = null;
  Building_RainWaterTank_Name: string = "";
  Building_LandType_KWD: number | null = 0;
  Building_LandType_Name: string = "";
  Building_LandOwnership_KWD: number | null = 0;
  Building_LandOwnership_Name: string = "";
  Building_EstimateCostOfProject: number | null = 0;
  Building_Determination_ENUM: GENERIC_YESNO | null = null;
  Building_Determination_Name: string = "";
  Building_EstimateCostOfDomesticWork: number | null = 0;
  Building_ApplicantRequiresFinalCertificate_ENUM: Application_ApplicantRequiresFinalCertificate | null =
    null;
  Building_ApplicantRequiresFinalCertificate_Name: string = "";
  Building_TotalApplicantRequiresFinalCertificate: number | null = 0;
  Building_FinalCertificateInspectionDate_1: Date | null = null;
  Building_FinalCertificateNumber_1: string = "";
  Building_FinalCertificatePermitType_1_ENUM: Application_FinalCertificatePermitType | null =
    null;
  Building_FinalCertificatePermitType_1_Name: string = "";
  Building_FinalCertificateInspectionDate_2: Date | null = null;
  Building_FinalCertificateNumber_2: string = "";
  Building_FinalCertificatePermitType_2_ENUM: Application_FinalCertificatePermitType | null =
    null;
  Building_FinalCertificatePermitType_2_Name: string = "";
  Building_FinalCertificateInspectionDate_3: Date | null = null;
  Building_FinalCertificateNumber_3: string = "";
  Building_FinalCertificatePermitType_3_ENUM: Application_FinalCertificatePermitType | null =
    null;
  Building_FinalCertificatePermitType_3_Name: string = "";
  Building_FinalCertificateInspectionDate_4: Date | null = null;
  Building_FinalCertificateNumber_4: string = "";
  Building_FinalCertificatePermitType_4_ENUM: Application_FinalCertificatePermitType | null =
    null;
  Building_FinalCertificatePermitType_4_Name: string = "";
  Building_FinalCertificateInspectionDate_5: Date | null = null;
  Building_FinalCertificateNumber_5: string = "";
  Building_FinalCertificatePermitType_5_ENUM: Application_FinalCertificatePermitType | null =
    null;
  Building_FinalCertificatePermitType_5_Name: string = "";
  Building_FinalCertificateInspectionDate_6: Date | null = null;
  Building_FinalCertificateNumber_6: string = "";
  Building_FinalCertificatePermitType_6_ENUM: Application_FinalCertificatePermitType | null =
    null;
  Building_FinalCertificatePermitType_6_Name: string = "";
  Building_FinalCertificateInspectionDate_7: Date | null = null;
  Building_FinalCertificateNumber_7: string = "";
  Building_FinalCertificatePermitType_7_ENUM: Application_FinalCertificatePermitType | null =
    null;
  Building_FinalCertificatePermitType_7_Name: string = "";
  Building_FinalCertificateInspectionDate_8: Date | null = null;
  Building_FinalCertificateNumber_8: string = "";
  Building_FinalCertificatePermitType_8_ENUM: Application_FinalCertificatePermitType | null =
    null;
  Building_FinalCertificatePermitType_8_Name: string = "";
  Building_FinalCertificateInspectionDate_9: Date | null = null;
  Building_FinalCertificateNumber_9: string = "";
  Building_FinalCertificatePermitType_9_ENUM: Application_FinalCertificatePermitType | null =
    null;
  Building_FinalCertificatePermitType_9_Name: string = "";
  Building_FinalCertificateInspectionDate_10: Date | null = null;
  Building_FinalCertificateNumber_10: string = "";
  Building_FinalCertificatePermitType_10_ENUM: Application_FinalCertificatePermitType | null =
    null;
  Building_FinalCertificatePermitType_10_Name: string = "";
  Building_CertificateOfDesignDate: Date | null = null;
  Building_CertificateOfDesignNo: string = "";
  Building_CLCDate: Date | null = null;
  CLCNumber: string = "";
  Building_EnforcementDate: Date | null = null;
  Flag_Section80: boolean = false;
  Flag_HistoricalSite: boolean = false;
  Date_Abandoned: Date | null = null;
  Date_EssentialServicesCert: Date | null = null;
  Date_Section80Received: Date | null = null;
  HistoricFileNumber: string = "";
  Flag_CILRequired: boolean | null = null;
  Flag_CILPaid: boolean | null = null;
  CIL_FeeType: string = "";
  CIL_Amount: number | null = 0;
  CIL_ReceiptNumber: string = "";
  CIL_ReceiptDate: Date | null = null;
  CIL_Letter1: string = "";
  CIL_Letter2: string = "";
  CIL_Letter3: string = "";
  CIL_Date_Letter1: Date | null = null;
  CIL_Date_Letter2: Date | null = null;
  CIL_Date_Letter3: Date | null = null;
  Building_Type: ApplicationBuildingDetails_Type | null = null;
  IsFireRelatedPerformanceSolution: boolean | null = null;
  FireEngineerRegistration: string = "";
  FireEngineerName: string = "";
  ReportingAuthorities: ReportingAuthority[] = [];
  ReportingAuthorities_Display: string = "";
  ReportingAuthorities_KWD: number | null = 0;
  ConsentedTo: string = "";
  RegulationNo: string = "";
  NextInspectionDate: Date | null = null;
  Building_PolicyCovered: number = 0;
  Building_RiseInstories: number | null = 0;
  Building_EffectiveHeight: string = "";
  Building_TypeOfConstruction_KWD: number | null = 0;
  Building_TypeOfConstruction_Name: string = "";
  Building_WholePart_KWD: number | null = 0;
  Building_WholePart_Name: string = "";
  Building_SpecificWork: SpecificBuildingWork[] = [];
  Building_SpecificWork_Display: string = "";
  Building_BuildingWorks_Display: BuildingWorksView[] = [];
  Flag_StageIndicator: boolean | null = null;
  BuildingStageDescription_KWD: number | null = 0;
  BuildingStageDescription_KWD_Name: string = "";
  Flag_ProtectionWorkRequired: boolean | null = null;
  Flag_CombinedAllotment: boolean | null = null;
  Flag_PerformanceSolution_PartBGHIJ: boolean | null = null;
  Flag_PerformanceSolution_PartC: boolean | null = null;
  Flag_PerformanceSolution_PartD: boolean | null = null;
  Flag_PerformanceSolution_PartE: boolean | null = null;
  Flag_PerformanceSolution_PartF: boolean | null = null;
  Flag_Regulation268ExemptionApply: boolean | null = null;
  Flag_CertificateOfConsentRequired: boolean | null = null;
  ConsentNo: string = "";
  Notes_PerformanceSolution_PartBGHIJ: string = "";
  Notes_PerformanceSolution_PartC: string = "";
  Notes_PerformanceSolution_PartD: string = "";
  Notes_PerformanceSolution_PartE: string = "";
  Notes_PerformanceSolution_PartF: string = "";
  Building_SubjectToBushFire_ENUM: GENERIC_YESNO | null = null;
  Building_SubjectToBushFire_NAME: string = "";
  Building_SoilClassification_KWD: number | null = 0;
  Building_SoilClassification_NAME: string = "";
  Building_BuildingAct: string = "";
  OPLocation: string = "";
  MaxNoOfOccupants: number | null = 0;
  AssessmentNotes: string = "";
  BuildingPermitNo: string = "";
  BuildingPermitID: number | null = 0;
  Flag_PerformanceSolution: boolean | null = null;
  PerformanceSolutionDetails: string = "";
  MaintainableFeatureDetails: string = "";
  CertificateDetails: string = "";

  constructor() {
    this.Building_TotalFloorArea = 0;
    this.Building_BCA = [];
    this.ReportingAuthorities = [];
    this.Building_BCAVersion = [];
    this.Building_SpecificWork = [];
    this.Building_BuildingWorks_Display = [];
    this.Building_UseOfBuildings = [];
  }
}

export interface pprNewApplicationParams {
  [key: string]: any;
  parentId?: number;
  uiTrigger?: UITownPlanning;
}

export interface IPPRApplicationParentSection {
  notification?: IAppNotificationItemAddProps[];
  registerData?: Register;
  data?: Application;
  isAmendPermit?: boolean;
  isPlanToComply?: boolean;
}
