import { Ancestor } from "@app/core/further-info/[id]/model";
import { useInspectionsStore } from "@app/core/inspections/[id]/store";
import { getRefNoParent } from "@app/core/inspections/[id]/util";
import { RatingStar } from "@app/products/hm/components/rating-star/_index";
import { BubbleUpIdentifier } from "@app/products/waste-water/[id]/model";
import { DATE_FORMAT } from "@common/constants/common-format";
import { RECORDTYPE } from "@common/constants/recordtype";
import { mapEnum } from "@common/utils/common";
import { formatDisplayValue } from "@common/utils/formatting";
import { getPageLinkByRecordType } from "@common/utils/getPageLinkByRecordType";
import { CCLoadFailed } from "@components/cc-load-failed/_index";
import { useCCProductListViewStore } from "@components/cc-product-list-view/store";
import Loading from "@components/loading/Loading";
import { NoData } from "@components/no-data/NoData";
import {
  ProductReferenceBlock,
  ProductReferenceBody,
} from "@components/reference-sidebar/ProductReferenceBody";
import { ProductReferenceHeading } from "@components/reference-sidebar/ProductReferenceHeading";
import { ProductReferenceRow } from "@components/reference-sidebar/ProductReferenceRow";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const getBubbleUpValue = (
  bubbleUpObj: BubbleUpIdentifier,
  ancestor: Ancestor | undefined
) => {
  return (
    <Link
      className="cc-detail-tab-link"
      to={{
        pathname: getPageLinkByRecordType(
          mapEnum(
            bubbleUpObj.SourceIdentifier?._RecordSourceType_ENUM,
            RECORDTYPE
          ),
          bubbleUpObj.SourceIdentifier?._RecordSource_ID
        ),
      }}
    >
      {getRefNoParent(ancestor)}
    </Link>
  );
};

interface IInspectionDetailsTabProps {
  // Get the data by this field
  fieldName?: string;
}

export const InspectionDetailsTab = observer(
  ({ fieldName }: IInspectionDetailsTabProps) => {
    const { lastSelectedId, lastSelectedRow } = useCCProductListViewStore();
    const {
      loadInspectionsDetails,
      isLoadingDetails,
      inspections,
      parentBubbleUpDetails,
      ancestor,
      responseLoadError,
    } = useInspectionsStore();

    const params: { id: string } = useParams();
    const inspectionId =
      fieldName &&
      !isNil(lastSelectedRow?.[fieldName]) &&
      lastSelectedRow[fieldName] !== ""
        ? lastSelectedRow[fieldName]
        : params.id ?? lastSelectedId;

    const isCRMS =
      mapEnum(
        parentBubbleUpDetails?.SourceIdentifier?._RecordSourceType_ENUM,
        RECORDTYPE
      ) === RECORDTYPE.CUSTOMERSERVICE_Event;
    useEffect(() => {
      if (inspectionId) {
        loadInspectionsDetails(parseInt(inspectionId));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inspectionId]);

    if (isLoadingDetails) return <Loading isLoading={isLoadingDetails} />;
    if (responseLoadError) {
      return (
        <CCLoadFailed
          responseError={responseLoadError}
          onReload={() => {
            inspectionId && loadInspectionsDetails(+inspectionId);
          }}
        />
      );
    }
    if (!inspections) return <NoData />;
    return (
      <ProductReferenceBody>
        <ProductReferenceHeading
          title={"Reference number:"}
          value={inspections.Inspection_ID.toString()}
          primary
        />
        <ProductReferenceBlock>
          {inspections.AssessmentRating ? (
            <ProductReferenceRow
              customValue={
                <div className="cc-product-ref-row">
                  <div className="cc-product-ref-title">Rating:</div>
                  <RatingStar numberOfStar={inspections.AssessmentRating} />
                </div>
              }
            />
          ) : null}
          {inspections.DateInspected ? (
            <ProductReferenceRow
              title={"Date inspected:"}
              value={formatDisplayValue(
                inspections.DateInspected,
                DATE_FORMAT.DATE
              )}
            />
          ) : null}
          {inspections.DateCompleted ? (
            <ProductReferenceRow
              title={"Date completed:"}
              value={formatDisplayValue(
                inspections.DateCompleted,
                DATE_FORMAT.DATE
              )}
            />
          ) : null}
          <ProductReferenceRow
            title={"Recorded by:"}
            value={inspections.Sys_CreatedBy}
          />
          <ProductReferenceRow
            title={"Recorded on:"}
            value={formatDisplayValue(
              inspections.Sys_CreatedDate,
              DATE_FORMAT.DATE
            )}
          />
        </ProductReferenceBlock>
        <hr />
        <ProductReferenceBlock>
          {parentBubbleUpDetails ? (
            <ProductReferenceRow
              title={isCRMS ? "Event:" : "Parent:"}
              value={getBubbleUpValue(parentBubbleUpDetails, ancestor)}
            />
          ) : null}
          {ancestor ? (
            <ProductReferenceRow
              title={`${isCRMS ? "Event" : "Parent"} description:`}
              value={ancestor?.litParentDescription}
            />
          ) : null}
        </ProductReferenceBlock>
      </ProductReferenceBody>
    );
  }
);
