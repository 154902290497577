import { useNoticeRunGroupStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/groups-statuses-and-types/store";
import { useNoticeRunAttributeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-run-attributes/store";
import { useNoticeRunNoticeTypeStepStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/components/form-elements/notice-type/store";
import { listConfigStepCreateNoticeRun } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/config";
import {
  DTO_LOV_NoticeType,
  IListConfigStepCreateNoticeRun,
} from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/model";
import { useNoticeRunStore } from "@app/products/property/charge-and-notice-runs/notice-runs/components/dialogs/new-notice-run/store";
import { isFieldVisible } from "@app/products/property/util";
import { nameOfFactory } from "@common/utils/common";
import { IFormStepElement } from "@components/cc-form-step/model";
import { CCGrid } from "@components/cc-grid/_index";
import { FieldArray } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { isNil } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { colNoticeType } from "./config";
const validator = (values: any) => {
  if (
    !values ||
    isNil(values?._option?.SelectedNoticeType) ||
    values?._option?.SelectedNoticeType?.length === 0
  ) {
    return "Must select one notice type";
  }
  return undefined;
};
const nameOfNoticeType = nameOfFactory<DTO_LOV_NoticeType>();

export const NoticeTypeFormStep = (props: IFormStepElement) => {
  return (
    <FieldArray
      name={props.nameOf()}
      {...props}
      component={FormStepElement}
      validator={!props?.options?.isReadOnly ? validator : undefined}
    />
  );
};
const FormStepElement = observer(
  ({
    formRenderProps,
    nameOf,
    setStepsVisible,
    resetAllStepsAfter,
    options = {
      isReadOnly: false,
    },
  }: IFormStepElement) => {
    //store
    const { noticeRunOriginLOVs } = useNoticeRunStore();
    const { noticeTypeLOVs, setDataSelectedNoticeType } =
      useNoticeRunNoticeTypeStepStore();
    const { setGroupLOVs, groupLOVs } = useNoticeRunGroupStepStore();
    const { setAttributeLOVs, attributeLOVs } =
      useNoticeRunAttributeStepStore();

    const { valueGetter, onChange, errors } = formRenderProps;
    const getFieldValue = (name: string) => valueGetter(nameOf(name));
    //get data List notice type
    const typeList = useMemo(() => {
      return noticeTypeLOVs?.NoticeTypes ?? [];
    }, [noticeTypeLOVs?.NoticeTypes]);

    const selectedNoticeType = getFieldValue("_option.SelectedNoticeType");

    //handle selection change in grid
    const handleSelectionType = async (dataItem: DTO_LOV_NoticeType[]) => {
      onChange(nameOf("_option.SelectedNoticeType"), {
        value: dataItem,
      });
      if (dataItem?.[0]) {
        onChange(nameOf("Notice_Type_Id"), {
          value: dataItem?.[0]?.Code,
        });
        setDataSelectedNoticeType(dataItem?.[0]);
        handleChangeType(dataItem?.[0]);
      }
    };

    const handleChangeType = (type: DTO_LOV_NoticeType) => {
      if (setStepsVisible) {
        setAttributeLOVs({
          ...attributeLOVs,
          RatingPeriods: noticeRunOriginLOVs?.RatingPeriods ?? [],
          FinancialGroups: noticeRunOriginLOVs?.FinancialGroups ?? [],
        });
        setGroupLOVs({
          ...groupLOVs,
          AssessmentGroups: noticeRunOriginLOVs?.AssessmentGroups ?? [],
        });
        const newStepReset = resetAllStepsAfter();
        const listVisibleStep = listConfigStepCreateNoticeRun.map(
          (step: IListConfigStepCreateNoticeRun) => ({
            key: step.keyNameStep,
            visible: isFieldVisible(type[step.flagVisibilityStep]),
            isClearData: true,
          })
        );
        setStepsVisible(listVisibleStep, newStepReset);
      }
    };

    return (
      <div className="cc-form">
        <div className="cc-field-group">
          <div className="cc-form-cols-1">
            <div className="cc-field">
              {options?.isReadOnly ? (
                <CCGrid
                  data={selectedNoticeType ?? []}
                  selectedRows={selectedNoticeType ?? []}
                  columnFields={colNoticeType}
                  primaryField={nameOfNoticeType("Code")}
                  readOnly
                />
              ) : (
                <>
                  <CCGrid
                    data={typeList ?? []}
                    selectedRows={selectedNoticeType ?? []}
                    columnFields={colNoticeType}
                    itemPerPage={20}
                    primaryField={nameOfNoticeType("Code")}
                    selectableMode="single"
                    isLimitDetailWidth
                    onSelectionChange={(dataItem) => {
                      handleSelectionType(dataItem);
                    }}
                  />
                  {errors?.[nameOf("")] ? (
                    <Error>{errors[nameOf("")]}</Error>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
