import { eventEmitter } from "@/App";
import { deleteRefUsageByIdAndRecordType } from "@app/core/delete/buttons/api";
import { IDeleteConfirmation } from "@app/core/delete/dialogs/model";
import { ConfirmDelete } from "@app/core/delete/dialogs/_index";
import { CCJournalEventType } from "@app/core/journal/util";
import { TabTableEventType } from "@app/core/tab-table/constant";
import { useTabTableStore } from "@app/core/tab-table/store";
import { colPPREnquiries } from "@app/products/town-planning/ppr/[id]/components/child-screens/enquiries/config";
import { IPPREnquiries } from "@app/products/town-planning/ppr/[id]/components/child-screens/enquiries/model";
import { usePPRStore } from "@app/products/town-planning/ppr/[id]/store";
import { isSuccessResponse } from "@common/apis/util";
import { RECORDTYPE } from "@common/constants/recordtype";
import { nameOfFactory } from "@common/utils/common";
import { IAppNotificationItem } from "@components/cc-app-notification/components/notification-item/model";
import { useCCAppNotificationStore } from "@components/cc-app-notification/store";
import { CCGridEventType } from "@components/cc-grid/constant";
import { CCGrid } from "@components/cc-grid/_index";
import { Button } from "@progress/kendo-react-buttons";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

interface IPPREnquiriesProps {
  recordType: RECORDTYPE;
}

const nameOf = nameOfFactory<IPPREnquiries>();
export const PPREnquiries = observer(({ recordType }: IPPREnquiriesProps) => {
  const { ppr } = usePPRStore();
  const { isDisabled } = useTabTableStore();
  const [gridSelectedRows, setGridSelectedRows] = useState<IPPREnquiries[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const { pushNotification, clearErrorNotification } =
    useCCAppNotificationStore();
  const [notifications, setNotifications] = useState<IAppNotificationItem[]>(
    []
  );

  const handleConfirmDelete = async (data: IDeleteConfirmation) => {
    setIsDeleting(true);
    const response = await deleteRefUsageByIdAndRecordType(
      gridSelectedRows?.map((item: IPPREnquiries) => item.ID),
      recordType,
      data?.Reason
    );
    setIsDeleting(false);
    if (isSuccessResponse(response)) {
      setShowConfirmDeleteDialog(false);
      clearErrorNotification();
      setGridSelectedRows([]);
      pushNotification({
        title: "Enquiry deleted successfully",
        type: "success",
      });
      eventEmitter.emit(CCGridEventType.RefreshOData);
      eventEmitter.emit(CCJournalEventType.RefreshData);
      eventEmitter.emit(TabTableEventType.RefreshData);
    } else {
      setNotifications([
        {
          id: "1",
          autoClose: false,
          title: "Deleted enquiry failed",
          type: "error",
          description: response.data?.Errors,
        },
      ]);
    }
  };

  return (
    <>
      <div className="cc-ppr-enquiries">
        <CCGrid
          toolbar={
            <div className="cc-grid-tools-bar">
              <Button
                type="button"
                iconClass="fas fa-minus"
                title="Remove"
                disabled={
                  isDisabled ? isDisabled : gridSelectedRows.length !== 1
                }
                onClick={() => {
                  setShowConfirmDeleteDialog(true);
                }}
              />
            </div>
          }
          selectableMode="multiple"
          dataUrl={`/odata/tp/ppr/internal/pprenquiries/Summary(applicationNumber='${ppr?.Sys_ReferenceNumber.replace(
            /\//g,
            "[SLASH]"
          )}')?$count=true&`}
          primaryField={nameOf("ID")}
          selectedRows={gridSelectedRows}
          state={{
            sort: [{ field: nameOf("Date_Lodged"), dir: "desc" }],
          }}
          onSelectionChange={(dataItem: IPPREnquiries[]) => {
            if (dataItem) setGridSelectedRows([...dataItem]);
          }}
          columnFields={colPPREnquiries}
        />
      </div>
      {showConfirmDeleteDialog && (
        <ConfirmDelete
          onClose={() => {
            setShowConfirmDeleteDialog(false);
          }}
          header={"Delete Confirmation"}
          contentDelete={"Enquiry"}
          handleSubmit={handleConfirmDelete}
          isDeleting={isDeleting}
          notifications={notifications}
        />
      )}
    </>
  );
});
